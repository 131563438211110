import PageSection from 'components/v2/PageSection/PageSection'

import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import IconView from 'components/v2/IconView/IconView'

import { targetAudienceSection } from './data'
import Card from 'components/Card/Card'

const TargetAudienceSection = () => {
  return (
    <PageSection variant='white-icons'>
      <Stack gap={40}>
        <Heading variant='2'>{targetAudienceSection.title}</Heading>
        <IconView cols={3} tabletCols={1} columnGap={20} rowGap={20}>
          {targetAudienceSection.items.map((item: any, index: number) => {
            return <Card key={index} bgColor='light-gray'>
              <Stack alignItems='center'>
                <Image
                  src={item.imageUrl}
                  width={100}
                  animate
                  animationVariant='zoom-in'
                  animateDelayIndex={index}
                />
                <Text variant='text-m-medium' color='green' align='center'>{item.name}</Text>
                <Text variant='text-s'>{item.description}</Text>
              </Stack>
            </Card>
          })}
        </IconView>
      </Stack>

    </PageSection>
  )
}

export default TargetAudienceSection
