import nbspacer from 'common/nbspacer'
import scrollToId from 'common/scroll-to-id'
import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'

type WhatIsTheResultSectionProps = {
  onSelectTariff?: (id: string) => void;
}

const WhatIsTheResultSection = (props: WhatIsTheResultSectionProps) => {
  const resultItems: string[] = [
    'Появится чёткое понимание профессиональных целей, получите ответы на карьерные запросы',
    'Получите подробный план и рекомендации, действенные инструменты для решения запроса'
  ]

  const consultationPrices: Array<{name: string, price: string}> = [
    {
      name: 'Тариф 60 минут',
      price: '9 000 руб.'
    },
    {
      name: 'Тариф 90 минут',
      price: '12 000 руб.'
    }
  ]

  const consultationTariffs: any[] = [
    {
      id: 'tariff_240',
      name: 'Тариф 240',
      description: '4 часа индивидуального карьерного консультирования',
      items: [
        'Оптимизация резюме',
        'Подготовка к собеседованиям',
        'Экспресс-консультации в течение 20 дней после завершения работы.'
      ],
      price: '30 200 руб.',
      benefit: '17 800 рублей'
    },
    {
      id: 'tariff_360',
      name: 'Тариф 360',
      description: '6 часов индивидуального карьерного консультирования',
      items: [
        'Оптимизация резюме',
        'Подготовка к собеседованиям',
        'Подбор материалов и рекомендаций по развитию',
        'Помощь в подборе подходящих вакансий',
        'Экспресс-консультации в течение 30 дней'
      ],
      price: '39 500 руб.',
      benefit: '32 500 рублей'
    },
    {
      id: 'tariff_480',
      name: 'Тариф 480',
      description: '8 часов индивидуального карьерного консультирования',
      items: [
        'Оптимизация резюме',
        'Подготовка к собеседованиям',
        'Подбор материалов и рекомендаций по развитию',
        'Помощь в подборе подходящих вакансий',
        'Онлайн-оценка личностных компетенций (по валидной методике) с предоставление паспорта компетенций.',
        'Экспресс-консультации в течение 40 дней'
      ],
      price: '54 500 руб.',
      benefit: '41 500 рублей'
    }
  ]

  return (
    <PageSection variant='gray'>
      <Stack gap={100}>
        {/* section 1 */}
        <Stack gap={30}>
          <Heading variant='2' color='blue-gray'>
            {nbspacer('Что в итоге')}
          </Heading>
          <Stack
            orientation='horizontal'
            tabletOrientation='vertical'
            gap={20}
            alignItems='center'
          >
            <Stack gap={40}>
              {resultItems.map((item: string) => {
                return (
                  <Stack
                    key={item}
                    orientation='horizontal'
                    gap={30}
                    alignItems='center'
                  >
                    <Image
                      src='/static/img/pages/career-management/what-is-the-result-section/check.svg'
                      width={68}
                      style={{
                        flexShrink: 0
                      }}
                    />
                    <Heading variant='3' color='blue-gray'>
                      {nbspacer(item)}
                    </Heading>
                  </Stack>
                )
              })}
            </Stack>
            <Image src='/static/img/pages/career-management/what-is-the-result-section/section-image1.svg' />
          </Stack>
          <Text variant='text-xl' color='emerald'>
            {nbspacer('Останется только решить, готовы ли идти самостоятельно или нужна поддержка в реализации разработанного плана')}
          </Text>
          <Card
            style={{
              padding: '30px 40px'
            }}
          >
            <Stack
              orientation='horizontal'
              tabletOrientation='vertical'
              gap={20}
            >
              <Stack
                width='40%'
                tabletWidth='100%'
              >
                <Image src='/static/img/pages/career-management/what-is-the-result-section/section-image2.svg' />
              </Stack>
              <Stack
                gap={20}
                width='60%'
                tabletWidth='100%'
              >
                <Heading variant='2' color='blue-gray'>
                  {nbspacer('Сколько стоит карьерная консультация?')}
                </Heading>
                <Stack>
                  {consultationPrices.map((item: {name: string, price: string}) => {
                    return (
                      <Stack
                        key={item.name}
                        orientation='horizontal'
                        gap={30}
                        alignItems='center'
                      >
                        <Text variant='text-m-medium' color='blue-gray'>{item.name}</Text>
                        <div
                          style={{
                            flexGrow: 1,
                            height: '1px',
                            background: '#3F5878'
                          }}
                        />
                        <Heading variant='2' color='emerald' align='left'>{item.price}</Heading>
                      </Stack>
                    )
                  })}
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Stack>

        {/* section 2 */}
        <Stack gap={30}>
          <Card>
            <Stack gap={30}>
              <Text
                variant='text-xl'
                color='blue-gray'
              >
                {nbspacer('Возможна ли длительная профессиональная поддержка карьерного консультанта?')}
              </Text>
              <Stack gap={20}>
                <Text variant='text-m-medium'>
                  {nbspacer('Каждая карьера индивидуальна, и зачастую недостаточно одной консультации.')}
                </Text>
                <Text variant='text-s'>
                  {nbspacer('Возможно, необходимо несколько встреч с экспертом, более детальная проработка путей профессионального развития. Во многих случаях нужна длительная профессиональная поддержка, которая требует значительно больше времени карьерного консультанта.')}
                </Text>
                <Text variant='text-s'>
                  {nbspacer('Тарифы "240", "360" и "480" минут позволят вам совместно с вашим индивидуальным консультантом определить наиболее эффективные инструменты для достижения целей поиска работы и развития карьеры. И получить долгосрочную профессиональную поддержку.')}
                </Text>
                <Text variant='text-m-medium'>
                  {nbspacer('Во время первой сессии с консультантом вы можете согласовать количество, частоту, продолжительность и темы последующего общения')}
                  <Text variant='text-m' color='emerald'>
                    {nbspacer('в течение 20 рабочих дней (тариф 240), либо 30 рабочих дней (тариф 360), либо 40 рабочих дней (тариф 480).')}
                  </Text>
                </Text>

              </Stack>
            </Stack>
          </Card>

          <Grid
            cols={3}
            tabletCols={1}
            columnGap={20}
            rowGap={20}
          >
            {consultationTariffs.map((item: any, index: number) => {
              return (
                <Card
                  key={index}
                  style={{paddingTop: 0}}
                >
                  <Stack gap={30}
                    height='100%'
                  >
                    <Stack gap={20}>
                      <div>
                        <div
                          style={{
                            background: '#3D5267',
                            padding: '7px 30px',
                            borderBottomLeftRadius: '16px',
                            borderBottomRightRadius: '16px',
                            display: 'inline-block'
                          }}
                        >
                          <Heading variant='3' color='white' align='center' style={{display: 'inline'}}>
                            {nbspacer(item.name)}
                          </Heading>
                        </div>
                      </div>
                      <Text variant='text-l' color='emerald'>
                        {nbspacer(item.description)}
                      </Text>
                      <div
                        style={{
                          background: '#00826F',
                          height: '2px'
                        }}
                      />
                    </Stack>

                    <Stack
                      flexGrow={1}
                      gap={20}
                    >
                      {item.items.map((item: any, index: number) => {
                        return (
                          <Stack
                            key={'subitem' + index}
                            orientation='horizontal'
                            gap={20}
                          >
                            <Image
                              src='/static/img/pages/career-management/what-is-the-result-section/check-small.svg'
                              width={14}
                              style={{
                                flexShrink: 0
                              }}
                            />
                            <Text variant='text-m' color='blue-gray'>
                              {nbspacer(item)}
                            </Text>
                          </Stack>
                        )
                      })}
                    </Stack>

                    <Stack
                      gap={6}
                    >
                      <Heading
                        variant='2'
                        color='blue-gray'>
                        {nbspacer(item.price)}
                      </Heading>

                      <Grid
                        cols={2}
                        phoneCols={1}
                        columnGap={20}
                        alignItems='center'
                      >
                        <Text variant='text-m' color='blue-gray'>выгода<br/>{item.benefit}</Text>
                        <div>
                          <Button
                            variant='filled'
                            onClick={() => { props.onSelectTariff?.(item.id) }}
                          >
                            Выбрать
                          </Button>
                        </div>
                      </Grid>
                    </Stack>
                  </Stack>
                </Card>
              )
            })}
          </Grid>
        </Stack>

        {/* section 3 */}
        <Stack
          orientation='horizontal'
          tabletOrientation='vertical'
          gap={20}
          alignItems='center'
        >
          <Stack
            width='40%'
            tabletWidth='100%'
          >
            <Image src='/static/img/pages/career-management/what-is-the-result-section/section-image3.svg' />
          </Stack>
          <Stack
            gap={30}
            width='60%'
            tabletWidth='100%'
          >
            <Text
              variant='text-m'
              color='blue-gray'
            >
              {nbspacer('Мы готовы помочь определить наиболее эффективные инструменты для достижения целей поиска работы и развития карьеры, а также выбрать оптимальный пакет услуг. Свяжитесь с нами и получите бесплатную консультацию по выбору и приобретению услуг.')}
            </Text>
            <div>
              <Button
                variant='filled'
                onClick={() => scrollToId('question-section')}
              >
                    Связаться
              </Button>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default WhatIsTheResultSection
