import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Text from 'components/Text/Text'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'

import nbspacer from 'common/nbspacer'
import Color from 'common/Color.d'
import Indicator, { IndicatorData } from 'components/Indicator/Indicator'

type CareerSectionProps = {
  heading?: string;
  text?: string;
  bgColor?: Color;
  indicatorBullets?: IndicatorData[];
}

const CareerSection = (props: CareerSectionProps) => {
  return (
    <PageSection id='career-section' bgColor={props.bgColor}>
      <SectionHeading animate animationVariant='appear-up' showArrow={true}>{nbspacer(props.heading ?? '')}</SectionHeading>

      <Grid columnGap={20} cols={2} tabletCols={1}>
        <Text>
          <div dangerouslySetInnerHTML={{__html: nbspacer(props.text ?? '')}} />
        </Text>
        <Image animate animationVariant='appear-left' src='static/img/career-section-img.svg' width='100%' />
      </Grid>

      {props.indicatorBullets &&
        <Grid
          cols={3}
          tabletCols={2}
          phoneCols={1}
          columnGap={20}
          rowGap={20}
        >
          {props.indicatorBullets?.map((item: IndicatorData) => {
            return <Indicator animate key={item.id} {...item} />
          })}
        </Grid>
      }
    </PageSection>
  )
}

export default CareerSection
