import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'

const FreeConsultationInfoSection = () => {
  return (
    <PageSection variant='gray'>
      <Text variant='text-xl'>
        Приобретая все издание каталога ролей HR-специалист <b>бесплатно</b> даст рекомендации по его практическому использованию и актуальным методикам оценки.
      </Text>

    </PageSection>
  )
}

export default FreeConsultationInfoSection
