import Heading from 'components/Heading/Heading'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import Image, { ImageProps } from 'components/Image/Image'
import nbspacer from 'common/nbspacer'

type DotArrowListCardProps = {
  id?: number;
  key?: any;
  name?: string;
  description?: string;
}

const DotArrowListCard = ({name, description}: DotArrowListCardProps) => {
  const imageProps: ImageProps = {
    width: 28,
    src: '/static/img/icons/dots-arrow.svg',
    style: {
      position: 'relative',
      top: '8px'
    }
  }
  return (
    <>
      <Stack orientation='horizontal' gap={20} alignItems='start'>
        <Image {...imageProps} />
        <Stack gap={12}>
          {name && <Heading variant='3'>{name}</Heading>}
          {description && <Text>
            <div dangerouslySetInnerHTML={{ __html: nbspacer(description ?? '') }} />
          </Text>}
        </Stack>
      </Stack>
    </>
  )
}

export default DotArrowListCard
export { DotArrowListCard }
export type { DotArrowListCardProps }
