import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Stack from 'components/Stack/Stack'
import { DotArrowListCard, DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'

// import { heading, items } from './data'

const ItemsListCard = (props: {heading: string; items: any}) => {
  return (
    <Card bgColor='light-gray'>
      <Stack gap={20}>
        <Heading variant='2'>{props.heading}</Heading>
        <Stack gap={30}>
          {props.items.map((item: DotArrowListCardProps) => {
            return <DotArrowListCard key={item.id} {...item} />
          })}
        </Stack>
      </Stack>
    </Card>
  )
}

export default ItemsListCard
