const timetable: any = [
  {
    name: 'Цифровая трансформация: ключевые аспекты',
    url: 'https://cdto.ranepa.ru/program-registry/from-the-first-steps',
    cost: '65 000₽',
    streams: [
      {'from': '04.06.2024', 'to': '13.08.2024'},
      {'from': '18.06.2024', 'to': '26.08.2024'},
      {'from': '02.07.2024', 'to': '09.09.2024'},
      {'from': '16.07.2024', 'to': '23.09.2024'},
      {'from': '30.07.2024', 'to': '08.10.2024'},
      {'from': '13.08.2024', 'to': '21.10.2024'},
      {'from': '03.09.2024', 'to': '12.11.2024'},
      {'from': '17.09.2024', 'to': '26.11.2024'},
      {'from': '01.10.2024', 'to': '10.12.2024'}
    ]
  },
  {
    name: 'Целеполагание и принятие решений в практике цифрового развития организации',
    url: 'https://cdto.ranepa.ru/program-registry/decisions',
    cost: '50 000₽',
    streams: [
      {'from': '04.06.2024', 'to': '16.07.2024'},
      {'from': '18.06.2024', 'to': '29.07.2024'},
      {'from': '02.07.2024', 'to': '12.08.2024'},
      {'from': '17.07.2024', 'to': '27.08.2024'},
      {'from': '31.07.2024', 'to': '10.09.2024'},
      {'from': '14.08.2024', 'to': '24.09.2024'},
      {'from': '03.09.2024', 'to': '14.10.2024'},
      {'from': '17.09.2024', 'to': '28.10.2024'},
      {'from': '02.10.2024', 'to': '13.11.2024'},
      {'from': '08.10.2024', 'to': '19.11.2024'},
      {'from': '22.10.2024', 'to': '03.12.2024'}
    ]
  },
  {
    name: 'Основы управления данными',
    url: 'https://cdto.ranepa.ru/program-registry/data-management',
    cost: '55 000₽',
    streams: [
      {from: '04.06.2024', to: '13.08.2024'},
      {from: '18.06.2024', to: '26.08.2024'},
      {from: '02.07.2024', to: '09.09.2024'},
      {from: '16.07.2024', to: '23.09.2024'},
      {from: '30.07.2024', to: '08.10.2024'},
      {from: '13.08.2024', to: '21.10.2024'},
      {from: '03.09.2024', to: '12.11.2024'},
      {from: '17.09.2024', to: '26.11.2024'},
      {from: '01.10.2024', to: '10.12.2024'}
    ]
  },
  {
    name: 'Создание и развитие цифровых команд',
    url: 'https://cdto.ranepa.ru/program-registry/creation-team',
    cost: '55 000₽',
    streams: [
      {from: '04.06.2024', to: '13.08.2024'},
      {from: '18.06.2024', to: '26.08.2024'},
      {from: '02.07.2024', to: '09.09.2024'},
      {from: '16.07.2024', to: '23.09.2024'},
      {from: '30.07.2024', to: '08.10.2024'},
      {from: '13.08.2024', to: '21.10.2024'},
      {from: '03.09.2024', to: '12.11.2024'},
      {from: '17.09.2024', to: '26.11.2024'},
      {from: '01.10.2024', to: '10.12.2024'}
    ]
  },
  {
    name: 'Инструменты цифрового развития: проектный, процессный и продуктовый подходы',
    url: 'https://cdto.ranepa.ru/program-registry/digital-dev-management',
    cost: '65 000₽',
    streams: [
      {'from': '04.06.2024', 'to': '16.07.2024'},
      {'from': '18.06.2024', 'to': '29.07.2024'},
      {'from': '02.07.2024', 'to': '12.08.2024'},
      {'from': '17.07.2024', 'to': '27.08.2024'},
      {'from': '31.07.2024', 'to': '10.09.2024'},
      {'from': '14.08.2024', 'to': '24.09.2024'},
      {'from': '03.09.2024', 'to': '14.10.2024'},
      {'from': '17.09.2024', 'to': '28.10.2024'},
      {'from': '02.10.2024', 'to': '13.11.2024'},
      {'from': '08.10.2024', 'to': '19.11.2024'},
      {'from': '22.10.2024', 'to': '03.12.2024'}
    ]
  }
]

export { timetable }
