import React from 'react'
import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import Text from 'components/Text/Text'
import nbspacer from 'common/nbspacer'

import { heading, text, quote } from './data'
import Quote from 'pages/Home/AboutSection/Quote/Quote'
import Persona from 'components/Persona/Persona'

type HeaderBlockProps = {
  heading?: string;
}

const HeaderBlock = (props: HeaderBlockProps) => {
  const personaProps: any = {
    horizontal: true,
    imageSize: 80,
    nameTextVariant: 'small',
    nameFontWeight: 'medium',
    positionTextVariant: 'small',
    imageGap: 16
  }

  return (
    <Grid
      cols={2}
      tabletCols={1}
      columnGap={20}
      rowGap={40}
    >
      <Stack gap={40}>
        <Heading variant='1'>{nbspacer(heading ?? '')}</Heading>
        <Text maxWidth854>
          <div dangerouslySetInnerHTML={{__html: nbspacer(text ?? '')}} />
        </Text>
        <Stack gap={20}>
          <Quote text={quote.text} />
          <div style={{paddingLeft: '48px'}}>
            <Persona {...personaProps} {...quote} />
          </div>
        </Stack>
      </Stack>
      <Image animate animationVariant='appear-left' src='static/img/pages/ai-eval/header-image.png' width={'100%'} />
    </Grid>
  )
}

export default HeaderBlock
