import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import React from 'react'

const LogoCard = () => {
  const cardStyle: React.CSSProperties = {
    height: '240px',
    backgroundImage: 'url("/static/img/sum-of-tech/card-decorations/narrow-circles.svg")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom 450%',
    backgroundSize: 'auto'
  }

  return (
    <Card style={cardStyle}>
      <Stack gap={20} height='100%'>
        <Stack gap={20} flexGrow={1}>
          <Image src='/static/img/sum-of-tech/logo.svg' width={200} />
          <Text>— аналитический портал о цифровой трансформации в государственном управлении</Text>
        </Stack>
        {false && <div><Button variant='outlined'>Читать о проекте</Button></div>}
      </Stack>
    </Card>
  )
}

export default LogoCard
