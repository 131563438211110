import React from 'react'
import Color from 'common/Color.d'
import colors from 'common/colors'

type DotVariant = 'circle' | 'rounded' | 'square'

type TextWithDotProps = {
  children?: React.ReactNode;
  gap?: number;
  dotColor?: Color;
  dotSize?: number;
  dotVariant?: DotVariant;
}

const TextWithDot = (props: TextWithDotProps) => {
  const textWithDotStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: props.gap ? props.gap + 'px' : '16px'
  }

  const dotColor = props.dotColor ?? 'emerald'
  let borderRadius = ''
  if (props.dotVariant === 'circle') {
    borderRadius = '50%'
  }
  if (props.dotVariant === 'rounded') {
    borderRadius = '2px'
  }

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center'
  }

  const dotStyle: React.CSSProperties = {
    height: props.dotSize ? props.dotSize + 'px' : '8px',
    width: props.dotSize ? props.dotSize + 'px' : '8px',
    borderRadius: borderRadius,
    backgroundColor: colors[dotColor]
  }

  return (
    <div style={textWithDotStyle}>
      <div style={containerStyle}>
        <div style={dotStyle}></div>
      </div>
      <span style={containerStyle}>{props.children}</span>
    </div>
  )
}

export default TextWithDot
