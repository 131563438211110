import React, { useEffect } from 'react'

import styles from './TextField.module.css'

type TextFieldProps = {
  fullWidth?: boolean;
  blockElement?: boolean;
  themeWhite?: boolean;
  type?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  autoComplete?: string;
  size?: number;
  onInput?: (e: any) => void;
  onChangeInvalid?: (e: any) => void;
  value?: string;
}

const TextField = (props: TextFieldProps) => {
  const textFieldClassName: string = [
    styles['text-field'],
    props.fullWidth && styles['text-field--full-width'],
    props.blockElement && styles['text-field--block-element']
  ].filter(e => e !== undefined).join(' ')

  const inputClassName: string = [
    styles.input,
    props.themeWhite && styles['input--theme-white'],
    props.fullWidth && styles['input--full-width'],
    props.blockElement && styles['text-field--block-element']
  ].filter(e => e !== undefined).join(' ')

  const [isInvalid, setIsInvalid] = React.useState(false)
  const [validateMessage, setValidateMessage] = React.useState('')

  useEffect(() => {
    props.onChangeInvalid?.(isInvalid)
  }, [isInvalid]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkValidation = (el: any) => {
    setIsInvalid(!el.validity.valid)
    if (!el.validity.valid) {
      if (props.required && !el.value) {
        setValidateMessage('Обязательное поле')
      } else if (props.type === 'email') {
        setValidateMessage('Укажите корректный e-mail')
      }
    }
  }

  const handleInvalid = (e: any) => {
    e.preventDefault()
    checkValidation(e.target)
  }

  const handleInput = (e: any) => {
    props.onInput?.(e)
    checkValidation(e.target)
  }

  const textFieldProps: React.InputHTMLAttributes<HTMLInputElement> = {
    className: textFieldClassName
  }

  const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
    type: props.type ?? 'text',
    className: inputClassName,
    placeholder: props.placeholder,
    size: props.size,
    required: props.required,
    name: props.name,
    onInvalid: handleInvalid,
    onInput: handleInput,
    autoComplete: props.autoComplete,
    value: props.value
  }

  return (
    <div {...textFieldProps}>
      <input {...inputProps} />
      {isInvalid && <span className={styles.error}>{validateMessage}</span>}
    </div>
  )
}

export default TextField
