import React from 'react'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import QuestionForm from './QuestionForm/QuestionForm'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Color from 'common/Color.d'
import Grid from 'components/Grid/Grid'

type QuestionSectionProps = {
  bgColor?: Color;
  heading?: string;
  id?: number | string;
}

const QuestionSection = (props: QuestionSectionProps) => {
  const heading: string = props.heading ?? 'Остались вопросы?'
  return (
    <PageSection bgColor={props.bgColor} id={props.id}>
      <Grid cols={2} tabletCols={1} columnGap={130}>
        <Stack gap={60} tabletGap={40}>
          <SectionHeading>{heading}</SectionHeading>
          <QuestionForm />
        </Stack>
        <Image animate animationVariant='appear-left' src='/static/img/question-section-img.png' width='100%'/>
      </Grid>
    </PageSection>
  )
}

export default QuestionSection
