import React from 'react'

import styles from './Checkbox.module.css'

const Checkbox = (props: any) => {
  React.useEffect(() => {
    if (props.defaultChecked && !textInput.current.checked) {
      textInput.current.click()
    }
  }, [props.defaultChecked])

  const textInput: any = React.useRef(null)

  const handleChange = (e: any) => {
    if ('onChange' in props) {
      props.onChange(e)
    }
  }

  const checkboxInputProps: React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement> = {
    type: 'checkbox',
    className: styles['checkbox__input'],
    ref: textInput,
    name: props.name,
    checked: props.checked,
    disabled: props.disabled,
    onChange: handleChange
  }

  const boxClassName: string = [
    styles['checkbox__box'],
    props.whiteTheme && styles['checkbox__box--white-theme']
  ].filter(e => e !== undefined).join(' ')

  return (
    <label className={styles.checkbox}>
      <input {...checkboxInputProps} />
      <span className={boxClassName}></span>
      {props.label}
    </label>
  )
}

export default Checkbox
