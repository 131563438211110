import Card from 'components/Card/Card'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import IconView from 'components/v2/IconView/IconView'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'

const SpecialistSelectionSection = () => {
  const items: any = [
    {
      name: '15 000+',
      description: 'собственная база профессионалов'
    },
    {
      name: '7 000+',
      description: 'специалистов прошли независимую оценку компетенций'
    },
    {
      name: '350+',
      description: 'уникальных вакансий закрыто'
    },
    {
      name: '45%',
      description: 'вакансий закрываем собственной базой'
    },
    {
      name: '5 дней',
      description: 'среднее время закрытия вакансии'
    },
    {
      name: '100+',
      description: 'положительных отзывов заказчиков'
    }
  ]

  return (
    <PageSection paddingTop={20}>
      <Card bgColor='light-gray' style={{position: 'relative', padding: '40px 60px'}}>
        <Image
          style={{
            position: 'absolute',
            right: '0px',
            top: '-100px'
          }}
          width='300px'
          src='/static/img/pages/recruiting/digital-transformation-specialists/specialist-selection-section/section-image.svg'
        />
        <Stack gap='60px'>
          <Stack style={{paddingRight: '300px'}}>
            <Heading variant='2'>Быстрый и качественный подбор</Heading>
            <Heading variant='2' color='green'>IT-специалистов</Heading>
          </Stack>
          <IconView cols={3} tabletCols={1} columnGap={65} rowGap={65}>
            {items.map((item: any, index: number) => {
              return <Stack key={index} gap={10}>
                <Heading variant='2' color='green' uppercase>{item.name}</Heading>
                <div style={{height: '2px', backgroundColor: '#00826F'}} />
                <Text variant='text-m-medium'>{item.description}</Text>
              </Stack>
            })}
          </IconView>
        </Stack>
      </Card>
    </PageSection>
  )
}

export default SpecialistSelectionSection
