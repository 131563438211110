import HeaderSectionBase from 'page-sections/v2/HeaderSection/HeaderSection'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import nbspacer from 'common/nbspacer'

const HeaderSection = () => {
  return (
    <HeaderSectionBase
      style={{
      }}
    >
      <Stack
        gap={20}
        alignItems='center'
        orientation='horizontal'
        tabletOrientation='vertical'
      >
        {/* left */}
        <Stack gap={40} width='40%' tabletWidth='100%'>
          <Heading
            variant='1'
            color='white'
          >
            {nbspacer('Управляй карьерой в цифровую эпоху')}
          </Heading>
          <Text
            variant='text-xl'
            color='white'
          >
            {nbspacer('Персональная консультация с экспертом по развитию карьеры в цифре')}
          </Text>
        </Stack>

        {/* right */}
        <Stack width='60%'>
          <Image
            src='/static/img/pages/career-management/header-section/section-image.svg'
            width='120%'
            tabletHide
          />
        </Stack>
      </Stack>
    </HeaderSectionBase>
  )
}

export default HeaderSection
