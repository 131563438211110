import React from 'react'

import AboutCourseSection from './AboutCourseSection'
import HeaderSection from './HeaderSection'
import TargetAudienceSection from './TargetAudienceSection'
import ProgramDescriptionSection from 'pages/ProgramRegistry/page-sections/ProgramDescriptionSection'
import WillLearnSection from 'pages/ProgramRegistry/page-sections/WIlLearnSection'
import WillKnowSection from 'pages/ProgramRegistry/page-sections/WillKnowSection'
import PriceSection from 'pages/ProgramRegistry/page-sections/PriceSection'
import QuestionSection from '../page-sections/QuestionSection/QuestionSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'
import CertificateSection from '../page-sections/CertificateSection'
import CourseContentSection from 'pages/ProgramRegistry/page-sections/CourceContentSection'
import ExpertsSection from '../page-sections/ExpertsSection'
import InfoVideoSection from '../page-sections/InfoVideoSection'
import FaqSection from '../page-sections/FaqSection'

const DigitalDevManagementPage = () => {
  return (
    <div>
      <HeaderSection />

      <AboutCourseSection />

      {false && <InfoVideoSection
        variant='gray'
        src='https://play.boomstream.com/MPDGJY4r'
      />}

      <TargetAudienceSection />

      <WillKnowSection
        items={[
          'какие есть роли в системе управления процессами, их функции и задачи;',
          'ключевые действия, которые необходимы для запуска оптимизации процесса;',
          'как строится жизненный цикл ИТ-продукта;',
          'как создать клиентоцентричный продукт;',
          'как работает LEAN-подход в управлении процессами.'
        ]}
      />

      <WillLearnSection
        items={[
          'Описывать процесс и выстраивать его основные этапы.',
          'Выбирать инструменты для картирования и оптимизации процесса.',
          'Применять принципы клиентоцентричного подхода в разработке ИТ-продукта.',
          'Прогнозировать и выявлять риски проекта, управлять ситуацией.',
          'Выбирать подходящих специалистов в свою проектную команду.'
        ]}
      />

      <ProgramDescriptionSection
        items={[
          {
            name: '1. Управление процессами',
            items: [
              'Ключевые аспекты процессного управления',
              'Каталог процессов, описание, нотации',
              'Реинжиниринг и анализ эффективности процессов',
              'Бережливое управление',
              'Кейсы по управлению процессами'
            ]
          },
          {
            name: '2. Управление продуктами',
            items: [
              'Продуктовый подход',
              'Методология разработки ИТ-продукта',
              'Инструменты клиентоцентричного подхода в управлении продуктом ',
              'Проектирование, внедрение и продвижение продукта'
            ]
          },
          {
            name: '3. Управление проектами',
            items: [
              'Основы управления проектами',
              'Роли и ответственность участников проекта',
              'Жизненный цикл ИТ-проекта',
              'Инициирование и подготовительный этап разработки проекта',
              'Ключевые этапы разработки проекта: реализация, мониторинг и контроль, завершение',
              'Управление рисками проекта'
            ]
          }
        ]}
      />

      <ExpertsSection
        experts={['garkusha', 'popkova', 'shubin', 'alferov']}
        paddingBottom={0}
      />

      <CourseContentSection />

      <CertificateSection variant='gray' />

      <PriceSection />

      <FaqSection />

      <QuestionSection
        id={'question-section'}
        defaultPurposeOfRequest='program_registry_digital_dev_management'
      />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default DigitalDevManagementPage
