import HeaderSectionBase from 'page-sections/v2/HeaderSection/HeaderSection'
import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import nbspacer from 'common/nbspacer'
import Text from 'components/v2/Text/Text'

const HeaderSection = () => {
  return (
    <HeaderSectionBase paddingBottom={0}>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={0}
        rowGap={24}
        alignItems='center'
      >
        {/* left */}
        <Stack gap={40}>
          <Stack gap={40}>
            <Stack gap={40}>
              <Heading
                variant='1'
                color='white'
              >
                {nbspacer('Эксперты')}
              </Heading>
              <Text
                variant='text-m'
                color='white'
              >
                {nbspacer('Мы разрабатываем аналитические и методические материалы, а также занимаемся консультационным сопровождением. В этом нам помогают эксперты, которые отвечают за то, чтобы во всех продуктах и проектах Центра использовались актуальные и лучшие практики цифровой экономики')}
              </Text>
            </Stack>
          </Stack>
        </Stack>

        {/* right */}
        <Image
          src='/static/img/pages/v2/experts/header-section/section-image.svg'
          width='100%'
        />
      </Grid>
    </HeaderSectionBase>
  )
}

export default HeaderSection
