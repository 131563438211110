import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Text from 'components/Text/Text'
import React from 'react'
import Heading from 'components/Heading/Heading'

type SubscribeCardProps = {
  id?: number;
  image_url: string;
  heading: string;
  sub_heading: string;
  text: string;
  url: string;
  onMouseDown?: any;
}

const SubscribeCard = (props: SubscribeCardProps) => {
  const handleMouseDown = (e: any) => {
    if ('onMouseDown' in props) {
      props.onMouseDown(e)
    }
  }

  const buttonSectionStyle: React.CSSProperties = {
    flexShrink: 0
  }

  return (
    <Card animate animationVariant='appear-up'>
      <Stack gap={60} height='100%'>
        <Stack gap={30} flexGrow={1}>
          <Image src={props.image_url} width='44px' />
          <Stack gap={12}>
            <Heading variant='2'>{props.heading}</Heading>
            <Heading variant='3'>{props.sub_heading}</Heading>
            <Text color='dark-gray'>{props.text}</Text>
          </Stack>
        </Stack>
        <div style={buttonSectionStyle}>
          <Button
            onMouseDown={handleMouseDown}
            variant='outlined'
            href={props.url}
            target='_blank'
            rel='noreferrer'
          >
            Подписаться
          </Button>
        </div>
      </Stack>
    </Card>
  )
}

export default SubscribeCard
export type { SubscribeCardProps }
