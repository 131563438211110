import nbspacer from 'common/nbspacer'
import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'

const ExpertMaterialsSection = () => {
  const items: string[] = [
    'Как получить максимум пользы от услуг карьерного консультанта? 10 советов от экспертов',
    'Интервью Натальи Булаш: Карьерная консультация глазами эксперта',
    'Что такое карьерная консультация и кто такой карьерный консультант?'
  ]

  return (
    <PageSection
      variant='white-icons'
      paddingBottom={0}
    >
      <Stack gap={100}>
        <Stack
          gap={20}
          alignItems='center'
        >
          <Stack>
            <Text
              variant='text-xl'
              color='emerald'
              align='center'
            >
              {nbspacer('Карьера – эта большая часть нашей жизни')}
            </Text>
            <Text
              variant='text-xl'
              color='emerald'
              align='center'
            >
              {nbspacer('Доверьте свою карьеру экспертам')}
            </Text>
          </Stack>
          <Image
            src='/static/img/pages/career-management/expert-materials-section/section-image.svg'
            width={300}
          />
          <div
            style={{
              width: '70%',
              height: '6px',
              background: '#459689'
            }}
          />
        </Stack>

        <Heading
          color='blue-gray'
          variant='2'
          maxWidth={700}
        >
          {nbspacer('Посмотрите материалы, подготовленные нашими экспертами')}
        </Heading>

        <Grid
          cols={3}
          tabletCols={1}
          columnGap={14}
          rowGap={60}
        >
          {items.map((item: string, index: number) => {
            return (
              <Card
                key={index}
                bgColor='light-gray'
                style={{
                  height: '80%'
                }}
                withoutShadow
              >
                <Stack
                  gap={10}
                  alignItems='center'
                >
                  <Image
                    src={`/static/img/pages/career-management/expert-materials-section/image${index + 1}.svg`}
                    style={{
                      position: 'relative',
                      top: '-60px'
                    }}
                  />
                  <Text
                    variant='text-m'
                    style={{
                      position: 'relative',
                      top: '-60px'
                    }}
                  >
                    {nbspacer(item)}
                  </Text>
                </Stack>
              </Card>
            )
          })}
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default ExpertMaterialsSection
