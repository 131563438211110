import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'
import NumberedListItem from 'components/v2/NumberedListItem/NumberedListItem'
import Card from 'components/Card/Card'

const ThreeStepsSection = () => {
  type Item = {
    name: string;
    description: string;
  }
  const items: Array<Item> = [
    {
      name: 'Оставьте заявку на сайте',
      description: 'Начинаем работу по подбору вакансии до заключения договора'
    },
    {
      name: 'С вами свяжется рекрутер для уточнения деталей',
      description: 'С вами свяжется рекрутер для уточнения деталей'
    },
    {
      name: 'Получите первые резюме кандидатов уже через сутки',
      description: 'Фиксируем виды работ, этапы и сроки выполнения'
    }
  ]
  return (
    <PageSection paddingTop={0}>
      <Stack
        gap={48}
        orientation='horizontal'
        tabletOrientation='vertical'
      >
        {/* left */}
        <Stack
          width='70%'
          tabletWidth='100%'
        >
          <Card bgColor='light-gray'>
            <Stack
              gap={40}
            >
              <Heading variant='2'>Три шага до идеального сотрудника</Heading>
              <Stack gap={30}>
                {items.map((item: Item, index: number) => {
                  return <NumberedListItem key={index} {...item} number={index + 1} />
                })}
              </Stack>
            </Stack>
          </Card>
        </Stack>
        {/* right */}
        <Image src='/static/img/pages/recruiting/digital-transformation-specialists/three-steps-section/section-image.svg' />
      </Stack>
    </PageSection>
  )
}

export default ThreeStepsSection
