
import Modal from 'components/Modal/Modal'
import Image from 'components/Image/Image'
import React from 'react'

type CertPreviewProps = {
  visible: boolean;
  onClose: any;
}

const CertPreview = (props: CertPreviewProps) => {
  return (
    <Modal
      title='Пример удостоверения'
      visible={props.visible}
      onClose={props.onClose}
    >
      <Image
        width='100%'
        src='/static/img/pages/programs/cert-example.jpg'
      />
    </Modal>
  )
}

export default CertPreview
