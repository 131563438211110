import Grid from 'components/Grid/Grid'
import PageSection from 'components/PageSection/PageSection'
import Persona, { PersonaProps } from 'components/Persona/Persona'
import React from 'react'

type TeamSectionProps = {
  employee: any;
  subordinates: any;
  index: number;
}

const TeamSection = ({employee, subordinates, index}: TeamSectionProps) => {
  const employeeProps: PersonaProps = {
    horizontal: true,
    imageSize: 120,
    imageGap: 24,
    nameFontWeight: 'medium',
    positionTextVariant: 'small',
    positionTextMaxWidth: 496,
    showImageBorder: true,
    imageUrl: employee.image_url,
    ...employee
  }

  const getSubordinateProps = (item: any) => {
    return {
      horizontal: true,
      horizontalAlign: 'start',
      showImage: false,
      nameFontWeight: 'medium',
      positionTextVariant: 'small',
      imageUrl: item.image_url,
      ...item
    } as PersonaProps
  }

  return (
    <PageSection bgColor={(index & 1) ? 'light-gray' : 'white'} style={{padding: '40px 150px'}}>
      <Persona {...employeeProps} />
      <Grid columnGap={20} rowGap={20} cols={4} tabletCols={2} phoneCols={1}>
        {subordinates.map((subordinate: any) => {
          return (<Persona
            key={subordinate.id}
            {...getSubordinateProps(subordinate)}
          />)
        })}
      </Grid>
    </PageSection>
  )
}

export default TeamSection
