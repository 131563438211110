import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Heading from 'components/Heading/Heading'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Text from 'components/Text/Text'

import { heading, text, imageBullets } from './data'

type MethodologySectionProps = {
  id?: number | string;
  bgColor?: Color;
}

const MethodologySection = (props: MethodologySectionProps) => {
  return (
    <PageSection {...props}>
      <SectionHeading animate animationVariant='appear-up'>{heading}</SectionHeading>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={40}
        rowGap={40}
      >
        <Text>
          <div dangerouslySetInnerHTML={{__html: nbspacer(text ?? '')}} />
        </Text>
        <Stack gap={20}>
          <Heading variant='2'>Методология включает в себя:</Heading>

          <Stack alignItems='center' gap={60}>
            <Grid
              cols={2}
              tabletCols={1}
              phoneCols={1}
              columnGap={20}
              rowGap={20}
            >
              {imageBullets?.map((item: any, index: number) => {
                return (<Card
                  animate
                  animationVariant='appear-up'
                  animateDelayIndex={index % 2}
                  key={item.id}
                  bgColor='light-gray'
                  id={`${item.id}-card`}
                >
                  <Stack gap={20} alignItems='center'>
                    <Image src={item.imageUrl} width={80} />
                    <Text align='center' variant='small' fontWeight='medium'>{nbspacer(item.description)}</Text>
                  </Stack>
                </Card>)
              })}
            </Grid>
          </Stack>
        </Stack>
      </Grid>
    </PageSection>
  )
}

export default MethodologySection
