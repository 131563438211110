import React, { useEffect } from 'react'
import HeaderSection from './HeaderSection'
import QuestionSection from 'page-sections/v2/QuestionSection/QuestionSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'
import InfoSection from './InfoSection'
import WhoWillBeInterestedSection from './WhoWillBeInterestedSection'
import SolvingTasksSection from './SolvingTasksSection'
import WhyDoYouNeedSection from './WhyDoYouNeedSection'
import PurchaseForm from './PurchaseForm/PurchaseForm'
import ProfileStructureSection from './ProfileStructureSection'
import DemoInfoSection from './DemoInfoSection'
import FreeConsultationInfoSection from './FreeConsultationInfoSection'
import SpecialistCatalogSection from './SpecialistCatalogSection'
import Modal from 'components/Modal/Modal'
import Heading from 'components/v2/Heading/Heading'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import { useSearchParams } from 'react-router-dom'

const ProgramRegistryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    if (searchParams.get('payment_status') === 'success') {
      setIndividualOrderPaymentSuccessModalVisible(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line no-unused-vars
  const [isPurchaseFormVisible, setPurchaseFormVisible] = React.useState(false)
  const [isSuccessLegalOrderModalVisible, setSuccessLegalOrderModalVisible] = React.useState(false)
  const [isIndividualOrderPaymentSuccessModalVisible, setIndividualOrderPaymentSuccessModalVisible] = React.useState(false)
  const onLegalOrderSuccess = () => {
    setPurchaseFormVisible(false)
    setSuccessLegalOrderModalVisible(true)
  }

  return (
    <div>
      <Modal
        visible={isSuccessLegalOrderModalVisible}
        onClose={() => setSuccessLegalOrderModalVisible(false)}
      >
        <Stack
          gap={20}
          alignItems='center'
        >
          <Image src='/static/img/icons/check-in-circle.svg' />
          <Heading variant='3' align='center'>Ваша заявка принята, скоро с вами свяжется <br/> наш менеджер.<br/>support@cdto.center</Heading>
        </Stack>
      </Modal>

      <Modal
        visible={isIndividualOrderPaymentSuccessModalVisible}
        onClose={() => setIndividualOrderPaymentSuccessModalVisible(false)}
      >
        <Stack
          gap={20}
          alignItems='center'
        >
          <Image src='/static/img/icons/check-in-circle.svg' />
          <Heading variant='3' align='center'>
            После поступления оплаты <br/>на Ваш адрес электронной почты будет <br/> отправлен персональный каталог ролей.
          </Heading>
        </Stack>
      </Modal>

      <PurchaseForm
        visible={isPurchaseFormVisible}
        onClose={() => setPurchaseFormVisible(false)}
        onLegalOrderSuccess={onLegalOrderSuccess}
      />
      <HeaderSection />

      <InfoSection />

      <WhoWillBeInterestedSection />

      <SolvingTasksSection />

      <WhyDoYouNeedSection onBuyClick={() => setPurchaseFormVisible(true)} />

      <SpecialistCatalogSection onBuyClick={() => setPurchaseFormVisible(true)} />

      <ProfileStructureSection />

      <DemoInfoSection />

      <FreeConsultationInfoSection />

      <QuestionSection
        id={'question-section'}
        defaultPurposeOfRequest='role_catalog'
      />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default ProgramRegistryPage
