import React from 'react'
import { Link } from 'react-router-dom'

import styles from './LogosPanel.module.css'

const LogosPanel = () => {
  return (
    <div className={styles.LogosPanel}>
      <Link to='/'>
        <img
          className={styles.Image}
          src='/static/img/logo/cdto.svg'
        />
      </Link>
      <a href='https://gspm.ranepa.ru'>
        <img
          className={styles.Image}
          src='/static/img/logo/vshgu.svg'
        />
      </a>
      <a href='https://www.ranepa.ru'>
        <img
          className={styles.Image}
          src='/static/img/logo/ranepa.svg'
        />
      </a>
    </div>
  )
}

export default LogosPanel
