const heading: string = 'ИИ-зрелость ФОИВ'
const text: string = 'Наша цель — провести комплексный анализ факторов, влияющих на внедрение ИИ-решений в деятельность ФОИВ, и определить текущий уровень ИИ-зрелости ФОИВ.'

const quote: any = {
  text: 'Мероприятия по искусственному интеллекту входят в национальные и федеральные проекты, ведомственные программы цифровой трансформации. Каждому ведомству предстоит разработать дорожные карты по внедрению ИИ в деятельность своих ведомств и отраслей.',
  imageUrl: '/static/img/photos/d_chernyshenko.jpg',
  name: 'Дмитрий Чернышенко',
  position: 'Вице-премьер правительства РФ'
}
export { heading, text, quote }
