const headerTitle: string = 'Кадровый резерв Рекрутингового центра Минцифры России'

const headerText: any = <>
Сообщество высокомотивированных руководителей и специалистов, обладающих необходимыми профессиональными и личностными компетенциями в области цифровой трансформации
</>

const headerCardText1: any = <>
Кадровый резерв предназначен для <b>100%-го закрытия вакансий</b> в подразделениях цифровой трансформации федеральных и региональных органов власти.
</>
const headerCardText2: any = <>
  <b>Все участники</b> Кадрового резерва <b>рассматриваются в качестве потенциальных претендентов</b> на замещение вакантных должностей руководителей и специалистов в сфере цифровой трансформации государственных органов.
</>

const advantagesTitle: string = 'Преимущества для участников Кадрового резерва'

const advantages: any = [
  {
    id: 1,
    description: 'Возможность войти в число лучших российских специалистов по цифровому развитию государственного сектора;'
  },
  {
    id: 2,
    description: 'Возможность карьерного роста и участия в значимых проектах цифровой трансформации государственного управления;'
  },
  {
    id: 3,
    description: 'Возможность личного и профессионального развития на основе индивидуальной траектории, сформированной по результатам комплексной оценки компетенций;'
  }
]

const precedureTitle: string = 'Процедура отбора кандидата в Кадровый резерв'

const procedureItems: any = [
  {
    id: 1,
    number: 1,
    name: 'Регистрация на cdto.work. Оценка мотивации'
  },
  {
    id: 2,
    number: 2,
    name: 'Внесение данных в резюме',
    description: 'В течение трех дней после регистрации;'
  },
  {
    id: 3,
    number: 3,
    name: 'Оценка компетенций',
    description: 'Не  более чем за 10 дней после второго этапа;'
  },
  {
    id: 4,
    number: 4,
    name: 'Прохождение интервью',
    description: 'Специалист Центра организовывает, а кандидат проходит не позднее 10 дней после предыдущего этапа;'
  },
  {
    id: 5,
    number: 5,
    name: 'Решение экспертной комиссии о включении в Кадровый резерв',
    description: '<p>Заседание комиссии проходит с 1 по 10 число каждого месяца, уведомление кандидата о решении - в течение трех рабочих дней после заседания.</p><p>Ключевым этапом отбора в Кадровый резерв является оценка личностных и профессиональных компетенций кандидата.</p>',
    secondary_description: 'В методологии оценки за основу взята Модель компетенций команды цифровой трансформации в системе государственного управления.'
  }
]

const registerCardTitle: string = 'Регистрация'
const registerCardText: any = 'В методологии оценки за основу взята Модель компетенций команды цифровой трансформации в системе государственного управления.'

const registerUrl: string = 'https://cdto.work/register/'

export {
  headerTitle,
  headerText,
  headerCardText1,
  headerCardText2,
  advantagesTitle,
  advantages,
  precedureTitle,
  procedureItems,
  registerCardTitle,
  registerCardText,
  registerUrl
}
