const quoteList: any[] = [
  {
    id: 1,
    text: 'В основе государственных ИТ-решений должны быть современные клиентоориентированные подходы. Проекты выпускников становятся  реальной частью цифрового госуправления. Задача регионов — обеспечить качественную реализацию стратегий цифровой трансформации.',
    imageUrl: '/static/img/photos/d_chernyshenko.jpg',
    name: 'Дмитрий Чернышенко',
    position: 'Вице-премьер правительства РФ'
  },
  {
    id: 2,
    text: 'Цифровая трансформация вошла в пятерку целей развития России до 2030 года. Задача каждого РЦТ – организовать работу и сформировать сильную команду.',
    imageUrl: '/static/img/photos/m_shadaev.jpg',
    name: 'Максут Шадаев',
    position: 'Министр цифрового развития, связи и массовых коммуникаций Российской Федерации'
  },
  {
    id: 3,
    text: 'Наша миссия — менять представление о способах и инструментах работы в госуправлении. Мы создаем среду, раскрывающую потенциал участников и лидеров цифровой трансформации для принятия этичных, быстрых, обоснованных решений в условиях цифровой экономики.',
    imageUrl: '/static/img/photos/n_garkusha.jpg',
    name: 'Наталья Гаркуша',
    position: 'Директор Центра'
  }
]

export { quoteList }
