import Stack from 'components/Stack/Stack'
import { Link } from 'react-router-dom'

import styles from './LogosPanel.module.css'

const LogosPanel = () => {
  return (
    <Stack gap={54} orientation='horizontal' phoneOrientation='vertical'>
      <Link to='/'>
        <img
          className={styles.Image}
          src='/static/img/logo/v3/cdto.svg'
        />
      </Link>
      <img
        className={styles.Image}
        src='/static/img/logo/v3/vshgu.svg'
      />
      <img
        className={styles.Image}
        src='/static/img/logo/v3/ranepa.png'
      />
    </Stack>
  )
}

export default LogosPanel
