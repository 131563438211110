import Color from 'common/Color.d'
import Grid from 'components/Grid/Grid'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import React, { useEffect, useState } from 'react'
import ProductCard from './ProductCard/ProductCard'

type ProductsSectionProps = {
  id?: number | string;
  bgColor?: Color;
}

const ProductsSection = (props: ProductsSectionProps) => {
  const [productItems, setProductItems] = useState([])

  useEffect(() => {
    fetchProducts()
  }, [])

  const fetchProducts = async () => {
    const response = await fetch('/api/products')
    const data = await response.json()
    setProductItems(data)
  }

  return (
    <PageSection {...props}>
      <SectionHeading>Примеры наших продуктов</SectionHeading>

      <Grid
        cols={3}
        tabletCols={1}
        phoneCols={1}
        columnGap={20}
        rowGap={60}
        tabletRowGap={40}
      >
        {productItems.map((item: any) => {
          return <ProductCard key={item.id} {...item} />
        })}
      </Grid>
    </PageSection>
  )
}

export default ProductsSection
