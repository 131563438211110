import Stack from 'components/Stack/Stack'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'
import nbspacer from 'common/nbspacer'
import Image from 'components/Image/Image'

const WhatIssuesSolveSection = () => {
  const items: Array<string> = [
    'помогаем сориентироваться в текущей ситуации на рынке труда',
    'анализируем Ваш опыт, определяем исключительные компетенции',
    'определяем Ваш персональный вектор профессионального развития',
    'найдем неочевидные способы поиска работодателя мечты',
    'совместно составляем продающее резюме и сопроводительное письмо',
    'готовим и репетируем успешное собеседование',
    'проконсультируем в первые дни выхода на работу'
  ]

  return (
    <PageSection variant='white-icons'>
      <Stack
        orientation='horizontal'
        tabletOrientation='vertical'
        gap={20}
      >
        {/* left */}
        <Stack
          gap={40}
          width='60%'
          tabletWidth='100%'
        >
          <Heading
            variant='2'
            color='blue-gray'
          >
            Какие вопросы помогаем решить
          </Heading>
          <Stack gap={20}>
            {items.map((item: string, index: number) => {
              return (
                <TextWithDot
                  key={index}
                  dotVariant='circle'
                  dotColor='emerald'
                >
                  <Text
                    variant='text-m'
                    color='blue-gray'
                  >
                    {nbspacer(item)}
                  </Text>
                </TextWithDot>
              )
            })}
          </Stack>
        </Stack>

        {/* right */}
        <Stack
          width='40%'
          tabletWidth='100%'
        >
          <Image
            src='/static/img/pages/career-management/what-issues-solve-section/section-image.svg'
            width='140%'
            tabletHide
          />
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default WhatIssuesSolveSection
