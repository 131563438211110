import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import React from 'react'
import scrollToId from 'common/scroll-to-id'
import nbspacer from 'common/nbspacer'
import DotArrowListCard, { DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'
import PageSection from 'components/PageSection/PageSection'
import Color from 'common/Color.d'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'

type AdvertisingSectionProps = {
  heading?: string;
  text?: string;
  bullets?: DotArrowListCardProps[];
  imageBullets?: any[];
  itemList2: any;
  bgColor?: Color;
  onShowCertPreview: any;
}

const AdvertisingSection = (props: AdvertisingSectionProps) => {
  const linkProps = {
    onClick: (e: any) => {
      e.preventDefault()
      props.onShowCertPreview()
    },
    style: {
      paddingLeft: '48px',
      cursor: 'pointer',
      textDecoration: 'underline',
      fontSize: '20px'
    }
  }
  return (
    <PageSection bgColor={props.bgColor}>
      <Stack gap={120}>
        <Stack orientation='horizontal' gap={60} alignItems='flex-start' tabletOrientation='vertical'>
          <Stack
            orientation='vertical'
            gap={40}
            width='70%'
            tabletWidth={'unset'}
          >
            <Heading variant='3'>{nbspacer(props.heading ?? '')}</Heading>
            {props.bullets?.map((item: DotArrowListCardProps) => {
              return <DotArrowListCard key={item.id} {...item} />
            })}
            <div>
              <a {...linkProps}>Пример удостоверения</a>
            </div>
          </Stack>
          <Card
            bgColor='light-gray'
            animate
            animationVariant='appear-up'
            width={'30%'}
            tabletWidth={'unset'}
          >
            <Stack gap={20} justifyContent='flex-start'>
              <Heading variant='3'>Корпоративные образовательные программы</Heading>
              <Text>
                {nbspacer('Представителям коммерческого сектора рекомендуем посмотреть информацию о других наших программах.')}
              </Text>
              <div><Button onClick={() => scrollToId('question-section')} variant='outlined' href='#'>Посмотреть</Button></div>
            </Stack>
          </Card>
        </Stack>

        <Stack alignItems='center' gap={60}>
          <Grid
            cols={5}
            tabletCols={2}
            phoneCols={1}
            columnGap={20}
            rowGap={20}
          >
            {props.imageBullets?.map((item: any, index: number) => {
              return (<Card
                animate
                animationVariant='appear-up'
                animateDelayIndex={index % 5}
                key={item.id}
                bgColor='light-gray'
                id={`${item.id}-card`}
              >
                <Stack gap={20} alignItems='center'>
                  <Image src={item.imageUrl} width={80} />
                  <Text align='center' variant='small' fontWeight='medium'>{nbspacer(item.description)}</Text>
                </Stack>
              </Card>)
            })}
          </Grid>
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default AdvertisingSection
