import { DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'

const heading: string = 'Наши преимущества'

const itemList: DotArrowListCardProps[] = [
  {
    id: 1,
    name: 'Опытные специалисты',
    description: 'Над всеми проектами работает кросс-функциональная исследовательская команда с экспертностью в области цифровых технологий.'
  },
  {
    id: 2,
    name: 'Широкий спектр тем',
    description: 'Цифровая трансформация госуправления и бизнеса, ИИ, этика цифровых технологий, продуктовая разработка, доступность цифровых сервисов и т. д.'
  },
  {
    id: 3,
    name: 'Экспертная поддержка',
    description: 'Мы приглашаем ключевых представителей различных отраслей из российского госсектора (федеральный и региональный уровни) и бизнеса.'
  },
  {
    id: 4,
    name: 'Доступ к закрытым группам респондентов',
    description: 'Госслужащим, аудитории Центра и т. д.'
  },
  {
    id: 5,
    name: 'Дополнительные возможности',
    description: 'Привлечение подрядчиков для узкоспециализированных исследований, профессиональная красивая «упаковка» информации в доступный и привлекательный контент, печать в типографии, веб-верстка и т. д.'
  }
]

export { heading, itemList }
