
import PageSection from 'components/PageSection/PageSection'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import FooterSection from 'page-sections/FooterSection/FooterSection'
import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {ReactComponent as TelegramIcon} from 'assets/icons/telegram.svg'
import {ReactComponent as VkIcon} from 'assets/icons/vk.svg'
import {ReactComponent as OkIcon} from 'assets/icons/ok.svg'
import {ReactComponent as CopyLinkIcon} from 'assets/icons/copy-link.svg'

import colors from 'common/colors'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import Persona from 'components/Persona/Persona'

import MaterialData from 'common/MaterialData.d'

import styles from './MaterialItemPage.module.css'
import ColorMaterialCard from 'components/ColorMaterialCard/ColorMaterialCard'
import Grid from 'components/Grid/Grid'

type MaetrialItemData = {
  id: number;
  name: string;
  description?: string;
  date: string;
  imageUrl?: string;
  content: string;
  materialAuthors: any[];
  materialTags: any[];
} & MaterialData

const MaetrialItemPage = () => {
  const { id } = useParams()

  const [item, setItem] = useState({} as MaetrialItemData)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const url = window.location.href
  const vkShareUrl = `https://vk.com/share.php?url=${window.location.href}`
  const okShareUrl = `https://connect.ok.ru/offer?url=${window.location.href}`
  const telegramShareUrl = `https://t.me/share/url?url=${window.location.href}`

  useEffect(() => {
    fetchItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (item?.followTheUrl) {
      window.open(item.url, '_self', 'noreferrer')
    }
  }, [item])

  const fetchItem = async () => {
    const response = await fetch(`/api/materials/${id}`)
    if (response.ok) {
      const data = await response.json()
      setItem(data)
    }
  }

  const copyUrl = (event: React.SyntheticEvent) => {
    event.preventDefault()
    navigator.clipboard.writeText(url).then(() => {
      setIsModalVisible(true)
    })
  }

  const sectionStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0
    // width: '100%'
  }

  const shareLinkStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <div>
      {item.id && <>
        <Modal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        >
          <Stack gap={30}>
            <Stack gap={6}>
              <Text>Ссылка на материал скопирована в буфер обмена</Text>
            </Stack>
            <Stack alignItems='flex-end'><Button variant='filled' onClick={() => setIsModalVisible(false)}>Ok</Button></Stack>
          </Stack>
        </Modal>
        <HeaderSection paddingBottom={60} />
        <PageSection bgColor='light-gray' style={sectionStyle}>
          {/* <Stack gap={60} style={{maxWidth: '1300px'}}> */}
          <Stack gap={60} width={'100%'}>
            <ColorMaterialCard
              showLink={false}
              size='wide'
              gradientColor='blue'
              {...item}
            />
            {item.content && <div className={styles.MaterialContent} dangerouslySetInnerHTML={{__html: item.content ?? ''}} />}
            <Stack orientation='horizontal' gap={20}>
              {item.attachedPdfUrl &&
                <div>
                  <Button
                    variant='filled'
                    target='_blank'
                    rel='noreferrer'
                    href={item.attachedPdfUrl}
                  >
                    {item.attachedPdfLabel ?? 'Скачать'}
                  </Button>
                </div>
              }
              {item.attachedPdf2Url &&
                <div>
                  <Button
                    variant='filled'
                    target='_blank'
                    rel='noreferrer'
                    href={item.attachedPdf2Url}
                  >
                    {item.attachedPdf2Label ?? 'Скачать'}
                  </Button>
                </div>
              }
              {item.url &&
                <div>
                  <Button
                    variant='filled'
                    target='_self'
                    rel='noreferrer'
                    href={item.url}
                  >
                    {item.urlLabel ?? 'Открыть'}
                  </Button>
                </div>
              }
            </Stack>
            <Stack gap={20} orientation='horizontal' wrap={'wrap'}>
              {item.materialTags?.map((tag: any) => {
                return <Text color='emerald' key={tag.id}>#{tag.name}</Text>
              })}
            </Stack>
            <Stack orientation='horizontal' gap={20}>
              {item.materialTypeShowAuthors && <Grid columnGap={20} rowGap={20} cols={2}>
                {item.materialAuthors?.map((author: any) => {
                  return (
                    <Persona
                      key={author.id}
                      horizontal
                      name={author.fullName}
                      imageUrl={author.imageUrl}
                      imageSize={80}
                      imageGap={20}
                      nameTextVariant='normal'
                      nameFontWeight='medium'
                      position={''}
                    />
                  )
                })}
              </Grid>}
              <Stack orientation='horizontal' gap={28} justifyContent='flex-end' alignItems='flex-end' flexGrow={1}>
                <Stack orientation='horizontal' gap={8} alignItems='center'>
                  <a href={telegramShareUrl} target='_blank' rel='noreferrer' style={shareLinkStyle}>
                    <TelegramIcon fill={colors['emerald']} width='28px' />
                  </a>
                  <a
                    style={shareLinkStyle}
                    href={vkShareUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <VkIcon fill={colors['emerald']} width='28px' />
                  </a>
                  <a
                    style={shareLinkStyle}
                    href={okShareUrl}
                    target="_blank"
                    rel="noreferrer"
                    title='Поделиться в Одноклассниках'
                  >
                    <OkIcon fill={colors['emerald']} width='28px' />
                  </a>
                </Stack>
                <a
                  style={shareLinkStyle}
                  href={okShareUrl}
                  onClick={copyUrl}
                  title='Скопировать ссылку'
                >
                  <CopyLinkIcon
                    stroke={colors['emerald']}
                    width='28px'
                  />
                </a>
              </Stack>
            </Stack>
          </Stack>
        </PageSection>
        <FooterSection />
      </>}
    </div>
  )
}

export default MaetrialItemPage
