import PageSection from 'components/v2/PageSection/PageSection'

import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import nbspacer from 'common/nbspacer'

type WillKnowSectionProps = {
  items: string[];
  variant?: 'default' | 'v2'
}

const WillKnowSection = (props: WillKnowSectionProps) => {
  const variant = props.variant || 'default'

  const getPageSectionVariant = (variant: string) => {
    switch (variant) {
      case 'v2':
        return 'gradient-green-stars'
      default:
        return 'gradient-green-one-star'
    }
  }

  return (
    <PageSection variant={getPageSectionVariant(variant)}>
      <Grid tabletCols={1} cols={2} columnGap={20} rowGap={20}>
        <Stack gap={40}>
          {variant === 'default' && <Heading variant='2' color='white' style={{textTransform: 'uppercase'}}>Что вы узнаете</Heading>}
          {variant === 'v2' && <Heading variant='2' color='white'>Результаты обучения</Heading>}
          <Stack gap={20}>
            {props.items.map((item: any, index: number) => {
              return <Stack key={index} gap={30} orientation='horizontal' alignItems={'center'}>
                <Image
                  src='/static/img/pages/methodological-center/usefulness/checkbox.svg'
                  width={30}
                  animate
                  animationVariant='appear-right'
                  animateDelayIndex={index}
                />
                <Text variant='text-m-medium' color='white'>{nbspacer(item)}</Text>
              </Stack>
            })}
          </Stack>
        </Stack>
        <Image src='/static/img/pages/program-registry/page-sections/will-know-section/section-image.svg' width='100%' />
      </Grid>
    </PageSection>
  )
}

export default WillKnowSection
