import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'
import ExpertCard, { ExpertKey } from '../components/ExpertCard'

type ExpertsSectionProps = {
  experts: ExpertKey[],
  paddingBottom?: number
}

const ExpertsSection = (props: ExpertsSectionProps) => {
  return (
    <PageSection variant='white-icons' paddingBottom={props.paddingBottom}>
      <Stack gap={60}>
        <Heading variant='2'>Эксперты программы</Heading>

        <Grid cols={2} tabletCols={1} columnGap={20} rowGap={20}>
          {props.experts.map((expert: ExpertKey, index: number) => {
            return <ExpertCard key={index} expert={expert} />
          })}
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default ExpertsSection
