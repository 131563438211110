import Card from 'components/Card/Card'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import React from 'react'
import Heading from 'components/Heading/Heading'
import Text from 'components/Text/Text'

const NoEventCard = () => {
  return (
    <Card bgColor='light-gray'>
      <Stack orientation='horizontal' alignItems='start'>
        <Stack flexGrow={1} gap={20}>
          <Heading variant='3'>В ближайшее время мероприятия не запланированы.</Heading>
          <Text variant='small' color='dark-gray'>Следите за нашими обновлениями.</Text>
        </Stack>
        <Image src='/static/img/icons/dots-arrow.svg' />
      </Stack>
    </Card>
  )
}

export default NoEventCard
