import getAnimationStyle, { AnimationVariant } from 'common/getAnimationStyle'
import useScrolledTo from 'common/useScrolledTo'
import React, { useEffect, useState } from 'react'

type ImageProps = {
  src: string;
  alt?: string;
  height?: number | string;
  width?: number | string;
  className?: string;
  style?: React.CSSProperties;
  hide?: boolean;
  tabletHide?: boolean;
  phoneHide?: boolean;
  objectFit?: React.CSSProperties['objectFit'];
  animate?: boolean;
  animateDelayIndex?: number;
  animationVariant?: AnimationVariant;
  onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

const Image = (props: ImageProps) => {
  const elRef = React.useRef<HTMLImageElement>(null)
  const scrolledTo = useScrolledTo(elRef)

  const [isTablet, setIsTablet] = useState(false)
  const [isPhone, setIsPhone] = useState(false)

  const handleResize = () => {
    if (window.innerWidth < 1023) {
      setIsTablet(true)
    } else {
      setIsTablet(false)
    }
    if (window.innerWidth < 767) {
      setIsPhone(true)
    } else {
      setIsPhone(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  const handleClick = (e: any) => {
    props.onClick && props.onClick(e)
  }

  let style: React.CSSProperties = {
    objectFit: props.objectFit
  }

  if (isTablet && props.hide) {
    style['display'] = 'none'
  }

  if (isTablet && props.tabletHide) {
    style['display'] = 'none'
  }

  if (isPhone && props.phoneHide) {
    style['display'] = 'none'
  }

  if (props.animate) {
    style = {
      ...style,
      ...getAnimationStyle(scrolledTo, props.animationVariant, props.animateDelayIndex)
    }
  }

  style = {...style, ...props.style}

  const elProps: any = {
    className: props.className,
    src: props.src,
    alt: props.alt,
    height: props.height,
    width: props.width,
    style: style,
    onClick: handleClick
  }

  return (
    <img ref={elRef} {...elProps} />
  )
}

export default Image
export type { ImageProps }
