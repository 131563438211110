import React from 'react'

import PageSection from 'components/v2/PageSection/PageSection'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'

import Grid from 'components/Grid/Grid'
import Card from 'components/Card/Card'
import Image from 'components/Image/Image'
import Text from 'components/v2/Text/Text'

const CourseContentSection = () => {
  const items = [
    {
      name: 'Текстовые материалы и видеолекции',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-3.png'
    },
    {
      name: 'Тесты для самопроверки',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-2.png'
    },
    {
      name: 'Инфографику',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-4.png'
    },
    {
      name: 'Кейсы для ознакомления с лучшими практиками',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-7.svg'
    },
    {
      name: 'Интерактивные практические задания',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-1.png'
    },
    {
      name: 'Рекомендуемую литературу<br>по каждой теме',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-5.png'
    }
  ]

  return (
    <PageSection variant='white-icons'>
      <Stack gap={55}>
        <Heading variant='2'>Что содержит курс</Heading>
        <Grid
          cols={3}
          tabletCols={1}
          columnGap={20}
          rowGap={20}
        >
          {items.map((item: any, index: number) => {
            return <Card
              key={index}
              bgColor='light-gray'
              animate
              animationVariant='zoom-in'
              animateDelayIndex={index}
            >
              <Stack gap={10} alignItems='center'>
                <Image src={item.imageUrl} />
                <Text variant='text-m-medium' align='center'><span dangerouslySetInnerHTML={{__html: item.name}} /></Text>
              </Stack>
            </Card>
          })}
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default CourseContentSection
