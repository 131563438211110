import Color from 'common/Color.d'
import Grid from 'components/Grid/Grid'
import ImageCard, { ImageCardProps } from 'components/ImageCard/ImageCard'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'

import { heading, itemList } from './data'

type JointProjectsSectionProps = {
  id?: number | string;
  bgColor?: Color;
}

const JointProjectsSection = (props: JointProjectsSectionProps) => {
  const getItemProps = (item: ImageCardProps) => {
    const itemProps: ImageCardProps = {
      bgColor: 'light-gray',
      textVariant: 'small',
      textBold: true,
      animate: true,
      animationVariant: 'appear-up',
      ...item
    }
    return itemProps
  }

  return (
    <PageSection {...props}>
      <SectionHeading>{heading}</SectionHeading>
      <Grid
        cols={3}
        tabletCols={2}
        phoneCols={1}
        columnGap={20}
        rowGap={20}
      >
        {itemList.map((item: ImageCardProps, index: number) => {
          return (
            <ImageCard
              key={item.imageUrl + item.text}
              animateDelayIndex={index % 3}
              {...getItemProps(item)}
            />
          )
        })}
      </Grid>
    </PageSection>
  )
}

export default JointProjectsSection
