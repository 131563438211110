import FooterSection from 'page-sections/FooterSection/FooterSection'
import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import QuestionSection from 'page-sections/QuestionSection/QuestionSection'
import useSectionsData, { getDataByKey } from 'common/sections-data'
import HeaderBlock from './HeaderBlock/HeaderBlock'
import MethodologySection from './Sections/MethodologySection/MethodologySection'
import PurposeSection from './Sections/PurposeSection/PurposeSection'
import StructureSection from './Sections/StructureSection/StructureSection'
import LevelsSection from './Sections/LevelsSection/LevelsSection'

const Home = () => {
  const keys = 'ai-eval-header'
  const itemList = useSectionsData(keys) || []

  return (
    <div>
      <HeaderSection>
        <HeaderBlock {...getDataByKey(itemList, 'ai-eval-header')} />
      </HeaderSection>

      <MethodologySection id='ai-eval-methodology' />

      <PurposeSection id='ai-eval-purpose' bgColor='light-gray' />

      <LevelsSection id='ai-eval-levels' />

      <StructureSection id='ai-eval-structure' bgColor='light-gray' />

      <QuestionSection id='question-section' />

      <FooterSection />
    </div>
  )
}

export default Home
