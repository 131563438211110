import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'

import Grid from 'components/Grid/Grid'
import nbspacer from 'common/nbspacer'

type HeaderBlockProps = {
  heading?: string;
  subheading?: string;
  text?: string;
  text2?: string;
  imageUrl?: string;
}

const HeaderBlock = (props: HeaderBlockProps) => {
  return (
    <Stack gap={30}>
      <Grid cols={2} tabletCols={1} columnGap={20} rowGap={20}>
        <Stack gap={40}>
          <Heading variant='1'>{nbspacer(props.heading ?? '')}</Heading>
          <Stack gap={20}>
            <Text>
              <div dangerouslySetInnerHTML={{ __html: nbspacer(props.text ?? '') }} />
            </Text>
            <div>
              <Button
                variant='filled'
                href='https://cdto.work/dlja-rabotodatelej/'
                target='_blank'
                rel='noreferrer'
              >
                Оставить заявку на подбор и оценку
              </Button>
            </div>
          </Stack>
        </Stack>
        <Image
          animate
          animationVariant='appear-left'
          src={props.imageUrl ?? ''}
          width={'100%'}
        />
      </Grid>
      <Card maxWidth854>
        <Stack
          gap={20}
          orientation='horizontal'
          phoneOrientation='vertical'
          alignItems='center'
          tabletAlignItems='start'
        >
          <Image
            src='/static/img/pages/recruiting-center/header-card.svg'
            width={168}
          />
          <Text>
            <div dangerouslySetInnerHTML={{ __html: nbspacer(props.text2 ?? '') }} />
          </Text>
        </Stack>
      </Card>
    </Stack>
  )
}

export default HeaderBlock
