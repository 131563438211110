import Text from 'components/v2/Text/Text'
import React from 'react'
import styles from './Button.module.css'

type BattonVariant = 'primary' | 'secondary'

type ButtonProps = {
  variant?: BattonVariant;
  label?: string;
  onClick?: () => void;
}

const Button = (props: ButtonProps) => {
  const buttonVariant = props.variant ?? 'primary'
  const buttonClassName = `${styles.Button} ${styles[`Button--${buttonVariant}`]}`

  const buttonProps = {
    onClick: props.onClick,
    className: buttonClassName
  }

  return (
    <button
      {...buttonProps}
    >
      <Text color='white'>
        {props.label}
      </Text>
    </button>
  )
}

export default Button
