
import React from 'react'
import Color from 'common/Color.d'
import colors from 'common/colors'
import useMediaQuery from 'common/useMediaQuery'

type TextVariant = 'xs' | 'date-and-type-s' | 'date-and-type-m' | 'text-s-medium' | 'text-s' | 'text-m' | 'text-m-medium' | 'text-xl' |
  'text-l' | 'text-xxxs-medium' | 'text-xxxs'
type FontWeight = 'normal' | 'medium' | 'bold' | 'extra-bold'

const defaultTextVariant: TextVariant = 'text-m'

const FontWeights = {
  'xs': 500,
  'date-and-type-s': 500,
  'date-and-type-m': 500,
  'text-s-medium': 500,
  'text-s': 400,
  'text-m': 400,
  'text-m-medium': 500,
  'text-xl': 400,
  'text-l': 700,
  'text-xxxs-medium': 500,
  'text-xxxs': 400
}

const FontSizes = {
  'xs': '14px',
  'date-and-type-s': '14px',
  'date-and-type-m': '16px',
  'text-s-medium': '16px',
  'text-s': '16px',
  'text-m': '20px',
  'text-m-medium': '20px',
  'text-xl': '36px',
  'text-l': '18px',
  'text-xxxs-medium': '10px',
  'text-xxxs': '10px'
}

const lineHeights = {
  'xs': '120%',
  'date-and-type-s': '120%',
  'date-and-type-m': '120%',
  'text-s-medium': '150%',
  'text-s': '150%',
  'text-m': '170%',
  'text-m-medium': '170%',
  'text-xl': '110%',
  'text-l': '120%',
  'text-xxxs-medium': '110%',
  'text-xxxs': '170%'
}

type TextProps = {
  children?: React.ReactNode;
  color?: Color;
  className?: string;
  variant?: TextVariant;
  maxWidth854?: boolean;
  uppercase?: boolean;
  align?: React.CSSProperties['textAlign'];
  textOverflow?: React.CSSProperties['textOverflow'];
  overflow?: React.CSSProperties['overflow'];
  overflowY?: React.CSSProperties['overflowY'];
  maxHeight?: React.CSSProperties['maxHeight'];
  maxWidth?: React.CSSProperties['maxWidth'];
  width?: React.CSSProperties['width'];
  tabletWidth?: React.CSSProperties['width'];
  phoneWidth?: React.CSSProperties['width'];
  style?: React.CSSProperties;
  flexGrow?: React.CSSProperties['flexGrow'];
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const Text = (props: TextProps) => {
  const {isTablet, isPhone} = useMediaQuery()

  const getResponsiveProp = (desktop: any, tablet: any, phone: any) => {
    if (isPhone && phone) {
      return phone
    }
    if (isTablet && tablet) {
      return tablet
    }
    return desktop
  }

  const handleClick = (e: any) => {
    props.onClick && props.onClick(e)
  }

  const style: React.CSSProperties = {
    fontSize: FontSizes[props.variant ?? defaultTextVariant],
    fontWeight: FontWeights[props.variant ?? defaultTextVariant],
    lineHeight: lineHeights[props.variant ?? defaultTextVariant],
    textAlign: props.align,
    color: colors[props.color ?? 'black'],
    textTransform: props.uppercase ? 'uppercase' : 'none',
    maxWidth: props.maxWidth ?? (props.maxWidth854 ? '854px' : '100%'),
    textOverflow: props.textOverflow,
    overflow: props.overflow,
    overflowY: props.overflowY,
    maxHeight: props.maxHeight,
    flexGrow: props.flexGrow,
    width: getResponsiveProp(props.width, props.tabletWidth, props.phoneWidth)
  }

  const componentProps = {
    style: {...style, ...props.style},
    className: props.className,
    onClick: handleClick
  }

  return (
    <span {...componentProps}>{props.children}</span>
  )
}

export default Text
export type { TextProps, TextVariant, FontWeight }
