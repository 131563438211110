import { useState, useEffect, useRef, MouseEvent } from 'react'
import styles from './Dropdown.module.css'
import * as Icon from 'react-feather'

type DropdownOption = {
  key: number | string;
  text: string;
}

type DropdownProps = {
  placeholder?: string,
  value?: number | string;
  options: DropdownOption[];
  onChange?: any;
}

const Dropdown = (props: DropdownProps) => {
  const [expanded, setExpanded] = useState(false)
  // const [isInvalid, setIsInvalid] = useState(false)
  // const [validateMessage, setValidateMessage] = useState('')

  const buttonEl = useRef(null)

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        setExpanded(false)
        if (buttonEl.current) {
          (buttonEl.current as HTMLElement).blur()
        }
      }
    }
    const handleClick = () => {
      setExpanded(false)
    }
    window.addEventListener('keydown', handleEsc)
    document.addEventListener('mousedown', handleClick)

    return () => {
      window.removeEventListener('keydown', handleEsc)
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const handleButtonMouseDown = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setExpanded(!expanded)
  }
  const handleOptionMouseDown = (e: MouseEvent<HTMLButtonElement>, key: string | number) => {
    if ('onChange' in props) {
      props.onChange(key)
    }
  }

  const elProps: any = {
    className: styles['dropdown']
  }

  const buttonProps: any = {
    ref: buttonEl,
    onMouseDown: handleButtonMouseDown,
    className: styles['dropdown__button'],
    type: 'button'
  }

  const dropdownProps: any = {
    className: styles['dropdown__dropdown']
  }

  const text = () => {
    const option = props.value && props.options.find((e: DropdownOption) => e.key === props.value)
    return option ? option.text : props.placeholder
  }

  return (
    <div {...elProps}>
      <button {...buttonProps}>
        <span className={styles['dropdown__text']}>{text()}</span>
        <Icon.ChevronDown width={18} />
      </button>
      {expanded &&
        <div {...dropdownProps}>
          {props.options.map((item: DropdownOption) => {
            return (
              <div
                onMouseDown={(e: any) => handleOptionMouseDown(e, item.key)}
                key={item.key}
                // className={styles['dropdown__link'] + ((item.key === location.pathname) ? ' ' + styles['dropdown__link--current'] : '')}
                className={styles['dropdown__option']}
              >
                {item.text}
              </div>
            )
          })}
        </div>
      }
      {/* {isInvalid && <span className={styles.error}>{validateMessage}</span>} */}
    </div>
  )
}

export default Dropdown
export { Dropdown }
export type { DropdownOption }
