import { useState, useEffect } from 'react'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import { ProgramCard, ProgramCardProps } from './ProgramCard/ProgramCard'
import ArchivalProgramCard from './ArchivalProgramCard/ArchivalProgramCard'
import styles from './ProgramsSection.module.css'
import { useLocation } from 'react-router-dom'

const ProgramsSection = () => {
  const [programs, setPrograms] = useState([])
  const [expandedPrograms, setExpandedPrograms] = useState(new Map())
  const [archivalPrograms, setArchivalPrograms] = useState([])
  const location = useLocation()

  const fetchPrograms = async () => {
    const response = await fetch('/api/programs')
    const data = await response.json()
    setPrograms(data.filter((program: ProgramCardProps) => program.active === true))
    setArchivalPrograms(data.filter((program: ProgramCardProps) => program.active === false))
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const id = urlParams.get('id')
    if (id) {
      const el: any = document.getElementById(`program-${id}`)
      if (el) {
        el.scrollIntoView({block: 'start', behavior: 'smooth'})
      }
      handleToggleExpaned(id)
    } else {
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs])

  useEffect(() => {
    fetchPrograms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggleExpaned = (id: any) => {
    let tmp = new Map(expandedPrograms)
    tmp.set(parseInt(id), !getExpandedById(id))
    setExpandedPrograms(tmp)
  }

  const getExpandedById = (id: any) => {
    return Boolean(expandedPrograms.get(id))
  }

  return (
    <PageSection bgColor='light-gray'>

      <SectionHeading>Программы</SectionHeading>
      {programs.map((program: ProgramCardProps, index: number) => {
        return <ProgramCard
          key={program.id}
          imagePosition={(index & 1) ? 'left' : 'right'}
          expanded={getExpandedById(program.id)}
          onToggleExpanded={() => handleToggleExpaned(program.id)}
          {...program}
        />
      })}

      <SectionHeading>Архивные программы</SectionHeading>
      <div className={styles.ArchivalPrograms}>
        {archivalPrograms.map((program: ProgramCardProps, index: number) => {
          return <ArchivalProgramCard
            key={program.id}
            {...program}
          />
        })}
      </div>

    </PageSection>
  )
}

export default ProgramsSection
