import { NumberListCardProps } from 'components/NumberListCard/NumberListCard'

const heading: string = 'Наша услуга будет актуальной, если вы хотите:'

const itemList: NumberListCardProps[] = [
  {
    id: 1,
    number: 1,
    description: 'Провести любой тип исследования для закрытого или открытого использования;'
  },
  {
    id: 2,
    number: 2,
    description: 'Получить консалтинговые услуги в сфере онлайн-ресурсов, посвященных современным технологиям;'
  },
  {
    id: 3,
    number: 3,
    description: 'Определить текущий уровень цифровой зрелости своей организации;'
  },
  {
    id: 4,
    number: 4,
    description: 'Привлечь к работе над своим проектом отраслевых специалистов, а также получить доступ к экспертному контенту;'
  },
  {
    id: 5,
    number: 5,
    description: 'Разработать инструменты управления и методические рекомендации;'
  },
  {
    id: 6,
    number: 6,
    description: 'Получить уникальный материал, который можно использовать в качестве инфоповода для СМИ;'
  }
]

const buttonText: string = 'Стать партнером'

export { heading, itemList, buttonText }
