import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Heading from 'components/Heading/Heading'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'

import styles from './WhyWeSection.module.css'

// import { whenRelevantTitle, whenRelevantItems, whenRelevantBannerUrl } from '../data'

const items: any = [
  'CDTO, CDO, CTO',
  'руководителей проекта',
  'инженеров',
  'аналитиков',
  'технических писателей',
  'владельцев продукта',
  'разработчиков',
  'Scrum-мастеров',
  'архитекторов',
  'специалистов в области Data Science',
  'и многих других профессионалов в области цифровой трансформации'
]

const WhyWeSection = () => {
  return (
    <PageSection bgColor='light-gray'>
      <Grid cols={2} tabletCols={1} columnGap={20}>
        <Stack gap={60}>
          <Stack gap={30}>
            <div></div>
            <SectionHeading>Почему мы?</SectionHeading>
          </Stack>
          <Stack gap={20}>
            <Heading variant='3'>Команда экспертов</Heading>
            <Text>Опыт наших экспертов позволил разработать систему оценки специалистов для команд цифровой трансформации. Эта система помогает отобрать квалифицированные и мотивированные к развитию кадры.</Text>
          </Stack>
          <Stack gap={20}>
            <Heading variant='3'>Современные методики</Heading>
            <Text>Используемые нами современные методики оценки специалистов позволяют находить именно таких сотрудников, которые нужны работодателю.</Text>
          </Stack>
        </Stack>

        <Card>
          <Stack gap={60}>
            <SectionHeading>Кого мы ищем?</SectionHeading>
            <Stack>
              <ul className={styles.TwoColsUl}>
                {items.slice(0, 10).map((item: string, index: number) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
              <ul>
                {items.slice(-1).map((item: string, index: number) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </PageSection>
  )
}

export default WhyWeSection
