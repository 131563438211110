import React from 'react'
import HeaderSection from 'page-sections/v3/HeaderSection/HeaderSection'
import FooterSection from 'page-sections/v3/FooterSection/FooterSection'
import styles from './CompetencyModelPage.module.css'
// import CompetencyModal from 'components/CompetencyModal'
import Modal from 'components/Modal/Modal'
import Text from 'components/v2/Text/Text'
import nbspacer from 'common/nbspacer'
import Stack from 'components/Stack/Stack'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import PageSection from 'components/v2/PageSection/PageSection'
import Heading from 'components/v2/Heading/Heading'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import QuestionSection from 'page-sections/v3/QuestionSection/QuestionSection'

import { ReactComponent as VkIcon } from 'assets/icons/vk.svg'
import { ReactComponent as CopyLinkIcon } from 'assets/icons/copy-link.svg'
import colors from 'common/colors'
import Button from 'components/Button/Button'
import useMediaQuery from 'common/useMediaQuery'
import CompetencyModelDetailSection from './sections/CompetencyModelDetailSection'

const CompetencyModelPage = () => {
  const [showModal, setShowModal] = React.useState(false)
  const [currentItem, setCurrentItem] = React.useState(null as any)
  const [showDetail, setShowDetail] = React.useState(false)

  const [isCopyModalVisible, setIsCopyModalVisible] = React.useState(false)

  const { isPhone } = useMediaQuery()

  const url = window.location.href
  const vkShareUrl = `https://vk.com/share.php?url=${url}`

  const items: any = {
    'Личностные компетенции в сфере цифрового развития': {
      title: 'Личностные компетенции в сфере цифрового развития',
      titleColor: 'white',
      closeButtonColor: 'white',
      bgColor: 'red',
      content: <Text variant='text-m' color='white'>
        {nbspacer('Группа компетенций, отражающих индивидуальные особенности личности, позволяющие успешно участвовать в реализации стратегии цифровой трансформации и проектах цифрового развития')}
      </Text>
    },
    'Клиентоцентричность': {
      title: 'Клиентоцентричность',
      titleColor: 'red',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей личности, обеспечивающая сознательную направленность на определение и максимальное удовлетворение явных и скрытых потребностей пользователей результатов цифровой трансформации с учетом разумных издержек и соблюдения интересов заинтересованных сторон')}
      </Text>
    },
    'Коммуникативность': {
      title: 'Коммуникативность',
      titleColor: 'red',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей участника проектной деятельности, обеспечивающая выбор наилучшей стратегии и тактики общения, эффективную деловую коммуникацию, открытость и проявление уважения в целях развития сотрудничества в процессе реализации стратегии цифровой трансформации и проектов цифрового развития')}
      </Text>
    },
    'Нацеленность на результат': {
      title: 'Нацеленность на результат',
      titleColor: 'red',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей личности, обеспечивающая максимизацию собственных возможностей при выполнении взятых обязательств, решении нестандартных, сложных задач для полного и своевременного получения результатов и достижения целей цифрового развития наиболее продуктивным способом')}
      </Text>
    },
    'Эмоциональный интеллект': {
      title: 'Эмоциональный интеллект',
      titleColor: 'red',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей личности, обеспечивающая возможность управлять личными эмоциями и эмоциями других людей для решения практических задач, верно оценивать эмоции, намерения, мотивацию и желания (свои и чужие), создавать и поддерживать рабочую атмосферу во время командной работы')}
      </Text>
    },
    'Креативность': {
      title: 'Креативность',
      titleColor: 'red',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей личности, обеспечивающих способность формулировать нестандартные идеи, отходить от традиционных схем мышления, быстро находить выход из сложных ситуаций, используя нешаблонные подходы')}
      </Text>
    },
    'Критичность': {
      title: 'Критичность',
      titleColor: 'red',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей личности, позволяющая обдуманно и взвешенно проводить оценку событий, процессов, результатов деятельности для всесторонней проверки и исправления возможно допущенных ошибок; способность осознавать свои ошибочные решения и приводить доводы за и против, выдвигать предложения и подвергать их всестороннему анализу')}
      </Text>
    },
    'Цифровая культура': {
      title: 'Цифровая культура',
      titleColor: 'white',
      closeButtonColor: 'white',
      bgColor: 'orange',
      content: <Stack gap={20}>
        <Stack gap={4}>
          <Text variant='text-m-medium' color='white'>
            {nbspacer('Цифровая культура организации')}
          </Text>
          <Text variant='text-m' color='white'>
            {nbspacer('– система ценностей, установок, норм и правил поведения в цифровой среде, которую принимают, поддерживают и транслируют работники и руководство организации')}
          </Text>
        </Stack>
        <Stack gap={4}>
          <Text variant='text-m-medium' color='white'>
            {nbspacer('Цифровая культура специалиста')}
          </Text>
          <Text variant='text-m' color='white'>
            {nbspacer('– проявление совокупности личностных и профессиональных компетенций, сформированной в системе ценностей, установок, норм и правил поведения в цифровой среде, необходимой для эффективной работы с применением цифровых технологий в рамках своей профессиональной деятельности')}
          </Text>
        </Stack>
      </Stack>
    },
    'Цифровое мышление': {
      title: 'Цифровое мышление',
      titleColor: 'orange',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Осознанное познание цифровой среды, основанное на когнитивных способностях, позволяющих переосмыслить привычные процессы, изменить установки, модели поведения и адаптироваться к требованиям цифровой жизни')}
      </Text>
    },
    'Цифровая аффилиация': {
      title: 'Цифровая аффилиация',
      titleColor: 'orange',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Личные установки и мотивы, которые влияют на отношение человека к цифровым технологиям и их использованию в профессиональной деятельности. Например: интерес к новым технологиям, цифровые хобби (стриминг, программирование, гейминг, цифровая графика, цифровые книги и пр.), доступность технологий, удобство их использования и пр.')}
      </Text>
    },
    'Цифровая гибкость': {
      title: 'Цифровая гибкость',
      titleColor: 'orange',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Быстрая адаптация к изменяющимся условиям использования цифровых технологий и ресурсов, а также эффективное использование их в новых и непредсказуемых ситуациях, включая умение быстро ориентироваться в новых технологиях, осваивать и использовать новые инструменты и ресурсы, а также готовность к постоянному обучению и развитию')}
      </Text>
    },
    'Цифровой опыт': {
      title: 'Цифровой опыт',
      titleColor: 'orange',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Практический опыт использования цифровых технологий и ресурсов для достижения конкретных целей и удовлетворения личных и профессиональных потребностей. Влияет на поведение человека в цифровой среде, его предпочтения и потребности, а также на качество жизни и профессиональную эффективность')}
      </Text>
    },
    'Базовые цифровые компетенции': {
      title: 'Базовые цифровые компетенции',
      titleColor: 'white',
      closeButtonColor: 'white',
      bgColor: 'blue-gray',
      content: <Text variant='text-m' color='white'>
        {nbspacer('Минимально необходимый уровень знаний и навыков использования информационно-коммуникационных технологий (ИКТ) в повседневной и профессиональной деятельности')}
      </Text>
    },
    'Цифровая грамотность': {
      title: 'Цифровая грамотность',
      titleColor: 'blue-gray',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Базовая компетенция, включающая умения и навыки применения цифровых технологий для получения, оценки, обработки информации, выбора и безопасного применения программно-технических средств')}
      </Text>
    },
    'Цифровые коммуникации': {
      title: 'Цифровые коммуникации',
      titleColor: 'blue-gray',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Базовая компетенция, включающая умения и навыки применения цифровых технологий для создания цифрового контента, коммуникации и обмена информацией в различных контекстах, выбора технологий, сервисов, каналов, средств и форм коммуникаций, соблюдение этических стандартов и принципов')}
      </Text>
    },
    'Цифровая безопасность': {
      title: 'Цифровая безопасность',
      titleColor: 'blue-gray',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Базовая компетенция, включающая умения и навыки, необходимые для защиты и обеспечения безопасности информации в цифровой среде, в том числе защита устройств и сетей, обеспечение безопасности паролей, защита конфиденциальной информации, предотвращение кибератак и злонамеренных действий, а также управление репутацией в цифровой среде')}
      </Text>
    },
    'Цифровое потребление': {
      title: 'Цифровое потребление',
      titleColor: 'blue-gray',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Базовая компетенция, позволяющая применять цифровые знания, умения и навыки использования цифровых ресурсов (интернет, социальные сети, государственные услуги, телемедицина и т. д.) в различных жизненных ситуациях, понимание цифровых прав и обязанностей в качестве потребителя цифровых услуг')}
      </Text>
    },
    'Профессиональные компетенции': {
      title: 'Профессиональные компетенции',
      titleColor: 'white',
      closeButtonColor: 'white',
      bgColor: 'bright-emerald',
      content: <Text variant='text-m' color='white'>
        {nbspacer('Группа компетенций, связанных с функциональным использованием методов и инструментов управления процессами, проектами, продуктами цифровой трансформации и регулярным решением сложных профессиональных задач в цифровой среде')}
      </Text>
    },
    'Управление цифровым развитием': {
      title: 'Управление цифровым развитием',
      titleColor: 'bright-emerald',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Применение методов и инструментов стратегического, тактического и оперативного управления внедрением и развитием цифровых технологий, услуг, инфраструктуры')}
      </Text>
    },
    'Развитие организационной культуры': {
      title: 'Развитие организационной культуры',
      titleColor: 'bright-emerald',
      content: <Text variant='text-m' color='black'>
        {nbspacer('(В условиях цифровой трансформации) Применение технологий формирования, управления, изменения функциональных процессов и системы цифровых ценностей, норм и правил поведения персонала, нацеленных на повышение результативности организации')}
      </Text>
    },
    'Инструменты управления': {
      title: 'Инструменты управления',
      titleColor: 'bright-emerald',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность моделей и методов, используемых для решения задач управления процессами, проектами, продуктами и их информационного, организационного и методического обеспечения')}
      </Text>
    },
    'Управление и использование данных': {
      title: 'Управление и использование данных',
      titleColor: 'bright-emerald',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Знание и применение методов и технологий сбора, структурирования, анализа данных для построения новых организационных и управленческих моделей, продуктов и сервисов в системе государственного управления')}
      </Text>
    },
    'Применение цифровых технологий': {
      title: 'Применение цифровых технологий',
      titleColor: 'bright-emerald',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Знание и использование методов проектирования, построения и управления корпоративной архитектурой, управления ИТ-системами, применения сквозных технологий, а также средств и методов информационной и кибербезопасности в системе государственного управления')}
      </Text>
    },
    'Развитие ИТ-инфраструктуры': {
      title: 'Развитие ИТ-инфраструктуры',
      titleColor: 'bright-emerald',
      content: <Text variant='text-m' color='black'>
        {nbspacer('Применение методов и инструментов, направленных на развитие совокупности информационных центров, подсистем, банков данных и знаний, систем связи, центров управления, аппаратно-программных средств и технологий обеспечения сбора, хранения, обработки и передачи информации организации')}
      </Text>
    },
    'Механизмы и инструменты цифровой трансформации': {
      title: 'Механизмы и инструменты цифровой трансформации',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровая трансформация на основе платформ</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Правовое регулирование цифровой среды</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Критерии цифровой зрелости</Text></TextWithDot>
      </Stack>
    },
    'Концептуальные положения цифровой экономики': {
      title: 'Концептуальные положения цифровой экономики',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Целевые ориентиры развития промышленных технологий и инноваций</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Тезаурус цифровой экономики</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Приоритетные направления цифровой экономики</Text></TextWithDot>
      </Stack>
    },
    'Стратегическое управление цифровым развитием': {
      title: 'Стратегическое управление цифровым развитием',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Анализ текущего состояния</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Разработка дородной карты цифрового  развития организации</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Модель управления цифровой трансформацией</Text></TextWithDot>
      </Stack>
    },
    'Государственная технологическая повестка': {
      title: 'Государственная технологическая повестка',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Обеспечение технологического суверенитета</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровизация государственных услуг</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Отраслевая технологическая повестка</Text></TextWithDot>
      </Stack>
    },
    'Инструменты и методы формирования и поддержания организационной культуры': {
      title: 'Инструменты и методы формирования и поддержания организационной культуры',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы теории организационной культуры</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Принципы и этапы формирования организационной культуры</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Условия поддержания организационной культуры</Text></TextWithDot>
      </Stack>
    },
    'Механизмы управления организационными изменениями': {
      title: 'Механизмы управления организационными изменениями',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Принципы и алгоритмы управления организационными изменениями</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Модели управления организационными изменениями</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Методы работы с сопротивлением</Text></TextWithDot>
      </Stack>
    },
    'Технологии управления кадровой политикой цифрового развития': {
      title: 'Технологии управления кадровой политикой цифрового развития',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Организационные структуры управления цифровой трансформацией</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровые команды: модель компетенций; модели формирования</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Управление HR-циклом организации</Text></TextWithDot>
      </Stack>
    },
    'Принципы и алгоритмы практик регулярного менеджмента': {
      title: 'Принципы и алгоритмы практик регулярного менеджмента',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Управление ресурсами организации</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Управление командами</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Управление личной эффективностью</Text></TextWithDot>
      </Stack>
    },
    'Методы и инструменты процессного подхода': {
      title: 'Методы и инструменты процессного подхода',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы процессного управления</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Ролевая модель управления процессами</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Порядок и инструменты проведения реинжиниринга и оптимизации процессов</Text></TextWithDot>
      </Stack>
    },
    'Методы и инструменты продуктового подхода': {
      title: 'Методы и инструменты продуктового подхода',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы продуктового подхода</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Ролевая модель продуктовой команды</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Методы и этапы разработки продукта</Text></TextWithDot>
      </Stack>
    },
    'Методы и инструменты проектного подхода': {
      title: 'Методы и инструменты проектного подхода',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы проектного управления</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Ролевая модель управления проектом</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Методы и инструменты управления проектом</Text></TextWithDot>
      </Stack>
    },
    'Методы и инструменты антикризисного менеджмента': {
      title: 'Методы и инструменты антикризисного менеджмента',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы антикризисного менеджмента</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Методы и инструменты антикризисного менеджмента</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Эффективность антикризисного менеджмента</Text></TextWithDot>
      </Stack>
    },
    'Инструменты управления данными': {
      title: 'Инструменты управления данными в рамках функциональных направлений',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Планирование и проектирование данных</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Обеспечение доступности и обслуживания</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Использование и улучшение данных</Text></TextWithDot>
      </Stack>
    },
    'Технологии управления большими данными': {
      title: 'Технологии управления большими данными',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Архитектуры и среды обработки больших данных</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Процессы управления большими данными</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Техники и методы анализа больших данных</Text></TextWithDot>
      </Stack>
    },
    'Основы руководства данными': {
      title: 'Основы руководства данными',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Инструменты руководства данными</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Обеспечение качества данных</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Этика обращения с данными</Text></TextWithDot>
      </Stack>
    },
    'Управление организацией на основе данных': {
      title: 'Управление организацией на основе данных',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Интеграция управления на данных в системы менеджмента организации</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Механизм принятия решений, основанных на данных</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Оценка зрелости управления данными</Text></TextWithDot>
      </Stack>
    },
    'Сквозные технологии': {
      title: 'Сквозные технологии',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровые технологии в бизнес-процессах систем</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровые технологии, формирующие новую инфраструктуру</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровые технологии проектирования и моделирования</Text></TextWithDot>
      </Stack>
    },
    'ИИ-решения': {
      title: 'ИИ-решения',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы искусственного интеллекта</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Технологии ИИ-решений</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Оценка зрелости внедрения ИИ-решений</Text></TextWithDot>
      </Stack>
    },
    'Технологии трансформации общества': {
      title: 'Технологии трансформации общества',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Технологии умного города</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровое управление регионом</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровые профили граждан</Text></TextWithDot>
      </Stack>
    },
    'Перспективные технологии': {
      title: 'Перспективные технологии',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Технологии устойчивого развития</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Биотехнологии</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Новые материалы</Text></TextWithDot>
      </Stack>
    },
    'Основы создания ИТ-инфраструктуры организации': {
      title: 'Основы создания ИТ-инфраструктуры организации',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Формирование технической документации на этапах создания ИТ-инфраструктуры</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Характеристики архитектуры организации</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Процесс разработки архитектуры организации</Text></TextWithDot>
      </Stack>
    },
    'Инфраструктурные решения': {
      title: 'Инфраструктурные решения',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Принципы построения ИТ-инфраструктуры</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Концепции управления ИТ-инфраструктурой</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Модели и принципы построения облачных решений (IaaS, PaaS, SaaS)</Text></TextWithDot>
      </Stack>
    },
    'Средства и методы информационной и кибербезопасности': {
      title: 'Средства и методы информационной и кибербезопасности',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цели и задачи защиты информации,  типы и источники угроз</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Системы управления информационной безопасностью</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Инструменты защиты информации и обеспечения кибербезопасности</Text></TextWithDot>
      </Stack>
    },
    'Национальная ИТ-инфраструктура': {
      title: 'Национальная ИТ-инфраструктура',
      titleColor: 'bright-emerald',
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Государственные информационные системы (ГИС)</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Технологии платформы «ГосТех»</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Инфраструктура защищенных коммуникаций</Text></TextWithDot>
      </Stack>
    }
  }

  const onClick = (event: any, item: any) => {
    event.stopPropagation()
    setCurrentItem(items[item])
    setShowModal(true)
    return false
  }

  const copyUrl = (event: React.SyntheticEvent) => {
    event.preventDefault()
    navigator.clipboard.writeText(url).then(() => {
      setIsCopyModalVisible(true)
    })
  }

  return (
    <div>
      <Modal
        visible={showModal}
        title={currentItem?.title}
        titleColor={currentItem?.titleColor}
        closeButtonColor={currentItem?.closeButtonColor}
        bgColor={currentItem?.bgColor}
        onClose={() => setShowModal(false)}
        width={isPhone ? 300 : 632}
      >
        {currentItem?.content}
      </Modal>

      <Modal
        visible={isCopyModalVisible}
        onClose={() => setIsCopyModalVisible(false)}
      >
        <Stack gap={30}>
          <Stack gap={6}>
            <Text>Ссылка скопирована в буфер обмена</Text>
          </Stack>
          <Stack alignItems='flex-end'><Button variant='filled' onClick={() => setIsCopyModalVisible(false)}>Ok</Button></Stack>
        </Stack>
      </Modal>
      <HeaderSection bgColor='bright-emerald' style={{ paddingBottom: 0 }}>
        <Grid cols={2} tabletCols={1} columnGap={40} rowGap={20}>
          <Stack gap={40}>
            <Heading variant='1' color='white'>{nbspacer('Модель компетенций 3.0')}</Heading>
            <Text color='white'>{nbspacer('Методологическая разработка Центра, на основе которой выстроена комплексная оценка руководителей и команд цифровой трансформации. ')}</Text>
          </Stack>
          <Image width='100%' src='/static/img/pages/competency-models/image1.svg' />
        </Grid>
      </HeaderSection>
      <PageSection>
        <Stack gap={20}>
          <Heading variant='2' color='bright-emerald'>Описание</Heading>
          <Grid cols={2} tabletCols={1} columnGap={80} rowGap={20}>
            <Text>{nbspacer('Теоретическая основа Модели построена на исследованиях российского и международного опыта и анализа лучших практик, доказавших свою эффективность. К разработке и актуализации Модели компетенций привлечена группа отраслевых экспертов')}</Text>
            <Text>{nbspacer('и ИТ-специалистов, занимающихся цифровой трансформацией. Третья версия Модели компетенций разработана в 2023 году и учитывает актуальную технологическую повестку и важнейшие тенденции в области цифрового развития.')}</Text>
          </Grid>
          <svg width="100%" height="100%" viewBox="0 0 1314 767" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1208.09 728H921.91L911 747.45L921.91 767H1208.09L1219 747.45L1208.09 728Z" fill="#B62936" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1075" y="741.75">&#x41d;&#x430;&#x441;&#x442;&#x43e;&#x439;&#x447;&#x438;&#x432; &#13;&#10;</tspan><tspan x="1075" y="751.75">&#x432; &#x434;&#x43e;&#x441;&#x442;&#x438;&#x436;&#x435;&#x43d;&#x438;&#x438; &#x446;&#x435;&#x43b;&#x438; &#13;&#10;</tspan><tspan x="1075" y="761.75">&#x438; &#x43f;&#x440;&#x435;&#x43e;&#x434;&#x43e;&#x43b;&#x435;&#x43d;&#x438;&#x438; &#x442;&#x440;&#x443;&#x434;&#x43d;&#x43e;&#x441;&#x442;&#x435;&#x439;</tspan></text>
            <path d="M1070 729V766" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="925" y="741.75">&#x41f;&#x440;&#x435;&#x434;&#x43b;&#x430;&#x433;&#x430;&#x435;&#x442; &#x441;&#x432;&#x43e;&#x438; &#13;&#10;</tspan><tspan x="925" y="751.75">&#x438; &#x43f;&#x43e;&#x434;&#x434;&#x435;&#x440;&#x436;&#x438;&#x432;&#x430;&#x435;&#x442; &#13;&#10;</tspan><tspan x="925" y="761.75">&#x44d;&#x444;&#x444;&#x435;&#x43a;&#x442;&#x438;&#x432;&#x43d;&#x44b;&#x435; &#x438;&#x434;&#x435;&#x438; &#x43a;&#x43e;&#x43c;&#x430;&#x43d;&#x434;&#x44b;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1220.09 687H944.91L934 706.45L944.91 726H1220.09L1231 706.45L1220.09 687Z" fill="#B62936" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1104" y="700.75">&#x413;&#x438;&#x431;&#x43a;&#x43e; &#x438; &#x430;&#x434;&#x435;&#x43a;&#x432;&#x430;&#x442;&#x43d;&#x43e; &#13;&#10;</tspan><tspan x="1104" y="710.75">&#x440;&#x435;&#x430;&#x433;&#x438;&#x440;&#x443;&#x435;&#x442; &#x43d;&#x430; &#x438;&#x437;&#x43c;&#x435;&#x43d;&#x435;&#x43d;&#x438;&#x435; </tspan><tspan x="1104" y="720.75">&#x441;&#x438;&#x442;&#x443;&#x430;&#x446;&#x438;&#x438;</tspan></text>
            <path d="M1099 688V725" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="948" y="700.75">&#x41f;&#x440;&#x43e;&#x430;&#x43a;&#x442;&#x438;&#x432;&#x43d;&#x43e; &#x43c;&#x44b;&#x441;&#x43b;&#x438;&#x442; &#13;&#10;</tspan><tspan x="948" y="710.75">&#x438; &#x434;&#x435;&#x439;&#x441;&#x442;&#x432;&#x443;&#x435;&#x442; &#x432; &#x43b;&#x43e;&#x433;&#x438;&#x43a;&#x435;&#10;</tspan><tspan x="948" y="720.75">&#xab;&#x446;&#x435;&#x43b;&#x44c; &#x2013; &#x434;&#x435;&#x439;&#x441;&#x442;&#x432;&#x438;&#x435; &#x2013; &#x440;&#x435;&#x437;&#x443;&#x43b;&#x44c;&#x442;&#x430;&#x442;&#xbb;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1238.09 646H968.91L958 665.45L968.91 685H1238.09L1249 665.45L1238.09 646Z" fill="#85180F" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1115" y="659.75">&#x41a;&#x43e;&#x440;&#x440;&#x435;&#x43a;&#x442;&#x43d;&#x43e;&#10;</tspan><tspan x="1115" y="669.75">&#x438; &#x43e;&#x431;&#x43e;&#x441;&#x43d;&#x43e;&#x432;&#x430;&#x43d;&#x43d;&#x43e; &#x43e;&#x442;&#x441;&#x442;&#x430;&#x438;&#x432;&#x430;&#x435;&#x442; </tspan><tspan x="1115" y="679.75">&#x441;&#x432;&#x43e;&#x438; &#x438;&#x43d;&#x442;&#x435;&#x440;&#x435;&#x441;&#x44b;</tspan></text>
            <path d="M1110 647V684" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="972" y="659.75">&#x41f;&#x440;&#x43e;&#x44f;&#x432;&#x43b;&#x44f;&#x435;&#x442; &#x443;&#x432;&#x430;&#x436;&#x435;&#x43d;&#x438;&#x435; &#x438; &#10;</tspan><tspan x="972" y="669.75">&#x438;&#x441;&#x43f;&#x43e;&#x43b;&#x44c;&#x437;&#x443;&#x435;&#x442; &#x442;&#x435;&#x445;&#x43d;&#x438;&#x43a;&#x438; </tspan><tspan x="972" y="679.75">&#x430;&#x43a;&#x442;&#x438;&#x432;&#x43d;&#x43e;&#x433;&#x43e; &#x441;&#x43b;&#x443;&#x448;&#x430;&#x43d;&#x438;&#x44f;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1250.09 605H991.91L981 624.45L991.91 644H1250.09L1261 624.45L1250.09 605Z" fill="#85180F" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1122" y="617.75">&#x41f;&#x43e;&#x43d;&#x438;&#x43c;&#x430;&#x435;&#x442; &#x43c;&#x43e;&#x442;&#x438;&#x432;&#x44b; &#13;&#10;</tspan><tspan x="1122" y="627.75">&#x443;&#x447;&#x430;&#x441;&#x442;&#x43d;&#x438;&#x43a;&#x43e;&#x432; &#x43a;&#x43e;&#x43c;&#x43c;&#x443;&#x43d;&#x438;&#x43a;&#x430;&#x446;&#x438;&#x438;, </tspan><tspan x="1122" y="637.75">&#13;&#x432;&#x43b;&#x438;&#x44f;&#x435;&#x442; &#x43d;&#x430; &#x43d;&#x438;&#x445; </tspan></text>
            <path d="M1117 606V643" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="995" y="616.75">&#x41e;&#x434;&#x438;&#x43d;&#x430;&#x43a;&#x43e;&#x432;&#x43e; &#x44d;&#x444;&#x444;&#x435;&#x43a;&#x442;&#x438;&#x432;&#x43d;&#x43e; &#13;&#10;</tspan><tspan x="995" y="626.75">&#x438;&#x441;&#x43f;&#x43e;&#x43b;&#x44c;&#x437;&#x443;&#x435;&#x442; &#x440;&#x430;&#x437;&#x43d;&#x44b;&#x435; &#13;&#10;</tspan><tspan x="995" y="636.75">&#x43a;&#x430;&#x43d;&#x430;&#x43b;&#x44b; &#x43a;&#x43e;&#x43c;&#x43c;&#x443;&#x43d;&#x438;&#x43a;&#x430;&#x446;&#x438;&#x439;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1013.4 564H1262.4L1271.8 583.5L1262.4 603H1013.4L1004 583.5L1013.4 564Z" fill="#B62936" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1143" y="577.75">&#x417;&#x430;&#x43f;&#x440;&#x430;&#x448;&#x438;&#x432;&#x430;&#x435;&#x442; &#13;&#10;</tspan><tspan x="1143" y="587.75">&#x438; &#x438;&#x441;&#x43f;&#x43e;&#x43b;&#x44c;&#x437;&#x443;&#x435;&#x442; &#13;&#10;</tspan><tspan x="1143" y="597.75">&#x43e;&#x431;&#x440;&#x430;&#x442;&#x43d;&#x443;&#x44e; &#x441;&#x432;&#x44f;&#x437;&#x44c;</tspan></text>
            <path d="M1138 565V602" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1018" y="577.75">&#x410;&#x434;&#x430;&#x43f;&#x442;&#x438;&#x440;&#x443;&#x435;&#x442; &#x440;&#x435;&#x448;&#x435;&#x43d;&#x438;&#x44f; &#13;</tspan><tspan x="1018" y="587.75">&#x43f;&#x43e;&#x434; &#x43f;&#x43e;&#x442;&#x440;&#x435;&#x431;&#x43d;&#x43e;&#x441;&#x442;&#x438; &#13;&#10;</tspan><tspan x="1018" y="597.75">&#x43a;&#x430;&#x436;&#x434;&#x43e;&#x433;&#x43e; </tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1296.09 523H1037.91L1027 542.45L1037.91 562H1296.09L1307 542.45L1296.09 523Z" fill="#B62936" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1172" y="536.75">&#x421;&#x43e;&#x437;&#x434;&#x430;&#x435;&#x442; &#x43c;&#x430;&#x43a;&#x441;&#x438;&#x43c;&#x430;&#x43b;&#x44c;&#x43d;&#x443;&#x44e; </tspan><tspan x="1172" y="546.75">&#x446;&#x435;&#x43d;&#x43d;&#x43e;&#x441;&#x442;&#x44c; &#x43d;&#x430; &#x43e;&#x441;&#x43d;&#x43e;&#x432;&#x435; &#13;&#10;</tspan><tspan x="1172" y="556.75">&#x43f;&#x43e;&#x442;&#x440;&#x435;&#x431;&#x43d;&#x43e;&#x441;&#x442;&#x435;&#x439;</tspan></text>
            <path d="M1167 524V561" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1041" y="536.75">&#x41f;&#x43e;&#x43d;&#x438;&#x43c;&#x430;&#x435;&#x442; &#13;&#10;</tspan><tspan x="1041" y="546.75">&#x438; &#x443;&#x447;&#x438;&#x442;&#x44b;&#x432;&#x430;&#x435;&#x442; &#13;&#10;</tspan><tspan x="1041" y="556.75">&#x43f;&#x43e;&#x442;&#x440;&#x435;&#x431;&#x43d;&#x43e;&#x441;&#x442;&#x438; &#x43b;&#x44e;&#x434;&#x435;&#x439;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M393.09 728H134.91L124 747.45L134.91 767H393.09L404 747.45L393.09 728Z" fill="#B62936" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="277" y="741.75">&#x412;&#x44b;&#x434;&#x435;&#x43b;&#x44f;&#x435;&#x442; &#x438; &#x443;&#x447;&#x438;&#x442;&#x44b;&#x432;&#x430;&#x435;&#x442; &#13;&#10;</tspan><tspan x="277" y="751.75">&#x432;&#x441;&#x435; &#x430;&#x43a;&#x442;&#x443;&#x430;&#x43b;&#x44c;&#x43d;&#x44b;&#x435; &#x444;&#x430;&#x43a;&#x442;&#x43e;&#x440;&#x44b; &#10;</tspan><tspan x="277" y="761.75">&#x43f;&#x440;&#x438; &#x440;&#x435;&#x448;&#x435;&#x43d;&#x438;&#x438; &#x437;&#x430;&#x434;&#x430;&#x447;</tspan></text>
            <path d="M272.898 729V766" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="138" y="741.75">&#x410;&#x43d;&#x430;&#x43b;&#x438;&#x437;&#x438;&#x440;&#x443;&#x435;&#x442; &#x438; &#x43e;&#x446;&#x435;&#x43d;&#x438;&#x432;&#x430;&#x435;&#x442; &#13;&#10;</tspan><tspan x="138" y="751.75">&#x438;&#x43d;&#x444;&#x43e;&#x440;&#x43c;&#x430;&#x446;&#x438;&#x44e; &#x434;&#x43b;&#x44f; &#x43f;&#x440;&#x438;&#x43d;&#x44f;&#x442;&#x438;&#x44f; </tspan><tspan x="138" y="761.75">&#x440;&#x435;&#x448;&#x435;&#x43d;&#x438;&#x439;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M370.09 687H101.91L91 706.45L101.91 726H370.09L381 706.45L370.09 687Z" fill="#B62936" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="242" y="700.75">&#x41e;&#x43f;&#x438;&#x440;&#x430;&#x435;&#x442;&#x441;&#x44f;&#10;</tspan><tspan x="242" y="710.75">&#x43d;&#x430; &#x43c;&#x435;&#x442;&#x430;&#x437;&#x43d;&#x430;&#x43d;&#x438;&#x44f;, &#x43a;&#x440;&#x443;&#x433;&#x43e;&#x437;&#x43e;&#x440;, &#13;&#10;</tspan><tspan x="242" y="720.75">&#x43e;&#x43f;&#x44b;&#x442; &#x438; &#x44d;&#x43a;&#x441;&#x43f;&#x435;&#x440;&#x442;&#x43d;&#x44b;&#x435; &#x43c;&#x43d;&#x435;&#x43d;&#x438;&#x44f;</tspan></text>
            <path d="M238.801 688V725" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="104" y="700.75">&#x421;&#x442;&#x430;&#x432;&#x438;&#x442; &#x43f;&#x43e;&#x434; &#x441;&#x43e;&#x43c;&#x43d;&#x435;&#x43d;&#x438;&#x435; &#x43d;&#x43e;&#x432;&#x443;&#x44e;&#10;</tspan><tspan x="104" y="710.75">&#x438;&#x43d;&#x444;&#x43e;&#x440;&#x43c;&#x430;&#x446;&#x438;&#x44e; &#x438; &#x43f;&#x440;&#x43e;&#x432;&#x435;&#x440;&#x44f;&#x435;&#x442; </tspan><tspan x="104" y="720.75">&#x435;&#x435; &#x434;&#x43e;&#x441;&#x442;&#x43e;&#x432;&#x435;&#x440;&#x43d;&#x43e;&#x441;&#x442;&#x44c;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M346.09 646H87.9104L77 665.45L87.9104 685H346.09L357 665.45L346.09 646Z" fill="#85180F" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="223" y="659.75">&#x423;&#x43c;&#x435;&#x435;&#x442; &#x440;&#x430;&#x441;&#x43f;&#x43e;&#x437;&#x43d;&#x430;&#x432;&#x430;&#x442;&#x44c; &#13;&#10;</tspan><tspan x="223" y="669.75">&#x438; &#x43e;&#x442;&#x43a;&#x430;&#x437;&#x44b;&#x432;&#x430;&#x442;&#x44c;&#x441;&#x44f; &#13;&#10;</tspan><tspan x="223" y="679.75">&#x43e;&#x442; &#x441;&#x442;&#x435;&#x440;&#x435;&#x43e;&#x442;&#x438;&#x43f;&#x43e;&#x432;</tspan></text>
            <path d="M218.5 647V684" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="91" y="659.75">&#x418;&#x441;&#x43f;&#x43e;&#x43b;&#x44c;&#x437;&#x443;&#x435;&#x442; &#13;&#10;</tspan><tspan x="91" y="669.75">&#x43d;&#x435;&#x441;&#x442;&#x430;&#x43d;&#x434;&#x430;&#x440;&#x442;&#x43d;&#x44b;&#x435; &#x441;&#x43f;&#x43e;&#x441;&#x43e;&#x431;&#x44b; &#13;&#10;</tspan><tspan x="91" y="679.75">&#x440;&#x435;&#x448;&#x435;&#x43d;&#x438;&#x44f; &#x43f;&#x440;&#x43e;&#x431;&#x43b;&#x435;&#x43c;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M323.09 605H64.9104L54 624.45L64.9104 644H323.09L334 624.45L323.09 605Z" fill="#85180F" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="201" y="618.75">&#x413;&#x435;&#x43d;&#x435;&#x440;&#x438;&#x440;&#x443;&#x435;&#x442; &#x438; &#x440;&#x435;&#x430;&#x43b;&#x438;&#x437;&#x443;&#x435;&#x442; &#13;&#10;</tspan><tspan x="201" y="628.75">&#x438;&#x43d;&#x43d;&#x43e;&#x432;&#x430;&#x446;&#x438;&#x43e;&#x43d;&#x43d;&#x44b;&#x435; &#x438;&#x434;&#x435;&#x438; &#13;&#10;</tspan><tspan x="201" y="638.75">&#x438; &#x43f;&#x43e;&#x434;&#x445;&#x43e;&#x434;&#x44b;</tspan></text>
            <path d="M196.801 606V643" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="68" y="618.75">&#x41e;&#x431;&#x43b;&#x430;&#x434;&#x430;&#x435;&#x442; &#x43d;&#x435;&#x441;&#x442;&#x430;&#x43d;&#x434;&#x430;&#x440;&#x442;&#x43d;&#x44b;&#x43c; </tspan><tspan x="68" y="628.75">&#x438; &#x43e;&#x440;&#x438;&#x433;&#x438;&#x43d;&#x430;&#x43b;&#x44c;&#x43d;&#x44b;&#x43c; &#13;&#10;</tspan><tspan x="68" y="638.75">&#x43c;&#x44b;&#x448;&#x43b;&#x435;&#x43d;&#x438;&#x435;&#x43c;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M300.09 564H41.9104L31 583.45L41.9104 603H300.09L311 583.45L300.09 564Z" fill="#B62936" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="167" y="576.75">&#x421;&#x43e;&#x437;&#x434;&#x430;&#x435;&#x442; &#x431;&#x43b;&#x430;&#x433;&#x43e;&#x43f;&#x440;&#x438;&#x44f;&#x442;&#x43d;&#x443;&#x44e; &#13;&#10;</tspan><tspan x="167" y="586.75">&#x43f;&#x441;&#x438;&#x445;&#x43e;&#x44d;&#x43c;&#x43e;&#x446;&#x438;&#x43e;&#x43d;&#x430;&#x43b;&#x44c;&#x43d;&#x443;&#x44e; &#13;&#10;</tspan><tspan x="167" y="596.75">&#x430;&#x442;&#x43c;&#x43e;&#x441;&#x444;&#x435;&#x440;&#x443; &#x432; &#x43a;&#x43e;&#x43c;&#x430;&#x43d;&#x434;&#x435;</tspan></text>
            <path d="M162.801 565V602" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="45" y="577.75">&#x41f;&#x43e;&#x43d;&#x438;&#x43c;&#x430;&#x435;&#x442; &#x44d;&#x43c;&#x43e;&#x446;&#x438;&#x438;, &#13;&#10;</tspan><tspan x="45" y="587.75">&#x43d;&#x430;&#x43c;&#x435;&#x440;&#x435;&#x43d;&#x438;&#x44f; &#x438; &#x43c;&#x43e;&#x442;&#x438;&#x432;&#x44b; &#13;&#10;</tspan><tspan x="45" y="597.75">&#x434;&#x440;&#x443;&#x433;&#x438;&#x445; &#x43b;&#x44e;&#x434;&#x435;&#x439;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M276.09 523H17.9104L7 542.45L17.9104 562H276.09L287 542.45L276.09 523Z" fill="#B62936" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="163" y="536.75">&#x421;&#x43e;&#x445;&#x440;&#x430;&#x43d;&#x44f;&#x435;&#x442; &#13;&#10;</tspan><tspan x="163" y="546.75">&#x440;&#x430;&#x431;&#x43e;&#x442;&#x43e;&#x441;&#x43f;&#x43e;&#x441;&#x43e;&#x431;&#x43d;&#x43e;&#x441;&#x442;&#x44c; &#x43f;&#x440;&#x438; </tspan><tspan x="163" y="556.75">&#x43f;&#x43e;&#x432;&#x44b;&#x448;&#x435;&#x43d;&#x43d;&#x44b;&#x445; &#x43d;&#x430;&#x433;&#x440;&#x443;&#x437;&#x43a;&#x430;&#x445;</tspan></text>
            <path d="M158.898 524V561" stroke="white" strokeMiterlimit="10" />
            <text fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="19" y="536.75">&#x41f;&#x43e;&#x43d;&#x438;&#x43c;&#x430;&#x435;&#x442; &#x441;&#x432;&#x43e;&#x435; </tspan><tspan x="19" y="546.75">&#x44d;&#x43c;&#x43e;&#x446;&#x438;&#x43e;&#x43d;&#x430;&#x43b;&#x44c;&#x43d;&#x43e;&#x435; &#x441;&#x43e;&#x441;&#x442;&#x43e;&#x44f;&#x43d;&#x438;&#x435; &#x438; </tspan><tspan x="19" y="556.75">&#x44d;&#x444;&#x444;&#x435;&#x43a;&#x442;&#x438;&#x432;&#x43d;&#x43e; &#x443;&#x43f;&#x440;&#x430;&#x432;&#x43b;&#x44f;&#x435;&#x442; &#x438;&#x43c;</tspan></text>
            <path d="M875 747.795H908.2" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M913.702 747.794C913.702 746.297 912.502 745 910.902 745C909.302 745 908.102 746.198 908.102 747.794C908.102 749.391 909.302 750.589 910.902 750.589C912.502 750.589 913.702 749.391 913.702 747.794Z" fill="white" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M885 706.795H932.1" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M937.6 706.794C937.6 705.297 936.4 704 934.8 704C933.2 704 932 705.198 932 706.794C932 708.291 933.2 709.589 934.8 709.589C936.4 709.589 937.6 708.291 937.6 706.794Z" fill="white" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M930 665L955 665" stroke="#D74A6B" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M960.299 664.794C960.299 663.297 959.099 662 957.499 662C955.899 662 954.699 663.198 954.699 664.794C954.699 666.391 955.899 667.589 957.499 667.589C959.099 667.589 960.299 666.391 960.299 664.794Z" fill="white" stroke="#D74A6B" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M931 623.795H978.1" stroke="#D74A6B" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M983.6 623.794C983.6 622.297 982.4 621 980.8 621C979.2 621 978 622.198 978 623.794C978 625.291 979.2 626.589 980.8 626.589C982.4 626.589 983.6 625.391 983.6 623.794Z" fill="white" stroke="#D74A6B" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M968 582.795H1001.2" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M1004 585.589C1005.55 585.589 1006.8 584.338 1006.8 582.794C1006.8 581.251 1005.55 580 1004 580C1002.45 580 1001.2 581.251 1001.2 582.794C1001.2 584.338 1002.45 585.589 1004 585.589Z" fill="white" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M978 541.795H1025.2" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M1030.7 541.794C1030.7 540.297 1029.5 539 1027.9 539C1026.4 539 1025.1 540.198 1025.1 541.794C1025.1 543.391 1026.3 544.589 1027.9 544.589C1029.4 544.589 1030.7 543.391 1030.7 541.794Z" fill="white" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M439.7 746.795H406.5" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M401 746.794C401 745.297 402.2 744 403.8 744C405.4 744 406.6 745.198 406.6 746.794C406.6 748.391 405.4 749.589 403.8 749.589C402.2 749.589 401 748.391 401 746.794Z" fill="white" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M430.6 705.795H383.5" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M378 705.794C378 704.297 379.2 703 380.8 703C382.4 703 383.6 704.198 383.6 705.794C383.6 707.292 382.4 708.589 380.8 708.589C379.2 708.589 378 707.292 378 705.794Z" fill="white" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M392.8 664.795H359.5" stroke="#D74A6B" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M356.8 667.589C358.346 667.589 359.6 666.338 359.6 664.794C359.6 663.251 358.346 662 356.8 662C355.254 662 354 663.251 354 664.794C354 666.338 355.254 667.589 356.8 667.589Z" fill="white" stroke="#D74A6B" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M383.6 623.795H336.5" stroke="#D74A6B" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M331 623.794C331 622.297 332.2 621 333.8 621C335.4 621 336.6 622.198 336.6 623.794C336.6 625.391 335.4 626.589 333.8 626.589C332.2 626.589 331 625.291 331 623.794Z" fill="white" stroke="#D74A6B" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M360.6 582.795H313.5" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M310.8 585.589C312.346 585.589 313.6 584.338 313.6 582.794C313.6 581.251 312.346 580 310.8 580C309.254 580 308 581.251 308 582.794C308 584.338 309.254 585.589 310.8 585.589Z" fill="white" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M336.7 541.795H289.5" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M286.8 544.589C288.346 544.589 289.6 543.338 289.6 541.794C289.6 540.251 288.346 539 286.8 539C285.254 539 284 540.251 284 541.794C284 543.338 285.254 544.589 286.8 544.589Z" fill="white" stroke="#F196AF" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path onClick={(e) => onClick(e, 'Нацеленность на результат')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 767V687H906.96C914.595 687 919.413 695.211 915.69 701.877L882.174 761.877C880.407 765.04 877.067 767 873.444 767H657Z" fill="#B62936" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="776" y="723.5">&#x41d;&#x410;&#x426;&#x415;&#x41b;&#x415;&#x41d;&#x41d;&#x41e;&#x421;&#x422;&#x42c;&#10;</tspan><tspan x="776" y="739.5">&#x41d;&#x410; &#x420;&#x415;&#x417;&#x423;&#x41b;&#x42c;&#x422;&#x410;&#x422; </tspan></text>
            <path onClick={(e) => onClick(e, 'Критичность')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 767V687H407.04C399.405 687 394.587 695.211 398.31 701.877L431.826 761.877C433.593 765.04 436.933 767 440.556 767H657Z" fill="#B62936" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="440" y="731.5">&#x41a;&#x420;&#x418;&#x422;&#x418;&#x427;&#x41d;&#x41e;&#x421;&#x422;&#x42c;</tspan></text>
            <path onClick={(e) => onClick(e, 'Коммуникативность')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 685V605H953.96C961.595 605 966.413 613.211 962.69 619.877L929.174 679.877C927.407 683.04 924.067 685 920.444 685L657 685Z" fill="#85180F" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="781.001" y="649.5">&#x41a;&#x41e;&#x41c;&#x41c;&#x423;&#x41d;&#x418;&#x41a;&#x410;&#x422;&#x418;&#x412;&#x41d;&#x41e;&#x421;&#x422;&#x42c;</tspan></text>
            <path onClick={(e) => onClick(e, 'Креативность')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 685V605H360.04C352.405 605 347.587 613.211 351.31 619.877L384.826 679.877C386.593 683.04 389.933 685 393.556 685L657 685Z" fill="#85180F" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="393" y="649.5">&#x41a;&#x420;&#x415;&#x410;&#x422;&#x418;&#x412;&#x41d;&#x41e;&#x421;&#x422;&#x42c;</tspan></text>
            <path onClick={(e) => onClick(e, 'Клиентоцентричность')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 603V523H999.96C1007.59 523 1012.41 531.211 1008.69 537.877L975.174 597.877C973.407 601.04 970.067 603 966.444 603H657Z" fill="#B62936" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="834.137" y="567.5">&#x41a;&#x41b;&#x418;&#x415;&#x41d;&#x422;&#x41e;&#x426;&#x415;&#x41d;&#x422;&#x420;&#x418;&#x427;&#x41d;&#x41e;&#x421;&#x422;&#x42c;</tspan></text>
            <path onClick={(e) => onClick(e, 'Эмоциональный интеллект')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 603V523H314.04C306.405 523 301.587 531.211 305.31 537.877L338.826 597.877C340.593 601.04 343.933 603 347.556 603H657Z" fill="#B62936" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="347" y="559.5">&#x42d;&#x41c;&#x41e;&#x426;&#x418;&#x41e;&#x41d;&#x410;&#x41b;&#x42c;&#x41d;&#x42b;&#x419;&#10;</tspan><tspan x="347" y="575.5">&#x418;&#x41d;&#x422;&#x415;&#x41b;&#x41b;&#x415;&#x41a;&#x422;</tspan></text>
            <path onClick={(e) => onClick(e, 'Личностные компетенции в сфере цифрового развития')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M511.038 403.417C518.118 390.806 531.454 383 545.916 383H630.929H684.022H768.28C782.769 383 796.125 390.835 803.195 403.482L847.917 483.482C854.697 495.611 854.697 510.389 847.917 522.518L803.195 602.518C796.125 615.165 782.769 623 768.28 623H684.022H630.929H545.916C531.454 623 518.118 615.194 511.038 602.583L466.119 522.583C459.29 510.421 459.29 495.579 466.119 483.417L511.038 403.417Z" fill="url(#paint0_linear_0_1)" />
            <path d="M466.119 483.417L464.811 482.682L466.119 483.417ZM803.195 602.518L801.885 601.786L803.195 602.518ZM803.195 403.482L801.885 404.214L803.195 403.482ZM630.929 384.5H545.916V381.5H630.929V384.5ZM684.022 384.5H630.929V381.5H684.022V384.5ZM768.28 384.5H684.022V381.5H768.28V384.5ZM846.608 484.214L801.885 404.214L804.504 402.75L849.226 482.75L846.608 484.214ZM801.885 601.786L846.608 521.786L849.226 523.25L804.504 603.25L801.885 601.786ZM684.022 621.5H768.28V624.5H684.022V621.5ZM630.929 621.5H684.022V624.5H630.929V621.5ZM545.916 621.5H630.929V624.5H545.916V621.5ZM467.427 521.849L512.345 601.849L509.73 603.318L464.811 523.318L467.427 521.849ZM512.345 404.151L467.427 484.151L464.811 482.682L509.73 402.682L512.345 404.151ZM464.811 523.318C457.726 510.699 457.726 495.301 464.811 482.682L467.427 484.151C460.854 495.857 460.854 510.143 467.427 521.849L464.811 523.318ZM804.504 603.25C797.169 616.371 783.312 624.5 768.28 624.5V621.5C782.225 621.5 795.081 613.959 801.885 601.786L804.504 603.25ZM849.226 482.75C856.261 495.334 856.261 510.666 849.226 523.25L846.608 521.786C853.134 510.112 853.134 495.888 846.608 484.214L849.226 482.75ZM545.916 624.5C530.911 624.5 517.075 616.401 509.73 603.318L512.345 601.849C519.16 613.986 531.996 621.5 545.916 621.5V624.5ZM768.28 381.5C783.312 381.5 797.169 389.629 804.504 402.75L801.885 404.214C795.081 392.041 782.225 384.5 768.28 384.5V381.5ZM545.916 384.5C531.996 384.5 519.16 392.014 512.345 404.151L509.73 402.682C517.075 389.599 530.911 381.5 545.916 381.5V384.5Z" fill="white" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="24" fontWeight="bold" letterSpacing="0px"><tspan x="572.074" y="470">&#x41b;&#x418;&#x427;&#x41d;&#x41e;&#x421;&#x422;&#x41d;&#x42b;&#x415;&#10;</tspan><tspan x="565.359" y="498">&#x41a;&#x41e;&#x41c;&#x41f;&#x415;&#x422;&#x415;&#x41d;&#x426;&#x418;&#x418;&#10;</tspan><tspan x="526.078" y="526">&#x412; &#x421;&#x424;&#x415;&#x420;&#x415; &#x426;&#x418;&#x424;&#x420;&#x41e;&#x412;&#x41e;&#x413;&#x41e;&#10;</tspan><tspan x="593.977" y="554">&#x420;&#x410;&#x417;&#x412;&#x418;&#x422;&#x418;&#x42f;   </tspan></text>
            <path onClick={(e) => onClick(e, 'Цифровой опыт')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M1021 498V442H1262.73C1270.42 442 1275.23 450.312 1271.4 456.979L1250.73 492.979C1248.95 496.085 1245.64 498 1242.06 498H1021Z" fill="url(#paint1_linear_0_1)" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="14" letterSpacing="0px"><tspan x="1057" y="485.75">(&#x43f;&#x43e;&#x432;&#x435;&#x434;&#x435;&#x43d;&#x447;&#x435;&#x441;&#x43a;&#x438;&#x439; &#x43a;&#x43e;&#x43c;&#x43f;&#x43e;&#x43d;&#x435;&#x43d;&#x442;)</tspan></text>
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="16" fontWeight="500" letterSpacing="0px"><tspan x="1057" y="467.5">&#x426;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x43e;&#x439; &#x43e;&#x43f;&#x44b;&#x442; </tspan></text>
            <path onClick={(e) => onClick(e, 'Цифровая гибкость')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M1054 440V384H1295.73C1303.42 384 1308.23 392.312 1304.4 398.979L1283.73 434.979C1281.95 438.085 1278.64 440 1275.06 440H1054Z" fill="url(#paint2_linear_0_1)" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="14" letterSpacing="0px"><tspan x="1088" y="428.75">(&#x430;&#x434;&#x430;&#x43f;&#x442;&#x438;&#x432;&#x43d;&#x44b;&#x439; &#x43a;&#x43e;&#x43c;&#x43f;&#x43e;&#x43d;&#x435;&#x43d;&#x442;)</tspan></text>
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="16" fontWeight="500" letterSpacing="0px"><tspan x="1090" y="409.5">&#x426;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x430;&#x44f; &#x433;&#x438;&#x431;&#x43a;&#x43e;&#x441;&#x442;&#x44c; </tspan></text>
            <path onClick={(e) => onClick(e, 'Цифровая аффилиация')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M1054 326V382H1295.73C1303.42 382 1308.23 373.688 1304.4 367.021L1283.73 331.021C1281.95 327.915 1278.64 326 1275.06 326H1054Z" fill="url(#paint3_linear_0_1)" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="14" letterSpacing="0px"><tspan x="1087" y="369.75">(&#x43c;&#x43e;&#x442;&#x438;&#x432;&#x430;&#x446;&#x438;&#x43e;&#x43d;&#x43d;&#x44b;&#x439; &#x43a;&#x43e;&#x43c;&#x43f;&#x43e;&#x43d;&#x435;&#x43d;&#x442;)</tspan></text>
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="16" fontWeight="500" letterSpacing="0px"><tspan x="1088" y="351.5">&#x426;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x430;&#x44f; &#x430;&#x444;&#x444;&#x438;&#x43b;&#x438;&#x430;&#x446;&#x438;&#x44f; </tspan></text>
            <path onClick={(e) => onClick(e, 'Цифровое мышление')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M1020 268V324H1261.73C1269.42 324 1274.23 315.688 1270.4 309.021L1249.73 273.021C1247.95 269.915 1244.64 268 1241.06 268H1020Z" fill="url(#paint4_linear_0_1)" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="16" fontWeight="500" letterSpacing="0px"><tspan x="1057" y="293.5">&#x426;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x43e;&#x435; &#x43c;&#x44b;&#x448;&#x43b;&#x435;&#x43d;&#x438;&#x435; </tspan></text>
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="14" letterSpacing="0px"><tspan x="1057" y="311.75">(&#x43a;&#x43e;&#x433;&#x43d;&#x438;&#x442;&#x438;&#x432;&#x43d;&#x44b;&#x439; &#x43a;&#x43e;&#x43c;&#x43f;&#x43e;&#x43d;&#x435;&#x43d;&#x442;)</tspan></text>
            <path onClick={(e) => onClick(e, 'Цифровая культура')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M943.297 503H882.868C868.4 503 855.06 495.187 847.982 482.569L803.106 402.569C796.288 390.414 796.288 375.586 803.106 363.431L847.982 283.431C855.06 270.813 868.4 263 882.868 263H939.802H996.601C1011.1 263 1024.46 270.841 1031.52 283.496L1086.78 382.442C1086.98 382.786 1087.34 383 1087.74 383V383C1088.57 383 1089.09 383.895 1088.69 384.621L1034.02 482.504C1026.95 495.159 1013.59 503 999.098 503H943.297Z" fill="#E55E35" />
            <path d="M1034.02 482.504L1032.71 481.773L1034.02 482.504ZM1088.69 384.621L1090 385.353L1088.69 384.621ZM1086.78 382.442L1088.09 381.71L1086.78 382.442ZM847.982 283.431L849.29 284.165L847.982 283.431ZM1031.52 283.496L1030.21 284.227L1031.52 283.496ZM882.868 501.5H943.297V504.5H882.868V501.5ZM804.415 401.835L849.29 481.835L846.674 483.303L801.798 403.303L804.415 401.835ZM849.29 284.165L804.415 364.165L801.798 362.697L846.674 282.697L849.29 284.165ZM939.802 264.5H882.868V261.5H939.802V264.5ZM996.601 264.5H939.802V261.5H996.601V264.5ZM1085.47 383.173L1030.21 284.227L1032.83 282.765L1088.09 381.71L1085.47 383.173ZM1032.71 481.773L1087.38 383.89L1090 385.353L1035.33 483.235L1032.71 481.773ZM943.297 501.5H999.098V504.5H943.297V501.5ZM1035.33 483.235C1028 496.365 1014.14 504.5 999.098 504.5V501.5C1013.05 501.5 1025.91 493.953 1032.71 481.773L1035.33 483.235ZM1087.74 381.5C1089.71 381.5 1090.96 383.626 1090 385.353L1087.38 383.89C1087.22 384.163 1087.42 384.5 1087.74 384.5V381.5ZM1088.09 381.71C1088.02 381.58 1087.88 381.5 1087.74 381.5V384.5C1086.8 384.5 1085.93 383.992 1085.47 383.173L1088.09 381.71ZM846.674 282.697C854.017 269.606 867.858 261.5 882.868 261.5V264.5C868.943 264.5 856.103 272.02 849.29 284.165L846.674 282.697ZM801.798 403.303C794.724 390.692 794.724 375.308 801.798 362.697L804.415 364.165C797.852 375.864 797.852 390.136 804.415 401.835L801.798 403.303ZM996.601 261.5C1011.64 261.5 1025.5 269.635 1032.83 282.765L1030.21 284.227C1023.41 272.047 1010.55 264.5 996.601 264.5V261.5ZM882.868 504.5C867.858 504.5 854.017 496.394 846.674 483.303L849.29 481.835C856.103 493.98 868.943 501.5 882.868 501.5V504.5Z" fill="white" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="24" fontWeight="bold" letterSpacing="0px"><tspan x="870.578" y="370">&#x426;&#x418;&#x424;&#x420;&#x41e;&#x412;&#x410;&#x42f;&#10;</tspan><tspan x="879.379" y="398">&#x41a;&#x423;&#x41b;&#x42c;&#x422;&#x423;&#x420;&#x410; </tspan></text>
            <path onClick={(e) => onClick(e, 'Цифровое потребление')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M292 498V442H50.272C42.5848 442 37.7722 450.312 41.5996 456.979L62.2676 492.979C64.0507 496.085 67.3586 498 70.94 498H292Z" fill="url(#paint5_linear_0_1)" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="16" fontWeight="500" letterSpacing="0px"><tspan x="71" y="475.5">&#x426;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x43e;&#x435; &#x43f;&#x43e;&#x442;&#x440;&#x435;&#x431;&#x43b;&#x435;&#x43d;&#x438;&#x435;</tspan></text>
            <path onClick={(e) => onClick(e, 'Цифровая безопасность')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M259 440V384H17.272C9.58476 384 4.77218 392.312 8.59957 398.979L29.2676 434.979C31.0507 438.085 34.3586 440 37.94 440H259Z" fill="url(#paint6_linear_0_1)" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="16" fontWeight="500" letterSpacing="0px"><tspan x="35" y="417.5">&#x426;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x430;&#x44f; &#x431;&#x435;&#x437;&#x43e;&#x43f;&#x430;&#x441;&#x43d;&#x43e;&#x441;&#x442;&#x44c;</tspan></text>
            <path onClick={(e) => onClick(e, 'Цифровые коммуникации')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M259 326V382H17.272C9.58476 382 4.77218 373.688 8.59957 367.021L29.2676 331.021C31.0507 327.915 34.3586 326 37.94 326H259Z" fill="url(#paint7_linear_0_1)" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="16" fontWeight="500" letterSpacing="0px"><tspan x="29" y="359.5">&#x426;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x44b;&#x435; &#x43a;&#x43e;&#x43c;&#x43c;&#x443;&#x43d;&#x438;&#x43a;&#x430;&#x446;&#x438;&#x438;</tspan></text>
            <path onClick={(e) => onClick(e, 'Цифровая грамотность')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M292 268V324H50.272C42.5848 324 37.7722 315.688 41.5996 309.021L62.2676 273.021C64.0507 269.915 67.3586 268 70.94 268H292Z" fill="url(#paint8_linear_0_1)" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="16" fontWeight="500" letterSpacing="0px"><tspan x="77" y="301.5">&#x426;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x430;&#x44f; &#x433;&#x440;&#x430;&#x43c;&#x43e;&#x442;&#x43d;&#x43e;&#x441;&#x442;&#x44c;</tspan></text>
            <path onClick={(e) => onClick(e, 'Базовые цифровые компетенции')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M374.413 263H317.907C303.444 263 290.108 270.808 283.028 283.419L238.117 363.419C231.29 375.58 231.29 390.42 238.117 402.581L283.028 482.581C290.108 495.192 303.444 503 317.907 503H375.413H378.41H431.284C445.774 503 459.131 495.164 466.2 482.516L510.914 402.516C517.693 390.388 517.693 375.612 510.914 363.484L466.2 283.484C459.131 270.836 445.774 263 431.284 263H374.912H374.413Z" fill="#3D5267" />
            <path d="M466.2 283.484L464.891 284.216L466.2 283.484ZM510.914 402.516L509.605 401.784L510.914 402.516ZM510.914 363.484L509.605 364.216L510.914 363.484ZM466.2 482.516L467.509 483.247L466.2 482.516ZM374.413 264.5H317.907V261.5H374.413V264.5ZM284.336 284.154L239.425 364.154L236.809 362.685L281.72 282.685L284.336 284.154ZM239.425 401.846L284.336 481.846L281.72 483.315L236.809 403.315L239.425 401.846ZM317.907 501.5H375.413V504.5H317.907V501.5ZM375.413 501.5H378.41V504.5H375.413V501.5ZM378.41 501.5H431.284V504.5H378.41V501.5ZM464.891 481.784L509.605 401.784L512.223 403.247L467.509 483.247L464.891 481.784ZM509.605 364.216L464.891 284.216L467.509 282.753L512.223 362.753L509.605 364.216ZM431.284 264.5H374.912V261.5H431.284V264.5ZM374.912 264.5H374.413V261.5H374.912V264.5ZM464.891 284.216C458.086 272.042 445.23 264.5 431.284 264.5V261.5C446.317 261.5 460.175 269.63 467.509 282.753L464.891 284.216ZM284.336 481.846C291.15 493.985 303.987 501.5 317.907 501.5V504.5C302.902 504.5 289.065 496.4 281.72 483.315L284.336 481.846ZM509.605 401.784C516.129 390.111 516.129 375.889 509.605 364.216L512.223 362.753C519.256 375.335 519.256 390.665 512.223 403.247L509.605 401.784ZM239.425 364.154C232.854 375.859 232.854 390.141 239.425 401.846L236.809 403.315C229.726 390.698 229.726 375.302 236.809 362.685L239.425 364.154ZM431.284 501.5C445.23 501.5 458.086 493.958 464.891 481.784L467.509 483.247C460.175 496.37 446.317 504.5 431.284 504.5V501.5ZM317.907 264.5C303.986 264.5 291.15 272.015 284.336 284.154L281.72 282.685C289.065 269.6 302.902 261.5 317.907 261.5V264.5Z" fill="white" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="24" fontWeight="bold" letterSpacing="0px"><tspan x="316.223" y="364">&#x411;&#x410;&#x417;&#x41e;&#x412;&#x42b;&#x415;&#10;</tspan><tspan x="302.77" y="392">&#x426;&#x418;&#x424;&#x420;&#x41e;&#x412;&#x42b;&#x415;&#10;</tspan><tspan x="282.859" y="420">&#x41a;&#x41e;&#x41c;&#x41f;&#x415;&#x422;&#x415;&#x41d;&#x426;&#x418;&#x418;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1296.09 205H1037.91L1027 224.45L1037.91 244H1296.09L1307 224.45L1296.09 205Z" fill="#2B9FAC" />
            <text onClick={(e) => onClick(e, 'Национальная ИТ-инфраструктура')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1172" y="222.75">&#x41d;&#x430;&#x446;&#x438;&#x43e;&#x43d;&#x430;&#x43b;&#x44c;&#x43d;&#x430;&#x44f; &#13;&#10;</tspan><tspan x="1172" y="232.75">&#x418;&#x422;-&#x438;&#x43d;&#x444;&#x440;&#x430;&#x441;&#x442;&#x440;&#x443;&#x43a;&#x442;&#x443;&#x440;&#x430;</tspan></text>
            <path d="M1167 205.801V242.801" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Средства и методы информационной и кибербезопасности')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1041" y="217.75">&#x421;&#x440;&#x435;&#x434;&#x441;&#x442;&#x432;&#x430; &#x438; &#x43c;&#x435;&#x442;&#x43e;&#x434;&#x44b; &#13;&#10;</tspan><tspan x="1041" y="227.75">&#x438;&#x43d;&#x444;&#x43e;&#x440;&#x43c;&#x430;&#x446;&#x438;&#x43e;&#x43d;&#x43d;&#x43e;&#x439; &#13;&#10;</tspan><tspan x="1041" y="237.75">&#x438; &#x43a;&#x438;&#x431;&#x435;&#x440;&#x431;&#x435;&#x437;&#x43e;&#x43f;&#x430;&#x441;&#x43d;&#x43e;&#x441;&#x442;&#x438;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1273.09 164H1014.91L1004 183.45L1014.91 203H1273.09L1284 183.45L1273.09 164Z" fill="#2B9FAC" />
            <text onClick={(e) => onClick(e, 'Инфраструктурные решения')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1149" y="181.75">&#x418;&#x43d;&#x444;&#x440;&#x430;&#x441;&#x442;&#x440;&#x443;&#x43a;&#x442;&#x443;&#x440;&#x43d;&#x44b;&#x435; </tspan><tspan x="1149" y="191.75">&#x440;&#x435;&#x448;&#x435;&#x43d;&#x438;&#x44f;</tspan></text>
            <path d="M1144 164.686V201.686" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Основы создания ИТ-инфраструктуры организации')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1018" y="175.75">&#x41e;&#x441;&#x43d;&#x43e;&#x432;&#x44b; &#x441;&#x43e;&#x437;&#x434;&#x430;&#x43d;&#x438;&#x44f;&#13;&#10;</tspan><tspan x="1018" y="185.75">&#x418;&#x422;-&#x438;&#x43d;&#x444;&#x440;&#x430;&#x441;&#x442;&#x440;&#x443;&#x43a;&#x442;&#x443;&#x440;&#x44b;&#10;</tspan><tspan x="1018" y="195.75">&#x43e;&#x440;&#x433;&#x430;&#x43d;&#x438;&#x437;&#x430;&#x446;&#x438;&#x438;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1250.09 123H991.91L981 142.45L991.91 162H1250.09L1261 142.45L1250.09 123Z" fill="#1F818C" />
            <text onClick={(e) => onClick(e, 'Перспективные технологии')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1126" y="140.75">&#x41f;&#x435;&#x440;&#x441;&#x43f;&#x435;&#x43a;&#x442;&#x438;&#x432;&#x43d;&#x44b;&#x435; &#13;&#10;</tspan><tspan x="1126" y="150.75">&#x442;&#x435;&#x445;&#x43d;&#x43e;&#x43b;&#x43e;&#x433;&#x438;&#x438;</tspan></text>
            <path d="M1121 123.766V160.766" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Технологии трансформации общества')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="995" y="135.75">&#x422;&#x435;&#x445;&#x43d;&#x43e;&#x43b;&#x43e;&#x433;&#x438;&#x438; &#13;&#10;</tspan><tspan x="995" y="145.75">&#x442;&#x440;&#x430;&#x43d;&#x441;&#x444;&#x43e;&#x440;&#x43c;&#x430;&#x446;&#x438;&#x438; &#13;&#10;</tspan><tspan x="995" y="155.75">&#x43e;&#x431;&#x449;&#x435;&#x441;&#x442;&#x432;&#x430;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1227.09 82H968.91L958 101.45L968.91 121H1227.09L1238 101.45L1227.09 82Z" fill="#1F818C" />
            <text onClick={(e) => onClick(e, 'ИИ-решения')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1103" y="105.75">&#x418;&#x418;-&#x440;&#x435;&#x448;&#x435;&#x43d;&#x438;&#x44f;</tspan></text>
            <path d="M1098 83V120" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Сквозные технологии')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="972" y="100.75">&#x421;&#x43a;&#x432;&#x43e;&#x437;&#x43d;&#x44b;&#x435; &#13;&#10;</tspan><tspan x="972" y="110.75">&#x442;&#x435;&#x445;&#x43d;&#x43e;&#x43b;&#x43e;&#x433;&#x438;&#x438;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1203.09 41H944.91L934 60.4504L944.91 80H1203.09L1214 60.4504L1203.09 41Z" fill="#2B9FAC" />
            <text onClick={(e) => onClick(e, 'Управление организацией на основе данных')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1079" y="53.75">&#x423;&#x43f;&#x440;&#x430;&#x432;&#x43b;&#x435;&#x43d;&#x438;&#x435; &#13;&#10;</tspan><tspan x="1079" y="63.75">&#x43e;&#x440;&#x433;&#x430;&#x43d;&#x438;&#x437;&#x430;&#x446;&#x438;&#x435;&#x439; &#13;&#10;</tspan><tspan x="1079" y="73.75">&#x43d;&#x430; &#x43e;&#x441;&#x43d;&#x43e;&#x432;&#x435; &#x434;&#x430;&#x43d;&#x43d;&#x44b;&#x445;</tspan></text>
            <path d="M1074 42V79" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Основы руководства данными')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="948" y="53.75">&#x41e;&#x441;&#x43d;&#x43e;&#x432;&#x44b; &#13;&#10;</tspan><tspan x="948" y="63.75">&#x440;&#x443;&#x43a;&#x43e;&#x432;&#x43e;&#x434;&#x441;&#x442;&#x432;&#x430; &#13;&#10;</tspan><tspan x="948" y="73.75">&#x434;&#x430;&#x43d;&#x43d;&#x44b;&#x43c;&#x438;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M1180.09 0H921.91L911 19.4504L921.91 39H1180.09L1191 19.4504L1180.09 0Z" fill="#2B9FAC" />
            <text onClick={(e) => onClick(e, 'Технологии управления большими данными')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="1056" y="12.75">&#x422;&#x435;&#x445;&#x43d;&#x43e;&#x43b;&#x43e;&#x433;&#x438;&#x438; &#13;&#10;</tspan><tspan x="1056" y="22.75">&#x443;&#x43f;&#x440;&#x430;&#x432;&#x43b;&#x435;&#x43d;&#x438;&#x44f; &#x431;&#x43e;&#x43b;&#x44c;&#x448;&#x438;&#x43c;&#x438; &#13;&#10;</tspan><tspan x="1056" y="32.75">&#x434;&#x430;&#x43d;&#x43d;&#x44b;&#x43c;&#x438;</tspan></text>
            <path d="M1043 1V38" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Инструменты управления данными')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="925" y="12.75">&#x418;&#x43d;&#x441;&#x442;&#x440;&#x443;&#x43c;&#x435;&#x43d;&#x442;&#x44b; &#13;&#10;</tspan><tspan x="925" y="22.75">&#x443;&#x43f;&#x440;&#x430;&#x432;&#x43b;&#x435;&#x43d;&#x438;&#x44f; &#13;&#10;</tspan><tspan x="925" y="32.75">&#x434;&#x430;&#x43d;&#x43d;&#x44b;&#x43c;&#x438;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M277.09 205H18.9104L8 224.45L18.9104 244H277.09L288 224.45L277.09 205Z" fill="#2B9FAC" />
            <text onClick={(e) => onClick(e, 'Методы и инструменты антикризисного менеджмента')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="153" y="217.752">&#x41c;&#x435;&#x442;&#x43e;&#x434;&#x44b; &#x438; &#x438;&#x43d;&#x441;&#x442;&#x440;&#x443;&#x43c;&#x435;&#x43d;&#x442;&#x44b; &#13;&#10;</tspan><tspan x="153" y="227.752">&#x430;&#x43d;&#x442;&#x438;&#x43a;&#x440;&#x438;&#x437;&#x438;&#x441;&#x43d;&#x43e;&#x433;&#x43e; &#13;&#10;</tspan><tspan x="153" y="237.752">&#x43c;&#x435;&#x43d;&#x435;&#x434;&#x436;&#x43c;&#x435;&#x43d;&#x442;&#x430;</tspan></text>
            <path d="M148 206.002V243.002" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Методы и инструменты проектного подхода')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="22" y="217.752">&#x41c;&#x435;&#x442;&#x43e;&#x434;&#x44b; &#x438; &#x438;&#x43d;&#x441;&#x442;&#x440;&#x443;&#x43c;&#x435;&#x43d;&#x442;&#x44b; &#13;&#10;</tspan><tspan x="22" y="227.752">&#x43f;&#x440;&#x43e;&#x435;&#x43a;&#x442;&#x43d;&#x43e;&#x433;&#x43e; &#13;&#10;</tspan><tspan x="22" y="237.752">&#x43f;&#x43e;&#x434;&#x445;&#x43e;&#x434;&#x430;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M300.09 164H41.9104L31 183.45L41.9104 203H300.09L311 183.45L300.09 164Z" fill="#2B9FAC" />
            <text onClick={(e) => onClick(e, 'Методы и инструменты продуктового подхода')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="176" y="176.75">&#x41c;&#x435;&#x442;&#x43e;&#x434;&#x44b; &#x438; &#x438;&#x43d;&#x441;&#x442;&#x440;&#x443;&#x43c;&#x435;&#x43d;&#x442;&#x44b; &#13;&#10;</tspan><tspan x="176" y="186.75">&#x43f;&#x440;&#x43e;&#x434;&#x443;&#x43a;&#x442;&#x43e;&#x432;&#x43e;&#x433;&#x43e; &#13;&#10;</tspan><tspan x="176" y="196.75">&#x43f;&#x43e;&#x434;&#x445;&#x43e;&#x434;&#x430;</tspan></text>
            <path d="M171 165V202" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Методы и инструменты процессного подхода')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="45" y="176.75">&#x41c;&#x435;&#x442;&#x43e;&#x434;&#x44b; &#13;&#10;</tspan><tspan x="45" y="186.75">&#x438; &#x438;&#x43d;&#x441;&#x442;&#x440;&#x443;&#x43c;&#x435;&#x43d;&#x442;&#x44b; &#13;&#10;</tspan><tspan x="45" y="196.75">&#x43f;&#x440;&#x43e;&#x446;&#x435;&#x441;&#x441;&#x43d;&#x43e;&#x433;&#x43e; &#x43f;&#x43e;&#x434;&#x445;&#x43e;&#x434;&#x430;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M323.09 123H64.9104L54 142.45L64.9104 162H323.09L334 142.45L323.09 123Z" fill="#1F818C" />
            <text onClick={(e) => onClick(e, 'Принципы и алгоритмы практик регулярного менеджмента')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="199" y="135.75">&#x41f;&#x440;&#x438;&#x43d;&#x446;&#x438;&#x43f;&#x44b; &#x438; &#x430;&#x43b;&#x433;&#x43e;&#x440;&#x438;&#x442;&#x43c;&#x44b; &#13;&#10;</tspan><tspan x="199" y="145.75">&#x43f;&#x440;&#x430;&#x43a;&#x442;&#x438;&#x43a; &#x440;&#x435;&#x433;&#x443;&#x43b;&#x44f;&#x440;&#x43d;&#x43e;&#x433;&#x43e; &#13;&#10;</tspan><tspan x="199" y="155.75">&#x43c;&#x435;&#x43d;&#x435;&#x434;&#x436;&#x43c;&#x435;&#x43d;&#x442;&#x430;</tspan></text>
            <path d="M194 124V161" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Технологии управления кадровой политикой цифрового развития')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="68" y="135.75">&#x422;&#x435;&#x445;&#x43d;&#x43e;&#x43b;&#x43e;&#x433;&#x438;&#x438;&#13;&#10;</tspan><tspan x="68" y="145.75">&#x443;&#x43f;&#x440;&#x430;&#x432;&#x43b;&#x435;&#x43d;&#x438;&#x44f; &#x43a;&#x430;&#x434;&#x440;&#x43e;&#x432;&#x43e;&#x439; &#13;&#10;</tspan><tspan x="68" y="155.75">&#x43f;&#x43e;&#x43b;&#x438;&#x442;&#x438;&#x43a;&#x43e;&#x439;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M346.09 82H87.9104L77 101.45L87.9104 121H346.09L357 101.45L346.09 82Z" fill="#1F818C" />
            <text onClick={(e) => onClick(e, 'Механизмы управления организационными изменениями')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="226" y="95.75">&#x41c;&#x435;&#x445;&#x430;&#x43d;&#x438;&#x437;&#x43c;&#x44b; &#x443;&#x43f;&#x440;&#x430;&#x432;&#x43b;&#x435;&#x43d;&#x438;&#x44f; </tspan><tspan x="226" y="105.75">&#x43e;&#x440;&#x433;&#x430;&#x43d;&#x438;&#x437;&#x430;&#x446;&#x438;&#x43e;&#x43d;&#x43d;&#x44b;&#x43c;&#x438; &#13;&#10;</tspan><tspan x="226" y="115.75">&#x438;&#x437;&#x43c;&#x435;&#x43d;&#x435;&#x43d;&#x438;&#x44f;&#x43c;&#x438;</tspan></text>
            <path d="M221 82.9746V119.975" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Инструменты и методы формирования и поддержания организационной культуры')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="91" y="94.75">&#x418;&#x43d;&#x441;&#x442;&#x440;&#x443;&#x43c;&#x435;&#x43d;&#x442;&#x44b; &#13;&#10;</tspan><tspan x="91" y="104.75">&#x438; &#x43c;&#x435;&#x442;&#x43e;&#x434;&#x44b;  &#x444;&#x43e;&#x440;&#x43c;&#x438;&#x440;&#x43e;&#x432;&#x430;&#x43d;&#x438;&#x44f; </tspan><tspan x="91" y="114.75">&#x43e;&#x440;&#x433;&#x43a;&#x443;&#x43b;&#x44c;&#x442;&#x443;&#x440;&#x44b;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M370.09 41H111.91L101 60.4504L111.91 80H370.09L381 60.4504L370.09 41Z" fill="#2B9FAC" />
            <text onClick={(e) => onClick(e, 'Государственная технологическая повестка')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="246" y="53.75">&#x413;&#x43e;&#x441;&#x443;&#x434;&#x430;&#x440;&#x441;&#x442;&#x432;&#x435;&#x43d;&#x43d;&#x430;&#x44f; &#13;&#10;</tspan><tspan x="246" y="63.75">&#x442;&#x435;&#x445;&#x43d;&#x43e;&#x43b;&#x43e;&#x433;&#x438;&#x447;&#x435;&#x441;&#x43a;&#x430;&#x44f; &#13;&#10;</tspan><tspan x="246" y="73.75">&#x43f;&#x43e;&#x432;&#x435;&#x441;&#x442;&#x43a;&#x430;</tspan></text>
            <path d="M241 42V79" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Стратегическое управление цифровым развитием')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="115" y="53.75">&#x421;&#x442;&#x440;&#x430;&#x442;&#x435;&#x433;&#x438;&#x447;&#x435;&#x441;&#x43a;&#x43e;&#x435; &#13;&#10;</tspan><tspan x="115" y="73.75">&#x440;&#x430;&#x437;&#x432;&#x438;&#x442;&#x438;&#x435;&#x43c;</tspan></text>
            <text onClick={(e) => onClick(e, 'Стратегическое управление цифровым развитием')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="115" y="63.75">&#x443;&#x43f;&#x440;&#x430;&#x432;&#x43b;&#x435;&#x43d;&#x438;&#x435; &#x446;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x44b;&#x43c; &#13;&#10;</tspan></text>
            <path fillRule="evenodd" clipRule="evenodd" d="M393.09 0H134.91L124 19.4504L134.91 39H393.09L404 19.4504L393.09 0Z" fill="#2B9FAC" />
            <text onClick={(e) => onClick(e, 'Механизмы и инструменты цифровой трансформации')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="264" y="13.75">&#x41c;&#x435;&#x445;&#x430;&#x43d;&#x438;&#x437;&#x43c;&#x44b; &#10;</tspan><tspan x="264" y="23.75">&#x438; &#x438;&#x43d;&#x441;&#x442;&#x440;&#x443;&#x43c;&#x435;&#x43d;&#x442;&#x44b; &#13;&#10;</tspan><tspan x="264" y="33.75">&#x446;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x43e;&#x439; &#x442;&#x440;&#x430;&#x43d;&#x441;&#x444;&#x43e;&#x440;&#x43c;&#x430;&#x446;&#x438;&#x438;</tspan></text>
            <path d="M259 1V38" stroke="white" strokeMiterlimit="10" />
            <text onClick={(e) => onClick(e, 'Концептуальные положения цифровой экономики')} className={styles.shadow} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="10" letterSpacing="0px"><tspan x="138" y="13.75">&#x41a;&#x43e;&#x43d;&#x446;&#x435;&#x43f;&#x442;&#x443;&#x430;&#x43b;&#x44c;&#x43d;&#x44b;&#x435;&#10;</tspan><tspan x="138" y="23.75">&#x43f;&#x43e;&#x43b;&#x43e;&#x436;&#x435;&#x43d;&#x438;&#x44f; &#x446;&#x438;&#x444;&#x440;&#x43e;&#x432;&#x43e;&#x439; </tspan><tspan x="138" y="33.75">&#x44d;&#x43a;&#x43e;&#x43d;&#x43e;&#x43c;&#x438;&#x43a;&#x438;</tspan></text>
            <path d="M977 224.795H1024.2" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M1029.7 224.794C1029.7 223.297 1028.5 222 1026.9 222C1025.4 222 1024.1 223.198 1024.1 224.794C1024.1 226.391 1025.3 227.589 1026.9 227.589C1028.4 227.589 1029.7 226.391 1029.7 224.794Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M954 183.795H1001.2" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M1003.9 186.589C1005.45 186.589 1006.7 185.338 1006.7 183.794C1006.7 182.251 1005.45 181 1003.9 181C1002.36 181 1001.1 182.251 1001.1 183.794C1001.1 185.338 1002.36 186.589 1003.9 186.589Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M948 142.795H978.4" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M983.901 142.794C983.901 141.297 982.701 140 981.101 140C979.501 140 978.301 141.198 978.301 142.794C978.301 144.291 979.501 145.589 981.101 145.589C982.701 145.589 983.901 144.291 983.901 142.794Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M929 101.795H955.3" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M960.799 101.794C960.799 100.297 959.599 99 957.999 99C956.399 99 955.199 100.198 955.199 101.794C955.199 103.291 956.399 104.589 957.999 104.589C959.599 104.589 960.799 103.291 960.799 101.794Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M876 60.7949H931.5" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M936.998 60.7944C936.998 59.2974 935.798 58 934.198 58C932.598 58 931.398 59.1976 931.398 60.7944C931.398 62.2915 932.598 63.5889 934.198 63.5889C935.798 63.5889 936.998 62.2915 936.998 60.7944Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M882.301 19.7949H908.601" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M914.1 19.7944C914.1 18.2974 912.9 17 911.3 17C909.7 17 908.5 18.1976 908.5 19.7944C908.5 21.3913 909.7 22.5889 911.3 22.5889C912.9 22.5889 914.1 21.2915 914.1 19.7944Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M360.9 224.795H291.5" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M288.8 227.589C290.346 227.589 291.6 226.338 291.6 224.794C291.6 223.251 290.346 222 288.8 222C287.254 222 286 223.251 286 224.794C286 226.338 287.254 227.589 288.8 227.589Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M350.5 183.795H314.5" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M309 183.794C309 182.297 310.2 181 311.8 181C313.4 181 314.6 182.198 314.6 183.794C314.6 185.391 313.4 186.589 311.8 186.589C310.2 186.589 309 185.291 309 183.794Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M403.512 142.201H337.012" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M336.978 142.658C337.227 141.135 336.192 139.699 334.665 139.45C333.139 139.202 331.7 140.235 331.451 141.758C331.202 143.281 332.237 144.717 333.763 144.966C335.289 145.214 336.729 144.181 336.978 142.658Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M399.3 101.795H360.5" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M357.8 104.589C359.346 104.589 360.6 103.338 360.6 101.794C360.6 100.251 359.346 99 357.8 99C356.254 99 355 100.251 355 101.794C355 103.338 356.254 104.589 357.8 104.589Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M439 60.5H385.445" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M380 60.5C380 59.1607 381.071 58 382.5 58C383.929 58 385 59.0714 385 60.5C385 61.9286 383.929 63 382.5 63C381.071 63 380 61.8393 380 60.5Z" fill="white" stroke="#74C4BC" strokeWidth="1.0167" strokeMiterlimit="10" />
            <path d="M433.002 19.5H407.363" stroke="#74C4BC" strokeMiterlimit="10" />
            <path d="M402 19.5C402 18.1607 403.071 17 404.5 17C405.929 17 407 18.0714 407 19.5C407 20.9286 405.929 22 404.5 22C403.071 22 402 20.8393 402 19.5Z" fill="white" stroke="#74C4BC" strokeMiterlimit="10" />
            <path onClick={(e) => onClick(e, 'Развитие ИТ-инфраструктуры')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 164V244H999.96C1007.59 244 1012.41 235.789 1008.69 229.123L975.174 169.123C973.407 165.96 970.067 164 966.444 164H657Z" fill="#2B9FAC" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="848" y="200.5">&#x420;&#x410;&#x417;&#x412;&#x418;&#x422;&#x418;&#x415;&#10;</tspan><tspan x="848" y="216.5">&#x418;&#x422;-&#x418;&#x41d;&#x424;&#x420;&#x410;&#x421;&#x422;&#x420;&#x423;&#x41a;&#x422;&#x423;&#x420;&#x42b; </tspan></text>
            <path onClick={(e) => onClick(e, 'Инструменты управления')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 164V244H314.04C306.405 244 301.587 235.789 305.31 229.123L338.826 169.123C340.593 165.96 343.933 164 347.556 164H657Z" fill="#2B9FAC" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="347" y="200.5">&#x418;&#x41d;&#x421;&#x422;&#x420;&#x423;&#x41c;&#x415;&#x41d;&#x422;&#x42b;&#10;</tspan><tspan x="347" y="216.5">&#x423;&#x41f;&#x420;&#x410;&#x412;&#x41b;&#x415;&#x41d;&#x418;&#x42f; </tspan></text>
            <path onClick={(e) => onClick(e, 'Применение цифровых технологий')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 82V162H953.96C961.595 162 966.413 153.789 962.69 147.123L929.174 87.1233C927.407 83.96 924.067 82 920.444 82L657 82Z" fill="#1F818C" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="838.98" y="110.5">&#x41f;&#x420;&#x418;&#x41c;&#x415;&#x41d;&#x415;&#x41d;&#x418;&#x415;&#10;</tspan><tspan x="846.762" y="126.5"> &#x426;&#x418;&#x424;&#x420;&#x41e;&#x412;&#x42b;&#x425;&#10;</tspan><tspan x="838.734" y="142.5"> &#x422;&#x415;&#x425;&#x41d;&#x41e;&#x41b;&#x41e;&#x413;&#x418;&#x419;</tspan></text>
            <path onClick={(e) => onClick(e, 'Развитие организационной культуры')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 82V162H360.04C352.405 162 347.587 153.789 351.31 147.123L384.826 87.1233C386.593 83.96 389.933 82 393.556 82L657 82Z" fill="#1F818C" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="393" y="110.5">&#x420;&#x410;&#x417;&#x412;&#x418;&#x422;&#x418;&#x415;&#10;</tspan><tspan x="393" y="126.5">&#x41e;&#x420;&#x413;&#x410;&#x41d;&#x418;&#x417;&#x410;&#x426;&#x418;&#x41e;&#x41d;&#x41d;&#x41e;&#x419;&#10;</tspan><tspan x="393" y="142.5">&#x41a;&#x423;&#x41b;&#x42c;&#x422;&#x423;&#x420;&#x42b; </tspan></text>
            <path onClick={(e) => onClick(e, 'Управление и использование данных')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 0V80H906.96C914.595 80 919.413 71.789 915.69 65.1233L882.174 5.12331C880.407 1.96001 877.067 0 873.444 0H657Z" fill="#2B9FAC" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="794.992" y="28.5">&#x423;&#x41f;&#x420;&#x410;&#x412;&#x41b;&#x415;&#x41d;&#x418;&#x415;&#10;</tspan><tspan x="757.059" y="44.5">&#x418; &#x418;&#x421;&#x41f;&#x41e;&#x41b;&#x42c;&#x417;&#x41e;&#x412;&#x410;&#x41d;&#x418;&#x415;&#10;</tspan><tspan x="820.363" y="60.5">&#x414;&#x410;&#x41d;&#x41d;&#x42b;&#x425; </tspan></text>
            <path onClick={(e) => onClick(e, 'Управление цифровым развитием')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M657 0V80H407.04C399.405 80 394.587 71.789 398.31 65.1233L431.826 5.12331C433.593 1.96001 436.933 0 440.556 0H657Z" fill="#2B9FAC" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="12" fontWeight="600" letterSpacing="0px"><tspan x="440" y="28.5">&#x423;&#x41f;&#x420;&#x410;&#x412;&#x41b;&#x415;&#x41d;&#x418;&#x415;&#10;</tspan><tspan x="440" y="44.5">&#x426;&#x418;&#x424;&#x420;&#x41e;&#x412;&#x42b;&#x41c;&#10;</tspan><tspan x="440" y="60.5">&#x420;&#x410;&#x417;&#x412;&#x418;&#x422;&#x418;&#x415;&#x41c; </tspan></text>
            <path onClick={(e) => onClick(e, 'Профессиональные компетенции')} className={styles.shadow} fillRule="evenodd" clipRule="evenodd" d="M511.038 362.583C518.118 375.194 531.454 383 545.916 383H630.929H684.023H768.28C782.769 383 796.125 375.165 803.195 362.518L847.917 282.518C854.697 270.389 854.697 255.611 847.917 243.482L803.195 163.482C796.125 150.835 782.769 143 768.28 143H684.023H630.929H545.916C531.454 143 518.118 150.806 511.038 163.417L466.119 243.417C459.29 255.579 459.29 270.421 466.119 282.583L511.038 362.583Z" fill="url(#paint9_linear_0_1)" />
            <path d="M466.119 282.583L467.427 281.849L466.119 282.583ZM803.195 163.482L801.886 164.214L803.195 163.482ZM803.195 362.518L801.886 361.786L803.195 362.518ZM630.929 381.5H545.916V384.5H630.929V381.5ZM684.023 381.5H630.929V384.5H684.023V381.5ZM768.28 381.5H684.023V384.5H768.28V381.5ZM846.608 281.786L801.886 361.786L804.504 363.25L849.226 283.25L846.608 281.786ZM801.886 164.214L846.608 244.214L849.226 242.75L804.504 162.75L801.886 164.214ZM684.023 144.5H768.28V141.5H684.023V144.5ZM630.929 144.5H684.023V141.5H630.929V144.5ZM545.916 144.5H630.929V141.5H545.916V144.5ZM467.427 244.151L512.346 164.151L509.73 162.682L464.812 242.682L467.427 244.151ZM512.346 361.849L467.427 281.849L464.812 283.318L509.73 363.318L512.346 361.849ZM464.812 242.682C457.726 255.301 457.726 270.699 464.812 283.318L467.427 281.849C460.855 270.143 460.855 255.857 467.427 244.151L464.812 242.682ZM804.504 162.75C797.169 149.629 783.312 141.5 768.28 141.5V144.5C782.226 144.5 795.081 152.041 801.886 164.214L804.504 162.75ZM849.226 283.25C856.261 270.666 856.261 255.334 849.226 242.75L846.608 244.214C853.134 255.888 853.134 270.112 846.608 281.786L849.226 283.25ZM545.916 141.5C530.912 141.5 517.076 149.599 509.73 162.682L512.346 164.151C519.161 152.014 531.996 144.5 545.916 144.5V141.5ZM768.28 384.5C783.312 384.5 797.169 376.371 804.504 363.25L801.886 361.786C795.081 373.959 782.226 381.5 768.28 381.5V384.5ZM545.916 381.5C531.996 381.5 519.161 373.986 512.346 361.849L509.73 363.318C517.076 376.401 530.912 384.5 545.916 384.5V381.5Z" fill="white" />
            <text className={styles.text} fill="white" xmlSpace="preserve" fontFamily="IBM Plex Sans" fontSize="24" fontWeight="bold" letterSpacing="0px"><tspan x="519.801" y="254">&#x41f;&#x420;&#x41e;&#x424;&#x415;&#x421;&#x421;&#x418;&#x41e;&#x41d;&#x410;&#x41b;&#x42c;&#x41d;&#x42b;&#x415;&#10;</tspan><tspan x="564.859" y="290">&#x41a;&#x41e;&#x41c;&#x41f;&#x415;&#x422;&#x415;&#x41d;&#x426;&#x418;&#x418; </tspan></text>
            <defs>
              <linearGradient id="paint0_linear_0_1" x1="682.181" y1="624" x2="682.181" y2="420" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CB6871" />
                <stop offset="1" stopColor="#B62936" />
              </linearGradient>
              <linearGradient id="paint1_linear_0_1" x1="1273.14" y1="442" x2="1115.34" y2="442" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F5A671" />
                <stop offset="1" stopColor="#E55E35" />
              </linearGradient>
              <linearGradient id="paint2_linear_0_1" x1="1306.14" y1="384" x2="1148.34" y2="384" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F5A671" />
                <stop offset="1" stopColor="#E55E35" />
              </linearGradient>
              <linearGradient id="paint3_linear_0_1" x1="1306.14" y1="382" x2="1148.34" y2="382" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F5A671" />
                <stop offset="1" stopColor="#E55E35" />
              </linearGradient>
              <linearGradient id="paint4_linear_0_1" x1="1272.14" y1="324" x2="1114.34" y2="324" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F5A671" />
                <stop offset="1" stopColor="#E55E35" />
              </linearGradient>
              <linearGradient id="paint5_linear_0_1" x1="39.8609" y1="442" x2="197.662" y2="442" gradientUnits="userSpaceOnUse">
                <stop stopColor="#5C718B" />
                <stop offset="1" stopColor="#3E5878" />
              </linearGradient>
              <linearGradient id="paint6_linear_0_1" x1="6.86093" y1="384" x2="164.662" y2="384" gradientUnits="userSpaceOnUse">
                <stop stopColor="#5C718B" />
                <stop offset="1" stopColor="#3E5878" />
              </linearGradient>
              <linearGradient id="paint7_linear_0_1" x1="6.86093" y1="382" x2="164.662" y2="382" gradientUnits="userSpaceOnUse">
                <stop stopColor="#5C718B" />
                <stop offset="1" stopColor="#3E5878" />
              </linearGradient>
              <linearGradient id="paint8_linear_0_1" x1="39.8609" y1="324" x2="197.662" y2="324" gradientUnits="userSpaceOnUse">
                <stop stopColor="#5C718B" />
                <stop offset="1" stopColor="#3E5878" />
              </linearGradient>
              <linearGradient id="paint9_linear_0_1" x1="682.182" y1="142" x2="682.182" y2="346" gradientUnits="userSpaceOnUse">
                <stop stopColor="#7EC5CD" />
                <stop offset="1" stopColor="#2B9FAC" />
              </linearGradient>
            </defs>
          </svg>

          <Stack alignItems='center'>
            <Button onClick={() => setShowDetail(!showDetail)} variant='filled'>{showDetail ? 'Скрыть' : 'Подробнее'}</Button>
          </Stack>

          {showDetail && <CompetencyModelDetailSection />}

          <Stack alignItems='center'>
            <Text variant='text-m-medium'>Поделиться</Text>
          </Stack>

          <Stack justifyContent='center' orientation='horizontal' gap={28}>
            <a
              href={vkShareUrl}
              target="_blank"
              rel="noreferrer"
            >
              <VkIcon fill={colors['emerald']} width='26px' />
            </a>
            <a
              href=''
              onClick={copyUrl}
              title='Скопировать ссылку'
            >
              <CopyLinkIcon
                stroke={colors['emerald']}
                width='26px'
              />
            </a>
          </Stack>

        </Stack>
      </PageSection>

      <QuestionSection bgColor='light-gray' defaultPurposeOfRequest='competency_model' />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default CompetencyModelPage
