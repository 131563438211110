import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'

import Text from 'components/Text/Text'
import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import Indicator, { IndicatorData } from 'components/Indicator/Indicator'
import Grid from 'components/Grid/Grid'

type ExpertMaterialsSectionProps = {
  id?: string | number;
  heading?: string;
  text?: string;
  indicatorBullets?: IndicatorData[]
  bgColor?: Color;
}

const AISection = (props: ExpertMaterialsSectionProps) => {
  return (
    <PageSection bgColor={props.bgColor} id={props.id}>
      <SectionHeading animate animationVariant='appear-up' showArrow={true}>{nbspacer(props.heading ?? '')}</SectionHeading>

      <Text maxWidth854>
        <div dangerouslySetInnerHTML={{__html: nbspacer(props.text ?? '')}} />
      </Text>

      {props.indicatorBullets &&
        <Grid cols={3} tabletCols={1} columnGap={20} rowGap={20}>
          {props.indicatorBullets?.map((indicator: IndicatorData, index: number) => {
            return <Indicator animate key={indicator.id} {...indicator} animateDelayIndex={index} />
          })}
        </Grid>
      }
    </PageSection>
  )
}

export default AISection
