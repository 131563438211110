import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'

const TestPage = () => {
  return (
    <div>
      <Player
        autoplay
        loop
        src="/static/animation/test-animation1.json"
        style={{ height: '300px', width: '300px' }}
      >
      </Player>
    </div>
  )
}

export default TestPage
