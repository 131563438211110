import React from 'react'
import HeaderSection from 'page-sections/v3/HeaderSection/HeaderSection'
import FooterSection from 'page-sections/v3/FooterSection/FooterSection'
import Text from 'components/v2/Text/Text'
import nbspacer from 'common/nbspacer'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import QuestionSection from 'page-sections/v3/QuestionSection/QuestionSection'
import PageSection from 'components/v2/PageSection/PageSection'
import useDevice from 'common/useDevice'
import Button from 'components/v3/Button/Button'
import Carousel from 'components/v3/Carousel/Carousel'
import Card from 'components/Card/Card'
import colors from 'common/colors'
import Checkbox from 'components/Checkbox/Checkbox'
import TextWithDot from 'components/TextWithDot/TextWithDot'

const WelcomePage = () => {
  const device = useDevice()
  const [currentInfoItem, setCurrentInfoItem] = React.useState({} as any)

  const infoItems = [
    {
      title: 'Рабочий график',
      image: '/static/img/pages/welcome/info/image1.svg',
      content: <>
        <Stack gap={10}>
          <Text variant='text-m-medium' color='bright-emerald'>Рабочий день обычно с 9.00 до 18.00.</Text>
          <Text>{nbspacer('Но так как большинство из нас работает в удаленном режиме, то тут мы гибкие. А так все нюансы твоего графика работы прописаны в трудовом договоре.')}</Text>
          <Text>{nbspacer('Если вы планируете работать в офисе постоянно или приходить туда в определенные дни, обратитесь в отдел кадров, чтобы вам показали рабочее место, которое можно занять.')}</Text>
          <Text variant='text-m-medium' color='bright-emerald'>Канцелярские товары </Text>
          <Text>{nbspacer('Можно получить в каб. 2100 (корпус 9, этаж 21) у Ирины Ражновой')}</Text>
          <a
            href="mailto: razhnova-in@ranepa.ru"
            style={{
              color: colors['black']
            }}
          ><Text>razhnova-in@ranepa.ru</Text></a>
        </Stack>
      </>
    },
    {
      title: 'Больничный лист',
      image: '/static/img/pages/welcome/info/image2.svg',
      content: <>
        <Stack gap={20}>
          <Text>{nbspacer('Конечно, мы надеемся, что ты не будешь болеть, но, тем не менее, Центр всегда оплачивает больничные листы сотрудников в соответствии с действующим трудовым законодательством Российской Федерации.')}</Text>
          <Stack gap={5}>
            <Text variant='text-m-medium' color='bright-emerald'>Что делать, если заболел?</Text>
            <Text>{nbspacer('Обязательно напиши и предупреди своего руководителя и любого сотрудника команды кадрового делопроизводства. Вызови врача и оформи больничный лист. Как только получишь номер электронного больничного, передай его в отдел кадров.')}</Text>
          </Stack>
        </Stack>
      </>
    },
    {
      title: 'Как одеться',
      image: '/static/img/pages/welcome/info/image3.svg',
      content: <>
        <Stack gap={20}>
          <Text variant='text-m-medium' color='bright-emerald'>{nbspacer('У нас нет строгих правил относительно внешнего вида сотрудников, однако при выборе одежды следует помнить о нормах, принятых в общественных местах.')}</Text>
          <Text>{nbspacer('Если тебе предстоит встреча с нашими партнерами, представителями органов власти или ты являешься организатором программ обучения, то деловой стиль в одежде очень желателен.')}</Text>
        </Stack>
      </>
    },
    {
      title: 'Прием пищи',
      image: '/static/img/pages/welcome/info/image4.svg',
      content: <>
        <Stack gap={20}>
          <Text>{nbspacer('Для тех, кто работает в офисе, есть комната приема пищи, оборудованная холодильником, кофе-машиной и микроволновкой. Еду можно брать с собой в контейнерах и оставлять в холодильнике. Ты можешь пользоваться всей посудой, которая есть на кухне. Главное - вымыть ее после себя.')}</Text>
          <Text variant='text-m-medium' color='bright-emerald'>{nbspacer('Мы старались сделать нашу кухню по настоящему домашней, и нам будет приятно, если ты будешь к ней бережно относиться и поддерживать чистоту.')}</Text>
        </Stack>
      </>
    },
    {
      title: 'Зарплата',
      image: '/static/img/pages/welcome/info/image5.svg',
      content: <>
        <Stack gap={20}>
          <Text variant='text-m-medium' color='bright-emerald'>{nbspacer('Выплата заработной платы производится всем сотрудникам 2 раза в месяц на карту Мир любого банка:')}</Text>
          <Stack>
            <TextWithDot dotVariant='circle' dotColor='black'>1 часть заработной платы - 21 числа текущего месяца,</TextWithDot>
            <TextWithDot dotVariant='circle' dotColor='black'>2 часть - 6 числа следующего месяца. </TextWithDot>
          </Stack>
          <Text>{nbspacer('Если выплата попадает на выходной день, то свою заработную плату ты получишь в рабочий день перед выходным днём.')}</Text>
        </Stack>
      </>
    },
    {
      title: 'CRM ресурсы',
      image: '/static/img/pages/welcome/info/image6.svg',
      content: <>
        <Stack gap={20}>
          <Text variant='text-m-medium' color='bright-emerald'>{nbspacer('Мы используем облачную платформу Битрикс24')}</Text>
          <Stack>
            <TextWithDot dotVariant='circle' dotColor='black'>как основную новостную площадку,</TextWithDot>
            <TextWithDot dotVariant='circle' dotColor='black'>инструмент для внутренних согласований,</TextWithDot>
            <TextWithDot dotVariant='circle' dotColor='black'>{nbspacer('как инструмент для постановки задач и контроля их выполнения и формирования отчетности.')}</TextWithDot>
          </Stack>
          <Text>{nbspacer('Отпуск должен быть заблаговременно согласован с непосредственным руководителем.')}</Text>
          <a href="https://cdto.tech/nextcloud/apps/files/?dir=/HR&fileid=619642"
            style={{
              color: colors['bright-emerald']
            }}
          ><Text color='bright-emerald'>Знакомство с работой на платформе Битрикс</Text></a>
        </Stack>
      </>
    },
    {
      title: 'Отпуск',
      image: '/static/img/pages/welcome/info/image7.svg',
      content: <>
        <Stack gap={10}>
          <Text variant='text-m-medium' color='bright-emerald'>Мы работаем в соответствии с Трудовым Кодексом РФ.</Text>
          <Text>{nbspacer('Согласно законодательству, твой отпуск составляет 28 календарных дней. Отпуск должен быть кратен 7 дням (по требованиям РАНХиГС), и одна из частей отпуска обязательно должна быть не менее14 дней. ')}</Text>
          <Text>{nbspacer('Отпуск должен быть заблаговременно согласован с непосредственным руководителем.')}</Text>
          <Text variant='text-m-medium' color='bright-emerald'>У кого узнать, сколько дней отпуска осталось? </Text>
          <Text>{nbspacer('Обратись к Дарье Ворожейкиной')}</Text>
          <a
            href="mailto: vorozheykina-dg@ranepa.ru"
            style={{
              color: colors['black']
            }}
          ><Text>vorozheykina-dg@ranepa.ru</Text></a>
        </Stack>
      </>
    },
    {
      title: 'Почта и календарь',
      image: '/static/img/pages/welcome/info/image8.svg',
      content: <>
        <Stack gap={20}>
          <Text variant='text-m-medium' color='bright-emerald'>{nbspacer('Мы используем Outlook ')}</Text>
          <Stack>
            <TextWithDot dotVariant='circle' dotColor='black'>для общения с внешними лицами, </TextWithDot>
            <TextWithDot dotVariant='circle' dotColor='black'>для обсуждения и согласования документов, </TextWithDot>
            <TextWithDot dotVariant='circle' dotColor='black'>для переноса внешних задач в CRM Битрикс24,</TextWithDot>
            <TextWithDot dotVariant='circle' dotColor='black'>для планирования рабочего времени</TextWithDot>
          </Stack>
          <Text>{nbspacer('У всех сотрудников почта начинается с фамилии и инициалов, поэтому ты всегда можешь набрать ее самостоятельно.')}</Text>
          <a href="https://it.ranepa.ru/pochtovaya-sluzhba/pochtovaya-sluzhba/"
            style={{
              color: colors['bright-emerald']
            }}
          ><Text color='bright-emerald'>Инструкция по настройке электронной почты</Text></a>
          <Text>{nbspacer('В календаре ты сможешь увидеть, какое время свободно у коллег, чтобы было удобно планировать общие рабочие встречи.')}</Text>
        </Stack>
      </>
    }
  ]

  React.useEffect(() => {
    setCurrentInfoItem(infoItems[0])
  }, []) // eslint-disable-line

  return (
    <div>
      <HeaderSection bgColor='bright-emerald' style={{ paddingBottom: 0 }}>
        <Grid cols={2} tabletCols={1} columnGap={40} rowGap={20}>
          <Stack gap={40}>
            <Heading variant='1' color='white'>{nbspacer('Привет, Коллега! Добро пожаловать в Центр!')}</Heading>
            <Text color='white'>{nbspacer('Ты успешно преодолел все этапы интервью и пришел к нам в команду. Этот welcome book поможет тебе быстрее адаптироваться и ответит на основные вопросы. Поэтому устраивайся поудобнее и листай дальше.')}</Text>
          </Stack>
          <Image width='100%' height={385} src='/static/img/pages/welcome/image1.svg' />
        </Grid>
      </HeaderSection>

      <PageSection variant='white-icons'>
        <Stack gap={80}>
          <Stack gap={40}>
            <Stack gap={20}>
              <Heading variant='2' color='bright-emerald'>{nbspacer('Наша команда')}</Heading>
              <Grid
                columnGap={20}
                rowGap={20}
                cols={2}
                tabletCols={1}
              >
                <Stack
                  orientation='horizontal'
                  gap={6}
                >
                  <Image
                    width={100}
                    height={100}
                    objectFit='cover'
                    src='/static/img/pages/welcome/team/image1.jpg'
                    style={{
                      borderRadius: '50%',
                      flexShrink: 0
                    }}
                  />
                  <Stack gap={20}>
                    <Heading variant='3'>{nbspacer('Наталья Гаркуша')}</Heading>
                    <Text>{nbspacer('Директор Центра. Курирует все направления и вдохновляет нас на новые идеи и решения.')}</Text>
                  </Stack>
                </Stack>
                <Stack gap={20}>
                  <Heading variant='3'>{nbspacer('Центр представлен сотрудниками из разных городов: ')}</Heading>
                  <Text>{nbspacer('Москва, Волгоград, Калининград, Кострома, Сочи, Белгород, Хабаровск, Красноярск, Минск, Рязань, Челябинск, Ленинградская, Иркутская и Смоленская области.')}</Text>
                </Stack>
              </Grid>
            </Stack>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: device === 'phone' ? 'repeat(3, minmax(90px, 1fr))' : (device === 'tablet' ? 'repeat(6, minmax(90px, 1fr))' : 'repeat(12, minmax(90px, 1fr))'),
                columnGap: 20,
                rowGap: 20
              }}
            >
              {[...Array(25)].map((_, i) => (
                <Image
                  key={i}
                  width={90}
                  height={90}
                  objectFit='cover'
                  style={{ borderRadius: '50%' }}
                  src={`/static/img/pages/welcome/team/image${i + 2}.jpg`}
                />
              ))}
            </div>
            <Stack alignItems='center'>
              <div>
                <Button
                  label='Подробнее о команде'
                  onClick={() => { window.open('/team', '_self') }}
                /></div>
            </Stack>
          </Stack>

          <Stack alignItems='center'>
            <a
              href='/media/welcome.mp4'
              target='_blank' rel="noreferrer"
            >
              <Image
                width='100%'
                src='/static/img/pages/welcome/image2.svg'
              />
            </a>
          </Stack>
        </Stack>
      </PageSection>

      {/* Наши ценности */}
      <PageSection variant='gray'>
        <Stack gap={40}>
          <Heading variant='2' color='bright-emerald'>
            {nbspacer('Наши ценности')}
          </Heading>

          <Stack gap={20}>
            <Heading variant='3'>Миссия Центра</Heading>
            <Grid
              cols={2}
              tabletCols={1}
              columnGap={100}
              rowGap={20}
            >
              <Text>
                {nbspacer('Создать среду, раскрывающую потенциал участников и лидеров цифровой трансформации для принятия этичных, быстрых, обоснованных решений в условиях цифровой экономики.')}
              </Text>
              <Text>
                {nbspacer('Мы меняем представление о способах и инструментах работы в государственном управлении, чтобы обеспечить достойную жизнь людей.')}
              </Text>
            </Grid>
          </Stack>

          <Carousel
            quantity={2}
            tabletQuantity={1}
            phoneQuantity={1}
            gap={10}
          >
            {[
              {
                image: '/static/img/pages/welcome/values/image1.jpg',
                title: 'Проактивность',
                text: 'Мы прилагаем максимум усилий для реализации поставленных задач на своем участке работы, зачастую превышая ожидания.'
              },
              {
                image: '/static/img/pages/welcome/values/image2.jpg',
                title: 'Критическое мышление',
                text: 'Мы все заинтересованы в общем успехе, поэтому каждый день, выполняя свою работу, мы стараемся делать это как можно лучше.'
              },
              {
                image: '/static/img/pages/welcome/values/image3.jpg',
                title: 'Вовлеченность',
                text: 'Мы все заинтересованы в общем успехе, поэтому каждый день, выполняя свою работу, мы стараемся делать это как можно лучше.'
              },
              {
                image: '/static/img/pages/welcome/values/image4.jpg',
                title: 'Развитие',
                text: 'Мы постоянно обучаемся, не боимся пробовать новые инструменты, методы и подходы.'
              },
              {
                image: '/static/img/pages/welcome/values/image5.jpg',
                title: 'Ответственность',
                text: 'Умение прогнозировать последствия своих действий и готовность ответить за результат.'
              }
            ].map((item, index) => (
              <div
                key={index}
                style={{
                  padding: 5,
                  boxSizing: 'border-box'
                }}
              >
                <Card
                  style={{
                    height: '100%',
                    boxSizing: 'border-box',
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingRight: 0
                  }}
                >
                  <Stack
                    gap={20}
                  >
                    <Image
                      width='100%'
                      height={268}
                      objectFit='cover'
                      src={item.image}
                      style={{
                        borderRadius: '20px'
                      }}
                    />
                    <Stack
                      gap={20}
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20
                      }}
                    >
                      <Heading variant='3'>{item.title}</Heading>
                      <Text>{item.text}</Text>
                    </Stack>
                  </Stack>
                </Card>
              </div>
            ))}
          </Carousel>
        </Stack>
      </PageSection>

      <div
        style={{
          backgroundColor: colors['bright-emerald'],
          padding: device === 'phone' ? '10px 20px' : '0 150px'
        }}
      >
        <Grid
          cols={2}
          tabletCols={1}
          columnGap={100}
          rowGap={20}
          alignItems='center'
        >
          <Heading variant='1' color='white'>Корпоративная культура</Heading>
          <Image width='100%' src='/static/img/pages/welcome/image3.svg' />
        </Grid>
      </div>

      <PageSection
        paddingTop={40}
        paddingBottom={40}
        variant='white-icons'
      >
        <Grid
          cols={2}
          tabletCols={1}
          columnGap={100}
          rowGap={20}
        >
          <Stack gap={20}>
            {[
              {
                image: '/static/img/pages/welcome/culture/image1.svg',
                title: 'Общение с коллегами',
                content: <Text>{nbspacer('Мы открыты для общения, поэтому можно легко обратиться за помощью к коллегам. Большая часть сотрудников обращается друг к другу по имени  и на «ты». Лучший способ что-то выяснить – подойти и поговорить. Мы уважительно относимся друг  к другу, поэтому разговариваем вежливо.')}</Text>
              },
              {
                image: '/static/img/pages/welcome/culture/image2.svg',
                title: 'Перенос срока',
                content: <Text>{nbspacer('Если что-то пошло не по плану, и вы понимаете, что не выполните задачу в установленный срок, нужно предупредить своего руководителя до наступления дедлайна о том, что срок нужно перенести. При этом необходимо согласовать с руководителем новый срок реализации задачи.')}</Text>
              },
              {
                image: '/static/img/pages/welcome/culture/image3.svg',
                title: 'Корпоративные праздники',
                content: <Text>{nbspacer('Мы очень любим собираться вместе, особенно, если для этого есть приятный повод: такой как, Новый год, день рождения Центра. Мы отмечаем праздники дружно и готовимся к ним всей командой. Собираемся как в офисе, так и за его пределами. Мероприятия проходят офлайн и онлайн.')}</Text>
              },
              {
                image: '/static/img/pages/welcome/culture/image4.svg',
                title: 'Дни рождения',
                content: <Text>{nbspacer('В канале CDTO Daily при желании именинника выходит пост с поздравлением. По желанию коллеги именинника собирают на подарок, участвовать  в этом или нет - ты решаешь сам. Если хочешь, ты можешь отпраздновать день рождения в офисе и угостить коллег пиццей или пирогами, выбор за тобой.')}</Text>
              }
            ].map((item, index) => (
              <Stack
                gap={20}
                key={index}
              >
                <Stack
                  orientation='horizontal'
                  gap={20}
                  alignItems='center'
                >
                  <Image
                    src={item.image}
                    width={68}
                    height={68}
                    style={{
                      flexShrink: 0
                    }}
                  />
                  <Heading variant='3'>{item.title}</Heading>
                </Stack>
                {item.content}
              </Stack>
            ))}
          </Stack>
          <Stack gap={60}>
            <Stack gap={20}>
              {[
                {
                  image: '/static/img/pages/welcome/culture/image5.svg',
                  title: 'Дедлайн — это святое!',
                  content: <Text>{nbspacer('Сроки нужно соблюдать, поэтому, когда вы планируете свое время, распределяйте его таким образом, чтобы времени хватило на выполнение всех задач. Если вы понимаете, что задач слишком много, то стоит обратиться к своему руководителю с просьбой о переносе срока.')}</Text>
                },
                {
                  image: '/static/img/pages/welcome/culture/image6.svg',
                  title: nbspacer('Правила поведения в публичном поле и соцсетях'),
                  content: <Stack gap={20}>
                    <Text>{nbspacer('1. Используйте тэги (#CDTO, #РАНХиГС, #CDTOteam, #ЦентрРЦТ #мывцентре).')}</Text>
                    <Text>{nbspacer('2. Отмечайте в публикациях коллег, которые имеют отношение к темам публикаций.')}</Text>
                    <Text>{nbspacer('3. Прежде чем упоминать в соцсетях слушателей и лекторов курса, получите их согласие на это.')}</Text>
                    <Text>{nbspacer('4. Помните о том, что даже закрытые посты могут оказаться в открытом доступе.')}</Text>
                    <Text>{nbspacer('5. Рекомендуем делать репосты записи со страниц Центра в социальных сетях, сопровождая их cобственными комментариями.')}</Text>
                    <Text>{nbspacer('6. Неформальные фотографии лучше выкладывать в нерабочее время — после 18:00 в будние дни или в выходные дни.')}</Text>
                  </Stack>
                }
              ].map((item, index) => (
                <Stack
                  gap={20}
                  key={index}
                >
                  <Stack
                    orientation='horizontal'
                    gap={20}
                    alignItems='center'
                  >
                    <Image
                      src={item.image}
                      width={68}
                      height={68}
                      style={{
                        flexShrink: 0
                      }}
                    />
                    <Heading variant='3'>{item.title}</Heading>
                  </Stack>
                  {item.content}
                </Stack>
              ))}
            </Stack>

            <Stack gap={20}>
              <Text variant='text-m-medium'>{nbspacer('Будем рады, если ты присоединишься к нам в социальных сетях')}</Text>
              <Stack
                orientation='horizontal'
                gap={40}
              >
                {[
                  {
                    image: '/static/img/pages/welcome/culture/vk.svg',
                    url: 'https://vk.com/cdtocenter',
                    label: 'ЦПРиК ЦТ'
                  },
                  {
                    image: '/static/img/pages/welcome/culture/vk.svg',
                    url: 'https://vk.com/cdto.work',
                    label: 'Кадровый центр'
                  },
                  {
                    image: '/static/img/pages/welcome/culture/telegram.svg',
                    url: 'https://t.me/CDTOonline'
                  }
                ].map((item, index) => (
                  <a
                    key={index}
                    href={item.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      textDecoration: 'none'
                    }}
                  >
                    <Stack
                      gap={20}
                      width={120}
                    >
                      <Image
                        width={48}
                        height={48}
                        src={item.image}
                      />
                      {item.label && <Text>{item.label}</Text>}
                    </Stack>
                  </a>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </PageSection>

      {/* Правила коммуникаций */}
      <PageSection variant='gray'>
        <Stack gap={40}>
          <Heading variant='1' color='bright-emerald'>{nbspacer('Правила коммуникаций')}</Heading>

          <Carousel
            quantity={2}
            tabletQuantity={1}
            phoneQuantity={1}
            gap={10}
          >
            {[
              {
                image: '/static/img/pages/welcome/rules/image1.jpg',
                title: 'Базовая вежливость',
                text: 'Любую переписку нового дня стоит начинать с приветствия. Если вы впервые общаетесь с человеком, представьтесь и кратко расскажите, в каком направлении работаете и за что отвечаете. Говорить «спасибо» после получения помощи тоже всегда уместно. Пример: — Алла, привет! Меня зовут Катя Иванова, я новый администратор площадки, помогаю Ульяне с организацией обучения в Москве. Уточни, пожалуйста, …'
              },
              {
                image: '/static/img/pages/welcome/rules/image2.jpg',
                title: 'Правило 24 часов',
                text: 'На любой запрос/вопрос необходимо отреагировать в течение одного рабочего дня. Если это невозможно сделать в течение 24 часов с момента получения вопроса, то необходимо указать срок, когда будет готов содержательный ответ. Не ожидайте немедленной реакции, кроме случаев, когда вопрос требует срочного урегулирования. В этом случае имеет смысл созвониться. '
              },
              {
                image: '/static/img/pages/welcome/rules/image3.jpg',
                title: 'Фиксация устных договоренностей',
                text: 'Любые устные договоренности, достигнутые в ходе встречи, фиксируются в письменной орме. Инициатор встречи в течение 24 часов с момента ее завершения отправляет всем участникам встречи follow-up письмо, в котором зафиксированы все договоренности, а именно: поставленные задачи, сроки их выполнения и лица, ответственные за реализацию.'
              },
              {
                image: '/static/img/pages/welcome/rules/image4.jpg',
                title: 'Привлечение внимания',
                text: 'Если вы общаетесь по рабочему вопросу с коллегой в Битрикс24, в Telegram и (или) почте, и ожидаете обратной связи или хотите донести адресное сообщение, отмечайте коллегу/коллег тегом в переписке.'
              },
              {
                image: '/static/img/pages/welcome/rules/image5.jpg',
                title: 'Анти-флуд',
                text: 'Разговоры, касающиеся только двоих, нужно вести в личной переписке, а не в общем чате.'
              },
              {
                image: '/static/img/pages/welcome/rules/image6.jpg',
                title: 'Отложенные сообщения',
                text: 'Если вы вспомнили что-то важное в нерабочее время, и есть необходимость написать коллеге, сделайте это с помощью отложенных сообщений в чатах в Telegram и с помощью функции отложенной отправки писем в почте (клиент Outlook). Стремитесь к спокойствию по вечерам и выходные дни!'
              },
              {
                image: '/static/img/pages/welcome/rules/image7.jpg',
                title: 'Отключенные уведомления',
                text: 'Для личного комфорта в нерабочее время все уведомления на телефоне можно и нужно отключать. Если ситуация срочная, то с вами всегда могут связаться по телефону.'
              },
              {
                image: '/static/img/pages/welcome/rules/image8.jpg',
                title: 'Правило одного сообщения',
                text: 'Если пишете в мессенджере, не стоит разбивать целостное сообщение на несколько частей. В одном сообщении вы здороваетесь и задаете вопрос или описываете проблему. Множество сообщений служит дополнительным фактором стресса. Cтарайтесь делать общение максимально конструктивным.'
              },
              {
                image: '/static/img/pages/welcome/rules/image9.jpg',
                title: 'Неравнодушие',
                text: 'Если вы видите проблему или нашли решение — сообщите всем заинтересованным коллегам! Если решаете вопрос, который напрямую затрагивает работу других коллег, поставьте их в известность. Если нашли что-то интересное, полезно в работе другим коллегам, сообщите им.'
              }
            ].map((item, index) => (
              <div
                key={index}
                style={{
                  padding: 5,
                  boxSizing: 'border-box'
                }}
              >
                <Card
                  style={{
                    height: '100%',
                    boxSizing: 'border-box',
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingRight: 0
                  }}
                >
                  <Stack
                    gap={20}
                  >
                    <Image
                      width='100%'
                      height={268}
                      objectFit='cover'
                      src={item.image}
                      style={{
                        borderRadius: '20px'
                      }}
                    />
                    <Stack
                      gap={20}
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20
                      }}
                    >
                      <Heading variant='3'>{item.title}</Heading>
                      <Text>{item.text}</Text>
                    </Stack>
                  </Stack>
                </Card>
              </div>
            ))}
          </Carousel>
        </Stack>
      </PageSection>

      {/* Рабочие моменты */}
      <div
        style={{
          backgroundColor: colors['bright-emerald'],
          padding: device === 'phone' ? '10px 20px' : '0 150px'
        }}
      >
        <Grid
          cols={2}
          tabletCols={1}
          columnGap={100}
          rowGap={20}
          alignItems='center'
        >
          <Heading variant='1' color='white'>Рабочие моменты</Heading>
          <Image width='100%' src='/static/img/pages/welcome/image4.svg' />
        </Grid>
      </div>

      {/* Твоя первая рабочая неделя */}
      <PageSection
        paddingTop={40}
        paddingBottom={40}
        variant='gray'
      >
        <Stack gap={40}>
          <Heading variant='2' color='bright-emerald'>Твоя первая рабочая неделя</Heading>
          <Grid
            cols={2}
            tabletCols={1}
            columnGap={20}
            rowGap={20}
          >
            <Stack gap={20}>
              {[
                <>
                  <Text>В первую неделю ты получишь все необходимые доступы (пропуск, пароли к почте и учетной записи).</Text>
                </>,
                <>
                  <Text>Тебя ожидают:</Text>
                  <TextWithDot dotVariant='circle' dotColor='black'>экскурсии по офису,</TextWithDot>
                  <TextWithDot dotVariant='circle' dotColor='black'>знакомство с коллегами,</TextWithDot>
                  <TextWithDot dotVariant='circle' dotColor='black'>обязательный вводный инструктаж,</TextWithDot>
                  <TextWithDot dotVariant='circle' dotColor='black'>{nbspacer('первая рабочая встреча с руководителем, где вы обсудите задачи на испытательный срок.')}</TextWithDot>
                  <Text>{nbspacer('В день выхода нового сотрудника HR отдела делает его представление в корпоративном канале, чтобы все сотрудники компании могли с ним познакомиться.')}</Text>
                </>,
                <>
                  <Text>{nbspacer('Поэтому накануне выхода к нам, не забудь прислать в HR отдел свое фото в электронном виде, где ты улыбаешься и максимально похож на себя в обычной жизни (можно прислать несколько, можно селфи). Еще нужно будет написать небольшой абзац о себе (хобби, интересы и все, что считаешь важным).')}</Text>
                </>
              ].map((item, index) => (
                <Stack
                  key={index}
                  orientation='horizontal'
                  gap={20}
                >
                  <div
                    style={{
                      width: 48,
                      height: 48,
                      flexShrink: 0,
                      background: colors['bright-emerald'],
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Text color='white'>{index + 1}</Text>
                  </div>
                  <Stack gap={8}>{item}</Stack>
                </Stack>
              ))}
              <Stack
                orientation='horizontal'
                gap={20}
              >
                <div
                  style={{
                    width: 48,
                    height: 48,
                    flexShrink: 0,
                    background: colors['red'],
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Text color='white'>!</Text>
                </div>
                <Heading variant='3'>Главное, не забудь хорошее настроение!</Heading>
              </Stack>
            </Stack>
            <div>
              <Card>
                <Stack gap={30}>
                  <Heading variant='3'>Чек-лист после первой недели работы</Heading>
                  <Stack gap={13}>
                    {[
                      'Я подписал(а) документы о приеме',
                      'Я получил(а) рабочий аккаунт',
                      'У меня есть аккаунт на Битрикс24',
                      'Я получил(а) технику для работы',
                      'Меня добавили в рабочие чаты Центра',
                      'Я опубликовал(а) в чате самопрезентацию',
                      'У меня была встреча с моим руководителем',
                      'Руководитель добавил меня в чат моей команды',
                      'Я изучил(а) правила коммуникаций',
                      'Я изучил(а) инструкции по работе с CRM Битрикс24',
                      'У меня есть задачи на ближайшее время'
                    ].map(
                      (item, index) => (
                        <Checkbox key={index} label={item} />
                      )
                    )}
                  </Stack>
                </Stack>
              </Card>
            </div>
          </Grid>

          <Grid
            cols={2}
            tabletCols={1}
            columnGap={20}
            rowGap={20}
          >
            {/* left */}
            <Grid
              cols={2}
              tabletCols={1}
              columnGap={20}
              rowGap={20}
            >
              {infoItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    padding: '47px 43px',
                    border: 'none',
                    outline: 'none',
                    borderRadius: '10px',
                    background: item.title === currentInfoItem?.title ? '#14D4C9' : colors['bright-emerald'],
                    cursor: 'pointer'
                  }}
                  onClick={() => setCurrentInfoItem(item)}
                >
                  <Heading variant='3' color='white'>{item.title}</Heading>
                </button>
              ))}
            </Grid>
            {/* right */}
            <Card>
              <Stack gap={10}>
                <Stack
                  orientation='horizontal'
                  gap={20}
                  alignItems='center'
                >
                  <Image
                    width={100}
                    height={100}
                    src={currentInfoItem?.image}
                    style={{
                      flexShrink: 0
                    }}
                  />
                  <Heading variant='3' color='bright-emerald'>{currentInfoItem?.title}</Heading>
                </Stack>

                {currentInfoItem?.content}
              </Stack>
            </Card>
          </Grid>

          <Heading variant='2'>Ссылки на ресурсы</Heading>
          <Grid
            cols={3}
            tabletCols={2}
            phoneCols={1}
            columnGap={20}
            rowGap={20}
          >
            {[
              {
                image: '/static/img/pages/welcome/links/image1.svg',
                title: 'Личный кабинет',
                url: 'https://my.ranepa.ru/profile/'
              },
              {
                image: '/static/img/pages/welcome/links/image2.svg',
                title: 'Спортивный комплекс',
                url: 'https://www.ranepa.ru/ob-akademii/sport-v-akademii/?sphrase_id=2856760 '
              },
              {
                image: '/static/img/pages/welcome/links/image3.svg',
                title: 'Корпоративный университет',
                url: 'https://my.ranepa.ru/profile_new/cab/corp.php'
              },
              {
                image: '/static/img/pages/welcome/links/image4.svg',
                title: 'Автошкола',
                url: 'http://www.autoshkola.ranepa.ru/'
              },
              {
                image: '/static/img/pages/welcome/links/image5.svg',
                title: 'Медицинский центр',
                url: 'https://med.ranepa.ru/'
              },
              {
                image: '/static/img/pages/welcome/links/image6.svg',
                title: 'Брендбук Центра',
                url: 'https://www.ranepa.ru/ob-akademii/brendbuk/'
              }
            ].map(
              (item, index) => (
                <a
                  key={index}
                  href={item.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    textDecoration: 'none'
                  }}
                >
                  <Card
                    style={{
                      height: '100%',
                      boxSizing: 'border-box'
                    }}
                  >
                    <Stack gap={24} alignItems='center'>
                      <Image width={110} src={item.image} />
                      <Heading variant='3'>{item.title}</Heading>
                    </Stack>
                  </Card>
                </a>
              )
            )}
          </Grid>
        </Stack>
      </PageSection>

      {/* Теперь ты в нашей команде! */}
      <div
        style={{
          backgroundColor: colors['bright-emerald'],
          padding: device === 'phone' ? '10px 20px' : '0 150px'
        }}
      >
        <Grid
          cols={2}
          tabletCols={1}
          phoneCols={1}
          columnGap={100}
          rowGap={20}
          alignItems='center'
        >
          <Stack gap={20}>
            <Heading variant='1' color='white'>Теперь ты в нашей команде!</Heading>
            <Text color='white'>{nbspacer('Надеемся, что полученная информация поможет сориентироваться в первые дни работы и  они пройдут комфортно. Желаем продуктивной работы и профессиональных успехов!')}</Text>
          </Stack>
          <Image width='100%' height={358} src='/static/img/pages/welcome/image5.svg' />
        </Grid>
      </div>
      <QuestionSection bgColor='light-gray' defaultPurposeOfRequest='welcome_book' />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default WelcomePage
