import React from 'react'

import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import CourseAboutCard from 'components/v2/CourseAboutCard/CourseAboutCard'
import Heading from 'components/v2/Heading/Heading'
import IconView from 'components/v2/IconView/IconView'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'

const AboutCourseSection = () => {
  return (
    <PageSection variant='gray'>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={20}
        rowGap={20}
      >
        {/* left */}
        <Stack gap={40}>
          <Heading variant='2'>О курсе</Heading>
          <Text variant='text-m'>Курс о том, как устроен и функционирует государственный аппарат Российской Федерации и как взаимодействуют государство и органы местного самоуправления</Text>
          <Stack alignItems='center'>
            <Image
              width='50%'
              src='/static/img/pages/program-registry/state-administration/about-course-section/section-image.svg'
            />
          </Stack>
        </Stack>
        {/* right */}
        <div>
          <IconView cols={2} tabletCols={1} columnGap={20} rowGap={20}>
            <CourseAboutCard
              image='horn'
              name='Как?'
              description='Онлайн'
            />
            <CourseAboutCard
              image='laptop'
              name='Интерактивные учебные материалы'
              description=''
            />
            <CourseAboutCard
              image='clock'
              name='Как долго?'
              description='2 недели'
            />

            <CourseAboutCard image='checkbox' name='Когда?' url='/program-registry/timetable' description='Ближайшая дата начала обучения' />
          </IconView>
        </div>
      </Grid>
    </PageSection>
  )
}

export default AboutCourseSection
