// import Stack from 'components/Stack/Stack'
import FooterSection from '../../page-sections/FooterSection/FooterSection'
import HeaderSection from '../../page-sections/HeaderSection/HeaderSection'
import HeaderBlock from './HeaderBlock/HeaderBlock'
import ResultsSection from './ResultsSection/ResultsSection'
import SolveProblemsSection from './SolveProblemsSection/SolveProblemsSection'
import WhenRelevantSection from './WhenRelevantSection/WhenRelevantSection'
import WhyWeSection from './WhyWeSection/WhyWeSection'
import RegulationsSection from './RegulationsSection/RegulationsSection'
import useSectionsData, { getDataByKey } from 'common/sections-data'

const Experts = () => {
  const keys = 'recruiting-header,recruiting-advertising1,recruiting-advertising2,recruiting-advertising3,recruiting-advertising4,recruiting-advertising5,recruiting-steps'
  const sectionList = useSectionsData(keys)

  return (
    <div>
      <HeaderSection>
        <HeaderBlock
          imageUrl='/static/img/pages/recruiting-center/header-banner.svg'
          {...getDataByKey(sectionList, 'recruiting-header')}
        />
      </HeaderSection>

      <SolveProblemsSection {...getDataByKey(sectionList, 'recruiting-advertising1')} />
      <ResultsSection {...getDataByKey(sectionList, 'recruiting-advertising2')} bgColor='light-gray' />
      <WhenRelevantSection
        imageUrl='/static/img/pages/recruiting-center/when-relevant-banner.svg'
        {...getDataByKey(sectionList, 'recruiting-advertising3')}
      />
      <WhyWeSection />
      <RegulationsSection {...getDataByKey(sectionList, 'recruiting-steps')} bgColor='light-gray' />

      <FooterSection />
    </div>
  )
}

export default Experts
