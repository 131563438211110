import FooterSection from 'page-sections/FooterSection/FooterSection'
import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import PartnersSection from './PartnersSection/PartnersSection'

const Partners = () => {
  return (
    <div>
      <HeaderSection bgColor='white' paddingBottom={0} />
      <PartnersSection />
      <FooterSection />
    </div>
  )
}

export default Partners
