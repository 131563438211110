import React from 'react'
import HeaderSection from 'page-sections/v3/HeaderSection/HeaderSection'
import FooterSection from 'page-sections/v3/FooterSection/FooterSection'
import Text from 'components/v2/Text/Text'
import nbspacer from 'common/nbspacer'
import Stack from 'components/Stack/Stack'
import PageSection from 'components/v2/PageSection/PageSection'
import Heading from 'components/v2/Heading/Heading'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import QuestionSection from 'page-sections/v3/QuestionSection/QuestionSection'
import Carousel from 'components/v3/Carousel/Carousel'
import Color from 'common/Color.d'
import CompetencyModelCard from './components/CompetencyModelCard'
import TextWithDot from 'components/TextWithDot/TextWithDot'

const CompetencyModelDetailPage = () => {
  const items1 = [
    {
      title: 'Цифровая грамотность',
      titleColor: 'blue-gray' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Базовая компетенция, включающая умения и навыки применения цифровых технологий для получения, оценки, обработки информации, выбора и безопасного применения программно-технических средств')}
      </Text>
    },
    {
      title: 'Цифровые коммуникации',
      titleColor: 'blue-gray' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Базовая компетенция, включающая умения и навыки применения цифровых технологий для создания цифрового контента, коммуникации и обмена информацией в различных контекстах, выбора технологий, сервисов, каналов, средств и форм коммуникаций, соблюдение этических стандартов и принципов')}
      </Text>
    },
    {
      title: 'Цифровая безопасность',
      titleColor: 'blue-gray' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Базовая компетенция, включающая умения и навыки, необходимые для защиты и обеспечения безопасности информации в цифровой среде, в том числе защита устройств и сетей, обеспечение безопасности паролей, защита конфиденциальной информации, предотвращение кибератак и злонамеренных действий, а также управление репутацией в цифровой среде')}
      </Text>
    },
    {
      title: 'Цифровое потребление',
      titleColor: 'blue-gray' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Базовая компетенция, позволяющая применять цифровые знания, умения и навыки использования цифровых ресурсов (интернет, социальные сети, государственные услуги, телемедицина и т. д.) в различных жизненных ситуациях, понимание цифровых прав и обязанностей в качестве потребителя цифровых услуг')}
      </Text>
    }
  ]

  const items2 = [
    {
      title: 'Клиентоцентричность',
      titleColor: 'red' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей личности, обеспечивающая сознательную направленность на определение и максимальное удовлетворение явных и скрытых потребностей пользователей результатов цифровой трансформации с учетом разумных издержек и соблюдения интересов заинтересованных сторон')}
      </Text>
    },
    {
      title: 'Коммуникативность',
      titleColor: 'red' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей участника проектной деятельности, обеспечивающая выбор наилучшей стратегии и тактики общения, эффективную деловую коммуникацию, открытость и проявление уважения в целях развития сотрудничества в процессе реализации стратегии цифровой трансформации и проектов цифрового развития')}
      </Text>
    },
    {
      title: 'Нацеленность на результат',
      titleColor: 'red' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей личности, обеспечивающая максимизацию собственных возможностей при выполнении взятых обязательств, решении нестандартных, сложных задач для полного и своевременного получения результатов и достижения целей цифрового развития наиболее продуктивным способом')}
      </Text>
    },
    {
      title: 'Эмоциональный интеллект',
      titleColor: 'red' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей личности, обеспечивающая возможность управлять личными эмоциями и эмоциями других людей для решения практических задач, верно оценивать эмоции, намерения, мотивацию и желания (свои и чужие), создавать и поддерживать рабочую атмосферу во время командной работы')}
      </Text>
    },
    {
      title: 'Креативность',
      titleColor: 'red' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей личности, обеспечивающих способность формулировать нестандартные идеи, отходить от традиционных схем мышления, быстро находить выход из сложных ситуаций, используя нешаблонные подходы')}
      </Text>
    },
    {
      title: 'Критичность',
      titleColor: 'red' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Совокупность устойчивых особенностей личности, позволяющая обдуманно и взвешенно проводить оценку событий, процессов, результатов деятельности для всесторонней проверки и исправления возможно допущенных ошибок; способность осознавать свои ошибочные решения и приводить доводы за и против, выдвигать предложения и подвергать их всестороннему анализу')}
      </Text>
    }
  ]

  const items3 = [
    {
      title: 'Управление цифровым развитием',
      titleColor: 'white' as Color,
      bgColor: 'bright-emerald' as Color,
      content: <Text variant='text-m' color='white'>
        {nbspacer('Применение методов и инструментов стратегического, тактического и оперативного управления внедрением и развитием цифровых технологий, услуг, инфраструктуры')}
      </Text>
    },
    {
      title: 'Концептуальные положения цифровой экономики',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Целевые ориентиры развития промышленных технологий и инноваций</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Тезаурус цифровой экономики</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Приоритетные направления цифровой экономики</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Механизмы и инструменты цифровой трансформации',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровая трансформация на основе платформ</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Правовое регулирование цифровой среды</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Критерии цифровой зрелости</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Стратегическое управление цифровым развитием',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Анализ текущего состояния</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Разработка дородной карты цифрового  развития организации</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Модель управления цифровой трансформацией</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Государственная технологическая повестка',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Обеспечение технологического суверенитета</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровизация государственных услуг</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Отраслевая технологическая повестка</Text></TextWithDot>
      </Stack>
    }
  ]

  const items4 = [
    {
      title: 'Развитие организационной культуры',
      titleColor: 'white' as Color,
      bgColor: 'bright-emerald' as Color,
      content: <Text variant='text-m' color='white'>
        {nbspacer('(В условиях цифровой трансформации) Применение технологий формирования, управления, изменения функциональных процессов и системы цифровых ценностей, норм и правил поведения персонала, нацеленных на повышение результативности организации')}
      </Text>
    },
    {
      title: 'Инструменты и методы формирования и поддержания организационной культуры',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы теории организационной культуры</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Принципы и этапы формирования организационной культуры</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Условия поддержания организационной культуры</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Механизмы управления организационными изменениями',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Принципы и алгоритмы управления организационными изменениями</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Модели управления организационными изменениями</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Методы работы с сопротивлением</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Технологии управления кадровой политикой цифрового развития',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Организационные структуры управления цифровой трансформацией</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровые команды: модель компетенций; модели формирования</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Управление HR-циклом организации</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Принципы и алгоритмы практик регулярного менеджмента',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Управление ресурсами организации</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Управление командами</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Управление личной эффективностью</Text></TextWithDot>
      </Stack>
    }
  ]

  const items5 = [
    {
      title: 'Инструменты управления',
      titleColor: 'white' as Color,
      bgColor: 'bright-emerald' as Color,
      content: <Text variant='text-m' color='white'>
        {nbspacer('Совокупность моделей и методов, используемых для решения задач управления процессами, проектами, продуктами и их информационного, организационного и методического обеспечения')}
      </Text>
    },
    {
      title: 'Методы и инструменты процессного подхода',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы процессного управления</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Ролевая модель управления процессами</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Порядок и инструменты проведения реинжиниринга и оптимизации процессов</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Методы и инструменты продуктового подхода',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы продуктового подхода</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Ролевая модель продуктовой команды</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Методы и этапы разработки продукта</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Методы и инструменты проектного подхода',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы проектного управления</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Ролевая модель управления проектом</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Методы и инструменты управления проектом</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Методы и инструменты антикризисного менеджмента',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы антикризисного менеджмента</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Методы и инструменты антикризисного менеджмента</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Эффективность антикризисного менеджмента</Text></TextWithDot>
      </Stack>
    }
  ]

  const items6 = [
    {
      title: 'Управление и использование данных',
      titleColor: 'white' as Color,
      bgColor: 'bright-emerald' as Color,
      content: <Text variant='text-m' color='white'>
        {nbspacer('Знание и применение методов и технологий сбора, структурирования, анализа данных для построения новых организационных и управленческих моделей, продуктов и сервисов в системе государственного управления')}
      </Text>
    },
    {
      title: 'Инструменты управления данными в рамках функциональных направлений',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Планирование и проектирование данных</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Обеспечение доступности и обслуживания</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Использование и улучшение данных</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Технологии управления большими данными',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Архитектуры и среды обработки больших данных</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Процессы управления большими данными</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Техники и методы анализа больших данных</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Основы руководства данными',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Инструменты руководства данными</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Обеспечение качества данных</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Этика обращения с данными</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Управление организацией на основе данных',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Интеграция управления на данных в системы менеджмента организации</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Механизм принятия решений, основанных на данных</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Оценка зрелости управления данными</Text></TextWithDot>
      </Stack>
    }
  ]

  const items7 = [
    {
      title: 'Применение цифровых технологий',
      titleColor: 'white' as Color,
      bgColor: 'bright-emerald' as Color,
      content: <Text variant='text-m' color='white'>
        {nbspacer('Знание и использование методов проектирования, построения и управления корпоративной архитектурой, управления ИТ-системами, применения сквозных технологий, а также средств и методов информационной и кибербезопасности в системе государственного управления')}
      </Text>
    },
    {
      title: 'Сквозные технологии',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровые технологии в бизнес-процессах систем</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровые технологии, формирующие новую инфраструктуру</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровые технологии проектирования и моделирования</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'ИИ-решения',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Основы искусственного интеллекта</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Технологии ИИ-решений</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Оценка зрелости внедрения ИИ-решений</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Технологии трансформации общества',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Технологии умного города</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровое управление регионом</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цифровые профили граждан</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Перспективные технологии',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Технологии устойчивого развития</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Биотехнологии</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Новые материалы</Text></TextWithDot>
      </Stack>
    }
  ]

  const items8 = [
    {
      title: 'Развитие ИТ-инфраструктуры',
      titleColor: 'white' as Color,
      bgColor: 'bright-emerald' as Color,
      content: <Text variant='text-m' color='white'>
        {nbspacer('Применение методов и инструментов, направленных на развитие совокупности информационных центров, подсистем, банков данных и знаний, систем связи, центров управления, аппаратно-программных средств и технологий обеспечения сбора, хранения, обработки и передачи информации организации')}
      </Text>
    },
    {
      title: 'Основы создания ИТ-инфраструктуры организации',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Формирование технической документации на этапах создания ИТ-инфраструктуры</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Характеристики архитектуры организации</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Процесс разработки архитектуры организации</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Инфраструктурные решения',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Принципы построения ИТ-инфраструктуры</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Концепции управления ИТ-инфраструктурой</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Модели и принципы построения облачных решений (IaaS, PaaS, SaaS)</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Средства и методы информационной и кибербезопасности',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Цели и задачи защиты информации,  типы и источники угроз</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Системы управления информационной безопасностью</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Инструменты защиты информации и обеспечения кибербезопасности</Text></TextWithDot>
      </Stack>
    },
    {
      title: 'Национальная ИТ-инфраструктура',
      titleColor: 'bright-emerald' as Color,
      content: <Stack>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Государственные информационные системы (ГИС)</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Технологии платформы «ГосТех»</Text></TextWithDot>
        <TextWithDot dotColor='black' dotVariant='circle' dotSize={5}><Text>Инфраструктура защищенных коммуникаций</Text></TextWithDot>
      </Stack>
    }
  ]

  const items9 = [
    {
      title: 'Цифровое мышление',
      titleColor: 'orange' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Осознанное познание цифровой среды, основанное на когнитивных способностях, позволяющих переосмыслить привычные процессы, изменить установки, модели поведения и адаптироваться к требованиям цифровой жизни')}
      </Text>
    },
    {
      title: 'Цифровая аффилиация',
      titleColor: 'orange' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Личные установки и мотивы, которые влияют на отношение человека к цифровым технологиям и их использованию в профессиональной деятельности. Например: интерес к новым технологиям, цифровые хобби (стриминг, программирование, гейминг, цифровая графика, цифровые книги и пр.), доступность технологий, удобство их использования и пр.')}
      </Text>
    },
    {
      title: 'Цифровая гибкость',
      titleColor: 'orange' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Быстрая адаптация к изменяющимся условиям использования цифровых технологий и ресурсов, а также эффективное использование их в новых и непредсказуемых ситуациях, включая умение быстро ориентироваться в новых технологиях, осваивать и использовать новые инструменты и ресурсы, а также готовность к постоянному обучению и развитию')}
      </Text>
    },
    {
      title: 'Цифровой опыт',
      titleColor: 'orange' as Color,
      content: <Text variant='text-m' color='black'>
        {nbspacer('Практический опыт использования цифровых технологий и ресурсов для достижения конкретных целей и удовлетворения личных и профессиональных потребностей. Влияет на поведение человека в цифровой среде, его предпочтения и потребности, а также на качество жизни и профессиональную эффективность')}
      </Text>
    }
  ]

  return (
    <div>
      <HeaderSection bgColor='bright-emerald' style={{ paddingBottom: 0 }}>
        <Grid cols={2} tabletCols={1} columnGap={40} rowGap={20}>
          <Stack gap={40}>
            <Heading variant='1' color='white'>{nbspacer('Модель компетенций 3.0')}</Heading>
            <Text color='white'>{nbspacer('Методологическая разработка Центра, на основе которой выстроена комплексная оценка руководителей и команд цифровой трансформации. ')}</Text>
          </Stack>
          <Image width='100%' src='/static/img/pages/competency-models/image1.svg' />
        </Grid>
      </HeaderSection>
      <PageSection paddingTop={80} paddingBottom={80}>
        <Stack gap={80}>
          <Stack gap={30}>
            <Stack gap={20}>
              <Heading variant='2' color='blue-gray'>Базовые цифровые компетенции</Heading>
              <Text>{nbspacer('Минимально необходимый уровень знаний и навыков использования информационно-коммуникационных технологий (ИКТ) в повседневной и профессиональной деятельности')}</Text>
            </Stack>
            <Carousel
              quantity={2}
              tabletQuantity={1}
              phoneQuantity={1}
              gap={10}
              bottomNavColor='blue-gray'
            >
              {items1.map((item, index) => (
                <CompetencyModelCard
                  key={index}
                  {...item}
                />
              ))}
            </Carousel>
          </Stack>

          <Stack gap={30}>
            <Stack gap={20}>
              <Heading variant='2' color='red'>Личностные компетенции в сфере цифрового развития</Heading>
              <Text>{nbspacer('Группа компетенций, отражающих индивидуальные особенности личности, позволяющие успешно участвовать в реализации стратегии цифровой трансформации и проектах цифрового развития')}</Text>
            </Stack>
            <Carousel
              quantity={2}
              tabletQuantity={1}
              phoneQuantity={1}
              gap={10}
              bottomNavColor='red'
            >
              {items2.map((item, index) => (
                <CompetencyModelCard
                  key={index}
                  {...item}
                />
              ))}
            </Carousel>
          </Stack>

          <Stack gap={30}>
            <Stack gap={20}>
              <Heading variant='2' color='bright-emerald'>Профессиональные компетенции</Heading>
              <Text>{nbspacer('Группа компетенций, связанных с функциональным использованием методов и инструментов управления процессами, проектами, продуктами цифровой трансформации и регулярным решением сложных профессиональных задач в цифровой среде')}</Text>
            </Stack>
            <Carousel quantity={2} phoneQuantity={1} tabletQuantity={1} gap={10} bottomNavColor='bright-emerald'>
              {items3.map((item, index) => (
                <CompetencyModelCard
                  key={index}
                  {...item}
                />
              ))}
            </Carousel>

            <Carousel quantity={2} phoneQuantity={1} tabletQuantity={1} gap={10} bottomNavColor='bright-emerald'>
              {items4.map((item, index) => (
                <CompetencyModelCard
                  key={index}
                  {...item}
                />
              ))}
            </Carousel>

            <Carousel quantity={2} phoneQuantity={1} tabletQuantity={1} gap={10} bottomNavColor='bright-emerald'>
              {items5.map((item, index) => (
                <CompetencyModelCard
                  key={index}
                  {...item}
                />
              ))}
            </Carousel>

            <Carousel quantity={2} phoneQuantity={1} tabletQuantity={1} gap={10} bottomNavColor='bright-emerald'>
              {items6.map((item, index) => (
                <CompetencyModelCard
                  key={index}
                  {...item}
                />
              ))}
            </Carousel>

            <Carousel quantity={2} phoneQuantity={1} tabletQuantity={1} gap={10} bottomNavColor='bright-emerald'>
              {items7.map((item, index) => (
                <CompetencyModelCard
                  key={index}
                  {...item}
                />
              ))}
            </Carousel>

            <Carousel quantity={2} phoneQuantity={1} tabletQuantity={1} gap={10} bottomNavColor='bright-emerald'>
              {items8.map((item, index) => (
                <CompetencyModelCard
                  key={index}
                  {...item}
                />
              ))}
            </Carousel>

          </Stack>

          <Stack gap={30}>
            <Stack gap={20}>
              <Heading variant='2' color='orange'>Цифровая культура</Heading>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  gap: 20
                }}
              >
                <Text variant='text-m-medium'>Цифровая культура организации</Text>
                <Text>{nbspacer('– система ценностей, установок, норм и правил поведения в цифровой среде, которую принимают, поддерживают и транслируют работники и руководство организации')}</Text>
                <Text variant='text-m-medium'>Цифровая культура специалиста</Text>
                <Text>{nbspacer('– проявление совокупности личностных и профессиональных компетенций, сформированной в системе ценностей, установок, норм и правил поведения в цифровой среде, необходимой для эффективной работы с применением цифровых технологий в рамках своей профессиональной деятельности')}</Text>
              </div>
            </Stack>
            <Carousel
              quantity={2}
              tabletQuantity={1}
              phoneQuantity={1}
              gap={10}
              bottomNavColor='orange'
            >
              {items9.map((item, index) => (
                <CompetencyModelCard
                  key={index}
                  {...item}
                />
              ))}
            </Carousel>
          </Stack>

        </Stack>
      </PageSection>

      <QuestionSection bgColor='light-gray' defaultPurposeOfRequest='competency_model' />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default CompetencyModelDetailPage
