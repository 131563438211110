import PageSection from 'components/PageSection/PageSection'
import { heading, itemList } from './data'
import Color from 'common/Color.d'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Grid from 'components/Grid/Grid'
import NumberListCard, { NumberListCardProps } from 'components/NumberListCard/NumberListCard'

// import styles from './Advertising2Section.module.css'

type Advertising2SectionProps = {
  bgColor?: Color
}

const Advertising2Section = (props: Advertising2SectionProps) => {
  return (
    <PageSection bgColor={props.bgColor}>
      <SectionHeading>{heading}</SectionHeading>
      <Grid
        cols={2}
        tabletCols={1}
        rowGap={60}
        tabletRowGap={40}
        columnGap={20}
      >
        {itemList.map((item: NumberListCardProps) => {
          return <NumberListCard key={item.id} {...item} />
        })}
      </Grid>
    </PageSection>
  )
}

export default Advertising2Section
