import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Text from 'components/Text/Text'

import { heading, imageBullets } from './data'

type StructureSectionProps = {
  id?: number | string;
  bgColor?: Color;
}

const StructureSection = (props: StructureSectionProps) => {
  return (
    <PageSection {...props}>
      <SectionHeading animate animationVariant='appear-up'>{heading}</SectionHeading>

      <Grid
        cols={4}
        tabletCols={2}
        phoneCols={1}
        columnGap={20}
        rowGap={20}
      >
        {imageBullets?.map((item: any, index: number) => {
          return (<Card
            animate
            animationVariant='appear-up'
            animateDelayIndex={index % 4}
            key={item.id}
            bgColor='white'
            id={`${item.id}-card`}
          >
            <Stack gap={20} alignItems='center'>
              <Image src={item.imageUrl} width={80} />
              <Text align='center' variant='small' fontWeight='medium'>{nbspacer(item.description)}</Text>
            </Stack>
          </Card>)
        })}
      </Grid>
    </PageSection>
  )
}

export default StructureSection
