import getCookie from 'common/get-coockie'
import Heading from 'components/Heading/Heading'
import Modal from 'components/Modal/Modal'
import React, { useState } from 'react'
import Button from 'components/Button/Button'
import Checkbox from 'components/Checkbox/Checkbox'
import TextField from 'components/TextField/TextField'
import Text from 'components/v2/Text/Text'

import styles from './SubscribeForm.module.css'
import Link from 'components/Link/Link'

const SubscribeForm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [allowProcessPersonalData, setAllowProcessPersonalData] = React.useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      name: { value: string }
      email: { value: string }
      allow_process_personal_data: { value: boolean }
    }
    const name = target.name.value
    const email = target.email.value
    const allowPd = target.allow_process_personal_data.checked

    const body = {
      name: name,
      email: email,
      allow_pd: allowPd
    }

    const csrftoken = getCookie('csrftoken')

    const url = `/api/subscribtions`
    let params: any = {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, params)
    if (response.ok) {
      setIsModalVisible(true)
      setAllowProcessPersonalData(false)
      e.target.reset()
    }
  }

  const handleClick = (e: React.SyntheticEvent) => {
  }

  const handleCheckboxChange = (e: any) => {
    setAllowProcessPersonalData(e.target.checked)
  }

  const checkboxLabel: any = <Text variant='text-s' color='white'>
    Даю согласие на обработку
    <a
      className={styles.Link + ' color-white'}
      href='https://cdto.ranepa.ru/media/consent_to_the_processing_of_personal_data.pdf'
      target='_blank'
      rel='noreferrer'
    >
      персональных данных
    </a>
  </Text>

  return (
    <div className={styles.SubscribeForm}>
      <Modal
        visible={isModalVisible} onClose={() => setIsModalVisible(false)}
      >
        <Heading variant='3'>Спасибо, вы подписались на наши новости</Heading>
      </Modal>
      <h3 className='color-white'>Email-рассылка Центра</h3>
      <form className={styles.Form} onSubmit={handleSubmit}>
        <div>
          <TextField
            name='name'
            blockElement
            fullWidth
            themeWhite
            autoComplete='off'
            placeholder='Ф. И. О. '
            required
          />
        </div>
        <div>
          <TextField
            name='email'
            type='email'
            blockElement
            fullWidth
            themeWhite
            required
            autoComplete='off'
            placeholder='E-mail'
          />
        </div>
        <div>
          <Checkbox
            name='allow_process_personal_data'
            whiteTheme
            onChange={handleCheckboxChange}
            label={checkboxLabel}
            checked={allowProcessPersonalData}
          />
        </div><div>
          <Link
            to='https://disk.yandex.ru/i/mRz0zVJIdkThlA'>
            <Text color='white' style={{ textDecoration: 'underline' }} variant='text-s'>Политика РАНХиГС в отношении обработки персональных данных</Text>
          </Link>
        </div>
        <div>
          <Button
            onClick={handleClick}
            type='submit'
            disabled={!allowProcessPersonalData}
          >
            Отправить
          </Button>
        </div>
      </form>
    </div>
  )
}

export default SubscribeForm
