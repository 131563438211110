const colors = {
  'white': '#FFFFFF',
  'black': '#0F1010',
  'burgundy': '#85180F',
  'blue': '#CEE1EC',
  'bright-emerald': '#2B9FAC',
  'emerald': '#459689',
  'dark-emerald': '#24685F',
  'light-emerald': '#699D9A',
  'gray': '#B2B2B2',
  'dark-gray': '#636F7A',
  'light-gray': '#E9F0F4',
  'pale-gray': '#D4D4D4',
  'green': '#00826F',
  'new-blue': '#407BFF',
  'blue-gray': '#3D5267',
  'red': '#B62936',
  'orange': '#E78E24',
  'orange-dark': '#E55E35'
}

export default colors
