import colors from 'common/colors'
import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import Persona, { PersonaProps } from 'components/Persona/Persona'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import { getFormattedDate, getFormattedTime } from 'common/utils'
import React from 'react'

type EventCardProps = {
  id?: number;
  date: string;
  time?: string;
  name: string;
  description: string;
  url?: string;
  category_name: string;
  person_name: string;
  person_position: string;
  person_image_url: string;
}

const EventCard = (props: EventCardProps) => {
  const personaProps: PersonaProps = {
    name: props.person_name,
    position: props.person_position,
    imageUrl: props.person_image_url,
    horizontal: true
  }

  const photoStyle: React.CSSProperties = {
    objectFit: 'contain',
    borderRadius: '50%',
    border: '1px solid white',
    backgroundColor: colors['light-gray'],
    flexShrink: 0,
    width: '42px',
    height: '42px'
  }

  return (
    <Card bgColor='light-gray' width={'83%'} tabletWidth={'unset'}>
      <Stack orientation='horizontal' alignItems='start'>
        <Stack flexGrow={1} gap={20}>
          <Stack gap={16} orientation='horizontal' phoneOrientation='vertical'>
            <TextWithDot gap={16} dotColor='burgundy' dotVariant='rounded'>
              <Text variant='tiny' fontWeight='medium' uppercase>{getFormattedDate(props.date)}</Text>
            </TextWithDot>
            {props.time &&
              <TextWithDot gap={16} dotColor='burgundy' dotVariant='rounded'>
                <Text variant='tiny' fontWeight='medium' uppercase>{getFormattedTime(props.time)}</Text>
              </TextWithDot>
            }
            <TextWithDot gap={16} dotColor='burgundy' dotVariant='rounded'>
              <Text variant='tiny' fontWeight='medium' uppercase>{props.category_name}</Text>
            </TextWithDot>
          </Stack>

          <Stack gap={16}>
            <Heading variant='3'>{props.name}</Heading>
            <Text variant='small' color='dark-gray'>{props.description}</Text>
          </Stack>

          <Stack orientation='horizontal' gap={10}>
            <Image style={photoStyle} src={props.person_image_url} width='42px' />
            <Stack>
              <Text variant='small' fontWeight='medium'>{props.person_name}</Text>
              <Text variant='small' color='dark-gray'>{props.person_position}</Text>
            </Stack>
          </Stack>

          {false && <Persona {...personaProps} />}
          {props.url && <div>
            <Button
              variant='filled'
              href={props.url}
              target='_blank'
              rel='noreferrer'
            >
                Зарегистрироваться
            </Button>
          </div>
          }
        </Stack>
        <Image src='/static/img/icons/dots-arrow.svg' />
      </Stack>
    </Card>
  )
}

export default EventCard
export type { EventCardProps }
