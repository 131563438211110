import nbspacer from 'common/nbspacer'
import useScrolledTo from 'common/useScrolledTo'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import React, { useEffect } from 'react'

type IndicatorData = {
  id: number;
  value: number;
  description: string;
  showPercent?: boolean;
  showPlus?: boolean;
}

type IndicatorProps = {
  animate?: boolean;
  animateDelayIndex?: number;
} & IndicatorData

const Indicator = (props: IndicatorProps) => {
  const spanRef = React.useRef<HTMLSpanElement>(null)
  const scrolledTo = useScrolledTo(spanRef)

  useEffect(() => {
    const animationDuration = 1500
    const frameDuration = 1000 / 60
    const totalFrames = Math.round(animationDuration / frameDuration)
    const easeOutQuad = (t: any) => t * (2 - t)

    const el = spanRef.current

    const animateCountUp = (el: any, countTo: number) => {
      let frame = 0
      const counter = setInterval(() => {
        frame++
        const progress = easeOutQuad(frame / totalFrames)
        const currentCount = Math.round(countTo * progress)

        if (parseInt(el.innerHTML, 10) !== currentCount) {
          el.innerHTML = currentCount
        }

        if (frame === totalFrames) {
          clearInterval(counter)
        }
      }, frameDuration)
    }

    if (props.animate && scrolledTo && el) {
      animateCountUp(el, props.value)
    }
  }, [scrolledTo, props.animate, props.value])

  return (
    <Stack gap={6} alignItems='flex-start'>
      <Text variant='extra-large' fontWeight='bold' color='emerald'>
        <span ref={spanRef}>{props.value}</span>
        {props.showPercent && '%'}
        {props.showPlus && '+'}
      </Text>
      <Text fontWeight='medium'>
        <div dangerouslySetInnerHTML={{ __html: nbspacer(props.description ?? '') }} />
      </Text>
    </Stack>
  )
}

export default Indicator
export type { IndicatorData, IndicatorProps }
