import useMediaQuery from 'common/useMediaQuery'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import React, { useEffect, useState } from 'react'

type CarouselProps = {
  id?: string | number;
  children: React.ReactNode;
  gap?: number;
  quantity: number;
  tabletQuantity?: number;
}

const Carousel = (props: CarouselProps) => {
  const { isTablet } = useMediaQuery()
  const [index, setIndex] = useState(0)
  const [gapWidth, setGapWidth] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)
  const [itemCount, setItemCount] = useState(0)
  const carouselRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    updateCarouselWidth()
    window.addEventListener('resize', handleResize)
  }, []) // eslint-disable-line

  useEffect(() => {
    updateCarouselWidth()
  }, [props.children]) // eslint-disable-line

  const handleResize = () => {
    setIndex(0)
    updateCarouselWidth()
  }

  const getQuantity = () => {
    return (isTablet && props.tabletQuantity) ? props.tabletQuantity : props.quantity
  }

  const updateCarouselWidth = () => {
    const carouselWidth = carouselRef.current?.clientWidth ?? 0
    const gapWidth = props.gap ? props.gap * (getQuantity() - 1) : 0
    const itemWidth = (carouselWidth - gapWidth) / getQuantity()
    setGapWidth(gapWidth)
    setItemWidth(itemWidth)
    let items = carouselRef.current?.children as HTMLCollectionOf<HTMLElement> // eslint-disable-line
    setItemCount(items.length)
    for (let item of items) {
      const paddingLeft = parseInt(window.getComputedStyle(item).paddingLeft)
      const paddingRight = parseInt(window.getComputedStyle(item).paddingRight)
      item.style.width = (itemWidth - paddingLeft - paddingRight) + 'px'
      item.style.flexShrink = '0'
    }
  }

  let carouselWrapperStyle: React.CSSProperties = {
    overflowX: 'hidden',
    flexGrow: 1
  }

  const carouselWrapperProps = {
    style: carouselWrapperStyle,
    position: 'relative'
  }
  const onClickLeft = () => {
    if (index === 0) return
    setIndex(index - 1)
  }

  const onClickRight = () => {
    if (index === itemCount - getQuantity()) return
    setIndex(index + 1)
  }

  const getLeft = () => {
    return '-' + index * (gapWidth / 2 + itemWidth) + 'px'
  }

  let carouselStyle: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: props.gap,
    position: 'relative',
    left: getLeft(),
    transition: 'all 200ms cubic-bezier(0.61, 1, 0.88, 1)',
    width: '100%'
  }

  const carouselProps = {
    style: carouselStyle,
    ref: carouselRef
  }

  return (
    <Stack orientation='horizontal' alignItems={'center'} gap={20}>
      <div onClick={onClickLeft} style={{cursor: 'pointer', userSelect: 'none', width: '15px'}}>
        <Image
          src={index === 0 ? '/static/img/components/carousel/arrow-disabled.svg' : '/static/img/components/carousel/arrow-active.svg'}
        />
      </div>
      <div {...carouselWrapperProps}>
        <div {...carouselProps}>{props.children}</div>
      </div>
      <div onClick={onClickRight} style={{cursor: 'pointer', userSelect: 'none', width: '15px'}}>
        <Image
          src={index + getQuantity() < itemCount ? '/static/img/components/carousel/arrow-active.svg' : '/static/img/components/carousel/arrow-disabled.svg'}
          style={{transform: 'rotate(180deg)'}}
        />
      </div>
    </Stack>
  )
}

export default Carousel
