import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'

import { registerCardTitle, registerCardText, registerUrl } from '../data'

const RegisterCard = () => {
  return (
    <Card bgColor='emerald'>
      <Stack gap={20}>
        <Stack gap={8}>
          <Heading variant='3' color='white'>{registerCardTitle}</Heading>
          <Text color='white'>{registerCardText}</Text>
        </Stack>
        <div>
          <Button
            href={registerUrl}
            target='_blank'
            rel='noreferrer'
          >
            Перейти на страницу регистрации
          </Button>
        </div>
      </Stack>
    </Card>
  )
}

export default RegisterCard
