import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Button from 'components/Button/Button'

import Text from 'components/Text/Text'
import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Indicator, { IndicatorData } from 'components/Indicator/Indicator'

type ExpertMaterialsSectionProps = {
  heading?: string;
  text?: string;
  bgColor?: Color;
  indicatorBullets?: IndicatorData[];
}

const ExpertMaterialsSection = (props: ExpertMaterialsSectionProps) => {
  return (
    <PageSection id='expert-materials-section' bgColor={props.bgColor}>
      <SectionHeading animate animationVariant='appear-up' showArrow={true}>{nbspacer(props.heading ?? '')}</SectionHeading>

      <Grid
        cols={2}
        tabletCols={1}
        columnGap={20}
        rowGap={20}
      >
        <Text>
          <div dangerouslySetInnerHTML={{__html: nbspacer(props.text ?? '')}} />
        </Text>
        <Image animate animationVariant='appear-left' src='/static/img/pages/home/ai-image.png' width={'100%'} />
      </Grid>

      {props.indicatorBullets &&
        <Grid cols={3} tabletCols={1} columnGap={20} rowGap={20}>
          {props.indicatorBullets?.map((indicator: IndicatorData, index: number) => {
            return <Indicator animate key={indicator.id} {...indicator} animateDelayIndex={index} />
          })}
        </Grid>
      }

      <div><Button variant='filled' href='/sum-of-tech' target='_blank' rel='noreferrer'>Читать</Button></div>
    </PageSection>
  )
}

export default ExpertMaterialsSection
