import nbspacer from 'common/nbspacer'
import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'

const WhenNeededSection = () => {
  const items: string[] = [
    'Безрезультатно ищете работу на протяжении нескольких месяцев, хотя испробованы все очевидные способы',
    'Сложная ситуация на текущем месте работы, боитесь что-то поменять или сменить работодателя',
    'Столкнулись с обесцениванием и вам нужно объективно свои профессиональные и карьерные возможности',
    'Задаетесь вопросом, почему на больших позициях люди с меньшими компетенциями, чем у Вас',
    'Считаете, что карьера закончилась в 40 лет и ваши возможности упущены',
    'Есть опыт и компетенции, но вы не знаете, как и какой дальнейший шаг сделать в карьере',
    'Решили кардинально сменить профессию/ сферу деятельности, но не знаете, с чего начать, и каковы реальные риски',
    'Уже известна компания \\ команда мечты, но недостаточно связей, инсайдерской информации о компании \\ вакансии \\ требованиях или нужных контактов',
    'Везде требуются специалисты «с опытом не менее года», а ты молодой выпускник'
  ]
  return (
    <PageSection
      variant='gray'
    >
      <Stack gap={30}>
        <Heading
          variant='2'
          color='blue-gray'
        >
          В каких случаях необходима карьерная консультация?
        </Heading>

        <Grid
          cols={3}
          tabletCols={2}
          phoneCols={1}
          columnGap={20}
          rowGap={30}
        >
          {items.map((item: string, index: number) => {
            return (
              <Card
                key={index}
                animate
                animateDelayIndex={index}
                animationVariant='zoom-in'
              >
                <Stack
                  orientation='horizontal'
                  gap={20}
                  alignItems='center'
                  height='100%'
                >
                  <Image
                    width='100px'
                    src={`/static/img/pages/career-management/when-needed-section/image${index + 1}.svg`}
                  />
                  <Text>{nbspacer(item)}</Text>
                </Stack>
              </Card>
            )
          })}
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default WhenNeededSection
