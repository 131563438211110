import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'
import { Button } from 'components/Button/Button'
import scrollToId from 'common/scroll-to-id'

const WhyTrustSection = () => {
  const items: Array<string> = [
    'Профессиональная команда HR, готовая помочь на всех этапах — от формирования состава команды и требований к кандидатам до онбординга',
    'Современные методики, включая независимую оценку компетенций',
    'Собственная проверенная база профессионалов',
    'Сотрудничаем с узкопрофильными специалистами',
    'Безупречная репутация в профессиональном сообществе'
  ]

  return (
    <PageSection variant='gradient-green-stars'>
      <Stack gap={40}>
        <Heading
          variant='2'
          color='white'
        >
          Почему нам доверяют?
        </Heading>
        <Grid
          cols={2}
          tabletCols={1}
          columnGap={80}
          rowGap={40}
        >
          {/* left */}
          <Stack gap={40}>
            <Stack gap={30}>
              {items.map((item: string, index: number) => {
                return (
                  <Stack
                    key={index}
                    orientation='horizontal'
                    gap={30}
                  >
                    <Image src='/static/img/pages/recruiting/digital-transformation-specialists/why-trust-section/checkbox.svg' />
                    <Text
                      variant='text-m'
                      color='white'
                    >
                      {item}
                    </Text>
                  </Stack>)
              })}
            </Stack>
            <div><Button onClick={() => scrollToId('question-section')}>Оставить заявку</Button></div>
          </Stack>
          {/* right */}
          <Image src='/static/img/pages/recruiting/digital-transformation-specialists/why-trust-section/section-image.svg' />
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default WhyTrustSection
