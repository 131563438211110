import QuestionSection from 'page-sections/QuestionSection/QuestionSection'
import FooterSection from '../../page-sections/FooterSection/FooterSection'
import HeaderSection from '../../page-sections/HeaderSection/HeaderSection'
import AdvantagesSection from './AdvantagesSection/AdvantagesSection'
import HeaderBlock from './HeaderBlock/HeaderBlock'
import ServicesSection from './ServicesSection/ServicesSection'

const Experts = () => {
  return (
    <div>
      <HeaderSection>
        <HeaderBlock />
      </HeaderSection>

      <ServicesSection />
      <AdvantagesSection />
      <QuestionSection heading='Оставить заявку' id='question-section' />

      <FooterSection />
    </div>
  )
}

export default Experts
