import { useEffect, useState } from 'react'

const useMediaQuery = () => {
  const [isTablet, setIsTablet] = useState(false)
  const [isPhone, setIsPhone] = useState(false)

  const handleChange = () => {
    if (window.innerWidth < 1023) {
      setIsTablet(true)
    } else {
      setIsTablet(false)
    }
    if (window.innerWidth < 767) {
      setIsPhone(true)
    } else {
      setIsPhone(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleChange)
    window.addEventListener('orientationchange', handleChange)
    window.addEventListener('load', handleChange)
    window.addEventListener('reload', handleChange)
    return () => {
      window.removeEventListener('resize', handleChange)
      window.removeEventListener('orientationchange', handleChange)
      window.removeEventListener('load', handleChange)
      window.removeEventListener('reload', handleChange)
    }
  })

  return {
    isTablet: isTablet,
    isPhone: isPhone
  }
}

export default useMediaQuery
