
import React from 'react'

import AboutCourseSection from './AboutCourseSection'
import HeaderSection from './HeaderSection'
import TargetAudienceSection from './TargetAudienceSection'
import ProgramDescriptionSection from 'pages/ProgramRegistry/page-sections/ProgramDescriptionSection'
import WillKnowSection from 'pages/ProgramRegistry/page-sections/WillKnowSection'
import PriceSection from 'pages/ProgramRegistry/page-sections/PriceSection'
import QuestionSection from '../page-sections/QuestionSection/QuestionSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'
import CertificateSection from '../page-sections/CertificateSection'
import CourseContentSection from './CourseContentSection'
import InfoVideoSection from '../page-sections/InfoVideoSection'
import ExpertsSection from '../page-sections/ExpertsSection'
import FaqSection from '../page-sections/FaqSection'

const StateAdministrationPage = () => {
  return (
    <div>
      <HeaderSection />

      <AboutCourseSection />

      <TargetAudienceSection />

      {false && <InfoVideoSection
        variant='gray'
        src='https://play.boomstream.com/8y734sSl'
      />}

      <WillKnowSection
        variant='v2'
        items={[
          'Познакомитесь с особенностями государственного управления. Российской Федерации как федеративного государства',
          'Узнаете, как взаимодействуют государство и органы местного самоуправления',
          'Разберетесь, как создаются нормативные правовые акты: от инициативы до подписания президентом',
          'Изучите стадии бюджетного процесса и поймете, как устроена система управления общественными финансами России',
          'Узнаете, какой должна быть информационная прозрачность деятельности органов исполнительной власти и органов местного самоуправления',
          'Разберетесь в ограничениях и запретах, установленных для государственных гражданских и муниципальных служащих'
        ]}
      />

      <ProgramDescriptionSection
        duration='40 академических часов'
        items={[
          {
            name: '1. Система органов исполнительной власти и органов местного самоуправления. Принципы разграничения полномочий ФОИВ, РОИВ и органов местного самоуправления ',
            items: [
              'Система органов исполнительной власти Российской Федерации',
              'Система органов федеральной исполнительной власти РФ',
              'Система органов региональной исполнительной власти РФ',
              'Система органов местного самоуправления',
              'Принципы разграничения полномочий ФОИВ и РОИВ'
            ]
          },
          {
            name: '2. Документы стратегического планирования: система, практическое значение',
            items: [
              'Как выстроена система стратегического планирования',
              'Документы стратегического планирования и их практическое значение',
              'Мониторинг и контроль реализации документов стратегического планирования',
              'Как реализовывать цифровую трансформацию: стратегические документы'
            ]
          },
          {
            name: '3. Нормативные правовые акты, их виды, особенности разработки и утверждения',
            items: [
              'Нормативный правовой акт: от Конституции до решений муниципалитета',
              'Как создаются НПА: от инициативы до подписания президентом',
              'Роль оценки регулирующего воздействия при разработке НПА',
              'Какие НПА регулируют ИТ-сферу'
            ]
          },
          {
            name: '4. Бюджетный процесс в органах власти. Планирование закупок как часть бюджетного процесса',
            items: [
              'Бюджетный процесс: принципы и участники',
              'Как составляется проект федерального бюджета',
              'Как утверждается проект федерального бюджета',
              'Как исполняется бюджет',
              'Как составляется и утверждается отчет',
              'Принципы бюджетной системы',
              'Концепция повышения эффективности бюджетных расходов: основные направления. Регламентированные закупки в РФ',
              'Законодательство о контрактной системе',
              'Способы определения поставщиков',
              'Начальная максимальная цена контракта'
            ]
          },
          {
            name: '5. Институты общественного контроля и информационная прозрачность деятельности органов исполнительной власти и органов местного самоуправления',
            items: [
              'Особенности общественного контроля',
              'Информационная прозрачность деятельности органов исполнительной власти и органов местного самоуправления',
              'Мониторинг открытости органов власти',
              'Открытые данные как элемент диалога общества с властью'
            ]
          },
          {
            name: '6. Культура управления в органах исполнительной власти: система поручений, проектная и иная деятельность',
            items: [
              'Культура управления в органах власти: предпосылки, специфика, бюрократическая система управления',
              'Система поручений в госсекторе. Управление системой поручений',
              'Процессная и проектная деятельность: признаки и управление'
            ]
          },
          {
            name: '7. Государственная гражданская и муниципальная служба: основные ограничения и особенности работы ',
            items: [
              'Государственная гражданская служба и муниципальная служба: понятия, должности, общие принципы и квалификационные требования к гражданским и муниципальным служащим',
              'Ограничения и запреты для государственных гражданских и муниципальных служащих',
              'Права и обязанности государственных гражданских и муниципальных служащих',
              'Особенности приема на государственную гражданскую службу, ее прохождения и прекращения'
            ]
          }
        ]}
      />

      <ExpertsSection
        experts={['shubin']}
        paddingBottom={0}
      />

      <CourseContentSection />

      <CertificateSection variant='gray' />

      <PriceSection />

      <FaqSection />

      <QuestionSection id={'question-section'}
        defaultPurposeOfRequest='program_registry_state_administration'
      />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default StateAdministrationPage
