import React, { useEffect, useState } from 'react'
import Button from 'components/Button/Button'
import Text from 'components/v2/Text/Text'
import Stack from 'components/Stack/Stack'

const CookieConsent = () => {
  const [userConsent, setUserConsent] = useState(true)

  useEffect(() => {
    const consent = getCookieConsent()
    setUserConsent(consent)
  }, []) // eslint-disable-line

  const style: React.CSSProperties = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#B62936',
    padding: '20px 20px'
  }

  const onClick = (e: any) => {
    localStorage.setItem('cookieConsent', 'true')
    setUserConsent(true)
  }

  const getCookieConsent = () => {
    const consent = localStorage.getItem('cookieConsent')
    return consent === 'true'
  }

  const componentProps = {
    style: style
  }

  return (
    <>
      {!userConsent &&
      <div {...componentProps}>
        <Stack
          orientation="horizontal"
          phoneOrientation="vertical"
          gap={20}
          justifyContent="space-between"
          alignItems='center'
        >
          <Text color="white">
            Используя этот сайт, Вы соглашаетесь на обработку Ваших персональных
            данных (Cookie файлы) в порядке и целях, указнных в{' '}
            <a
              href="https://www.ranepa.ru/politika-ispolzovaniya-faylov-cookie"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text color="white" style={{ textDecoration: 'underline' }}>
                Политике
              </Text>
            </a>
            .
          </Text>
          <div>
            <Button variant="filled" onClick={onClick}>
              Я согласен
            </Button>
          </div>
        </Stack>
      </div>
      }
    </>
  )
}

export default CookieConsent
