type AnimationVariant = 'appear-up' | 'appear-down' | 'appear-right' | 'appear-left' | 'zoom-out' | 'zoom-in'

const getAnimationStyle = (scrolledTo: boolean, variant?: AnimationVariant, delayIndex?: number) => {
  const _variant = variant ?? 'appear-up'
  if (_variant === 'appear-up') {
    return {
      transition: 'all 400ms cubic-bezier(0.61, 1, 0.88, 1)',
      opacity: scrolledTo ? 1 : 0,
      transform: scrolledTo ? 'translate(0, 0)' : 'translate(0, 40px)',
      transitionDelay: `${200 * (delayIndex ?? 0)}ms`
    }
  }
  if (_variant === 'appear-down') {
    return {
      transition: 'all 400ms cubic-bezier(0.61, 1, 0.88, 1)',
      opacity: scrolledTo ? 1 : 0,
      transform: scrolledTo ? 'translate(0, 0)' : 'translate(0, -40px)',
      transitionDelay: `${200 * (delayIndex ?? 0)}ms`
    }
  }
  if (_variant === 'appear-right') {
    return {
      transition: 'all 400ms cubic-bezier(0.61, 1, 0.88, 1)',
      opacity: scrolledTo ? 1 : 0,
      transform: scrolledTo ? 'translate(0, 0)' : 'translate(-90px, 0)',
      transitionDelay: `${200 * (delayIndex ?? 0)}ms`
    }
  }
  if (_variant === 'appear-left') {
    return {
      transition: 'all 400ms cubic-bezier(0.61, 1, 0.88, 1)',
      opacity: scrolledTo ? 1 : 0,
      transform: scrolledTo ? 'translate(0, 0)' : 'translate(90px, 0)',
      transitionDelay: `${200 * (delayIndex ?? 0)}ms`
    }
  }
  if (_variant === 'zoom-out') {
    return {
      transition: 'all 400ms cubic-bezier(0.61, 1, 0.88, 1)',
      opacity: scrolledTo ? 1 : 0,
      transform: scrolledTo ? 'scale(1, 1)' : 'scale(2, 2)',
      transitionDelay: `${100 * (delayIndex ?? 0)}ms`
    }
  }
  if (_variant === 'zoom-in') {
    return {
      transition: 'all 400ms cubic-bezier(0.61, 1, 0.88, 1)',
      opacity: scrolledTo ? 1 : 0,
      transform: scrolledTo ? 'scale(1, 1)' : 'scale(0.1, 0.1)',
      transitionDelay: `${100 * (delayIndex ?? 0)}ms`
    }
  }
}

export default getAnimationStyle
export type { AnimationVariant }
