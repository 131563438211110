import { DotArrowListCard, DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'

import { heading, advantageList, image1Url, image2Url } from './data'

const AdvantagesSection = () => {
  return (
    <PageSection bgColor='light-gray'>
      <SectionHeading>{heading}</SectionHeading>
      <Grid cols={2} tabletCols={1} columnGap={40}>
        <Stack gap={60} tabletGap={40} tabletReverse>
          <Stack gap={40}>
            {advantageList.slice(0, 3).map((item: DotArrowListCardProps) => {
              return <DotArrowListCard key={item.id} {...item} />
            })}
          </Stack>
          <Image src={image1Url} width='100%' />
        </Stack>
        <Stack gap={60}>
          <Image src={image2Url} width='100%' tabletHide />
          <Stack gap={40}>
            {advantageList.slice(-2).map((item: DotArrowListCardProps) => {
              return <DotArrowListCard key={item.id} {...item} />
            })}
          </Stack>
        </Stack>
      </Grid>
    </PageSection>
  )
}

export default AdvantagesSection
