import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import colors from 'common/colors'
import Color from 'common/Color.d'

import { CSSProperties } from 'react'
import nbspacer from 'common/nbspacer'

type NumberListCardVariant = 'default' | 'card'

type NumberListCardProps = {
  id?: number;
  key?: any;
  number: number;
  name?: string;
  color?: Color;
  bgColor?: Color;
  description?: string;
  secondary_description?: string;
  variant?: NumberListCardVariant
}

const NumberListCard = (props: NumberListCardProps) => {
  const color: Color = props.color ?? 'emerald'
  const variant: NumberListCardVariant = props.variant ?? 'default'

  const numberStyles: any = {
    default: {
      flexShrink: 0,
      fontWeight: 700,
      fontSize: '46px',
      lineHeight: '110%',
      color: colors[color]
    },
    card: {
      width: '48px',
      height: '48px',
      flexShrink: 0,
      fontWeight: 800,
      fontSize: '30px',
      lineHeight: '120%',
      color: colors['white'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: colors['emerald'],
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px'
    }
  }

  const numberProps: {
    style: CSSProperties
  } = {
    style: numberStyles[variant]
  }

  const infoStackStyle: CSSProperties = {
    paddingTop: '8px'
  }

  let stackGap: number = 40
  if (variant === 'card') {
    stackGap = 20
  }

  const cardStyle: CSSProperties = {
    padding: '12px 28px 32px 12px'
  }

  const CardContent = () => {
    return (
      <Stack orientation='horizontal' gap={stackGap} alignItems='start'>
        <div {...numberProps}>{props.number}</div>
        <Stack gap={8} style={infoStackStyle}>
          {props.name && <Heading variant='3'>{props.name}</Heading>}
          <Stack gap={20}>
            {props.description && <Text fontWeight={!props.name ? 'medium' : 'normal'}>
              <div dangerouslySetInnerHTML={{ __html: nbspacer(props.description ?? '') }} />
            </Text>}
            {props.secondary_description && <Text color='dark-gray'>
              <div dangerouslySetInnerHTML={{ __html: nbspacer(props.secondary_description ?? '') }} />
            </Text>}
          </Stack>
        </Stack>
      </Stack>
    )
  }

  return (
    <>
      {variant === 'default' && <><CardContent/></>}
      {variant === 'card' &&
        <Card bgColor={props.bgColor} style={cardStyle}>
          <CardContent/>
        </Card>
      }
    </>
  )
}

export default NumberListCard
export { NumberListCard }
export type { NumberListCardProps }
