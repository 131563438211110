import nbspacer from 'common/nbspacer'
import HeaderBlock from 'components/v2/HeaderBlock/HeaderBlock'
import PageSection from 'components/v2/PageSection/PageSection'
import HeaderSection from 'page-sections/v2/HeaderSection/HeaderSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'
import QuestionSection from 'page-sections/v2/QuestionSection/QuestionSection'
import React, { useState, useEffect } from 'react'
import Text from 'components/v2/Text/Text'

import { header, howItWorks, contactUs, advantages } from './data'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import TextWithDot from 'components/TextWithDot/TextWithDot'
// import useMediaQuery from 'common/useMediaQuery'
import ProgramsSection from './page-sections/ProgramsSection'
import Program from 'common/program-constructor/Program.d'
import { Link } from 'react-router-dom'

const ProgramConstructorPage = () => {
  // const { isTablet } = useMediaQuery()
  const [selectedPrograms, setSelectedPrograms] = useState([] as Program[])
  const [stickyHeader, setStickyHeader] = useState(false)

  const subheaderRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    setStickyHeader(false)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleScroll = () => {
    const stickyOffset = subheaderRef?.current?.offsetTop ?? 0
    if (window.pageYOffset > stickyOffset) {
      setStickyHeader(true)
    } else {
      setStickyHeader(false)
    }
  }

  return (
    <div>
      <HeaderSection paddingBottom={55}>
        <HeaderBlock
          heading={header.heading}
          imageUrl={header.imageUrl}
        >
          <Stack gap={40}>
            <Text
              variant='text-xl'
              color='white'
            >
              {nbspacer(header.text)}
            </Text>
            <Stack gap={20}>
              {header.items.map((item: string, index: number) => {
                return <TextWithDot key={index} dotColor='white'>
                  <Text variant='text-s-medium' color='white'>{item}</Text>
                </TextWithDot>
              })}
            </Stack>
          </Stack>
        </HeaderBlock>
      </HeaderSection>

      <div ref={subheaderRef}>
        <PageSection
          paddingBottom={17}
          paddingTop={17}
          style={{
            backgroundColor: 'white',
            borderBottom: '2px solid #00826F',
            position: stickyHeader ? 'fixed' : undefined,
            top: 0,
            zIndex: 100
          }}
        >
          <Stack
            orientation='horizontal'
            phoneOrientation='vertical'
          >
            <Stack
              gap={40}
              orientation='horizontal'
              flexGrow={1}
              alignItems={'center'}
            >
              <Stack gap={8} orientation='horizontal' alignItems={'center'}>
                <Image src='/static/img/pages/program-constructor/icons/address-icon.svg' width={30} />
                <Stack gap={2}>
                  <Text variant='xs'>г. Москва, Проспект Вернадского, 84,</Text>
                  <Text variant='xs'>корпус 3, 21 этаж, офис 2100</Text>
                </Stack>
              </Stack>
              <a href='mailto: support-cdto@ranepa.ru'>
                <Stack gap={8} orientation='horizontal' alignItems={'center'}>
                  <Image src='/static/img/pages/program-constructor/icons/email-icon.svg' width={30} />
                  support-cdto@ranepa.ru
                </Stack>
              </a>
              <a
                href='https://vk.com/cdtocenter'
                target='_blank'
                rel='noreferrer'
              >
                <img src="/static/img/pages/program-constructor/icons/vk-icon.svg" width={30} />
              </a>
              <a
                href='https://t.me/CDTOonline'
                target='_blank'
                rel='noreferrer'
              >
                <img src="/static/img/pages/program-constructor/icons/telegram-icon.svg" width={30} />
              </a>
            </Stack>
            <Link
              to='/program-constructor/shoppingcart'
              style={{
                // display: 'flex',
                // flexDirection: 'row',
                position: 'relative',
                cursor: 'pointer',
                textAlign: 'right'
              }}
            >
              <Image src='/static/img/pages/program-constructor/icons/shoppingcart-icon.svg' width={72} />
              {selectedPrograms.length > 0 && <div
                style={{
                  position: 'absolute',
                  right: '10px',
                  bottom: '0',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  backgroundColor: '#B62936',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Text variant='xs' color='white'>{selectedPrograms.length}</Text>
              </div>}
            </Link>
          </Stack>
        </PageSection>
      </div>

      <PageSection variant='gray'>
        <Stack gap={40}>
          <Heading variant='2'>{howItWorks.heading}</Heading>
          <Grid
            columnGap={40}
            rowGap={40}
            cols={2}
            phoneCols={1}
          >
            <Stack gap={30}>
              {howItWorks.items.map((item: any, index: number) => {
                return <Stack
                  key={index}
                  gap={35}
                  orientation='horizontal'
                  alignItems='center'
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '55px',
                      height: '55px',
                      borderRadius: '50%',
                      flexShrink: 0,
                      background: 'linear-gradient(to right, #047A69, #449791)'
                    }}
                  >
                    <Text
                      variant='text-m-medium'
                      color='white'
                    >
                      {index + 1}
                    </Text>
                  </div>
                  <Heading
                    variant='3'
                    animate
                    animateDelayIndex={index}
                    animationVariant='appear-left'
                  >
                    {item}
                  </Heading>
                </Stack>
              })}
              {false && <Stack
                gap={35}
                orientation='horizontal'
                alignItems='center'
              >
                <Image
                  src='/static/img/pages/program-constructor/icons/play-icon.svg'
                  width={62}
                />
                <Heading
                  variant='3'
                  color='green'
                  animate
                  animateDelayIndex={howItWorks.items.length}
                  animationVariant='appear-left'
                >
                  Включите инструкцию и все станет понятно
                </Heading>
              </Stack>}
            </Stack>
            <Image
              src={howItWorks.imageUrl}
              width='100%'
              animate
              animateDelayIndex={howItWorks.items.length + 1}
              animationVariant='zoom-out'
            />
          </Grid>
        </Stack>
      </PageSection>

      <ProgramsSection onUpdateSelectedPrograms={setSelectedPrograms} />

      <PageSection variant='gray'>
        <Grid
          cols={2}
          tabletCols={1}
          columnGap={20}
          rowGap={20}
        >
          <Stack gap={40}>
            <Heading variant='2'>Удостоверение государственного образца и сертификат</Heading>
            <Text variant='text-s-medium'>Обучая сотрудников по нашим программам вы даете им возможность получить официальный документ о повышении квалификации или переподготовке ВШГУ РАНХиГС – крупнейшего ВУЗа страны</Text>
          </Stack>
          <Image
            src='/static/img/pages/program-constructor/certificate1.png'
            width='100%'
            animate
            animationVariant='zoom-in'
          />
        </Grid>
      </PageSection>

      <PageSection variant='gradient-green'>
        <Stack gap={40}>
          <Heading variant='2' color='white'>{contactUs.heading}</Heading>
          <Grid
            cols={2}
            rows={3}
            columnGap={20}
            rowGap={20}
            tabletCols={1}
          >
            {contactUs.items.map((item: any, index: number) => {
              return <Stack key={index} gap={30} orientation='horizontal' alignItems={'center'}>
                <Image
                  src='/static/img/pages/methodological-center/usefulness/checkbox.svg'
                  width={30}
                  animate
                  animateDelayIndex={index}
                  animationVariant='zoom-out'
                />
                <Text variant='text-m-medium' color='white'>{item}</Text>
              </Stack>
            })}

          </Grid>
        </Stack>
      </PageSection>

      <PageSection variant='white-icons'>
        <Stack gap={100}>
          <Stack gap={40}>
            <Heading variant='2'>{advantages.heading}</Heading>
            <Stack gap={40}>
              {advantages.items.map((item: any, index: number) => {
                return <Stack key={index} orientation='horizontal' alignItems={'center'} gap={40}>
                  <div style={{width: '30%', flexShrink: 0}}>
                    <Heading
                      variant='3'
                      animate
                      animateDelayIndex={index}
                      animationVariant='appear-right'
                    >
                      {item.title}
                    </Heading>
                  </div>
                  <div style={{height: '50px', width: '4px', backgroundColor: '#00826F', flexShrink: 0}} />
                  <Text variant='text-m-medium'>{item.text}</Text>
                </Stack>
              })}

            </Stack>
          </Stack>
        </Stack>
      </PageSection>

      <PageSection variant='gray'>
        <Stack gap={100}>
          <Stack gap={40}>
            <Heading variant='2'>Наши партнеры</Heading>
            <Grid cols={5} phoneCols={1} tabletCols={2} columnGap={20} rowGap={50} justifyItems='center'>
              <Image height={45} src='/static/img/pages/methodological-center/clients/image1.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image2.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image3.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image4.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image5.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image6.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image7.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image8.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image9.png' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image10.svg' />
            </Grid>
          </Stack>
        </Stack>
      </PageSection>

      <QuestionSection id={'question-section'} />

      <FooterSection />
    </div>
  )
}

export default ProgramConstructorPage

