import Button from 'components/Button/Button'
import Grid from 'components/Grid/Grid'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'

import { headerTitle, headerText, headerLinkUrl, headerBannerUrl } from '../data'

const HeaderBlock = () => {
  const handleClick = () => {
    const el: any = document.getElementById('question-section')
    el.scrollIntoView({block: 'start', behavior: 'smooth'})
  }
  return (
    <Grid cols={2} tabletCols={1} columnGap={60} tabletColumnGap={40}>
      <Stack gap={40}>
        <Heading variant='1'>{headerTitle}</Heading>
        <Stack gap={20}>
          <Text>{headerText}</Text>
          <div>
            <Button onClick={handleClick} variant='filled' href={headerLinkUrl}>Заказать консультацию</Button>
          </div>
        </Stack>
      </Stack>
      <Image src={headerBannerUrl} width='100%' />
    </Grid>
  )
}

export default HeaderBlock
