import nbspacer from 'common/nbspacer'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'

type QuoteProps = {
  id?: number;
  text: string;
}

const Quote = (props: QuoteProps) => {
  return (
    <Stack gap={12} orientation='horizontal' alignItems='start'>
      <Image src='/static/img/icons/double-quotes.svg' width={40} />
      <Stack gap={20}>
        <Text variant='small'>{nbspacer(props.text)}</Text>
      </Stack>
    </Stack>
  )
}

export default Quote
export type { QuoteProps }
