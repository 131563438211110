import nbspacer from 'common/nbspacer'
import Image from 'components/Image/Image'
import Modal from 'components/Modal/Modal'
import Stack from 'components/Stack/Stack'
import Text from 'components/v2/Text/Text'
import React from 'react'

type SuccessSubmitModalProps = {
  visible: boolean;
  onClose?: () => void;
}

const SuccessSubmitModal = (props: SuccessSubmitModalProps) => {
  return (
    <Modal
      visible={props.visible}
      onClose={() => { props.onClose?.() }}
    >
      <Stack
        gap={20}
        alignItems='center'
      >
        <Image
          src='/static/img/components/success-submit-modal/check-circle.svg'
          width={36}
        />
        <Text
          variant='text-xl'
          color='blue-gray'
          align='center'
        >
          {nbspacer('Благодарим за оставленную заявку,')}
          <br/>
          {nbspacer('скоро с Вами свяжется менеджер.')}
        </Text>
      </Stack>
    </Modal>
  )
}

export default SuccessSubmitModal
