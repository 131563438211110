import Button from 'components/Button/Button'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'

const PriceSection = () => {
  return (
    <PageSection
      variant="gradient-green-one-star"
      paddingTop={40}
      paddingBottom={40}
    >
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={40}
        tabletGap={40}
        alignItems="center"
      >
        <Stack gap={40}>
          <Heading variant="1" color="white">
            Стоимость обучения 65&nbsp;000&nbsp;руб.
          </Heading>
          <div>
            <Button
              variant="filled-white"
              target="_blank"
              rel="noreferrer"
              href="https://forms.yandex.ru/u/6655ed653e9d08165e1e8108"
            >
              Регистрация на программу
            </Button>
          </div>
        </Stack>
        <Stack orientation="horizontal" justifyContent="flex-end">
          <Image
            width="400px"
            src="/static/img/pages/program-registry/page-sections/price-section/section-image.svg"
          />
        </Stack>
      </Grid>
    </PageSection>
  )
}

export default PriceSection
