import React from 'react'

import PageSection from 'components/v2/PageSection/PageSection'

import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import Button from 'components/Button/Button'

type ProgramDescriptionSectionProps = {
  items: any[];
  duration?: string;
}

const ProgramDescriptionSection = (props: ProgramDescriptionSectionProps) => {
  const [expandedIndexses, setExpandedIndexses] = React.useState<number[]>([])

  const toggleExpanded = (index: number) => {
    if (expandedIndexses.includes(index)) {
      setExpandedIndexses(expandedIndexses.filter((i) => i !== index))
    } else {
      setExpandedIndexses([...expandedIndexses, index])
    }
  }

  const getExpanded = (index: number) => {
    return expandedIndexses.includes(index)
  }
  return (
    <PageSection variant='gray'>
      <Stack gap={40}>
        <Stack gap={20}>
          <Heading variant='2'>Программа обучения</Heading>
          {props.duration && <Text variant='date-and-type-m' color='green' uppercase>{props.duration}</Text>}
        </Stack>
        <Stack gap={30}>
          {props.items.map((item: any, index: number) => {
            return <Stack key={index} style={{ borderBottom: '1px solid #B2B2B2'}}>
              <Stack
                style={{cursor: 'pointer'}}
                orientation='horizontal'
                onClick={() => toggleExpanded(index)}
              >
                <Heading variant='3' style={{flexGrow: 1}}>{item.name}</Heading>
                <Image
                  src='/static/img/pages/program-registry/digital-strategy/training-program-section/arrow.svg'
                  width={20}
                  height={20}
                  alt='arrow'
                  style={{
                    transform: getExpanded(index) ? 'rotate(180deg)' : ''
                  }}
                />
              </Stack>
              {getExpanded(index) && <ul>
                {item.items.map((item: any, index: number) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>}
            </Stack>
          })}
        </Stack>

        <Stack
          orientation='horizontal'
          tabletOrientation='vertical'
          tabletGap={60}
          gap={200}
        >
          {/* left */}
          <Stack
            width='60%'
            tabletWidth='100%'
            gap={40}
          >
            {false && <Text variant='text-xl'>Вы можете узнать ближайшую дату обучения</Text>}
            <div>
              <Button variant='filled' href='/program-registry/timetable'>Ближайшая дата обучения</Button>
            </div>
          </Stack>
          {/* right */}
          <Image
            src='/static/img/pages/program-registry/page-sections/program-description-section/section-image.svg'
            width='200px'
          />
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default ProgramDescriptionSection
