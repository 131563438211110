import React from 'react'

import QuestionSection from '../page-sections/QuestionSection/QuestionSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'

import AboutCourseSection from './AboutCourseSection'
import TargetAudienceSection from './TargetAudienceSection'

import WillKnowSection from './WillKnowSection'
import WillLearnSection from './WillLearnSection'
import CourseContentSection from 'pages/ProgramRegistry/page-sections/CourceContentSection'
import CertificateSection from '../page-sections/CertificateSection'
import PriceSection from 'pages/ProgramRegistry/page-sections/PriceSection'
import HeaderSection from './HeaderSection'
import ExpertsSection from '../page-sections/ExpertsSection'
import InfoVideoSection from '../page-sections/InfoVideoSection'
import ProgramDescriptionSection from '../page-sections/ProgramDescriptionSection'
import FaqSection from '../page-sections/FaqSection'

const DigitalStrategyPage = () => {
  return (
    <div>
      <HeaderSection />

      <AboutCourseSection />

      {false && <InfoVideoSection
        variant='gray'
        src='https://play.boomstream.com/Yofc5hNK'
      />}

      <TargetAudienceSection />

      <WillKnowSection />

      <WillLearnSection />

      <ProgramDescriptionSection
        items={[
          {
            name: '1. Стратегическое управление организацией',
            items: [
              'Узнаете, в чем феномен хорошей стратегии',
              'Познакомитесь с компонентами цифровой стратегии',
              'Изучите жизненный цикл стратегии: разработка → реализация → мониторинг → корректировка',
              'Разберетесь, как работать с разными видами цифровых стратегий'
            ]
          },
          {
            name: '2. Разработка стратегии цифрового развития',
            items: [
              'Оцените управленческую зрелость организации с помощью чек-листа',
              'Познакомитесь с системой стратегического планирования OGSM',
              'Изучите 6 шагов разработки цифровой стратегии',
              'Разберете критерии, которые помогут определить качество и перспективность стратегии',
              'Выполните SWOT-анализ для формирования стратегии и разработаете ценностное предложение для клиента'
            ]
          },
          {
            name: '3. Система управления достижением стратегических целей',
            items: [
              'Познакомитесь с моделью цифрового развития',
              'Изучите систему ключевых показателей эффективности цифрового развития',
              'Узнаете, как оценивать трансформационный эффект стратегии'
            ]
          },
          {
            name: '4. Реализация стратегии цифрового развития',
            items: [
              'Познакомитесь с портфельным управлением как инструментом реализации цифровой стратегии',
              'Изучите принципы формирования портфеля продуктов, услуг, программ, проектов',
              'Узнаете ключевые факторы эффективности стратегии',
              'Освоите этапы мониторинга реализации стратегии цифрового развития'
            ]
          },
          {
            name: 'Практика',
            items: [
              'Оцените уровень зрелости процесса цифровой трансформации в вашей организации',
              'Определите, на какой стадии цифрового развития находится ваша организация',
              'Освоите методику OKR (Objectives and Key Results)',
              'Проведете SWOT-анализ организации',
              'Реализуете ценностный подход при разработке стратегии'
            ]
          }
        ]}
      />

      <ExpertsSection
        experts={['ozharovskiy', 'boganov', 'danko', 'nikitchenko']}
        paddingBottom={0}
      />

      <CourseContentSection />

      <CertificateSection variant='gray' />

      {false && <PriceSection />}

      <FaqSection />

      <QuestionSection
        id={'question-section'}
        defaultPurposeOfRequest='program_registry_digital_strategy'
      />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default DigitalStrategyPage
