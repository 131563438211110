import HeaderSectionBase from 'page-sections/v2/HeaderSection/HeaderSection'
import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Button from 'components/Button/Button'

import scrollToId from 'common/scroll-to-id'
import nbspacer from 'common/nbspacer'
import Text from 'components/v2/Text/Text'

const HeaderSection = () => {
  return (
    <HeaderSectionBase>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={160}
        rowGap={24}
        alignItems='center'
      >
        {/* left */}
        <Stack gap={40}>
          <Stack gap={40}>
            <Stack gap={40}>
              <Heading
                variant='1'
                color='white'
              >
                {nbspacer('Команда цифрового развития организации: 44 профиля ролей')}
              </Heading>
              <Text
                variant='text-xl'
                color='white'
              >
                {nbspacer('Готовый инструмент HR для создания успешных команд')}
              </Text>
            </Stack>
          </Stack>
          <div><Button onClick={() => scrollToId('question-section')}>Оставить заявку</Button></div>
        </Stack>

        {/* right */}
        <Image
          src='/static/img/pages/role-catalog/header-section/section-image.svg'
          width='100%'
        />
      </Grid>
    </HeaderSectionBase>
  )
}

export default HeaderSection
