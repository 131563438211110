import Button from 'components/Button/Button'
import Grid from 'components/Grid/Grid'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'
import styles from './RegulationsSection.module.css'
import { NumberListCard } from 'components/NumberListCard/NumberListCard'

import { placeRequestUrl } from '../data'
import nbspacer from 'common/nbspacer'
import Color from 'common/Color.d'

type RegulationsSectionProps = {
  bgColor?: Color;
  heading?: string;
  imageUrl?: string;
  bullets?: any[];
}

const RegulationsSection = (props: RegulationsSectionProps) => {
  return (
    <PageSection>
      <Stack gap={60}>
        <SectionHeading>{nbspacer(props.heading ?? '')}</SectionHeading>
        <Grid
          cols={2}
          tabletCols={1}
          columnGap={20}
          rowGap={20}
        >
          {props.bullets?.map((item: any, index: number) => {
            return <NumberListCard number={index + 1} bgColor='light-gray' variant='card' key={item.id} {...item} />
          })}
        </Grid>
        <div className={styles.ButtonSection}>
          <Button
            variant='filled'
            href={placeRequestUrl}
            target='_blank'
            rel='noreferrer'
          >
            Оставить заявку на подбор и оценку
          </Button>
        </div>
      </Stack>
    </PageSection>
  )
}

export default RegulationsSection
