import React, { useState, useEffect, CSSProperties, ReactNode } from 'react'

type Flow = 'row' | 'column'

type GridProps = {
  id?: string;
  className?: string;
  cols?: number;
  tabletCols?: number;
  phoneCols?: number;
  rows?: number;
  tabletRows?: number;
  phoneRows?: number;
  flow?: Flow;
  tabletFlow?: Flow;
  phoneFlow?: Flow;
  tabletGap?: number;
  phoneGap?: number;
  columnGap?: number;
  tabletColumnGap?: number;
  phoneColumnGap?: number;
  rowGap?: number;
  tabletRowGap?: number;
  phoneRowGap?: number;
  children?: ReactNode;
  alignItems?: React.CSSProperties['alignItems'];
  justifyItems?: React.CSSProperties['justifyItems'];
}

const Grid = (props: GridProps) => {
  const [isTablet, setIsTablet] = useState(false)
  const [isPhone, setIsPhone] = useState(false)

  const handleResize = () => {
    if (window.innerWidth < 1023) {
      setIsTablet(true)
    } else {
      setIsTablet(false)
    }
    if (window.innerWidth < 767) {
      setIsPhone(true)
    } else {
      setIsPhone(false)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
  })

  let flow = props.flow
  let cols = props.cols
  let rows = props.rows
  let columnGap = props.columnGap
  let rowGap = props.rowGap

  if (isTablet) {
    flow = props.tabletFlow ?? flow
    cols = props.tabletCols ?? cols
    rows = props.tabletRows ?? rows
    columnGap = props.tabletColumnGap ?? columnGap
    rowGap = props.tabletRowGap ?? rowGap
  }

  if (isPhone) {
    flow = props.phoneFlow ?? flow
    cols = props.phoneCols ?? cols
    rows = props.phoneRows ?? rows
    columnGap = props.phoneColumnGap ?? columnGap
    rowGap = props.phoneRowGap ?? rowGap
  }

  const gridStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: cols ? `repeat(${cols}, minmax(min(200px,100%), 1fr))` : undefined,
    gridTemplateRows: rows ? `repeat(${rows}, min-content)` : undefined,
    gridAutoFlow: flow,
    columnGap: columnGap ? `${columnGap}px` : undefined,
    rowGap: rowGap ? `${rowGap}px` : undefined,
    alignItems: props.alignItems,
    justifyItems: props.justifyItems,
    width: '100%'
  }

  const gridProps: {
    id?: string;
    className?: string;
    style?: CSSProperties;
  } = {
    id: props.id,
    className: props.className,
    style: gridStyle
  }

  return (
    <div {...gridProps}>{props.children}</div>
  )
}

export default Grid
export type { GridProps }
