import React from 'react'
import Stack from 'components/Stack/Stack'
import SubscribeForm from 'page-sections/v2/FooterSection/SubscribeForm/SubscribeForm'
import styles from './ContactsBlock.module.css'
import { socialNetworks } from './data'
import Text from 'components/v2/Text/Text'
import Grid from 'components/Grid/Grid'
import Color from 'common/Color.d'
import Heading from 'components/v2/Heading/Heading'

type ContactsBlockProps = {
  themeWhite?: boolean;
  showSubscribeForm?: boolean;
  headingGap?: number;
}

const ContactsBlock = ({ themeWhite, showSubscribeForm, headingGap }: ContactsBlockProps) => {
  const classNameMixin: string = themeWhite ? ' color-white' : ''
  const textColor: Color = themeWhite ? 'white' : 'black'
  const _headingGap: number = headingGap ?? 60
  return (
    <Stack gap={_headingGap}>
      <Grid cols={2} columnGap={130} tabletCols={1} tabletColumnGap={20}>
        <Stack gap={60}>

          <Stack gap={16}>
            <Heading variant='3' color={textColor}>Служба поддержки</Heading>
            <a
              className={styles.Link + classNameMixin}
              href='mailto: zakharova-maa@ranepa.ru'
            >
              zakharova-maa@ranepa.ru
            </a>
          </Stack>

          <Stack gap={6}>
            <Heading variant='3' color={textColor}>PR-служба Центра</Heading>
            <a
              className={styles.Link + classNameMixin}
              href='mailto: selskaya-vv@ranepa.ru'
            >
              selskaya-vv@ranepa.ru
            </a>
            <Text variant='text-s' color={textColor}>Телеграм @VarvaraSe</Text>
            <Text variant='text-s' color={textColor}>Сельская Варвара</Text>
          </Stack>

          {showSubscribeForm && <SubscribeForm />}

          <Stack orientation='horizontal' alignItems='center' gap={35}>
            <a
              className={styles.SocialLink + classNameMixin}
              href={socialNetworks.vk_url}
              target='_blank'
              rel='noreferrer'
            >
              {!themeWhite &&
                <img src="/static/img/icons/vk--emerald.svg" /> ||
                <img src="/static/img/icons/v2/vk.svg" />
              }
            </a>
            <a
              className={styles.SocialLink + classNameMixin}
              href={socialNetworks.telegram_url}
              target='_blank'
              rel='noreferrer'
            >
              {!themeWhite &&
                <img src="/static/img/icons/telegram--emerald.svg" /> ||
                <img src="/static/img/icons/v2/telegram.svg" />
              }
            </a>
          </Stack>

        </Stack>

        <Stack gap={60}>
          <Stack gap={20}>
            <div>
              <Heading variant="3" color={textColor}>Офис Центра</Heading>
              <Text variant='text-m-medium' color={textColor}>г. Москва, пр. Вернадского, 84<br /> корп. 3, 21 этаж, офис 2100</Text>
            </div>
          </Stack>

          <Stack gap={20}>
            <Heading variant="3" color={textColor}>Как добраться?</Heading>
            <Text variant='text-s-medium' color={textColor}>Ст. метро «Юго-Западная»</Text>
            <div>
              <Text variant='text-s' color={textColor}>Последний вагон из центра, выходы 5, 6. Выходя из метро, поверните на ул. Покрышкина, далее — прямо до КПП № 5 (пр. Вернадского, 84, корпус 3), проходите через КПП № 5 РАНХиГС в 3-й корпус, 21 этаж, офис 2100.</Text>
            </div>
          </Stack>

          <img className={styles.MapImage} src="/static/img/map.jpg" />
        </Stack>
      </Grid>
    </Stack>
  )
}

export default ContactsBlock
