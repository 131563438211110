// import { useState, useEffect } from 'react'
import { useContext } from 'react'
import MenuItem from './MenuItem/MenuItem'
import styles from './MainMenu.module.css'
import { MainMenuContext } from 'App'

type MainMenuData = {
  id: number;
  url: string;
  name: string;
  level: number;
  parent: number | null;
  children: any[];
}

const MainMenu = () => {
  const menuItems = useContext(MainMenuContext)
  return (
    <div className={styles.MainMenu}>
      {menuItems.filter(
        (item: any) => item.level === 0
      ).map((item: any) => {
        return <MenuItem
          key={item.id}
          childrenItems={menuItems.filter((sItem: MainMenuData) => sItem.parent === item.id)}
          {...item}
        />
      })}
    </div>
  )
}

export default MainMenu
