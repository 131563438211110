import Color from 'common/Color.d'
import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Text from 'components/v2/Text/Text'
import React from 'react'

type ProgramCardProps = {
  name: string;
  duration: string;
  start: string | null;
  imageSrc: string;
  url: string | null;
  bgColor?: Color;
}

const ProgramCard = (props: ProgramCardProps) => {
  return (
    <Card bgColor={props.bgColor}>
      <Stack gap={12} height='100%'>
        <Stack flexGrow={1} gap={16}>
          <Image src={props.imageSrc} height={'160px'} />
          <Stack gap={12}>
            <TextWithDot>
              <Text
                variant='date-and-type-s'
              >{props.duration}</Text>
            </TextWithDot>
            <Text variant='text-m-medium'><span dangerouslySetInnerHTML={{__html: props.name}} /></Text>
          </Stack>
        </Stack>
        <Stack gap={14}>
          {props.start && <Text variant='text-m-medium' color='red'>{props.start}</Text>}
          {props.url && <div>
            <Button
              href={props.url}
              variant='filled'
            >Подробнее</Button>
          </div>}
          {!props.url && <div style={{height: '40px'}} />}
        </Stack>
      </Stack>
    </Card>
  )
}

export default ProgramCard
