import Stack from 'components/Stack/Stack'
import Text from 'components/v2/Text/Text'
import React from 'react'

type StepInfoProps = {
  step: 1 | 2;
  onSetStep?: (step: 1 | 2) => void;
}

const StepInfo = (props: StepInfoProps) => {
  return (
    <Stack
      gap={10}
      width={400}
    >
      <Stack orientation='horizontal'>
        <div style={{flexGrow: '1'}}>
          {props.step === 1 && <Text variant='text-xl'>1 шаг</Text>}
          {props.step === 2 && <Text onClick={() => props.onSetStep?.(1)} variant='xs' style={{cursor: 'pointer'}}>1 шаг</Text>}
        </div>
        <div>
          {props.step === 1 && <Text variant='xs'>2 шаг</Text>}
          {props.step === 2 && <Text variant='text-xl'>2 шаг</Text>}
        </div>
      </Stack>

      <Stack orientation='horizontal' alignItems='center'>
        {/* left circle */}
        <div>
          {props.step === 1 && <div style={{width: '21px', height: '21px', borderRadius: '50%', background: '#699D9A'}} />}
          {props.step === 2 && <div
            onClick={() => props.onSetStep?.(1)}
            style={{width: '11px', height: '11px', borderRadius: '50%', background: '#699D9A', cursor: 'pointer'}} />
          }
        </div>
        {/* line */}
        <div style={{flexGrow: '1'}}>
          {props.step === 1 && <div style={{width: '100%', height: '1px', background: '#D4D4D4'}}></div>}
          {props.step === 2 && <div style={{width: '100%', height: '1px', background: '#699D9A'}}></div>}
        </div>
        {/* right circle */}
        <div>
          {props.step === 1 && <div style={{width: '11px', height: '11px', borderRadius: '50%', background: '#D4D4D4'}} />}
          {props.step === 2 && <div style={{width: '21px', height: '21px', borderRadius: '50%', background: '#699D9A'}} />}
        </div>
      </Stack>
    </Stack>
  )
}

export default StepInfo
