import FooterSection from '../../page-sections/FooterSection/FooterSection'
import HeaderSection from '../../page-sections/HeaderSection/HeaderSection'
import AdvantagesSection from './AdvantagesSection/AdvantagesSection'
import HeaderBlock from './HeaderBlock/HeaderBlock'
import ProcedureSection from './ProcedureSection/ProcedureSection'

const Experts = () => {
  return (
    <div>
      <HeaderSection>
        <HeaderBlock />
      </HeaderSection>

      <AdvantagesSection />

      <ProcedureSection />

      <FooterSection />
    </div>
  )
}

export default Experts
