import Button from 'components/Button/Button'
import Checkbox from 'components/Checkbox/Checkbox'
import Stack from 'components/Stack/Stack'
import TextField from 'components/TextField/TextField'
import React from 'react'
import getCookie from 'common/get-coockie'
import Link from 'components/Link/Link'
import Text from 'components/v2/Text/Text'

type Step2LegalProps = {
  onSetStep?: (step: 1 | 2) => void;
  onOrderSuccess?: () => void;
}

const Step2Legal = (props: Step2LegalProps) => {
  const [allowPd, setAllowPd] = React.useState(false)
  const checkboxLabel: any = <span>
    Даю согласие на обработку&nbsp;
    <a
      href='https://cdto.ranepa.ru/media/consent_to_the_processing_of_personal_data.pdf'
      target='_blank'
      rel='noreferrer'
    >
      персональных данных
    </a>
  </span>

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      name: { value: string }
      email: { value: string }
      phone: { phone: string }
      organization: { organization: string }
    }
    const name = target.name.value
    const email = target.email.value
    const phone = target.phone.value
    const organization = target.organization.value

    const body = {
      name: name,
      email: email,
      phone: phone,
      organization: organization
    }

    const csrftoken = getCookie('csrftoken')

    const url = `/api/role-catalog/legal-orders`
    let params: any = {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, params)
    if (response.ok) {
      setAllowPd(false)
      e.target.reset()
      props.onOrderSuccess?.()
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        gap={40}
        style={{ maxWidth: '530px' }}
      >
        <TextField
          name='name'
          blockElement
          fullWidth
          required
          autoComplete='off'
          placeholder='ФИО'
        />
        <TextField
          name='phone'
          type='phone'
          blockElement
          fullWidth
          required
          autoComplete='off'
          placeholder='Телефон'
        />
        <TextField
          name='email'
          type='email'
          blockElement
          fullWidth
          required
          autoComplete='off'
          placeholder='E-mail'
        />
        <TextField
          name='organization'
          blockElement
          fullWidth
          required
          autoComplete='off'
          placeholder='Компания'
        />
        <div>
          <Checkbox
            name='allow_pd'
            onChange={() => setAllowPd(!allowPd)}
            label={checkboxLabel}
            checked={allowPd}
          />
        </div><div>
          <Link
            to='https://disk.yandex.ru/i/mRz0zVJIdkThlA'>
            <Text variant='text-s' color='emerald' style={{textDecoration: 'underline'}}>Политика РАНХиГС в отношении обработки персональных данных</Text>
          </Link>
        </div>
        <Stack orientation='horizontal' gap={10}>
          <Button
            onClick={() => props.onSetStep?.(1)}
          >
            &lt; Назад
          </Button>
          <Button
            type='submit'
            variant='filled'
            disabled={!allowPd}
          >
            Оставить заявку
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default Step2Legal
