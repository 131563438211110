import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import FooterSection from 'page-sections/FooterSection/FooterSection'
import QuestionSection from 'page-sections/QuestionSection/QuestionSection'
import HeaderBlock from 'components/HeaderBlock/HeaderBlock'
import {header} from './data'
import Stack from 'components/Stack/Stack'
import Paragraphs from 'components/Paragraphs/Paragraphs'
import Button from 'components/Button/Button'
import JointProjectsSection from './JointProjectsSection/JointProjectsSection'
import AdvantagesSection from './AdvantagesSection/AdvantagesSection'
import YourGoalsSection from './YourGoalsSection/YourGoalsSection'
import ProductsSection from './ProductsSection/ProductsSection'

const ExpertSupport = () => {
  const handleClick = () => {
    const el: any = document.getElementById('question-section')
    el.scrollIntoView({block: 'start', behavior: 'smooth'})
  }

  return (
    <div>
      <HeaderSection>
        <HeaderBlock
          heading={header.heading}
          imageUrl={header.imageUrl}
        >
          <Stack gap={20}>
            <Paragraphs>
              {header.text}
            </Paragraphs>
            <div><Button onClick={handleClick} variant='filled'>{header.buttonText}</Button></div>
          </Stack>
        </HeaderBlock>
      </HeaderSection>

      <JointProjectsSection />

      <AdvantagesSection bgColor='light-gray' />

      <YourGoalsSection />

      <ProductsSection bgColor='light-gray' />

      <QuestionSection id='question-section' heading='Оставить заявку' />

      <FooterSection id='footer-section' />
    </div>
  )
}

export default ExpertSupport
