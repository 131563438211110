import FooterSection from '../../page-sections/FooterSection/FooterSection'
import HeaderSection from '../../page-sections/HeaderSection/HeaderSection'
import HeaderBlock from './HeaderBlock/HeaderBlock'

import TeamSections, { EmployeeData } from './TeamSections/TeamSections'
import useSectionsData, { getDataByKey } from 'common/sections-data'
import { useEffect, useState } from 'react'

const Team = () => {
  const [employeeList, setEmployeeList] = useState([])

  useEffect(() => {
    fetchEmployees()
  }, [])

  const fetchEmployees = async () => {
    const response = await fetch('/api/employees')
    const data = await response.json()
    setEmployeeList(data)
  }

  const keys = 'team-header'
  const itemList = useSectionsData(keys) || []

  return (
    <div>
      <HeaderSection style={{ 'paddingBottom': '40px' }}>
        <HeaderBlock
          companyDirector={employeeList.find((employee: EmployeeData) => employee.level === 0)}
          {...getDataByKey(itemList, 'team-header')}
        />
      </HeaderSection>
      <TeamSections items={employeeList} />
      <FooterSection />
    </div>
  )
}

export default Team
