import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import NumberedListItem from 'components/v2/NumberedListItem/NumberedListItem'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'

type WhyDoYouNeedSectionProps = {
  onBuyClick?: () => void;
}

const WhyDoYouNeedSection = (props: WhyDoYouNeedSectionProps) => {
  const items: Array<string> = [
    'Каталог ролей позволит руководителю эффективнее использовать ресурсы на цифровизацию.',
    'Каталог ролей поможет проводить объективный контроль работы отдельных специалистов с пониманием решаемых ими задач.',
    'HR и кадровые службы смогут общаться с ИТ-специалистами на одном языке и профессионально проводить интервью.',
    'Каталог ролей – основа для формирования инструментов оценки и развития.',
    'С каталогом ролей составить кадровые документы не составит труда.'
  ]

  return (
    <PageSection variant='white-icons'>
      <Stack
        gap={20}
        orientation='horizontal'
        tabletOrientation='vertical'
        alignItems='center'
      >
        {/* Left */}
        <Card
          width='70%'
          tabletWidth='100%'
          bgColor='light-gray'
        >
          <Stack gap={40}>
            <Heading variant='2'>Зачем Вам каталог ролей?</Heading>
            <Stack gap={30}>
              {items.map((item: string, index: number) => {
                return (
                  <NumberedListItem
                    key={index}
                    number={index + 1}
                    description={item}
                    descriptionTextVariant='text-m-medium'
                  />
                )
              })}
              <Stack
                orientation='horizontal'
                alignItems='center'
                gap={35}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '55px',
                    height: '55px',
                    borderRadius: '50%',
                    flexShrink: 0,
                    background: '#B62936'
                  }}
                >
                  <Heading
                    variant='3'
                    color='white'
                  >
                    !
                  </Heading>
                </div>
                <Stack gap={8}>
                  <Text variant='text-m-medium'>Обратитесь к нам</Text>
                  <div>
                    <Button
                      variant='filled'
                      onClick={() => { props.onBuyClick?.() }}
                    >
                    Купить каталог
                    </Button>
                  </div>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Card>

        {/* Right */}
        <Stack
          width='30%'
          tabletWidth='100%'
        >
          <Image
            src='/static/img/pages/role-catalog/why-do-you-need-section/section-image.svg'
            width='100%'
          />
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default WhyDoYouNeedSection
