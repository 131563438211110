import nbspacer from 'common/nbspacer'
import HeaderBlock from 'components/v2/HeaderBlock/HeaderBlock'
import PageSection from 'components/v2/PageSection/PageSection'
import HeaderSection from 'page-sections/v2/HeaderSection/HeaderSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'
import { useState, useEffect } from 'react'
import Text from 'components/v2/Text/Text'

import { header } from './data'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import TextWithDot from 'components/TextWithDot/TextWithDot'
// import useMediaQuery from 'common/useMediaQuery'
import Program from 'common/program-constructor/Program.d'
import { Link } from 'react-router-dom'
import Grid from 'components/Grid/Grid'
import Heading from 'components/v2/Heading/Heading'
import Card from 'components/Card/Card'
import Checkbox from 'components/Checkbox/Checkbox'
import OrderFrom from './OrderForm'
import { Button } from 'components/Button/Button'
import Modal from 'components/Modal/Modal'

const ShoppingcartPage = () => {
  const [selectedPrograms, setSelectedPrograms] = useState([] as Program[])
  const [checkoutPrograms, setCheckoutPrograms] = useState([] as Program[])
  const [recommendedPrograms, setRecommendedPrograms] = useState([] as Program[])

  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false)
  const [showEmptyMessage, setShowEmptyMessage] = useState(false)

  const [numberOfStudents, setNumberOfStudents] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    const selectedProgramsFromStorage = JSON.parse(localStorage.getItem('selectedPrograms') ?? '[]')
    const checkoutProgramsFromStorage = JSON.parse(localStorage.getItem('checkoutPrograms') ?? '[]')
    const filteredCheckoutPrograms = checkoutProgramsFromStorage.filter((program: Program) => {
      return !!selectedProgramsFromStorage.find((selectedProgram: Program) => selectedProgram.id === program.id)
    })
    setSelectedPrograms(selectedProgramsFromStorage)
    setCheckoutPrograms(filteredCheckoutPrograms)
    localStorage.setItem('checkoutPrograms', JSON.stringify(filteredCheckoutPrograms))
    if (selectedProgramsFromStorage.length === 0) {
      setShowEmptyMessage(true)
    }
    if (selectedProgramsFromStorage.length === filteredCheckoutPrograms.length) {
      setSelectAllCheckbox(true)
    }

    fetchRecommendedPrograms()
  }, [])

  const onNumberOfStudentsInput = (event: any) => {
    // console.log(event.target.value)
    setNumberOfStudents(event.target.value ?? 0)
  }

  const fetchRecommendedPrograms = async () => {
    const response = await fetch('/api/program-constructor/programs/')
    const data = await response.json()
    setRecommendedPrograms(data)
  }

  const handeSelectAllCheckbox = () => {
    setSelectAllCheckbox(!selectAllCheckbox)
    if (!selectAllCheckbox) {
      setCheckoutPrograms(selectedPrograms)
      localStorage.setItem('checkoutPrograms', JSON.stringify(selectedPrograms))
    } else {
      setCheckoutPrograms([])
      localStorage.setItem('checkoutPrograms', JSON.stringify([]))
    }
  }

  const isProgramInCheckout = (program: Program) => {
    return checkoutPrograms.some((p: Program) => p.id === program.id)
  }

  const handeToggleCheckoutProgram = (program: Program) => {
    let newCheckoutPrograms = [] as Program[]
    if (isProgramInCheckout(program)) {
      newCheckoutPrograms = checkoutPrograms.filter((p: Program) => p.id !== program.id)
    } else {
      newCheckoutPrograms = [...checkoutPrograms, program]
    }
    setCheckoutPrograms(newCheckoutPrograms)
    localStorage.setItem('checkoutPrograms', JSON.stringify(newCheckoutPrograms))
    if (newCheckoutPrograms.length === 0) {
      setSelectAllCheckbox(false)
    }
    if (newCheckoutPrograms.length === selectedPrograms.length) {
      setSelectAllCheckbox(true)
    }
  }

  const getCost = () => {
    return checkoutPrograms.reduce((acc: number, program: Program) => {
      return acc + program.cost
    }, 0)
  }

  const getTotalCost = () => {
    return checkoutPrograms.reduce((acc: number, program: Program) => {
      return acc + program.cost
    }, 0) * numberOfStudents
  }

  const onFormSubmit = () => {
    setCheckoutPrograms([])
    setSelectedPrograms([])
    localStorage.setItem('selectedPrograms', JSON.stringify([]))
    localStorage.setItem('checkoutPrograms', JSON.stringify([]))
    setShowEmptyMessage(true)
  }

  const getProgramLabel = (count: number) => {
    if (count === 1) {
      return 'программа'
    } else if (count > 1 && count < 5) {
      return 'программы'
    } else {
      return 'программ'
    }
  }

  const butifyNumber = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  const addProgram = (program: Program) => {
    const newSelectedPrograms = [...selectedPrograms, program]
    setSelectedPrograms(newSelectedPrograms)
    localStorage.setItem('selectedPrograms', JSON.stringify(newSelectedPrograms))
  }

  const getFilteredRecommendedPrograms = () => {
    return recommendedPrograms.filter((program: Program) => {
      return !selectedPrograms.some((selectedProgram: Program) => selectedProgram.id === program.id)
    })
  }

  return (
    <div>
      <Modal
        visible={isModalVisible} onClose={() => setIsModalVisible(false)}
      >
        <Stack gap={20}>
          <Heading variant='3'>Заявка отправлена</Heading>
          <Stack gap={20}>
            <Text variant='text-s'>В ближайшее время с вами свяжется наш менеджер</Text>
            <div><Button variant='filled' onClick={() => setIsModalVisible(false)}>Ок</Button></div>
          </Stack>
        </Stack>
      </Modal>
      <HeaderSection paddingBottom={55}>
        {false && <HeaderBlock
          heading={header.heading}
          imageUrl={header.imageUrl}
        >
          <Stack gap={40}>
            <Text
              variant='text-xl'
              color='white'
            >
              {nbspacer(header.text)}
            </Text>
            <Stack gap={20}>
              {header.items.map((item: string, index: number) => {
                return <TextWithDot key={index} dotColor='white'>
                  <Text variant='text-s-medium' color='white'>{item}</Text>
                </TextWithDot>
              })}
            </Stack>
          </Stack>
        </HeaderBlock>}
      </HeaderSection>

      <PageSection
        paddingBottom={17}
        paddingTop={17}
      >
        <Grid
          cols={3}
          alignItems={'center'}
        >
          <Link to={'/program-constructor/'} style={{textDecoration: 'none'}}>
            <Stack gap={10} orientation='horizontal' alignItems={'center'}>
              <Image src='/static/img/pages/program-constructor/icons/back-icon.svg' width={30} />
              <Text variant='text-m-medium' style={{textDecoration: 'none'}}>Вернуться в конструктор</Text>
            </Stack>
          </Link>
          <Image src='/static/img/pages/program-constructor/cdto-logo.svg' width={170} />
          <Stack
            gap={40}
            orientation='horizontal'
            flexGrow={1}
            alignItems={'center'}
            style={{justifyContent: 'flex-end'}}
          >

            <a href='mailto: support-cdto@ranepa.ru'>
              <Stack gap={8} orientation='horizontal' alignItems={'center'}>
                <Image src='/static/img/pages/program-constructor/icons/email-icon.svg' width={30} />
                support-cdto@ranepa.ru
              </Stack>
            </a>
            <a
              href='https://vk.com/cdtocenter'
              target='_blank'
              rel='noreferrer'
            >
              <img src="/static/img/pages/program-constructor/icons/vk-icon.svg" width={30} />
            </a>
            <a
              href='https://t.me/CDTOonline'
              target='_blank'
              rel='noreferrer'
            >
              <img src="/static/img/pages/program-constructor/icons/telegram-icon.svg" width={30} />
            </a>
          </Stack>
        </Grid>
      </PageSection>
      <div style={{width: '100%', height: '2px', backgroundColor: '#00826F'}}></div>

      <PageSection variant='gray'>
        <Stack gap={40}>
          <Heading variant='1'>Корзина</Heading>
          {selectedPrograms.length > 0 && <Stack gap={20} orientation='horizontal' tabletOrientation='vertical'>
            {/* left */}
            <Stack flexGrow={1} gap={60}>
              <Stack gap={40}>
                <Card
                  style={{padding: '17px 30px'}}
                >
                  <Checkbox label='Выбрать все' checked={selectAllCheckbox} onChange={handeSelectAllCheckbox} />
                </Card>
                {/* programs */}
                <Stack gap={20}>
                  <Text variant='text-xl'>Программы</Text>
                  <Card
                    style={{padding: '17px 30px'}}
                  >
                    <Stack gap={10}>
                      {selectedPrograms.map((program: Program, index: number) => {
                        return <Stack
                          orientation='horizontal'
                          key={index}
                        >
                          <div style={{flexGrow: 1}}>
                            <Checkbox
                              label={program.name}
                              checked={isProgramInCheckout(program)}
                              onChange={() => handeToggleCheckoutProgram(program)}
                            />
                          </div>
                          <Text variant='text-m-medium'>{butifyNumber(program.cost)} ₽</Text>
                        </Stack>
                      })}
                    </Stack>
                  </Card>
                </Stack>
                <div style={{width: '100%', height: '1px', backgroundColor: '#B2B2B2'}} />
              </Stack>

              {getFilteredRecommendedPrograms().length > 0 && <Stack gap={40}>
                <Text variant='text-xl' color='green'>Рекомендуем также</Text>
                <Grid cols={2} columnGap={30}>
                  {getFilteredRecommendedPrograms().slice(0, 2).map((program: Program, index: number) => {
                    return <Card key={index}>
                      <Stack gap={30} height='100%'>
                        <Stack gap={16} style={{flexGrow: 1}}>
                          <Image src={program.image_url ?? ''} />
                          <Stack gap={12}>
                            <Stack
                              gap={20}
                              orientation='horizontal'
                            >
                              <TextWithDot dotColor='dark-gray'>
                                <Text variant='date-and-type-s' color='dark-gray'>{program.academic_duration}</Text>
                              </TextWithDot>
                              <TextWithDot dotColor='dark-gray'>
                                <Text variant='date-and-type-s' color='dark-gray'>{butifyNumber(program.cost)} ₽</Text>
                              </TextWithDot>
                            </Stack>
                            <Text variant='text-m-medium'>{program.name}</Text>
                          </Stack>
                        </Stack>
                        <div><Button variant='filled' onClick={() => addProgram(program)}>Добавить</Button></div>
                      </Stack>
                    </Card>
                  })}
                </Grid>
              </Stack>}
            </Stack>
            {/* right */}
            <div style={{width: '30%'}}>
              <Card
                // width={'100%'}
                style={{padding: '20px 24px'}}
              >
                <Stack gap={20}>
                  <Heading variant='3'>Детали заказа</Heading>
                  <Stack orientation='horizontal'>
                    <Text variant='xs'><>{checkoutPrograms.length} {getProgramLabel(checkoutPrograms.length)}</></Text>
                    <div style={{flexGrow: '1', borderBottom: '1px dashed #D4D4D4'}} />
                    <Text variant='xs'>{butifyNumber(getCost())} ₽</Text>
                  </Stack>
                  <Stack orientation='horizontal'>
                    <Text variant='text-m-medium'>Итого</Text>
                    <div style={{flexGrow: '1', borderBottom: '1px dashed #D4D4D4'}} />
                    <Text variant='text-m-medium'>{butifyNumber(getTotalCost())} ₽</Text>
                  </Stack>
                  <OrderFrom
                    onNumberInput={onNumberOfStudentsInput}
                    onSubmit={onFormSubmit}
                    disabled={checkoutPrograms.length === 0}
                    checkoutPrograms={checkoutPrograms}
                    onSuccessResponse={() => { setIsModalVisible(true) }}
                  />
                </Stack>
              </Card>
            </div>
          </Stack>}
          {showEmptyMessage && <Text variant='text-m-medium'>Корзина пуста</Text>}
        </Stack>
      </PageSection>

      <FooterSection />
    </div>
  )
}

export default ShoppingcartPage
