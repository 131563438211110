import MaterialTypeData from 'common/MaterialTypeData.d'
import Card from 'components/Card/Card'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import React from 'react'
import Link from 'components/Link/Link'

type MaterialTypeCardProps = {
  url?: string;
} & MaterialTypeData

const MaterialTypeCard = (props: MaterialTypeCardProps) => {
  const cardStyle: React.CSSProperties = {
    paddingTop: '0'
  }

  const getDecorationSrc = (decoration?: string) => {
    return `/static/img/sum-of-tech/card-decorations/material-types/${decoration ?? 'squares'}.svg`
  }

  return (
    <Card style={cardStyle}>
      <Stack gap={4} alignItems='center'>
        <Image src={getDecorationSrc(props.decoration)} />
        <Text fontWeight='medium'>{props.namePlural ?? props.name ?? ''}</Text>
        <Link to={props.url ?? ''}>
          <Text
            fontWeight='medium'
            variant='small'
            color='emerald'
          >
              Читать
          </Text>
        </Link>
      </Stack>
    </Card>
  )
}

export default MaterialTypeCard
