import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Button from 'components/Button/Button'

import Text from 'components/Text/Text'
import { NumberListCard, NumberListCardProps } from 'components/NumberListCard/NumberListCard'
import Grid from 'components/Grid/Grid'
import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import scrollToId from 'common/scroll-to-id'

type CommercialProjectsProps = {
  heading?: string;
  text?: string;
  bgColor?: Color;
  bullets?: NumberListCardProps[];
}

const CommercialProjectsSection = (props: CommercialProjectsProps) => {
  return (
    <PageSection id='commercial-projects-section' bgColor={props.bgColor}>
      <SectionHeading animate animationVariant='appear-up' showArrow={true}>{nbspacer(props.heading ?? '')}</SectionHeading>

      <Text maxWidth854>
        <div dangerouslySetInnerHTML={{__html: nbspacer(props.text ?? '')}} />
      </Text>

      <Grid
        cols={2}
        tabletCols={1}
        rowGap={60}
        tabletRowGap={40}
        columnGap={40}
        tabletColumnGap={20}
      >
        {props.bullets?.map((item: any, index: number) => {
          return <NumberListCard key={item.id} number={index + 1} {...item} />
        })}
      </Grid>

      <div><Button variant='filled' onClick={() => scrollToId('question-section')}>Получить консультацию</Button></div>
    </PageSection>
  )
}

export default CommercialProjectsSection
