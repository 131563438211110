import HeaderSectionBase from 'page-sections/v2/HeaderSection/HeaderSection'
import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import Button from 'components/Button/Button'

import scrollToId from 'common/scroll-to-id'
import nbspacer from 'common/nbspacer'

const HeaderSection = () => {
  return (
    <HeaderSectionBase paddingBottom={0}>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={160}
        rowGap={24}
      >
        {/* left */}
        <Stack gap={40}>
          <Stack gap={40}>
            <Stack>
              <Heading
                variant='1'
                color='white'
              >
                {nbspacer('Рекрутинг специалистов цифровой трансформации')}
              </Heading>
            </Stack>
            <Text
              variant='text-xl'
              color='white'
            >
              {nbspacer('Поиск профессионалов для решения цифровых задач — руководителей и специалистов разных уровней')}
            </Text>
          </Stack>
          <div><Button onClick={() => scrollToId('question-section')}>Оставить заявку</Button></div>
        </Stack>

        {/* right */}
        <Image
          src='/static/img/pages/recruiting/digital-transformation-specialists/header-section/section-image.png'
          width='100%'
        />
      </Grid>
    </HeaderSectionBase>
  )
}

export default HeaderSection
