import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'

const DemoInfoSection = () => {
  return (
    <PageSection variant='white-icons'>
      <Card bgColor='light-gray'>
        <Grid
          cols={2}
          tabletCols={1}
          columnGap={40}
          rowGap={40}
        >
          {/* left */}
          <Stack gap={40}>
            <Heading variant='1'>Команда цифрового развития организации: 44 профиля ролей доступен в электронном виде</Heading>
            <Heading variant='3' color='light-emerald'>Предлагаем ознакомиться с его демо-версией</Heading>
            <div><Button
              target='_blank'
              href='/media/role_catalog/demo_catalog.pdf'
              variant='filled'
            >Получить демо версию</Button></div>
          </Stack>
          {/* right */}
          <Image src='/static/img/pages/role-catalog/demo-info-section/section-image.svg' />
        </Grid>
      </Card>
    </PageSection>
  )
}

export default DemoInfoSection
