import { useEffect, useState } from 'react'

const useScrolledTo = (ref: any) => {
  const [visible, setVisible] = useState(false)

  const handleScroll = () => {
    if (!ref) {
      return
    }
    const offsetTop = (el: any) => {
      const rect = el.getBoundingClientRect()
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return rect.top + scrollTop
    }

    const elHeight = ref.current.offsetHeight ?? 0
    const elOffsetTop: any = offsetTop(ref.current)

    const winHeight = window.innerHeight

    const position = winHeight + scrollY

    let triggerPosition = elOffsetTop + elHeight / 4

    if (position > triggerPosition) {
      if (visible === false) {
        // if (ref.current.id) {
        //   console.log(ref.current.id, position, elOffsetTop, elHeight, triggerPosition)
        // }
        setVisible(true)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return visible
}

export default useScrolledTo
