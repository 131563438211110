import nbspacer from 'common/nbspacer'
import { getFormattedDate } from 'common/utils'
import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Image from 'components/Image/Image'
import React from 'react'
import Link from 'components/Link/Link'
// import { Link } from 'react-router-dom'
import useMediaQuery from 'common/useMediaQuery'

type MaterialCardProps = {
  style: React.CSSProperties;
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  date: string;
  materialTypeName: string;
  contentType: string;
  attachedPdfUrl: string;
  decoration: string;
  showImage: boolean;
  url?: string;
  followTheUrl?: boolean;
}

const getDecrorationStyle = (decoration: string, showImage: boolean) => {
  if (showImage || !decoration) {
    return {}
  }
  return {
    backgroundImage: `url("/static/img/sum-of-tech/card-decorations/${decoration}.svg")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom 50% right',
    backgroundSize: 'auto 100%'
  }
}

const MaterialCard = (props: MaterialCardProps) => {
  const { isTablet } = useMediaQuery()

  const linkStyle: React.CSSProperties = {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    height: '100%'
  }

  const cardStyle: React.CSSProperties = {
    ...props.style,
    ...getDecrorationStyle(props.decoration, props.showImage),

    height: '100%',
    padding: '0'
  }

  const stackStyle: React.CSSProperties = {
    padding: '24px'
  }

  const imageStyle: React.CSSProperties = {
    borderTopRightRadius: !isTablet ? '20px' : '0px',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: isTablet ? '20px' : '0px',
    width: isTablet ? '100%' : '50%'
  }

  const getUrl = () => {
    if (props.followTheUrl && props.url) {
      return props.url
    }
    return `/sum-of-tech/materials/${props.id}`
  }

  const linkProps: any = {
    to: getUrl(),
    style: linkStyle
  }

  return (
    <Link {...linkProps}>
      <Card style={cardStyle} clickable>
        <Stack orientation='horizontal' tabletOrientation='vertical' gap={20} height='100%'>
          <Stack gap={20} style={stackStyle} width={props.showImage ? '50%' : undefined}>
            <Stack orientation='horizontal' gap={16}>
              <Text
                uppercase
                variant='tiny'
                fontWeight='medium'
                color='dark-gray'
              >
                {getFormattedDate(props.date)}
              </Text>
              {props.materialTypeName && <TextWithDot gap={16} dotVariant='rounded'>
                <Text
                  uppercase
                  variant='tiny'
                  fontWeight='medium'
                >
                  {props.materialTypeName}
                </Text>
              </TextWithDot>}
            </Stack>
            <Stack gap={16}>
              <Heading variant='3' maxWidth={'400px'}>{nbspacer(props.name ?? '')}</Heading>
              {props.description && <Text variant='small' maxWidth={'400px'}>{nbspacer(props.description ?? '')}</Text>}
            </Stack>
          </Stack>
          {props.showImage && <Image style={imageStyle} src={props.imageUrl} objectFit='cover' />}
        </Stack>
      </Card>
    </Link>
  )
}

export default MaterialCard
