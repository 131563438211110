import Modal from 'components/Modal/Modal'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'
import IndividualForm from './components/IndividualForm'
import LegalForm from './components/LegalForm'
import SubjectTypeSelect from './components/SubjectTypeSelect'

type SubjectType = '' | 'individual' | 'legal'

type QuestionSectionProps = {
  id?: string;
  defaultPurposeOfRequest?: string;
}

const QuestionSection = (props: QuestionSectionProps) => {
  const [subjectType, setSubjectType] = React.useState<SubjectType>('')
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const onFormSubmit = () => {
    setIsModalVisible(true)
    setSubjectType('')
  }

  return (
    <PageSection variant='white-icons' id={props.id}>
      <Modal
        visible={isModalVisible} onClose={() => setIsModalVisible(false)}
      >
      В ближайшее время с Вами свяжется наш<br/> специалист.<br/>
        <a href="mailto: support-cdto@ranepa.ru">support-cdto@ranepa.ru</a><br/>
        <br/>
      С уважением,<br/>
      Центр подготовки руководителей и команд<br/>
      цифровой трансформации ВШГУ РАНХиГС
      </Modal>

      {subjectType === '' && (
        <SubjectTypeSelect onSelect={(subjectType: SubjectType) => setSubjectType(subjectType)} />
      )}

      {subjectType === 'individual' && (
        <IndividualForm
          purpose={props.defaultPurposeOfRequest}
          onSubmit={onFormSubmit}
        />
      )}

      {subjectType === 'legal' && (
        <LegalForm
          purpose={props.defaultPurposeOfRequest}
          onSubmit={onFormSubmit}
        />
      )}
    </PageSection>
  )
}

export default QuestionSection
