import Card from 'components/Card/Card'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import IconView from 'components/v2/IconView/IconView'
import Text from 'components/v2/Text/Text'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'
import Grid from 'components/Grid/Grid'
import Button from 'components/Button/Button'

type SpecialistCatalogSectionProps = {
  onBuyClick?: () => void;
}

const SpecialistCatalogSection = (props: SpecialistCatalogSectionProps) => {
  const items = [
    {
      imageUrl: '/static/img/pages/recruiting/digital-transformation-specialists/specialist-catalog-section/image1.svg',
      name: 'Управление цифровой трансформацией',
      items: [
        'Руководитель по работе с данными',
        'Руководитель цифровой трансформации',
        'Руководитель по цифровому проектированию и процессам',
        'Главный IT-архитектор',
        'Руководитель по информационной безопасности',
        'Руководитель по информационным технологиям',
        'Руководитель разработки ИИ-решений'
      ]
    },
    {
      imageUrl: '/static/img/pages/recruiting/digital-transformation-specialists/specialist-catalog-section/image2.svg',
      name: 'Управление данными',
      items: [
        'Архитектор данных',
        'Аналитик данных',
        'Исследователь данных',
        'Распорядитель данных',
        'Инженер данных'
      ]
    },
    {
      imageUrl: '/static/img/pages/recruiting/digital-transformation-specialists/specialist-catalog-section/image3.svg',
      name: 'Управление проектами и процессами',
      items: [
        'Руководитель проекта',
        'Администратор проекта',
        'Бизнес-аналитик',
        'Специалист по регламентации процессов',
        'Координатор процессов',
        'Процессный аналитик'
      ]
    },
    {
      imageUrl: '/static/img/pages/recruiting/digital-transformation-specialists/specialist-catalog-section/image4.svg',
      name: 'Управление продуктами',
      items: [
        'Владелец продуктов',
        'Эксперт по клиентскому опыту',
        'Scrum-мастер',
        'Дизайнер интерфейсов',
        'Фулстек-разработчик',
        'Фронтенд-разработчик',
        'Бэкенд-разработчик',
        'Тестировщик'
      ]
    },
    {
      imageUrl: '/static/img/pages/recruiting/digital-transformation-specialists/specialist-catalog-section/image5.svg',
      name: 'IT архитектура',
      items: [
        'Системный архитектор ',
        'Системный аналитик',
        'Системный администратор',
        'Специалист по информационной безопасности',
        'Бизнес-архитектор',
        'Инженер DevOps',
        'Технический писатель',
        'Сетевой инженер'
      ]
    }
  ]

  const [activeItem, setActiveItem] = React.useState(items[0])
  return (
    <PageSection variant='gray' paddingBottom={0}>
      <Stack gap={40}>
        <Heading variant='2'>
          44 профиля основных специалистов удобно распределены
          <br />
          <span style={{color: '#00826F'}}>по направлениям деятельности.</span>
        </Heading>
        <Stack gap={20}
          orientation='horizontal'
          tabletOrientation='vertical'
        >
          {/* left */}
          <Stack
            width='40%'
            tabletWidth='100%'
          >
            <IconView
              cols={2}
              columnGap={20}
              rowGap={20}
            >
              {items.map((item, index: number) => {
                return (
                  <Card
                    clickable
                    onClick={() => setActiveItem(item)}
                    key={index}
                    backgroundGradient={item.name === activeItem.name ? 'green' : undefined}
                    style={{userSelect: 'none'}}
                  >
                    <Stack
                      gap={8}
                      alignItems='center'
                    >
                      <Image
                        width={40}
                        src={item.imageUrl}
                      />
                      <Text
                        variant='date-and-type-s'
                        uppercase
                        align='center'
                        color={item.name === activeItem.name ? 'white' : 'black'}
                      >
                        {item.name}
                      </Text>
                    </Stack>
                  </Card>
                )
              })}
            </IconView>
          </Stack>
          {/* right */}
          <Stack
            width='60%'
            tabletWidth='100%'
          >
            <Card>
              <Stack gap={40}>
                <Grid
                  cols={2}
                  columnGap={20}
                  rowGap={20}
                >
                  {activeItem.items.map((item, index: number) => {
                    return <Stack key={index}>
                      <Text variant='text-s-medium'>{item}</Text>
                    </Stack>
                  })}
                </Grid>
                <div>
                  <Button
                    variant='filled'
                    onClick={() => { props.onBuyClick?.() }}
                  >
                    Купить каталог
                  </Button>
                </div>
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default SpecialistCatalogSection
