import React from 'react'
import styles from './Textarea.module.css'

type TextareaProps = {
  id?: string;
  children?: React.ReactNode;
  name?: string;
  style?: React.CSSProperties;
  rows?: number;
  cols?: number;
  placeholder?: string;
}

const Textarea = (props: TextareaProps) => {
  const componentStyle: React.CSSProperties = {
  }

  const componentProps = {
    className: styles.textarea,
    style: {...componentStyle, ...props.style},
    id: props.id,
    name: props.name,
    rows: props.rows,
    cols: props.cols,
    placeholder: props.placeholder
  }

  return (
    <textarea {...componentProps}></textarea>
  )
}

export default Textarea
