import { DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'

const heading1: string = 'С нами вы сможете'

const itemList1: DotArrowListCardProps[] = [
  {
    id: 1,
    description: 'Сформировать стратегическое видение и направление развития.'
  },
  {
    id: 2,
    description: 'Донести до ваших сотрудников необходимость и цели изменений.'
  },
  {
    id: 3,
    description: 'Вовлечь вашу команду или отдельных ее членов в реализацию изменений, начиная с этапа их планирования и заканчивая внедрением.'
  },
  {
    id: 4,
    description: 'Сформировать активные группы поддержки, которые будут проводниками изменений в коллективе.'
  },
  {
    id: 5,
    description: 'Повысить свою результативность в профессиональной и персональной деятельности.'
  },
  {
    id: 6,
    description: 'Разобраться, как реализовать свои карьерные цели.'
  }
]

const heading2: string = 'Что мы можем провести для вас?'
const itemList2: any = [
  'Стратегическую сессию',
  'Карьерную консультацию',
  'Тренинг',
  'Коучинг',
  'Вебинар'
]

export {heading1, itemList1, heading2, itemList2}
