import Card from 'components/Card/Card'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import React from 'react'

import MaterialData from 'common/MaterialData.d'
import { getFormattedDate } from 'common/utils'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Heading from 'components/Heading/Heading'
import nbspacer from 'common/nbspacer'
import Button from 'components/Button/Button'
import { Link } from 'react-router-dom'

type GradientColor = 'blue' | 'green' | 'gray' | 'blueGray'

type ColorMaterialCardProps = {
  size: 'small' | 'large' | 'wide';
  gradientColor: GradientColor;
  showLink?: boolean;
} & MaterialData

const getLinearGradient = (gradientColor: GradientColor) => {
  if (gradientColor === 'blue') {
    return 'linear-gradient(to right, #4E6582, #027686)'
  }
  if (gradientColor === 'green') {
    return 'linear-gradient(to right, #047A69, #449791)'
  }
  if (gradientColor === 'blueGray') {
    return 'linear-gradient(to right, #3F5878, #5C718B)'
  }
  if (gradientColor === 'gray') {
    return 'linear-gradient(to right, #2E2F30, #6C7478)'
  }
}

const getDecrorationStyle = (decoration: string, size: string) => {
  if (!decoration) {
    return {}
  }

  const positions: any = {
    small: 'bottom -70% right',
    large: 'bottom -30% right',
    wide: 'bottom 80% right'
  }
  const sizes: any = {
    small: '40% auto',
    large: '70% auto',
    wide: '30% auto'
  }
  return {
    backgroundImage: ` url("/static/img/sum-of-tech/card-decorations/${decoration}.svg")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: positions[size],
    backgroundSize: sizes[size]
  }
}

const ColorMaterialCard = (props: ColorMaterialCardProps) => {
  const cardHeight: any = {
    small: '300px',
    large: '620px',
    wide: '300px'
  }

  const cardStyle: React.CSSProperties = {
    height: cardHeight[props.size],
    padding: '0',
    background: getLinearGradient(props.gradientColor),
    textDecoration: 'none'
  }

  const height: any = {
    small: '236px',
    large: '556px',
    wide: '236px'
  }

  const containerStyle: React.CSSProperties = {
    height: height[props.size],
    padding: '32px 24px',
    ...getDecrorationStyle(props.decoration, props.size)
  }

  const showLink = props.showLink ?? true

  const getUrl = () => {
    if (props.followTheUrl && props.url) {
      return props.url
    }
    return `/sum-of-tech/materials/${props.id}`
  }

  return (
    <Card style={cardStyle}>
      <Stack style={containerStyle}>
        <Stack gap={props.size === 'large' ? 40 : 16} flexGrow={1} >
          <Stack orientation='horizontal' gap={16}>
            <Text
              uppercase
              variant='small'
              fontWeight='medium'
              color='white'
            >
              {getFormattedDate(props.date)}
            </Text>
            {props.materialTypeName && <TextWithDot gap={16} dotVariant='rounded' dotColor='white'>
              <Link to={`/sum-of-tech/materials-by-type/${props.materialTypeId}`} style={{textDecoration: 'none'}}>
                <Text
                  uppercase
                  variant='small'
                  fontWeight='medium'
                  color='white'
                >
                  {props.materialTypeName}
                </Text>
              </Link>
            </TextWithDot>}
          </Stack>
          <Stack gap={16}>
            <Heading variant='2' color='white'>{nbspacer(props.name ?? '')}</Heading>
            {props.size !== 'small' && props.description &&
              <Text variant='small' color='white'>{nbspacer(props.description ?? '')}</Text>
            }
          </Stack>
        </Stack>
        {showLink &&
          <div>
            <Button href={getUrl()} variant='filled-and-outlined'>Читать</Button>
          </div>
        }
      </Stack>
    </Card>
  )
}

export default ColorMaterialCard
