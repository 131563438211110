import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import React, { useEffect } from 'react'

type FileInputProps = {
  onChange?: (file: string | null) => void;
  file?: File | null;
  setFile?: (file: File | null) => void;
}

const FileInput = (props: FileInputProps) => {
  const [error, setError] = React.useState<string | null>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!props.file) {
      inputRef.current?.value && (inputRef.current.value = '')
    }
  }, [props.file])

  const inputStyle: React.CSSProperties = {
    display: 'none'
  }
  const buttonStyle: React.CSSProperties = {
    padding: 0,
    border: 0,
    margin: 0,
    background: 'none',
    cursor: 'pointer'
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      props.setFile?.(event.target.files[0])

      const file = event.target.files[0]
      if (file?.size && (file?.size > 2097152)) {
        setError('Размер файла не должен превышать 2 мб.')
        props.setFile?.(null)
        if (inputRef.current) {
          inputRef.current.value = ''
        }
        return
      }

      props.setFile?.(file)
    }
  }

  const handleAddButtonClick = () => {
    props.setFile?.(null)
    setError(null)
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.click()
    }
  }

  const handleDeleteButtonClick = () => {
    props.setFile?.(null)
    setError(null)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const getFileIcon = () => {
    if (props.file?.type === 'application/pdf') {
      return '/static/img/components/file-input/pdf.svg'
    }
    if (props.file?.type === 'application/vnd.ms-word') {
      return '/static/img/components/file-input/doc.svg'
    }
    if (props.file?.type === 'application/msword') {
      return '/static/img/components/file-input/doc.svg'
    }
    if (props.file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return '/static/img/components/file-input/doc.svg'
    }
    if (props.file?.type === 'image/jpeg') {
      return '/static/img/components/file-input/jpg.svg'
    }
    if (props.file?.type === 'image/png') {
      return '/static/img/components/file-input/png.svg'
    }
    return ''
  }

  return (
    <div>
      <input
        onChange={handleInputChange}
        style={inputStyle}
        ref={inputRef}
        name="attachment"
        type="file"
        accept="application/pdf,application/vnd.ms-word,.doc,.docx,.jpg,.jpeg,.png"
      />

      {error && (
        <Stack
          orientation='horizontal'
          alignItems='center'
          gap={10}
        >
          <Image
            width={20}
            src='/static/img/components/file-input/error.svg'
          />
          <Text
            variant='text-m-medium'
            color='burgundy'
          >
            {error}
          </Text>
        </Stack>
      )}

      {!props.file && (
        <Stack
          orientation='horizontal'
          gap={20}
        >
          <button
            style={buttonStyle}
            type="button"
            onClick={handleAddButtonClick}
          >
            <Image
              width={30}
              src='/static/img/components/file-input/add.svg'
            />
          </button>

          <Stack>
            <Heading
              variant='2'
              color='blue-gray'
            >
            Прикрепить резюме
            </Heading>
            <Text variant='text-m-medium'>(PDF, DOC, DOCX, PNG, JPG, не более 2 мб.)</Text>
          </Stack>
        </Stack>
      )}

      {props.file && (
        <Stack
          orientation='horizontal'
          alignItems='center'
          gap={60}
        >
          <Stack
            orientation='horizontal'
            gap={10}
          >
            <Image
              width={40}
              src={ getFileIcon() }
            />
            <Text
              variant='text-m-medium'
              color='blue-gray'
            >
              {props.file.name}
            </Text>
          </Stack>

          <button
            style={buttonStyle}
            type="button"
            onClick={handleDeleteButtonClick}
          >
            <Image
              width={24}
              src='/static/img/components/file-input/remove.svg'
            />
          </button>
        </Stack>
      )}
    </div>
  )
}

export default FileInput
