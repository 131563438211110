import { SubscribeCardProps } from './SubscribeCard/SubscribeCard'

const heading: string = 'Подпишитесь на наши новости'

const subscribeItems: SubscribeCardProps[] = [
  {
    id: 1,
    image_url: '/static/img/icons/email.png',
    heading: 'Email-рассылка',
    sub_heading: 'Только самое важное у вас в почте',
    text: 'Несколько раз в неделю вы будете получать от нас только полезные письма — с анонсами мероприятий, «цифровыми» кейсами, отраслевыми новостями и экспертными разборами.',
    url: ''
  },
  {
    id: 2,
    image_url: '/static/img/icons/telegram.svg',
    heading: 'Телеграм-канал',
    sub_heading: 'Держим руку на «цифровом» пульсе',
    text: 'Вы хотите быть в курсе событий мира цифровой трансформации? Присоединяйтесь к нашему Телеграм-каналу. В нем — полезные подборки, новости и тренды из мира «цифры», анонсы наших образовательных мероприятий и многое другое. ',
    url: 'https://t.me/CDTOonline'
  }
]

export { heading, subscribeItems }
