import getResponsiveProp from 'common/getResponsiveProp'
import useDevice from 'common/useDevice'
import Stack from 'components/Stack/Stack'
import React, { useEffect, useState } from 'react'

import { ReactComponent as Arrow } from './img/arrow.svg'
import styles from './Carousel.module.css'
import colors from 'common/colors'
import Color from 'common/Color.d'

type CarouselProps = {
  id?: string | number;
  children: React.ReactNode;

  gap?: number;
  smallScreenGap?: number;
  tabletGap?: number;
  phoneGap?: number;

  quantity: number;
  smallScreenQuantity?: number;
  tabletQuantity?: number;
  phoneQuantity?: number;

  circular?: boolean;
  autoScroll?: boolean;
  autoScrollInterval?: number;
  bottomNavColor?: Color;
}

const Carousel = (props: CarouselProps) => {
  const device = useDevice()
  const [index, setIndex] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const carouselRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.addEventListener('resize', handleChange)
    window.addEventListener('orientationchange', handleChange)
    window.addEventListener('load', handleChange)
    window.addEventListener('reload', handleChange)
    updateCarouselWidth()
    return () => {
      window.removeEventListener('resize', handleChange)
      window.removeEventListener('orientationchange', handleChange)
      window.removeEventListener('load', handleChange)
      window.removeEventListener('reload', handleChange)
    }
  })

  useEffect(() => {
    if (props.autoScroll) {
      const interval = setInterval(() => {
        scrollRight()
      }, props.autoScrollInterval ?? 5000)
      return () => clearInterval(interval)
    }
  })

  useEffect(() => {
    setIndex(0)
  }, [device])

  useEffect(() => {
    setTotalItems(React.Children.toArray(props.children).length)
    updateCarouselWidth()
  }, [props.children]) // eslint-disable-line

  const handleChange = () => {
    updateCarouselWidth()
  }

  const updateCarouselWidth = () => {
    const carouselWidth = carouselRef.current?.clientWidth ?? 0
    const gapsWidth = getGap() * (getQuantity() - 1)
    const itemWidth = (carouselWidth - gapsWidth) / getQuantity()
    setItemWidth(itemWidth)
    let items = carouselRef.current?.children as HTMLCollectionOf<HTMLElement> // eslint-disable-line
    if (!items) return
    for (let item of items) {
      item.style.boxSizing = 'border-box'
      item.style.flexShrink = '0'
      item.style.width = `${itemWidth}px`
    }
  }

  const getQuantity = () => {
    const quantity = getResponsiveProp(device, props, 'quantity')
    if (totalItems < quantity) return totalItems
    return quantity
  }

  const getGap = () => {
    return getResponsiveProp(device, props, 'gap') ?? 32
  }

  const scrollTo = (index: number) => {
    if (index < 0 || index > totalItems - getQuantity()) {
      setIndex(index - 1)
      return
    }
    setIndex(index)
  }

  const scrollLeft = () => {
    if (index === 0) {
      setIndex(totalItems - getQuantity())
    } else {
      setIndex(index - 1)
    }
  }

  const scrollRight = () => {
    if (index === totalItems - getQuantity()) {
      if (props.circular) setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }

  const getLeft = () => {
    return '-' + index * (getGap() + itemWidth) + 'px'
  }

  let carouselStyle: React.CSSProperties = {
    left: getLeft(),
    gap: getResponsiveProp(device, props, 'gap') ?? 32
  }

  const carouselProps = {
    className: styles['Carousel__carousel'],
    style: carouselStyle,
    ref: carouselRef
  }

  return (<>
    {totalItems > 0 &&
      <Stack gap={40}>
        <Stack
          orientation='horizontal'
          alignItems={'center'}
          gap={getGap()}
        >
          <button
            className={styles['Carousel__arrow']}
            onClick={scrollLeft}
            disabled={!props.circular && (index === 0)}
          >
            <Arrow
              style={{
                transform: 'rotate(180deg)'
              }}
            />
          </button>
          <div className={styles['Carousel__wrapper']}>
            <div {...carouselProps}>{props.children}</div>
          </div>
          <button
            className={styles['Carousel__arrow']}
            onClick={scrollRight}
            disabled={!props.circular && (index === (totalItems - getQuantity()))}
          >
            <Arrow
            />
          </button>
        </Stack>

        <Stack alignItems='center'>
          <Stack
            orientation='horizontal'
            gap={20}
          >
            {Array.from(Array(totalItems - 1).keys()).map((i) => (
              <div
                key={i}
                style={{
                  cursor: 'pointer',
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  border: `1px solid ${colors[props.bottomNavColor ?? 'bright-emerald']}`,
                  backgroundColor: (i === index) ? colors[props.bottomNavColor ?? 'bright-emerald'] : 'transparent',
                  transition: 'background-color 0.3s ease-in-out'
                }}
                onClick={() => scrollTo(i)}
              ></div>
            ))}
          </Stack>
        </Stack>
      </Stack>
    }
  </>)
}

export default Carousel
