import React from 'react'
import Color from 'common/Color.d'
import colors from 'common/colors'

import styles from './Card.module.css'
import useScrolledTo from 'common/useScrolledTo'
import getAnimationStyle, { AnimationVariant } from 'common/getAnimationStyle'
import useMediaQuery from 'common/useMediaQuery'

type CardProps = {
  id?: string | number;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  bgColor?: Color;
  clickable?: boolean;
  onClick?: any;
  onMouseDown?: any;
  width?: React.CSSProperties['width'],
  tabletWidth?: React.CSSProperties['width'],
  phoneWidth?: React.CSSProperties['width'],
  maxWidth854?: boolean;
  animate?: boolean;
  animationVariant?: AnimationVariant
  animateDelayIndex?: number;
  withoutShadow?: boolean;
  backgroundGradient?: 'green';
}

const Card = (props: CardProps) => {
  const cardRef = React.useRef<HTMLDivElement>(null)
  const scrolledTo = useScrolledTo(cardRef)
  const { isTablet, isPhone } = useMediaQuery()

  const handleClick = (e: any) => {
    if ('onClick' in props) {
      props.onClick(e)
    }
  }

  const handleMouseDown = (e: any) => {
    if ('onMouseDown' in props) {
      props.onMouseDown(e)
    }
  }

  const bgColor: Color = props.bgColor ?? 'white'

  let style: React.CSSProperties = {
    background: colors[bgColor],
    width: (isPhone && props.phoneWidth) ? props.phoneWidth : (isTablet && props.tabletWidth) ? props.tabletWidth : props.width,
    maxWidth: props.maxWidth854 ? '854px' : '100%'
  }

  if (props.withoutShadow) {
    style = {
      ...style,
      boxShadow: 'none'
    }
  }

  if (props.animate) {
    style = {
      ...style,
      ...getAnimationStyle(scrolledTo, props.animationVariant, props.animateDelayIndex)
    }
  }

  if (props?.backgroundGradient === 'green') {
    style = {
      ...style,
      background: 'linear-gradient(to right, #047A69, #449791)'
    }
  }

  let className = styles.card
  className = props.clickable ? `${className} ${styles['card--clickable']}` : className
  className = props.className ? `${className} ${props.className}` : className

  const cardProps: any = {
    style: {...style, ...props.style},
    className: className,
    onClick: handleClick,
    onMouseDown: handleMouseDown,
    ref: cardRef
  }

  if (props.id) {
    cardProps['id'] = props.id
  }

  return (
    <div {...cardProps}>{props.children}</div>
  )
}

export default Card
export type { CardProps }
