import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'

import { heading, imageUrl, linkText, linkUrl, text } from './data'

const OtherAreasCard = () => {
  const handleClick = () => {
    const el: any = document.getElementById('question-section')
    el.scrollIntoView({block: 'start', behavior: 'smooth'})
  }

  return (
    <Card bgColor='light-gray'>
      <Stack gap={30} alignItems='center' orientation='horizontal' phoneOrientation='vertical'>
        <Image src={imageUrl} width='168px' />
        <Stack gap={20}>
          <Heading variant='3'>{heading}</Heading>
          <div>{text}</div>
          <div>
            <Button onClick={handleClick} variant='outlined' href={linkUrl}>{linkText}</Button>
          </div>
        </Stack>
      </Stack>
    </Card>
  )
}

export default OtherAreasCard
