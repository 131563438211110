import Card, { CardProps } from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Text from 'components/Text/Text'
import React from 'react'
import { Link } from 'react-router-dom'

import styles from './NewsCard.module.css'

import { getFormattedDate } from 'common/utils'
import useMediaQuery from 'common/useMediaQuery'

type NewsCardProps = {
  id?: number;
  image_url: string;
  date: string;
  name: string;
  description: string;
  orientation: 'horizontal' | 'vertical';
  tallImage?: boolean;
}

const NewsCard = (props: NewsCardProps) => {
  const { isTablet } = useMediaQuery()
  const orientation = props.orientation ?? 'vertical'

  const linkStyle: React.CSSProperties = {
    textDecoration: 'none',
    display: 'flex',
    maxHeight: isTablet ? 'auto' : (orientation === 'horizontal' ? '290px' : 'auto')
  }

  const cardStyle: React.CSSProperties = {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    maxHeight: isTablet ? 'auto' : (orientation === 'horizontal' ? '290px' : 'auto'),
    width: '100%'
  }

  const cardProps: CardProps = {
    style: cardStyle,
    bgColor: 'white',
    clickable: true
  }

  const imageSytle: React.CSSProperties = {
    flexShrink: 0,
    height: isTablet ? 'auto' : (orientation === 'vertical' ? (props.tallImage ? '330px' : '240px') : 'auto'),
    // height: orientation === 'vertical' ? (props.tallImage ? '330px' : '240px') : 'auto',
    width: orientation === 'horizontal' ? '275px' : 'auto',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: orientation === 'vertical' ? '20px' : '0',
    borderBottomLeftRadius: orientation === 'horizontal' ? '20px' : '0'
  }

  const infoBlockStyle: React.CSSProperties = {
    paddingTop: '28px',
    paddingLeft: '28px',
    paddingRight: '28px',
    paddingBottom: '32px'
  }

  return (
    <Link style={linkStyle} to={`/news/${props.id}`}>
      <Card {...cardProps}>
        <Stack gap={orientation === 'horizontal' ? 20 : 16 } height='100%' orientation={orientation} >
          {orientation === 'vertical' && <Image objectFit='cover' src={props.image_url} style={imageSytle} />}
          <Stack gap={12} flexGrow={1} style={infoBlockStyle} >
            <TextWithDot gap={16} dotVariant='rounded'>
              <Text uppercase variant='tiny' fontWeight='medium'>{getFormattedDate(props.date)}</Text>
            </TextWithDot>
            <Heading variant='3' overflowY='hidden' textOverflow='ellipsis'>{props.name}</Heading>
            <Text
              variant='small'
              textOverflow='ellipsis'
              overflowY='hidden'
              className={orientation === 'horizontal' ? styles.text : ''}
            >
              {props.description}
            </Text>
          </Stack>
        </Stack>
      </Card>
    </Link>
  )
}

export default NewsCard
export type { NewsCardProps }
