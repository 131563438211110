import FooterSection from 'page-sections/FooterSection/FooterSection'
import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import ProgramsSection from './ProgramsSection/ProgramsSection'
import QuestionSection from 'page-sections/QuestionSection/QuestionSection'
import HeaderBlock from './HeaderBlock/HeaderBlock'
import CertPreview from './CertPreview/CertPreview'
import AdvertisingSection from './AdvertisingSection/AdvertisingSection'

import { itemList2 } from './data'
import { useState } from 'react'
import useSectionsData, { getDataByKey } from 'common/sections-data'

const ProgramsPage = () => {
  const keys = 'programs-header,programs-advertising'
  const sectionList = useSectionsData(keys)

  const [visibleCertPreview, setVisibleCertPreview] = useState(false)

  return (
    <div>
      <CertPreview visible={visibleCertPreview} onClose={() => setVisibleCertPreview(false)} />

      <HeaderSection>
        <HeaderBlock
          imageUrl='/static/img/pages/programs/header-image.svg'
          {...getDataByKey(sectionList, 'programs-header')}
        />
      </HeaderSection>

      <AdvertisingSection
        {...getDataByKey(sectionList, 'programs-advertising')}
        itemList2={itemList2}
        onShowCertPreview={() => setVisibleCertPreview(true)}
      />

      <ProgramsSection />

      <QuestionSection id='question-section' />

      <FooterSection />
    </div>
  )
}

export default ProgramsPage
