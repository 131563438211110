import Stack from 'components/Stack/Stack'
import Text from 'components/v2/Text/Text'
import Image from 'components/Image/Image'
import React from 'react'

type FAQItemProps = {
  name: string;
  description: string;
}

const FAQItem = (props: FAQItemProps) => {
  const [expanded, setExpanded] = React.useState(false)

  return (
    <Stack gap={10}>
      <Stack
        orientation='horizontal'
        onClick={() => setExpanded(!expanded)}
        style={{cursor: 'pointer'}}
        alignItems='center'
      >
        <Text
          variant='text-m-medium'
          style={{flexGrow: '1'}}
        >
          {props.name}
        </Text>
        <Image
          src='/static/img/pages/program-registry/digital-strategy/training-program-section/arrow.svg'
          width={20}
          height={20}
          alt='arrow'
          style={{
            transform: expanded ? 'rotate(180deg)' : ''
          }}
        />
      </Stack>
      {expanded && <Text variant='text-s'>{props.description}</Text>}
      <div style={{width: '100%', height: '1px', backgroundColor: '#B2B2B2'}}></div>
    </Stack>
  )
}

export default FAQItem
