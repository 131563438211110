import { Link as ReactLink } from 'react-router-dom'
import React from 'react'

type LinkProps = {
  children: any;
  to: string;
  style?: React.CSSProperties;
}

const Link = ({children, to, style}: LinkProps) => {
  const linkProps: any = {
    to: to,
    style: {
      ...{textDecoration: 'none'},
      ...{style}
    }
  }

  const aProps: any = {
    href: to,
    target: '_blank',
    rel: 'noopener noreferrer',
    style: {
      ...{textDecoration: 'none'},
      ...{style}
    }
  }

  return (
    <>
      {to.startsWith('http') && <a {...aProps}>{children}</a>}
      {!to.startsWith('http') && <ReactLink {...linkProps}>{children}</ReactLink>}
    </>
  )
}

export default Link
