import React from 'react'
import ExpertMaterialsSection from './page-sections/ExpertMaterialsSection'
import HeaderSection from './page-sections/HeaderSection'
import HowConsultationGoingSection from './page-sections/HowConsultationGoingSection'
import HowInteractionGoingSection from './page-sections/HowInteractionGoingSection'
import HowToPrepareSection from './page-sections/HowToPrepareSection'
import WhatIssuesSolveSection from './page-sections/WhatIssuesSolveSection'
import WhatIsTheResultSection from './page-sections/WhatIsTheResultSection'
import WhenNeededSection from './page-sections/WhenNeededSection'
import QuestionSection from 'page-sections/v2/QuestionSection/QuestionSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'
import ConsultansSection from './page-sections/ConsultansSection'
import ReviewsSection from './page-sections/ReviewsSection'
import RequestForm from './components/RequestForm'
import SuccessSubmitModal from './components/SuccessSubmitModal'

const CareerManagementPage = () => {
  const [requestFromVisible, setRequestFromVisible] = React.useState(false)
  const [SuccessSubmitModalVisible, setSuccessSubmitModalVisible] = React.useState(false)
  const [tariff, setTariff] = React.useState('')

  const onSelectTariff = (tariff: string) => {
    setTariff(tariff)
    setRequestFromVisible(true)
  }

  const onSubmit = () => {
    setRequestFromVisible(false)
    setSuccessSubmitModalVisible(true)
  }

  return (
    <div>
      <RequestForm
        visible={requestFromVisible}
        defaultTariff={tariff}
        onClose={() => setRequestFromVisible(false)}
        onSubmit={onSubmit}
      />
      <SuccessSubmitModal
        visible={SuccessSubmitModalVisible}
        onClose={() => setSuccessSubmitModalVisible(false)}
      />

      <HeaderSection />
      <WhatIssuesSolveSection />
      <WhenNeededSection />
      <ExpertMaterialsSection />
      <HowToPrepareSection />
      <HowConsultationGoingSection />
      <HowInteractionGoingSection />
      <WhatIsTheResultSection
        onSelectTariff={onSelectTariff}
      />

      {false && <ConsultansSection />}
      {false && <ReviewsSection />}

      <QuestionSection
        id={'question-section'}
        bgColor='white'
        defaultPurposeOfRequest='career-management'
      />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default CareerManagementPage
