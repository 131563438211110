import Color from 'common/Color.d'
import Card from 'components/Card/Card'
import Stack from 'components/Stack/Stack'
import Text from 'components/v2/Text/Text'
import React from 'react'

type CompetencyModelCardProps = {
  title: string;
  titleColor: Color;
  textColor?: Color;
  bgColor?: Color;
  content: any;
}

const CompetencyModelCard = (props: CompetencyModelCardProps) => {
  return (
    <div
      style={{ boxSizing: 'border-box', padding: '5px' }}
    >
      <Card
        style={{ boxSizing: 'border-box', height: '100%' }}
        bgColor={props.bgColor}
      >
        <Stack gap={20}>
          <Text variant='text-l' color={props.titleColor}>{props.title}</Text>
          <Text>
            {props.textColor}
            {props.content}
          </Text>
        </Stack>
      </Card>
    </div>
  )
}

export default CompetencyModelCard
