const heading: string = 'Методология измерения индекса ИИ-\u2060зрелости ФОИВ'
const text: string = '<p>Для оценки эффективности мероприятий по внедрению искусственного интеллекта в деятельность ФОИВ мы разработали «Методологию измерения индекса ИИ-зрелости федеральных органов исполнительной власти».</p><p>Методология была создана по поручению вице-премьера Д.Н. Чернышенко и реализуется в рамках федерального проекта «Искусственный интеллект» национальной программы «Цифровая экономика Российской Федерации».</p>'

const imageBullets = [
  {
    id: 1,
    imageUrl: '/static/img/pages/ai-eval/methodology-icon1.svg',
    description: 'Методические рекомендации по организации оценки'
  },
  {
    id: 2,
    imageUrl: '/static/img/pages/ai-eval/methodology-icon2.svg',
    description: 'Методику измерения индекса'
  }
]
export { heading, text, imageBullets }
