import { Routes, Route, useLocation } from 'react-router-dom'
import Home from 'pages/Home/Home'
import Experts from 'pages/v2/Experts/ExpertsPage'
import Team from 'pages/Team/Team'
import Commnunity from 'pages/Community/Community'
import Contacts from 'pages/Contacts/Contacts'
import Programs from 'pages/Programs/Programs'
import PersonnelReserve from 'pages/PersonnelReserve/PersonnelReserve'
import RecruitingCenter from 'pages/RecruitingCenter/RecruitingCenter'
import Partners from 'pages/Partners/Partners'
import { useState, useEffect, createContext } from 'react'
import SelectionEvaluation from 'pages/SelectionEvaluation/SelectionEvaluation'
import Consulting from 'pages/Сonsulting/Сonsulting'
import ExpertSupport from 'pages/ExpertSupport/ExpertSupport'
import NewsItem from 'pages/NewsItem/NewsItem'
import News from 'pages/News/News'
import AiEvalPage from 'pages/AiEvalPage/AiEvalPage'
import SumOfTechPage from 'pages/SumOfTech/SumOfTechPage'
import MaterialItemPage from 'pages/SumOfTech/MaterialItemPage/MaterialItemPage'
import MaterialsByTypePage from 'pages/SumOfTech/MaterialsByTypePage'
import MethodologicalCenterPage from 'pages/MethodologicalCenter/MethodologicalCenterPage'
import ProgramConstructorPage from 'pages/ProgramConstructor/ProgramConstructor'
import ShoppingcartPage from 'pages/ProgramConstructor/ShoppingcartPage'
import DigitalStrategyPage from 'pages/ProgramRegistry/DigitalStrategy/DigitalStrategyPage'
import ItManagmentPracticePage from 'pages/ProgramRegistry/ItManagmentPractice/ItManagmentPracticePage'
import DigitalDevManagementPage from 'pages/ProgramRegistry/DigitalDevManagement/DigitalDevManagementPage'
import DigitalDevelopmentPage from 'pages/ProgramRegistry/DigitalDevelopment/DigitalDevelopmentPage'
import StateAdministrationPage from 'pages/ProgramRegistry/StateAdministration/StateAdministrationPage'
import RecruitingDTSpecialistsPage from 'pages/RecruitingDTSpecialists/RecruitingDTSpecialistsPage'
import ProgramRegistryPage from 'pages/ProgramRegistry/ProgramRegistryPage/ProgramRegistryPage'
import TimetablePage from 'pages/ProgramRegistry/Timetable/TimetablePage'
import RoleCatalogPage from 'pages/RoleCatalog/RoleCatalogPage'
import CareerManagementPage from 'pages/CareerManagement/CareerManagementPage'
import TestPage from 'pages/TestPage'
import CompetencyModelPage from 'pages/CompetencyModel/CompetencyModelPage'
import CompetencyModelDetailPage from 'pages/CompetencyModel/CompetencyModelDetailPage'
import WelcomePage from 'pages/Welcome/WelcomePage'

const MainMenuContext = createContext([])

function App () {
  const [menuItems, setMainMenuItems] = useState([])

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    fetchMainMenu()
  }, [])

  const fetchMainMenu = async () => {
    const response = await fetch('/api/main-menu')
    const data = await response.json()
    setMainMenuItems(data)
  }

  return (
    <div className="App">
      <MainMenuContext.Provider value={menuItems}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/experts" element={<Experts />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/personnel-reserve" element={<PersonnelReserve />} />
          <Route path="/recruiting-center" element={<RecruitingCenter />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/selection-evaluation" element={<SelectionEvaluation />} />
          <Route path="/community" element={<Commnunity />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/expert-support" element={<ExpertSupport />} />
          <Route path="/ai-eval" element={<AiEvalPage />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsItem />} />
          <Route path="/sum-of-tech" element={<SumOfTechPage />} />
          <Route path="/sum-of-tech/materials-by-type/:id" element={<MaterialsByTypePage />} />
          <Route path="/sum-of-tech/materials/:id" element={<MaterialItemPage />} />
          <Route path="/methodological-center" element={<MethodologicalCenterPage />} />
          <Route path="/program-constructor" element={<ProgramConstructorPage />} />
          <Route path="/program-constructor/shoppingcart" element={<ShoppingcartPage />} />
          <Route path="/program-registry" element={<ProgramRegistryPage />} />
          <Route path="/program-registry/timetable" element={<TimetablePage />} />
          <Route path="/program-registry/digital-strategy" element={<DigitalStrategyPage />} />
          <Route path="/program-registry/it-managment-practice" element={<ItManagmentPracticePage />} />
          <Route path="/program-registry/digital-dev-management" element={<DigitalDevManagementPage />} />
          <Route path="/program-registry/digital-development" element={<DigitalDevelopmentPage />} />
          <Route path="/program-registry/state-administration" element={<StateAdministrationPage />} />
          <Route path="/recruiting/digital-transformation-specialists" element={<RecruitingDTSpecialistsPage />} />
          <Route path="/career-management" element={<CareerManagementPage />} />
          <Route path="/expertise-models" element={<CompetencyModelPage />} />
          <Route path="/competency-models" element={<CompetencyModelPage />} />
          <Route path="/competency-models/detail" element={<CompetencyModelDetailPage />} />
          <Route path="/welcomebook" element={<WelcomePage />} />
          <Route path="/aequaZ5K" element={<TestPage />} />

          <Route path="/role-catalog" element={<RoleCatalogPage />} />
        </Routes>
      </MainMenuContext.Provider>
    </div>
  )
}

export default App
export { MainMenuContext }
