import React from 'react'
import Card from 'components/Card/Card'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Text from 'components/Text/Text'
import Heading from 'components/Heading/Heading'

import styles from './ProductCard.module.css'

type ProductCardProps = {
  id: number;
  name: string;
  product_type: string;
  url: string;
  image_url: string;
}

const ProductCard = (props: ProductCardProps) => {
  const handleClick = () => {
    window.open(props.url, '_blank', 'noreferrer')
  }
  return (
    <Card clickable onClick={handleClick} className={styles.Card}>
      <Stack gap={16}>
        <Image className={styles.Image} src={props.image_url} />
        <Stack gap={12} className={styles.Info}>
          <TextWithDot dotVariant='rounded' dotColor='dark-gray'>
            <Text variant='tiny' uppercase color='dark-gray'>{props.product_type}</Text>
          </TextWithDot>
          <Heading variant='3'>{props.name}</Heading>
        </Stack>
      </Stack>
    </Card>
  )
}

export default ProductCard
export type { ProductCardProps }
