import Color from 'common/Color.d'
import Button from 'components/Button/Button'
import Grid from 'components/Grid/Grid'
import NumberListCard, { NumberListCardProps } from 'components/NumberListCard/NumberListCard'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'

import { buttonText, heading, itemList } from './data'

type YourGoalsSectionProps = {
  id?: number | string;
  bgColor?: Color;
}

const YourGoalsSection = (props: YourGoalsSectionProps) => {
  const handleClick = () => {
    const el: any = document.getElementById('question-section')
    el.scrollIntoView({block: 'start', behavior: 'smooth'})
  }

  return (
    <PageSection {...props}>
      <SectionHeading>{heading}</SectionHeading>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={120}
        tabletColumnGap={60}
        rowGap={60}
        tabletRowGap={40}
        phoneRowGap={30}
      >
        {itemList.map((item: NumberListCardProps) => {
          return (
            <NumberListCard
              key={item.id}
              {...item}
            />
          )
        })}
      </Grid>
      <div style={{textAlign: 'center'}}>
        <Button variant='filled' onClick={handleClick}>{buttonText}</Button>
      </div>
    </PageSection>
  )
}

export default YourGoalsSection
