import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import Indicator, { IndicatorData } from 'components/Indicator/Indicator'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'

type ResultsSectionProps = {
  bgColor?: Color;
  heading?: string;
  indicatorBullets?: any[];
}

const ResultsSection = (props: ResultsSectionProps) => {
  return (
    <PageSection bgColor={props.bgColor}>
      <Stack gap={60}>
        <SectionHeading>{nbspacer(props.heading ?? '')}</SectionHeading>
        <Stack
          gap={20}
          orientation='horizontal'
        >
          {props.indicatorBullets?.map((item: IndicatorData, index: number) => {
            return <Indicator animate animateDelayIndex={index} key={item.id} {...item} />
          })}
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default ResultsSection
