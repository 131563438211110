import React from 'react'
import PageSection from 'components/PageSection/PageSection'
import QuestionForm from './QuestionForm/QuestionForm'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Color from 'common/Color.d'
import Grid from 'components/Grid/Grid'
import Heading from 'components/v2/Heading/Heading'

type QuestionSectionProps = {
  bgColor?: Color;
  heading?: string;
  id?: number | string;
  defaultPurposeOfRequest?: string;
  hideDescription?: boolean;
}

const QuestionSection = (props: QuestionSectionProps) => {
  const heading: string = props.heading ?? 'Остались вопросы?'
  return (
    <PageSection bgColor={props.bgColor} id={props.id}>
      <Grid cols={2} tabletCols={1} columnGap={130}>
        <Stack gap={60} tabletGap={40}>
          <Heading variant='2'>{heading}</Heading>
          <QuestionForm
            defaultPurposeOfRequest={props.defaultPurposeOfRequest}
            hideDescription={props.hideDescription}
          />
        </Stack>
        <Image animate animationVariant='appear-left' src='/static/img/page-sections/question-section-image.svg' width='100%'/>
      </Grid>
    </PageSection>
  )
}

export default QuestionSection
