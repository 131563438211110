import useMediaQuery from 'common/useMediaQuery'
import React from 'react'

type PageSectionVariant = 'gradient-green' | 'white-icons' | 'gradient-green-one-star' | 'gradient-green-stars' | 'gray'

type PageSectionProps = {
  id?: number | string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  variant?: PageSectionVariant;
  paddingTop?: React.CSSProperties['paddingTop'];
  paddingBottom?: React.CSSProperties['paddingTop'];
}

const PageSection = (props: PageSectionProps) => {
  const { isPhone } = useMediaQuery()

  const elStyle: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: isPhone ? '40px 20px' : '100px 150px',
    paddingTop: props.paddingTop ?? '100px',
    paddingBottom: props.paddingBottom ?? '100px'
  }

  const getBackgroundStyle = () => {
    switch (props.variant) {
      case 'gradient-green':
        return {
          backgroundImage: 'url(/static/img/backgrounds/background1.png)',
          backgroundSize: 'cover'
        }
      case 'gradient-green-stars':
        return {
          backgroundImage: 'url(/static/img/backgrounds/background1.png)',
          backgroundSize: 'cover'
        }
      case 'gradient-green-one-star':
        return {
          backgroundImage: 'url(/static/img/backgrounds/background1.png)',
          backgroundSize: 'cover'
        }
      case 'white-icons':
        return {
          backgroundImage: 'url(/static/img/backgrounds/background2.png)',
          backgroundSize: 'contain'
        }
      case 'gray':
        return {
          backgroundColor: '#E9F0F4'
        }
    }
  }

  const getInner1Style = () => {
    if (!isPhone && (props.variant === 'gradient-green-stars' || props.variant === 'gradient-green-one-star')) {
      return {
        backgroundImage: 'url(/static/img/backgrounds/stars.svg)',
        backgroundPosition: '5% 5%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '5%'
        // border: '1px solid red'
      }
    }
  }

  const getInner2Style = () => {
    if (props.variant === 'gradient-green-stars') {
      return {
        backgroundImage: 'url(/static/img/backgrounds/stars.svg)',
        backgroundPosition: '95% 95%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '4%'
      }
    }
  }

  const elProps: any = {
    id: props.id,
    style: {...elStyle, ...props.style}
  }

  return (
    <section style={{width: '100%', display: 'flex', justifyContent: 'center', ...getBackgroundStyle()}} >
      <div style={{width: '100%', display: 'flex', justifyContent: 'center', ...getInner1Style()}}>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', ...getInner2Style()}}>
          <div {...elProps}>
            <div style={{textAlign: 'left', width: '100%', maxWidth: '1300px'}}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PageSection
