import PageSection from 'components/PageSection/PageSection'
import styles from './FooterSection.module.css'
import ContactsBlock from 'components/ContactsBlock/ContactsBlock'
import Text from 'components/Text/Text'
import Stack from 'components/Stack/Stack'
import CookieConsent from 'components/CookieConsent'

type FooterSectionPropts = {
  id?: number | string;
  showContacts?: boolean;
}

const FooterSection = (props: FooterSectionPropts) => {
  const showContacts = props.showContacts ?? true
  return (
    <PageSection id={props.id} className={styles.FooterSection} bgColor='emerald'>
      {showContacts &&
        <ContactsBlock showSubscribeForm themeWhite/>
      }
      <Stack gap={8}>
        <Text variant='small' color='white'>Cайт разработан в качестве информационно-аналитического материала, обеспечивающего реализацию программ развития компетенций</Text>
        <Text variant='small' color='white'>© 2024 Центр подготовки руководителей и команд цифровой трансформации ВШГУ РАНХиГС</Text>
      </Stack>
      <CookieConsent />
    </PageSection>
  )
}

export default FooterSection
