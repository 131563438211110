import React from 'react'
import ExpertsSection from './sections/ExpertsSection'
import HeaderSection from './sections/HeaderSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'

const ExpertsPage = () => {
  return (
    <div>
      <HeaderSection />
      <ExpertsSection />
      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default ExpertsPage
