import nbspacer from 'common/nbspacer'
import Grid from 'components/Grid/Grid'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'

type HeaderBlockProps = {
  heading?: string;
  subheading?: string;
  text?: string;
  imageUrl?: string;
}

const HeaderBlock = (props: HeaderBlockProps) => {
  return (
    <Grid cols={2} tabletCols={1} columnGap={60} tabletColumnGap={40}>
      <Stack gap={40}>
        <Heading variant='1'>{nbspacer(props.heading ?? '')}</Heading>
        <Stack gap={20}>
          <Text>
            <div dangerouslySetInnerHTML={{ __html: nbspacer(props.text ?? '') }} />
          </Text>
        </Stack>
      </Stack>
      <Image
        animate
        animationVariant='appear-left'
        width={'100%'}
        src={props.imageUrl ?? ''}
      />
    </Grid>
  )
}

export default HeaderBlock
