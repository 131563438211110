import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'

const SolvingTasksSection = () => {
  const items: Array<string> = [
    'Определить состав команд цифровых проектов',
    'Подготовить должностные инструкции',
    'Оценить компетенции и опыт новых специалистов',
    'Составить содержание программ развития сотрудников',
    'Разработать показатели эффективности',
    'Провести аудит задач действующей команды',
    'Сформировать систему грейдов и резерв'
  ]

  return (
    <PageSection variant='gradient-green-stars'>
      <Grid
        cols={2}
        columnGap={100}
        tabletCols={1}
      >
        {/* Left */}
        <Stack gap={40}>
          <Heading
            variant='2'
            color='white'
          >
            Какие задачи можно решить с помощью каталога ролей?
          </Heading>
          <Stack gap={30}>
            {items.map((item: string, index: number) => {
              return (
                <Stack
                  key={index}
                  orientation='horizontal'
                  gap={30}
                >
                  <Image src='/static/img/pages/recruiting/digital-transformation-specialists/why-trust-section/checkbox.svg' />
                  <Text
                    variant='text-m'
                    color='white'
                  >
                    {item}
                  </Text>
                </Stack>)
            })}
          </Stack>
        </Stack>

        {/* right */}
        <Image src='/static/img/pages/role-catalog/solving-tasks-section/section-image.svg' />
      </Grid>
    </PageSection>
  )
}

export default SolvingTasksSection
