import nbspacer from 'common/nbspacer'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import CourseAboutCard from 'components/v2/CourseAboutCard/CourseAboutCard'
import Heading from 'components/v2/Heading/Heading'
import IconView from 'components/v2/IconView/IconView'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'

const AboutCourseSection = () => {
  return (
    <PageSection variant='gray'>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={20}
        rowGap={20}
      >
        {/* left */}
        <Stack gap={40}>
          <Heading variant='2'>О курсе</Heading>
          <Text variant='text-m'>{nbspacer('Как сопровождать проекты на всех этапах — от коммуникации с заказчиком до реализации и поддержки проекта.')}</Text>
          <Image
            width='100%'
            src='/static/img/pages/program-registry/digital-dev-management/about-course-section/section-image.png'
          />
        </Stack>
        {/* right */}
        <div>
          <IconView cols={2} tabletCols={1} columnGap={20} rowGap={20}>
            <CourseAboutCard image='horn' name='Как?' description='Онлайн' />
            <CourseAboutCard image='clock' name='Как долго?' description='40 ак. часов, 6 недель' />
            <CourseAboutCard image='checkbox' name='Когда?' url='/program-registry/timetable' description='Ближайшая дата обучения' />
          </IconView>
        </div>
      </Grid>
    </PageSection>
  )
}

export default AboutCourseSection
