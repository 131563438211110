const aboutSection = {
  title: 'О курсе',
  text: 'ИТ-инфраструктура — это «скелет» организации, который помогает ей стать безопасной и сервисно-ориентированной. В эпоху цифровой трансформации ИТ-инфраструктура не просто влияет, а определяет операционную деятельность всех подразделений любых организаций. Неэффективное управление ИТ-инфраструктурой может привести к потере не только времени, но и денег.',
  imageUrl: '/static/img/pages/program-registry/it-managment-practice/about-section/about-image.svg',
  bullets: [
    {
      imageUrl: '/static/img/pages/program-registry/it-managment-practice/about-section/bullet-1.svg',
      name: 'Когда?',
      description: 'В любое время'
    },
    {
      imageUrl: '/static/img/pages/program-registry/it-managment-practice/about-section/bullet-2.svg',
      name: 'Как долго?',
      description: '40 часов, 2 недели'
    },
    {
      imageUrl: '/static/img/pages/program-registry/it-managment-practice/about-section/bullet-3.svg',
      name: 'Как?',
      description: 'Онлайн'
    },
    {
      imageUrl: '/static/img/pages/program-registry/it-managment-practice/about-section/bullet-4.svg',
      name: 'Тренды и идеи для ИТ-проектов органов власти',
      description: null
    }
  ]
}

const targetAudienceSection = {
  title: 'Для кого курс',
  items: [
    {
      imageUrl: '/static/img/pages/program-registry/it-managment-practice/target-audience-section/image1.svg',
      name: 'Руководители проектных офисов, департаментов, менеджеры проектов',
      description: <>
        <ul>
          <li>Научитесь управлять ИТ-инфраструктурой, чтобы она работала эффективно и без сбоев</li>
        </ul>
      </>
    },
    {
      imageUrl: '/static/img/pages/program-registry/it-managment-practice/target-audience-section/image2.svg',
      name: 'Все, кто разрабатывает и внедряет цифровую стратегию в компании',
      description: <>
        <ul>
          <li>Узнаете принципы построения ИТ-инфраструктуры и главный критерий ее эффективности</li>
        </ul>
      </>
    },
    {
      imageUrl: '/static/img/pages/program-registry/it-managment-practice/target-audience-section/image3.svg',
      name: <>Руководители цифровой трансформации, ИТ<span style={{fontFamily: 'sans-serif'}}>&#8209;</span>директора</>,
      description: <>
        <ul>
          <li>Узнаете, как сделать так, чтобы ИТ<span style={{fontFamily: 'sans-serif'}}>&#8209;</span>инфраструктура помогала организации достигать поставленных целей и реализовывать стратегию</li>
        </ul>
      </>
    }
  ]
}

const willKnowSection = {
  title: 'Что вы узнаете',
  imageUrl: '/static/img/pages/program-registry/digital-strategy/will-know-section/will-know-image.png',
  items: [
    'Зачем организации нужна стратегия',
    'Как создавать работающее цифровое решение',
    'Как анализировать эффективность цифровой стратегии',
    'Как оценивать текущее состояние организации: качество данных, степень цифровой зрелости, корпоративную культуру'
  ]
}

const willLearnSection = {
  title: 'Чему вы научитесь',
  imageUrl: '/static/img/pages/program-registry/digital-strategy/will-learn-section/will-learn-image.svg',
  items: [
    'Оценивать, как внедряется и развивается ИТ-инфраструктура на практике',
    'Составлять план миграции инфраструктуры вашей организации в облако',
    'Разрабатывать план перехода на новое ПО при импортозамещении',
    'Анализировать принципы построения и критерии эффективности ИТ-инфраструктуры на практических примерах и кейсах'
  ]
}

const courseContentSection = {
  title: 'Что будет в курсе',
  items: [
    {
      name: 'Интерактивные лонгриды и видеолекции',
      imageUrl: '/static/img/pages/program-registry/digital-strategy/course-content-section/icon1.svg'
    },
    {
      name: 'Тесты для самопроверки',
      imageUrl: '/static/img/pages/program-registry/digital-strategy/course-content-section/icon2.svg'
    },
    {
      name: 'Актуальные кейсы',
      imageUrl: '/static/img/pages/program-registry/digital-strategy/course-content-section/icon3.svg'
    },
    {
      name: 'Практические задания',
      imageUrl: '/static/img/pages/program-registry/digital-strategy/course-content-section/icon4.svg'
    }
  ]
}

export { aboutSection, targetAudienceSection, willKnowSection, willLearnSection, courseContentSection }
