import HeaderSectionBase from 'page-sections/v2/HeaderSection/HeaderSection'
import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'

import nbspacer from 'common/nbspacer'
import Text from 'components/v2/Text/Text'

const HeaderSection = () => {
  return (
    <HeaderSectionBase>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={160}
        rowGap={24}
        alignItems='center'
      >
        {/* left */}
        <Stack gap={40}>
          <Heading
            variant='1'
            color='white'
          >
            {nbspacer('Расписание программ')}
          </Heading>
          <Text variant='text-xl' color='white'>
            {nbspacer('На странице вы можете ознакомиться с расписанием программ на 2024 год')}
          </Text>
        </Stack>

        {/* right */}
        <Image
          src='/static/img/pages/program-registry/timetable/header-section/section-image.svg'
          width='100%'
        />
      </Grid>
    </HeaderSectionBase>
  )
}

export default HeaderSection
