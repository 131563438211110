import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Stack from 'components/Stack/Stack'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Text from 'components/Text/Text'
import Image from 'components/Image/Image'
import Button from 'components/Button/Button'
import * as Icon from 'react-feather'
import styles from './ProgramCard.module.css'
import { getFormattedDateLower, getFormattedDateLowerYear } from 'common/utils'
import scrollToId from 'common/scroll-to-id'
import { Link } from 'react-router-dom'

type ProgramCardProps = {
  id: number;
  name: string;
  description: string;
  short_description: string;
  academic_duration: string;
  duration: string;
  will_know: string;
  will_be_able: string;
  image_url: string;
  active: boolean;
  imagePosition?: 'left' | 'right'
  expanded?: boolean;
  onToggleExpanded?: any;
  timetable?: any;
  annotationsUrl?: any;
  admissionConditionsUrl?: any;
}

const TopPanel = ({ academicDuration, duration }: { academicDuration: string, duration: string }) => {
  return (
    <Stack orientation='horizontal' gap={16} tabletGap={4}>
      <TextWithDot dotVariant='rounded'>
        <Text variant='small' uppercase color='dark-gray'>{academicDuration}</Text>
      </TextWithDot>
      <TextWithDot dotVariant='rounded'>
        <Text variant='small' uppercase color='dark-gray'>{duration}</Text>
      </TextWithDot>
    </Stack>
  )
}

const ProgramCard = (props: ProgramCardProps) => {
  const name: string = props.name
  const description: string = props.description
  const shortDescription: string = props.short_description
  const imageUrl: string = props.image_url
  const academicDuration: string = props.academic_duration
  const duration: string = props.duration
  const willKnow: string = props.will_know
  const willBeAble: string = props.will_be_able
  const imagePosition = props.imagePosition

  const handleClick = (e: any) => {
    if ('onToggleExpanded' in props) {
      props.onToggleExpanded(e)
    }
  }

  return (
    <Stack
      id={'program-' + props.id}
      orientation='horizontal'
      gap={20}
      className={styles.ProgramCard}
    >
      {!props.expanded && imagePosition === 'left' && imageUrl && <Image src={imageUrl} className={styles.Image} />}
      <Card
        animate
        animationVariant={imagePosition === 'left' ? 'appear-left' : 'appear-right'}
        bgColor='white'
        className={styles.Card}
      >
        <Stack gap={20} className={styles.Info}>
          <Stack gap={20} className={styles.FlexGrow}>
            <Stack orientation='horizontal' gap={20}>
              {props.expanded && imagePosition === 'left' && <Image src={imageUrl} className={styles.Image} />}
              <Stack gap={30} className={styles.FlexGrow}>
                <TopPanel academicDuration={academicDuration} duration={duration} />
                <Stack gap={20}>
                  <Heading variant='2'>{name}</Heading>
                  <Stack gap={20}>
                    {!props.expanded && <div className={styles.Description} dangerouslySetInnerHTML={{ __html: shortDescription }} />}
                    {props.expanded && <div className={styles.Description} dangerouslySetInnerHTML={{ __html: description }} />}
                  </Stack>
                </Stack>
              </Stack>
              {props.expanded && imagePosition === 'right' && imageUrl && <Image src={imageUrl} className={styles.Image} />}
            </Stack>
            {props.expanded &&
              <Stack gap={20}>
                <Heading variant='3'>По окончании программы вы будете</Heading>
                <Heading variant='3'>Знать:</Heading>
                <div className={styles.WillKnow} dangerouslySetInnerHTML={{ __html: willKnow }} />
                <Heading variant='3'>Уметь:</Heading>
                <div className={styles.WillBeAble} dangerouslySetInnerHTML={{ __html: willBeAble }} />
              </Stack>
            }
          </Stack>

          {props.expanded && (props.annotationsUrl || props.admissionConditionsUrl) && <span>
            <Text>Дополнительные сведения о программе читайте в
              &nbsp;
            {props.annotationsUrl && <Link to={props.annotationsUrl}><Text color='emerald'>аннотации</Text></Link>}
              &nbsp;
            {props.admissionConditionsUrl && <Text>и</Text>}
              &nbsp;
            {props.admissionConditionsUrl && <Link to={props.admissionConditionsUrl}><Text color='emerald'>условиях приема.</Text></Link>}
            </Text>
          </span>}

          {props.expanded && props.timetable && props.timetable.length > 0 &&
            <Stack gap={20}>
              <Heading variant='3'>Расписание</Heading>
              <Text maxWidth854>На образовательной платформе для каждого региона установлены лимиты. Если лимит от вашего региона на поток исчерпан, вы сможете зарегистрироваться на следующий поток.</Text>
              <table className={styles.ProgramTimetable} cellSpacing={0}>
                <tr>
                  <th><Text variant='small' fontWeight='medium'>№ потока</Text></th>
                  <th><Text variant='small' fontWeight='medium'>Даты регистрации</Text></th>
                  <th><Text variant='small' fontWeight='medium'>Даты обучения</Text></th>
                </tr>
                <tbody>
                  {props.timetable.map((e: any, index: number) => {
                    return (
                      <tr key={e.id}>
                        <td><Text variant='small'>{index + 1}</Text></td>
                        <td><Text variant='small'>с {getFormattedDateLower(e.registration_date_from)} по {getFormattedDateLowerYear(e.registration_date_to)}</Text></td>
                        <td><Text variant='small'>с {getFormattedDateLower(e.study_date_from)} по {getFormattedDateLowerYear(e.study_date_to)}</Text></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Stack>
          }

          <Stack orientation='horizontal' gap={28}>
            <Button variant='outlined' onClick={() => scrollToId('question-section')}>Зарегистироваться</Button>
            <Button
              variant='simple'
              onClick={handleClick}
            >
              {!props.expanded && <Stack orientation='horizontal' alignItems={'center'} gap={7}>
                <Text variant='small' color={'emerald'}>Подробнее</Text><Icon.ChevronDown height={20} />
              </Stack>}
              {props.expanded && <Stack orientation='horizontal' alignItems={'center'} gap={7}>
                <Text variant='small' color={'emerald'}>Скрыть</Text><Icon.ChevronUp height={20} />
              </Stack>}
            </Button>
          </Stack>
        </Stack>
      </Card>

      {!props.expanded && imagePosition === 'right' && <Image src={imageUrl} className={styles.Image} />}
    </Stack>
  )
}

export default ProgramCard
export { ProgramCard }
export type { ProgramCardProps }
