import HeaderSectionBase from 'page-sections/v2/HeaderSection/HeaderSection'
import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import Button from 'components/Button/Button'

import scrollToId from 'common/scroll-to-id'

const HeaderSection = () => {
  return (
    <HeaderSectionBase>
      <Grid cols={2} tabletCols={1} columnGap={24} rowGap={24}>
        {/* left */}
        <Stack gap={40}>
          <Stack>
            <Heading variant="1" color="white" uppercase>
              ИНСТРУМЕНТЫ ЦИФРОВОГО РАЗВИТИЯ:
            </Heading>
            <Text variant="text-xl" color="white" uppercase>
              проектный, процессный и продуктовый подходы
            </Text>
          </Stack>
          <Text variant="text-m" color="white">
            Управляем цифровыми проектами грамотно и с высоким результатом!
          </Text>
          <div>
            <Stack gap={40} orientation="horizontal">
              <Button
                variant="filled-white"
                target="_blank"
                rel="noreferrer"
                href="https://forms.yandex.ru/u/6655ed653e9d08165e1e8108"
              >
                Регистрация на программу
              </Button>
              <Button
                variant="outlined-white"
                onClick={() => scrollToId('question-section')}
              >
                Получить консультацию
              </Button>
            </Stack>
          </div>
        </Stack>

        {/* right */}
        <Image
          src="/static/img/pages/program-registry/digital-dev-management/header-section/section-image.png"
          width="100%"
        />
      </Grid>
    </HeaderSectionBase>
  )
}

export default HeaderSection
