import PageSection from 'components/PageSection/PageSection'
import Stack from 'components/Stack/Stack'
import FooterSection from 'page-sections/FooterSection/FooterSection'
import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import React, { useEffect, useState } from 'react'
import MaterialCard from 'components/MaterialCard/MaterialCard'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import Grid from 'components/Grid/Grid'
import LogoCard from './LogoCard/LogoCard'
import ColorMaterialCard from 'components/ColorMaterialCard/ColorMaterialCard'
import MaterialData from 'common/MaterialData.d'
import MaterialTypeData from 'common/MaterialTypeData.d'
import MaterialTypeCard from 'components/MaterialTypeCard/MaterialTypeCard'

const SumOfTechPage = () => {
  const [materialTypeList, setMaterialTypeList] = useState([] as MaterialTypeData[])
  const [materialCategoryList, setMaterialCategoryList] = useState([] as any[])
  const [materialList, setMaterialList] = useState([] as any[])
  const [latestMaterialList, setLatestMaterialList] = useState([] as any[])
  const [selectedCategoryId, setSelectedCategoryId] = useState(null as null | number)

  useEffect(() => {
    fetchMaterialTypeList()
    fetchMaterialCategories()
    fetchMaterials()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchMaterialCategories = async () => {
    const response = await fetch(`/api/material-categories/`)
    if (response.ok) {
      const data = await response.json()
      setMaterialCategoryList(data)
    }
  }

  const fetchMaterialTypeList = async () => {
    const response = await fetch(`/api/material-types/`)
    if (response.ok) {
      const data = await response.json()
      setMaterialTypeList(data)
    }
  }

  useEffect(() => {
    fetchMaterials()
    fetchLatestMaterials()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryId])

  const fetchMaterials = async () => {
    const url = `/api/materials/`
    let params: any = {
      sticky: false
    }
    if (selectedCategoryId) {
      params['category_id'] = selectedCategoryId
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setMaterialList(data)
    }
  }

  const fetchLatestMaterials = async () => {
    const url = `/api/materials/`
    let params: any = {
      limit: 3,
      sticky: true
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setLatestMaterialList(data)
    }
  }

  const getCards = () => {
    let i = 0
    let result = []
    while (i < materialList.length) {
      let current = materialList[i]
      if (current.materialTypeName === 'Исследование') {
        result.push(
          <MaterialCard key={i} {...current} showImage />
        )
        i++
        continue
      }

      let next = i < materialList.length ? materialList[i + 1] : null
      if (next && next.materialTypeName !== 'Исследование') {
        result.push(
          <Grid columnGap={20} rowGap={20} cols={2} tabletCols={1} key={i}>
            <MaterialCard {...current} />
            <MaterialCard {...next} />
          </Grid>
        )
        i = i + 2
        continue
      }
      result.push(
        <Grid columnGap={20} rowGap={20} cols={2} tabletCols={1}>
          <MaterialCard key={i} {...current} />
          <div />
        </Grid>
      )
      i = i + 1
      continue
    }
    return result
  }

  return (
    <div>
      <HeaderSection paddingBottom={0}></HeaderSection>
      <PageSection bgColor='light-gray' style={{ paddingTop: '40px', alignItems: 'center' }}>
        <Grid cols={3} columnGap={20} rowGap={20} phoneCols={1}>
          <Stack gap={20}>
            <LogoCard />
            {latestMaterialList.slice(0, 1).map((e: MaterialData) => {
              return <ColorMaterialCard key={e.id} {...e} gradientColor='gray' size='small' />
            })}
          </Stack>
          {latestMaterialList.slice(1, 3).map((e: MaterialData, index: number) => {
            return <ColorMaterialCard key={e.id} {...e} gradientColor={index === 0 ? 'green' : 'blue'} size='large' />
          })}
        </Grid>

        <Grid columnGap={20} rowGap={20} cols={4} phoneCols={1}>
          {materialTypeList.map((item: MaterialTypeData) => {
            return <MaterialTypeCard key={item.id} url={`/sum-of-tech/materials-by-type/${item.id}`} {...item} />
          })}
          <MaterialTypeCard id={0} name='База знаний' url='https://kb.cdto.center' />
        </Grid>

        <Stack
          orientation='horizontal'
          justifyContent={'flex-start'}
          wrap='wrap'
          gap={20}
        >
          <SwitchButton
            pressed={selectedCategoryId === null}
            onClick={() => setSelectedCategoryId(null)}
          >
            Все тексты
          </SwitchButton>
          {materialCategoryList.map((item: any) => {
            return (<SwitchButton
              key={item.id}
              pressed={selectedCategoryId === item.id}
              onClick={() => setSelectedCategoryId(item.id)}
            >
              {item.name}
            </SwitchButton>)
          })}
        </Stack>

        <Stack gap={20} width='100%'>
          {getCards()}
        </Stack>

      </PageSection>
      <FooterSection />
    </div>
  )
}

export default SumOfTechPage
