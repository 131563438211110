import React from 'react'

import PageSection from 'components/v2/PageSection/PageSection'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'

import Card from 'components/Card/Card'
import Image from 'components/Image/Image'
import Text from 'components/v2/Text/Text'
import IconView from 'components/v2/IconView/IconView'

const CourseContentSection = () => {
  const items = [
    {
      name: 'Интерактивные лонгриды и видеолекции',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-1.png'
    },
    {
      name: 'Тесты для самопроверки',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-2.png'
    },
    {
      name: 'Актуальная подборка нормативно-правовых актов',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-6.png'
    },
    {
      name: 'Дополнительная литература',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-5.png'
    },
    {
      name: 'Практические задания',
      imageUrl: '/static/img/pages/program-registry/page-sections/course-content-section/image-4.png'
    }
  ]

  return (
    <PageSection variant='white-icons'>
      <Stack gap={55}>
        <Heading variant='2'>Что будет в курсе</Heading>
        <Stack gap={30} orientation='horizontal' tabletOrientation='vertical'>
          <IconView
            cols={3}
            tabletCols={1}
            columnGap={20}
            rowGap={20}
          >
            {items.map((item: any, index: number) => {
              return <Card
                key={index}
                bgColor='light-gray'
                animate
                animationVariant='zoom-in'
                animateDelayIndex={index}
              >
                <Stack gap={10} alignItems='center'>
                  <Image src={item.imageUrl} />
                  <Text variant='text-m-medium' align='center'>{item.name}</Text>
                </Stack>
              </Card>
            })}
          </IconView>
          <Stack gap={60}>
            <Stack alignItems='flex-end'>
              <Image
                src='/static/img/pages/program-registry/state-administration/course-content-section/stars.svg'
                width={100}
              />
            </Stack>
            <Image
              src='/static/img/pages/program-registry/state-administration/course-content-section/section-image.svg'
              width='100%'
              animate
              animationVariant='appear-up'
            />
          </Stack>
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default CourseContentSection
