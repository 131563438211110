import Button from 'components/Button/Button'
import Stack from 'components/Stack/Stack'
import Text from 'components/v2/Text/Text'
import React from 'react'

type Step1Props = {
  onNext: () => void;
  payerType: 'individual' | 'legal';
  onChangePayerType?: (payerType: 'individual' | 'legal') => void;
}

const Step1 = (props: Step1Props) => {
  const onNext = () => {
    props.onNext?.()
  }
  return (
    <Stack gap={40}>
      <Stack gap={8}>
        <Stack orientation='horizontal' gap={8}>
          <input
            type="radio"
            id="payerTypeIndividual"
            name="payerType"
            value="individual"
            checked={props.payerType === 'individual'}
            onChange={() => props.onChangePayerType?.('individual')}
          />
          <label htmlFor="payerTypeIndividual">Я оплачу, как физическое лицо</label>
        </Stack>

        <Stack orientation='horizontal' gap={8}>
          <input
            type="radio"
            id="payerTypeLegal"
            name="payerType"
            value="legal"
            checked={props.payerType === 'legal'}
            onChange={() => props.onChangePayerType?.('legal')}
          />
          <label htmlFor="payerTypeLegal">Я оплачу, как юридическое лицо</label>

        </Stack>
        <div style={{paddingLeft: '30px', maxWidth: '256px'}}>
          <Text variant='xs'>Для уточнения деталей оплаты с вами свяжется наш менеджер</Text>
        </div>

      </Stack>

      <div>
        <Button
          variant='filled'
          onClick={() => onNext()}
        >
          Дальше
        </Button>
      </div>
    </Stack>
  )
}

export default Step1
