import useSectionsData, { getDataByKey } from 'common/sections-data'
import QuestionSection from 'page-sections/QuestionSection/QuestionSection'
import FooterSection from '../../page-sections/FooterSection/FooterSection'
import HeaderSection from '../../page-sections/HeaderSection/HeaderSection'
import AdvertisingSection from './AdvertisingSection/AdvertisingSection'
import EventsSection from './EventsSection/EventsSection'
import HeaderBlock from './HeaderBlock/HeaderBlock'
import NewsSection from './NewsSection/NewsSection'
import SubscribeSection from './SubscribeSection/SubscribeSection'

const Commnunity = () => {
  const keys = 'community-header,community-advertising,community-subscribe,community-events'
  const sectionList = useSectionsData(keys)

  return (
    <div>
      <HeaderSection>
        <HeaderBlock
          imageUrl='/static/img/pages/community/header-image.png'
          {...getDataByKey(sectionList, 'community-header')}
        />
      </HeaderSection>

      <AdvertisingSection {...getDataByKey(sectionList, 'community-advertising')} />

      <SubscribeSection {...getDataByKey(sectionList, 'community-subscribe')} bgColor='light-gray' />

      <EventsSection {...getDataByKey(sectionList, 'community-events')} />

      {false && <NewsSection />}

      <QuestionSection id='question-section' bgColor='light-gray' />

      <FooterSection id='footer-section' />
    </div>
  )
}

export default Commnunity
