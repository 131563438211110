import nbspacer from 'common/nbspacer'
import scrollToId from 'common/scroll-to-id'
import Button from 'components/Button/Button'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import React from 'react'

type SubjectType = '' | 'individual' | 'legal'

type SubjectTypeSelectProps = {
  onSelect: (subjectType: SubjectType) => void;
}

const SubjectTypeSelect = (props: SubjectTypeSelectProps) => {
  return (
    <Grid
      cols={2}
      tabletCols={1}
      columnGap={60}
      rowGap={40}
      alignItems='center'
    >
      {/* left */}
      <Stack gap={40}>
        <Heading variant='1'>Остались вопросы?</Heading>
        <span>
          <Text variant='text-xl'>{nbspacer('Возможно, мы уже ответили на ваш вопрос')}</Text>
          <a onClick={() => scrollToId('faq-section')}>
            <Text variant='text-xl' color='emerald' style={{textDecoration: 'underline', cursor: 'pointer'}}>здесь</Text>
          </a>
        </span>
        <Stack
          orientation='horizontal'
          gap={40}
        >
          <Button
            onClick={() => { props.onSelect?.('individual') }}
            variant='outlined'
          >
            Физическое лицо
          </Button>
          <Button
            onClick={() => { props.onSelect?.('legal') }}
            variant='filled'
          >
            Юридическое лицо
          </Button>
        </Stack>
      </Stack>

      {/* right */}
      <Stack alignItems='flex-end' tabletAlignItems='flex-start'>
        <Image src='/static/img/pages/program-registry/page-sections/question-section/section-image.svg' />
      </Stack>
    </Grid>
  )
}

export default SubjectTypeSelect
