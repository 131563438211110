import PageSection from 'components/v2/PageSection/PageSection'

import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import IconView from 'components/v2/IconView/IconView'
import CourseAboutCard from 'components/v2/CourseAboutCard/CourseAboutCard'

const AboutCourseSection = () => {
  return (
    <PageSection variant='gray'>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={20}
        rowGap={20}
      >
        {/* left */}
        <Stack gap={40}>
          <Heading variant='2'>О курсе</Heading>
          <Text variant='text-m'>ИТ-инфраструктура — это фундамент, который не просто влияет, а определяет операционную деятельность всех подразделений организации. В курсе поговорим о том, как выстроить ИТ-ландшафт организации, как управлять им, обсудим принципы построения ИТ-инфраструктуры и разберем главный критерий ее эффективности.</Text>
          <Stack alignItems='center'>
            <Image
              src='/static/img/pages/program-registry/it-managment-practice/about-course-section/section-image.svg'
              width='75%'
            />
          </Stack>
        </Stack>
        {/* right */}
        <IconView cols={2} tabletCols={1} columnGap={20} rowGap={20}>
          <CourseAboutCard image='horn' name='Как?' description='Онлайн' />
          <CourseAboutCard image='clock' name='Как долго?' description='40 часов, 6 недель' />
          <CourseAboutCard image='checkbox' name='Когда?' url='/program-registry/timetable' description='Ближайшая дата начала обучения' />
        </IconView>
      </Grid>
    </PageSection>
  )
}

export default AboutCourseSection
