import React from 'react'
import styles from './PageSection.module.css'
import Color from 'common/Color.d'
import colors from 'common/colors'

type PageSectionProps = {
  id?: number | string;
  bgColor?: Color;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const PageSection = (props: PageSectionProps) => {
  let bgColor: string = props.bgColor ? props.bgColor : 'white'

  let _className: string = styles['page-section']
  _className = `${_className} ${styles['page-section--color-' + bgColor]}`
  _className = props.className ? `${_className} ${props.className}` : _className

  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors[bgColor as Color]
  }

  const containerStyle: React.CSSProperties = {
    width: '100%',
    maxWidth: '1300px',
    display: 'flex',
    flexDirection: 'column',
    gap: '60px',
    textAlign: 'left',
    alignItems: 'flex-start'
  }

  const elProps: any = {
    id: props.id,
    className: _className,
    style: {...style, ...props.style}
  }

  return (
    <section {...elProps}>
      <div style={containerStyle}>
        {props.children}
      </div>
    </section>
  )
}

export default PageSection
