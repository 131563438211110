import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import scrollToId from 'common/scroll-to-id'
import Grid from 'components/Grid/Grid'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import React from 'react'

import { subscribeItems } from './data'
import SubscribeCard from './SubscribeCard/SubscribeCard'

type SubscribeSectionProps = {
  bgColor?: Color;
  heading?: string;
}

const SubscribeSection = (props: SubscribeSectionProps) => {
  return (
    <PageSection bgColor={props.bgColor}>
      <SectionHeading>{nbspacer(props.heading ?? '')}</SectionHeading>
      <Grid rowGap={20} columnGap={20} cols={2} tabletCols={1}>
        <SubscribeCard {...subscribeItems[0]} onMouseDown={() => scrollToId('footer-section')} />
        <SubscribeCard {...subscribeItems[1]} />
      </Grid>
    </PageSection>
  )
}

export default SubscribeSection
