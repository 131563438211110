import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'

const WillHelpSection = () => {
  type Item = { iconUrl: string; text: string; }
  const items: Array<Item> = [
    {
      iconUrl: '/static/img/pages/recruiting/digital-transformation-specialists/will-help-section/image1.svg',
      text: 'У вас отсутствует подразделение по подбору ИТ-специалистов'
    },
    {
      iconUrl: '/static/img/pages/recruiting/digital-transformation-specialists/will-help-section/image2.svg',
      text: 'Вы ищете надежного партнера, способного решать любые кадровые задачи «под ключ»'
    },
    {
      iconUrl: '/static/img/pages/recruiting/digital-transformation-specialists/will-help-section/image3.svg',
      text: 'У вас планируете массовый набор специалистов, но внутренние ресурсы ограничены'
    },
    {
      iconUrl: '/static/img/pages/recruiting/digital-transformation-specialists/will-help-section/image4.svg',
      text: 'Вы хотите сосредоточится на приоритетных задачах и не отвлекаться на формирование команды'
    }
  ]

  return (
    <PageSection variant='gray'>
      <Stack gap={40}>
        <Heading variant='2'>Мы поможем в следующих случаях:</Heading>
        <Grid
          cols={4}
          tabletCols={1}
          rowGap={20}
          columnGap={20}
        >
          {items.map((item: Item, index: number) => {
            return <Card key={index}>
              <Stack
                gap={20}
                alignItems='center'
              >
                <div
                  style={{
                    display: 'flex',
                    height: '100px',
                    justifyContent: 'center'
                  }}
                >
                  <Image
                    width='100px'
                    src={item.iconUrl}
                  />
                </div>
                <Heading
                  align='center'
                  variant='3'
                >
                  {item.text}
                </Heading>
              </Stack>
            </Card>
          })}
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default WillHelpSection
