import getCookie from 'common/get-coockie'
import { Dropdown, DropdownOption } from 'components/Dropdown/Dropdown'
import Modal from 'components/Modal/Modal'
import React, { useEffect, useState } from 'react'
import Button from 'components/Button/Button'
import Checkbox from 'components/Checkbox/Checkbox'
import Stack from 'components/Stack/Stack'
import TextField from 'components/TextField/TextField'
import Text from 'components/v2/Text/Text'

import styles from './QuestionForm.module.css'
import Textarea from 'components/Textarea/Textarea'
import Link from 'components/Link/Link'

type QuestionFormProps = {
  defaultPurposeOfRequest?: string;
  hideDescription?: boolean;
}

const QuestionForm = (props: QuestionFormProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [allowPd, setAllowPd] = React.useState(false)
  const [purposeOfRequest, setPurposeOfRequest] = React.useState('')
  const [invalidPurpose, setInvalidPurpose] = React.useState(false)

  useEffect(() => {
    setPurposeOfRequest(props.defaultPurposeOfRequest ?? '')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (purposeOfRequest === '') {
      setInvalidPurpose(true)
      return
    }

    const target = e.target as typeof e.target & {
      name: { value: string }
      phone: { value: string }
      email: { value: string }
      comment: { value: string }
      allow_pd: { value: boolean }
    }
    const name = target.name.value
    const phone = target.phone.value
    const email = target.email.value
    const comment = target.comment.value
    const purpose = purposeOfRequest
    const allowPd = target.allow_pd.checked

    const body = {
      name: name,
      phone: phone,
      email: email,
      comment: comment,
      purpose: purpose,
      allow_pd: allowPd
    }

    const csrftoken = getCookie('csrftoken')

    const url = `/api/appeals`
    let params: any = {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, params)
    if (response.ok) {
      setIsModalVisible(true)
      setAllowPd(false)
      setPurposeOfRequest('')
      e.target.reset()
    }
  }

  const handleClick = (e: React.SyntheticEvent) => {
  }

  const handleCheckboxChange = (e: any) => {
    setAllowPd(e.target.checked)
  }

  const handleDropdownChange = (e: any) => {
    setPurposeOfRequest(e)
    setInvalidPurpose(false)
  }

  const options: DropdownOption[] = [
    { key: 'training_according_to_the_programs', text: 'Обучение по программам Центра' },
    { key: 'recruiting', text: 'Рекрутинговый центр' },
    { key: 'management_and_hr_consulting', text: 'Управленческий и HR-консалтинг' },
    { key: 'staff_training', text: 'Обучение персонала' },
    { key: 'expert_support', text: 'Экспертное сопровождение' },
    { key: 'ai_maturity', text: 'ИИ-зрелость ФОИВ' },
    { key: 'methodological_center', text: 'Методологический центр' },
    { key: 'general_issues', text: 'Общие вопросы' },
    { key: 'competency_model', text: 'Модель компетенций 3.0 ' }
  ]

  const checkboxLabel: any = <span>
    <span className={styles.PdText}>Даю согласие на обработку</span>
    <a
      target='_blank'
      rel='noreferrer'
      className={styles.Link}
      href="https://cdto.ranepa.ru/media/consent_to_the_processing_of_personal_data.pdf"
    >
      персональных данных
    </a>
  </span>

  return (
    <div>
      <Modal
        visible={isModalVisible} onClose={() => setIsModalVisible(false)}
      >
        В ближайшее время с Вами свяжется наш<br /> специалист.<br />
        <a href="mailto: support-cdto@ranepa.ru">support-cdto@ranepa.ru</a><br />
        <br />
        С уважением,<br />
        Центр подготовки руководителей и команд<br />
        цифровой трансформации ВШГУ РАНХиГС
      </Modal>
      <form className={styles.Form} onSubmit={handleSubmit}>
        <Stack gap={40}>
          <TextField
            name='name'
            blockElement
            fullWidth
            autoComplete='off'
            placeholder='Ф. И. О. '
            required
          />
          <TextField
            name='phone'
            type='phone'
            blockElement
            fullWidth
            required
            autoComplete='off'
            placeholder='Телефон'
          />
          <TextField
            name='email'
            type='email'
            blockElement
            fullWidth
            required
            autoComplete='off'
            placeholder='E-mail'
          />
          {!props.defaultPurposeOfRequest && <Dropdown
            value={purposeOfRequest}
            options={options}
            placeholder='Цель обращения'
            onChange={handleDropdownChange}
          />}
          <Textarea
            name='comment'
            placeholder='Текст обращения'
            rows={5}
          />
          {invalidPurpose && <Text variant='text-s' color='burgundy'>Обязательное поле</Text>}
          <div>
            <Checkbox
              name='allow_pd'
              onChange={handleCheckboxChange}
              label={checkboxLabel}
              checked={allowPd}
            />
          </div><div>
            <Link
              to='https://disk.yandex.ru/i/mRz0zVJIdkThlA'>
              <Text style={{ textDecoration: 'underline' }} variant='text-s' color='emerald'>Политика РАНХиГС в отношении обработки персональных данных</Text>
            </Link>
          </div>
          <div>
            <Button
              variant='filled'
              onClick={handleClick}
              disabled={!allowPd}
              type='submit'
            >
              Отправить
            </Button>
          </div>
        </Stack>
      </form>
    </div>
  )
}

export default QuestionForm
