import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import { timetable } from './data'
import React from 'react'
import Link from 'components/Link/Link'

const TimetableSection = () => {
  const [carouselOffset, setCarouselOffset] = React.useState(0)

  const carouselItemCount = 21
  const carouselVisibleItemCount = 5
  const firstStreamOffset = 9

  const getTableStreamHeaders = () => {
    const headers = []
    for (let i = carouselOffset; i < carouselVisibleItemCount + carouselOffset; i++) {
      headers.push(
        <Card key={i} bgColor='emerald'><Text variant='text-m-medium' color='white'>Поток {i + firstStreamOffset}</Text></Card>
      )
    }
    return headers
  }

  const onClickLeftArrow = () => {
    if (carouselOffset === 0) return
    setCarouselOffset(carouselOffset - 1)
  }
  const onClickRightArrow = () => {
    if (carouselOffset === carouselItemCount - carouselVisibleItemCount) return
    setCarouselOffset(carouselOffset + 1)
  }

  const getStreamType = (streams: Array<any>, stream: any) => {
    if (!stream) return null
    if (!stream.from) return null
    let parts = stream.from.split('.')
    let date = (new Date(`${parts[2]}-${parts[1]}-${parts[0]}`)).getTime()
    let today = (new Date()).getTime()
    if (date < today) return 'passed'

    const steamIndex = streams.indexOf(stream)
    if (steamIndex === 0) return 'current'
    const prevParts = streams[steamIndex - 1].from.split('.')
    const prevDate = (new Date(`${prevParts[2]}-${prevParts[1]}-${prevParts[0]}`)).getTime()
    if (prevDate > today) return 'future'
    return 'current'
  }

  const openRegistration = (streamType: any) => {
    if (streamType === 'current') {
      const url = 'https://my-cdto.gspm.ranepa.ru/registration/commerce'
      window.open(url, '_blank', 'noreferrer')
    }
  }

  return (
    <PageSection variant='white-icons'>
      <div style={{
        display: 'grid',
        gap: '4px',
        gridTemplateColumns: '30% 1fr 35px 1fr 1fr 1fr 1fr 1fr 35px'
      }}>
        <Card bgColor='emerald'><Text variant='text-m-medium' color='white'>Название программ</Text></Card>
        <Card bgColor='emerald'><Text variant='text-m-medium' color='white'>Стоимость</Text></Card>
        <Grid alignItems='center'>
          <Image width={35}
            onClick={onClickLeftArrow}
            style={{
              cursor: carouselOffset === 0 ? 'default' : 'pointer'
            }}
            src={carouselOffset === 0 ? '/static/img/pages/program-registry/timetable/timetable-section/arrow-disabled.svg' : '/static/img/pages/program-registry/timetable/timetable-section/arrow-active.svg'}
          />
        </Grid>
        {getTableStreamHeaders()}
        <Grid alignItems='center'>
          <Image width={35}
            onClick={onClickRightArrow}
            style={{ transform: 'rotate(180deg)',
              cursor: carouselOffset === carouselItemCount - carouselVisibleItemCount ? 'default' : 'pointer'
            }}
            src={carouselOffset === carouselItemCount - carouselVisibleItemCount ? '/static/img/pages/program-registry/timetable/timetable-section/arrow-disabled.svg' : '/static/img/pages/program-registry/timetable/timetable-section/arrow-active.svg'}
          />
        </Grid>

        {timetable.map((item: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <Card style={{display: 'flex', alignItems: 'center'}}>
                <Link to={item.url}>
                  <Text
                    variant='text-m-medium'
                    color='black'
                    style={{textDecoration: 'underline', cursor: 'pointer', color: '#407BFF'}}
                  >{item.name}</Text>
                </Link>
              </Card>
              <Card style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFECA8'}}><Text variant='text-m-medium' color='black' style={{whiteSpace: 'nowrap'}}>{item.cost}</Text></Card>
              <div />
              {Array.from({length: carouselVisibleItemCount}, (_, i) => i + carouselOffset).map((count: any, index: number) => {
                if (item.streams[count] !== null) {
                  return (
                    <Card
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderColor: '#CEE1EC',
                        backgroundColor: getStreamType(item.streams, item.streams[count]) === 'current' ? 'rgba(105, 157, 154, 0.4)' : 'white',
                        cursor: getStreamType(item.streams, item.streams[count]) === 'current' ? 'pointer' : 'default'

                      }}
                      onClick={() => openRegistration(getStreamType(item.streams, item.streams[count]))}
                    >
                      <Text
                        variant='text-m-medium'
                        color={getStreamType(item.streams, item.streams[count]) === 'passed' ? 'gray' : 'black'}
                      >{item.streams[count]?.from}&nbsp;{item.streams[count]?.to && '-'} {item.streams[count]?.to}</Text>
                      {item?.streams[count]?.byRequest && <Text>по запросу</Text>}
                    </Card>
                  )
                }
                return (<div key={index}></div>)
              })}

              <div />
            </React.Fragment>
          )
        })}
      </div>
    </PageSection>
  )
}

export default TimetableSection
