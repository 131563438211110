import Heading from 'components/Heading/Heading'
import PageSection from 'components/PageSection/PageSection'
import Stack from 'components/Stack/Stack'
import RegisterCard from '../RegisterCard/RegisterCard'

import { precedureTitle, procedureItems } from '../data'
import { NumberListCard, NumberListCardProps } from 'components/NumberListCard/NumberListCard'
import Grid from 'components/Grid/Grid'

const ProcedureSection = () => {
  return (
    <PageSection bgColor='light-gray'>
      <Stack gap={60}>
        <Heading variant='1'>{precedureTitle}</Heading>
        <Grid cols={2} columnGap={20} tabletCols={1}>
          <Stack gap={20}>
            {procedureItems.slice(0, 4).map((item: NumberListCardProps) => {
              return <NumberListCard bgColor='white' variant='card' key={item.id} {...item} />
            })}
          </Stack>
          <Stack gap={20}>
            {procedureItems.slice(-1).map((item: NumberListCardProps) => {
              return <NumberListCard bgColor='white' variant='card' key={item.id} {...item} />
            })}
            <RegisterCard />
          </Stack>
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default ProcedureSection
