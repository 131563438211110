import AboutSection from './AboutSection/AboutSection'
import CareerSection from './Sections/CareerSection'
import CommercialProjectsSection from './CommercialProjectsSection/CommercialProjectsSection'
import EducationSection from './EducationSection/EducationSection'
import ExpertMaterialsSection from './Sections/ExpertMaterialsSection'
import FooterSection from 'page-sections/FooterSection/FooterSection'
import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import QuestionSection from 'page-sections/QuestionSection/QuestionSection'
import HeaderBlock from './HeaderBlock/HeaderBlock'
import useSectionsData, { getDataByKey } from 'common/sections-data'
import AISection from './Sections/AISection'

const Home = () => {
  const keys = 'home-header,home-about,home-education,home-career,home-ai,home-commercial-projects,home-expert-materials'
  const itemList = useSectionsData(keys) || []

  return (
    <div>
      <HeaderSection>
        <HeaderBlock {...getDataByKey(itemList, 'home-header')} />
      </HeaderSection>

      <AboutSection {...getDataByKey(itemList, 'home-about')} />
      {false && <EducationSection {...getDataByKey(itemList, 'home-education')} />}
      <CareerSection {...getDataByKey(itemList, 'home-career')} bgColor='light-gray' />
      <AISection {...getDataByKey(itemList, 'home-ai')} id='home-ai' />
      <ExpertMaterialsSection {...getDataByKey(itemList, 'home-expert-materials')} bgColor='light-gray' />
      <CommercialProjectsSection {...getDataByKey(itemList, 'home-commercial-projects')} />
      <QuestionSection id='question-section' bgColor='light-gray' />

      <FooterSection />
    </div>
  )
}

export default Home
