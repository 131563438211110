import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Stack from 'components/Stack/Stack'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import { ProgramCardProps } from '../ProgramCard/ProgramCard'

const ArchivalProgramCard = (props: ProgramCardProps) => {
  return (
    <Card>
      <Stack gap={20}>
        <div>
          <TextWithDot dotColor='burgundy' dotVariant='rounded'>Архивная программа</TextWithDot>
        </div>
        <Heading variant='3'>{props.name}</Heading>
      </Stack>
    </Card>
  )
}

export default ArchivalProgramCard
