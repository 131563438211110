const header: any = {
  heading: 'Экспертное сопровождение',
  imageUrl: '/static/img/pages/expert-support/header-image.png',
  text: <>
    <p>Высококачественные и быстрые результаты. Передовые практики. Высокий уровень экспертности.</p>
    <p>Мы команда экспертов с многолетним опытом в подготовке аналитических и методических материалов различных форматов - от статьи до доклада.</p>
  </>,
  buttonText: 'Заказать проект'
}

export { header }
