import MenuDropdown from '../MenuDropdown/MenuDropdown'
import MenuLink from '../MenuLink/MenuLink'

type MenuItemProps = {
  id: number;
  name: string;
  url?: string;
  childrenItems: any[];
}

const MenuItem = ({name, url, childrenItems}: MenuItemProps) => {
  return (
    <>
      {url && childrenItems.length === 0 && <MenuLink url={url} name={name} />}
      {childrenItems.length > 0 && <MenuDropdown items={childrenItems} name={name} />}
    </>
  )
}

export default MenuItem
