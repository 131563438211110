import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import Grid from 'components/Grid/Grid'
import { NumberListCard, NumberListCardProps } from 'components/NumberListCard/NumberListCard'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'

type SolveProblemsSectionProps = {
  bgColor?: Color;
  heading?: string;
  bullets?: NumberListCardProps[];
}

const SolveProblemsSection = (props: SolveProblemsSectionProps) => {
  return (
    <PageSection bgColor={props.bgColor}>
      <Stack gap={60}>
        <SectionHeading>{nbspacer(props.heading ?? '')}</SectionHeading>
        <Grid
          cols={2}
          tabletCols={1}
          phoneCols={1}
          rowGap={60}
          columnGap={20}
        >
          {props.bullets?.map((item: any, index: number) => {
            return <NumberListCard key={item.id} number={index + 1} {...item} />
          })}
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default SolveProblemsSection
