import Heading from 'components/v2/Heading/Heading'
import Text, { TextVariant } from 'components/v2/Text/Text'
import Stack from 'components/Stack/Stack'
import React from 'react'

type NumberedListItemProps = {
  number: number;
  name?: string;
  description: string;
  descriptionTextVariant?: TextVariant;
}

const NumberedListItem = (props: NumberedListItemProps) => {
  return (
    <Stack
      orientation='horizontal'
      alignItems='center'
      gap={35}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '55px',
          height: '55px',
          borderRadius: '50%',
          flexShrink: 0,
          background: 'linear-gradient(to right, #047A69, #449791)'
        }}
      >
        <Heading
          variant='3'
          color='white'
        >
          {props.number}
        </Heading>
      </div>
      <Stack gap={8}>
        {props.name && <Heading variant='3'>{props.name}</Heading>}
        <Text
          variant={props.descriptionTextVariant ?? 'text-s'}
        >
          {props.description}
        </Text>
      </Stack>
    </Stack>
  )
}

export default NumberedListItem
