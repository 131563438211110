import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import React from 'react'
import Text from 'components/v2/Text/Text'
import Card from 'components/Card/Card'
import Link from 'components/Link/Link'
import nbspacer from 'common/nbspacer'

type ExpertKey = 'alferov' | 'boganov' | 'garkusha' | 'ozharovskiy' | 'popkova' | 'shubin' | 'mirzaev' | 'podiymov' | 'korotkih' | 'gontar' | 'danko' | 'nikitchenko'

type ExpertCardProps = {
  expert: ExpertKey
}

const ExpertCard = (props: ExpertCardProps) => {
  const experts: any = {
    alferov: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/alferov.png',
      name: 'Алферов Павел Александрович',
      position: 'Профессор бизнес-практики Московской школы управления СКОЛКОВО'
    },
    boganov: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/boganov.png',
      name: 'Боганов Антон Владимирович',
      position: 'Директор по стратегическому развитию IFS, председатель itSMF России, руководитель группы проектного трека Центра подготовки руководителей и команд ЦТ'
    },
    garkusha: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/garkusha.png',
      name: 'Гаркуша Наталья Сергеевна',
      position: 'Директор Центра подготовки руководителей и команд цифровой трансформации, д. п. н., сертифицированный руководитель по управлению сложными проектами',
      nameUrl: 'https://natalya-garkusha.ru'
    },
    ozharovskiy: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/ozharovskiy.png',
      name: 'Ожаровский Александр Вячеславович',
      position: 'Директор проектов СберУниверситета'
    },
    popkova: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/popkova.png',
      name: 'Попкова Юлия Халиловна',
      position: 'Заместитель директора Департамента финансового аудита Счетной палаты Российской Федерации'
    },
    shubin: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/shubin.png',
      name: 'Шубин Александр Сергеевич',
      position: 'Заместитель директора Центра подготовки руководителей и команд цифровой трансформации ВШГУ РАНХиГС'
    },
    mirzaev: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/mirzaev.png',
      name: 'Тимур Мирзаев',
      position: 'Практикующий консультант по направлению ИТ-консалтинга, директор по развитию бизнеса ДКЗ Rubytech'
    },
    podiymov: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/podiymov.jpg',
      name: 'Подымов Петр Владимирович',
      position: 'Советник генерального директора Ингосстрах'
    },
    korotkih: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/korotkih.png',
      name: 'Коротких Сергей Сергеевич',
      position: 'Советник руководителей по вопросам стратегического управления и развития корпоративной культуры, сертифицированный коуч ICF и старший преподаватель ВШЭ'
    },
    gontar: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/gontar.jpg',
      name: 'Людмила Гонтарь',
      position: 'Руководитель Центра компетенций ФРЦЭ, член отраслевых групп федерального и регионального уровней'
    },
    danko: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/danko.png',
      name: 'Филипп Данько',
      position: 'Генеральный директор компании O2Consulting'
    },
    nikitchenko: {
      photoUrl: '/static/img/pages/program-registry/components/expert-card/nikitchenko.png',
      name: 'Анна Никитченко',
      position: 'Вице-президент АНО «Национальный институт системных исследований проблем предпринимательства», Управляющий партнер компании O2Consulting'
    }
  }

  return (
    <div style={{ padding: '10px', display: 'flex', width: '100%' }}>
      <Card bgColor='light-gray' width='100%' style={{ display: 'flex', alignItems: 'center' }}>
        <Stack
          orientation='horizontal'
          alignItems='center'
          gap={20}
        >
          <Image src={experts[props.expert]?.photoUrl} width={100} objectFit='cover' style={{ flexShrink: 0, borderRadius: '10px' }} />
          <Stack gap={8}>
            {!experts[props.expert]?.nameUrl && <Text variant='text-m-medium'>{experts[props.expert]?.name}</Text>}
            {experts[props.expert]?.nameUrl && <Link
              to={experts[props.expert]?.nameUrl ?? ''}
            >
              <Text variant='text-m-medium' color='green' style={{ textDecoration: 'underline' }}>{experts[props.expert]?.name ?? ''}</Text>
            </Link>}
            <Text variant='text-m-medium' color='dark-gray'>
              <div dangerouslySetInnerHTML={{ __html: nbspacer(experts[props.expert]?.position) }} />
            </Text>
          </Stack>
        </Stack>
      </Card>
    </div>
  )
}

export default ExpertCard
export type { ExpertKey }
