import colors from 'common/colors'
import React from 'react'

type SwitchButtonProps = {
  children?: React.ReactNode;
  pressed?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SwitchButton = (props: SwitchButtonProps) => {
  const buttonStyle: React.CSSProperties = {
    backgroundColor: props.pressed ? colors['emerald'] : 'transparent',
    color: props.pressed ? colors['white'] : colors['emerald'],
    border: props.pressed ? `1px solid transparent` : `1px solid ${colors['emerald']}`,
    outline: 'none',
    padding: '8px 20px',
    borderRadius: '20px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '150%',
    cursor: 'pointer'
  }

  const buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement> = {
    onClick: props.onClick ?? (event => {}),
    style: buttonStyle
  }

  return (
    <button {...buttonProps}>{props.children}</button>
  )
}

export default SwitchButton
