import Card from 'components/Card/Card'
import Stack from 'components/Stack/Stack'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'
import Image from 'components/Image/Image'
import { timetable } from './data'

const TimetableSectionMobile = () => {
  const [carouselOffset, setCarouselOffset] = React.useState(0)
  const carouselItemCount = 21
  const carouselVisibleItemCount = 4

  const onClickLeftArrow = () => {
    if (carouselOffset === 0) return
    setCarouselOffset(carouselOffset - 1)
  }
  const onClickRightArrow = () => {
    if (carouselOffset === carouselItemCount - carouselVisibleItemCount) return
    setCarouselOffset(carouselOffset + 1)
  }

  const getStreamType = (streams: Array<any>, stream: any) => {
    if (!stream) return null
    if (!stream.from) return null
    let parts = stream.from.split('.')
    let date = (new Date(`${parts[2]}-${parts[1]}-${parts[0]}`)).getTime()
    let today = (new Date()).getTime()
    if (date < today) return 'passed'

    const steamIndex = streams.indexOf(stream)
    if (steamIndex === 0) return 'current'
    const prevParts = streams[steamIndex - 1].from.split('.')
    const prevDate = (new Date(`${prevParts[2]}-${prevParts[1]}-${prevParts[0]}`)).getTime()
    if (prevDate > today) return 'future'
    return 'current'
  }

  const getStreamBadgeColor = (streams: Array<any>, stream: any) => {
    const streamType = getStreamType(streams, stream)
    if (streamType === 'passed') return '#B2B2B2'
    if (streamType === 'current') return '#459689'
    if (streamType === 'future') return 'white'
    return '#B2B2B2'
  }

  const openRegistration = (streamType: any) => {
    if (streamType === 'current') {
      const url = 'https://my-cdto.gspm.ranepa.ru/registration/commerce'
      window.open(url, '_blank', 'noreferrer')
    }
  }

  return (
    <PageSection>
      <Stack gap={20}>
        <Text variant='text-l' color='green'>Цены действительны до 1 марта 2023 года</Text>
        {timetable.map((item: any, index: number) => {
          return (
            <Card
              bgColor='light-gray'
              key={index}
            >
              <Stack gap={12}>
                <Stack
                  orientation='horizontal'
                  gap={20}
                  alignItems='center'
                >
                  <div style={{width: '70%'}}>
                    <a
                      href={item.url}
                      style={{textDecoration: 'underline', color: '#407BFF'}}
                    >
                      {item.name}
                    </a>
                  </div>
                  <Text
                    variant='text-m-medium'
                    style={{flexGrow: '1'}}
                    align='right'
                  >
                    {item.cost}
                  </Text>
                </Stack>
                <Stack orientation='horizontal' gap={10} alignItems='center'>
                  <Text variant='text-xxxs-medium' color='green'>Потоки</Text>
                  <div
                    style={{
                      flexGrow: '1',
                      height: '2px',
                      backgroundColor: '#00826F'
                    }}
                  />
                </Stack>

                <div
                  style={{
                    display: 'grid',
                    gap: '22px',
                    alignItems: 'center',
                    gridTemplateColumns: '22px 1fr 1fr 1fr 1fr 22px'
                  }}
                >

                  <Stack alignItems='center'>
                    <Image width={22}
                      onClick={onClickLeftArrow}
                      style={{
                        cursor: carouselOffset === 0 ? 'default' : 'pointer'
                      }}
                      src={carouselOffset === 0 ? '/static/img/pages/program-registry/timetable/timetable-section/arrow-disabled.svg' : '/static/img/pages/program-registry/timetable/timetable-section/arrow-active.svg'}
                    />
                  </Stack>

                  {Array.from({length: carouselVisibleItemCount}, (_, i) => i + carouselOffset).map((count: any, index: number) => {
                    // if (item.streams[count] !== null) {
                    return (
                      <Stack
                        alignItems='center'
                        key={index}
                        gap={4}
                      >
                        <Stack
                          alignItems='center'
                          style={{
                            width: '22px',
                            height: '22px',
                            textAlign: 'center',
                            borderRadius: '50%',
                            background: getStreamBadgeColor(item.streams, item.streams[count])
                          }}
                        >
                          <Text variant='text-s'
                            style={{
                              fontWeight: 'bold',
                              cursor: getStreamType(item.streams, item.streams[count]) === 'current' ? 'pointer' : 'default'
                            }}
                            color={getStreamType(item.streams, item.streams[count]) === 'future' ? 'emerald' : 'white'}
                            onClick={() => openRegistration(getStreamType(item.streams, item.streams[count]))}
                          >
                            {count + 1}
                          </Text>
                        </Stack>
                        <Text variant='text-xxxs'>{item.streams[count]?.from ?? '-'} {item.streams[count]?.to ?? '-'}</Text>
                        {item?.streams[count]?.byRequest && <Text>по запросу</Text>}
                      </Stack>
                    )
                    // }
                    // return (<div key={index}></div>)
                  })}

                  <Stack alignItems='center'>
                    <Image width={22}
                      onClick={onClickRightArrow}
                      style={{ transform: 'rotate(180deg)',
                        cursor: carouselOffset === carouselItemCount - carouselVisibleItemCount ? 'default' : 'pointer'
                      }}
                      src={carouselOffset === carouselItemCount - carouselVisibleItemCount ? '/static/img/pages/program-registry/timetable/timetable-section/arrow-disabled.svg' : '/static/img/pages/program-registry/timetable/timetable-section/arrow-active.svg'}
                    />
                  </Stack>

                </div>
              </Stack>

            </Card>
          )
        })}
      </Stack>
    </PageSection>
  )
}

export default TimetableSectionMobile
