import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import Grid from 'components/Grid/Grid'

import { heading, text } from './data'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Heading from 'components/Heading/Heading'
import colors from 'common/colors'
import React from 'react'

// import {ReactComponent as ArrowIcon} from './Arrow.svg'
import Image from 'components/Image/Image'

type LevelsSectionProps = {
  id?: number | string;
  bgColor?: Color;
}

const LevelsSection = (props: LevelsSectionProps) => {
  // const arrowIconStyle: React.CSSProperties = { position: 'relative', left: '10px', height: '240px'}

  const lvl1Style: React.CSSProperties = {
    padding: '12px 40px',
    backgroundColor: colors['light-gray'],
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px'
  }
  const lvl2Style: React.CSSProperties = {
    padding: '12px 40px',
    width: '100%'
  }
  const lvl3Style: React.CSSProperties = {
    padding: '12px 40px'
  }
  const lvl4Style: React.CSSProperties = {
    padding: '12px 40px'
  }
  return (
    <PageSection {...props}>
      <SectionHeading animate animationVariant='appear-up'>{heading}</SectionHeading>

      <Text maxWidth854>
        <div dangerouslySetInnerHTML={{__html: nbspacer(text ?? '')}} />
      </Text>

      <Stack gap={12} alignItems='center' width={'100%'}>
        <Grid cols={4} tabletCols={1}>
          <div style={lvl1Style}>
            <Stack>
              <TextWithDot dotColor='emerald'><Text variant='tiny' uppercase>Уровень 1</Text></TextWithDot>
              <Heading variant='3'>«Начальный»</Heading>
            </Stack>
          </div>
          <div style={{backgroundColor: '#A9FFE6'}}>
            <Stack orientation='horizontal'>
              <Image src='/static/img/pages/ai-eval/arrow-lvl1.png' height={'76.8px'} />
              <Stack style={lvl2Style}>
                <TextWithDot dotColor='emerald'><Text variant='tiny' uppercase>Уровень 2</Text></TextWithDot>
                <Heading variant='3'>«Базовый»</Heading>
              </Stack>
            </Stack>
          </div>
          <div style={{backgroundColor: '#00CEA6'}}>
            <Stack orientation='horizontal'>
              <Image src='/static/img/pages/ai-eval/arrow-lvl2.png' height={'76.8px'} />
              <Stack style={lvl3Style}>
                <TextWithDot dotColor='emerald'><Text variant='tiny' uppercase>Уровень 3</Text></TextWithDot>
                <Heading variant='3'>«Прогрессивный»</Heading>
              </Stack>
            </Stack>
          </div>
          <div style={{backgroundColor: '#006050', borderTopRightRadius: '4px', borderBottomRightRadius: '4px'}}>
            <Stack orientation='horizontal'>
              <Image src='/static/img/pages/ai-eval/arrow-lvl3.png' height={'76.8px'} />
              <Stack style={lvl4Style}>
                <TextWithDot dotColor='emerald'><Text variant='tiny' uppercase color='white'>Уровень 4</Text></TextWithDot>
                <Heading variant='3' color='white'>«Лидерский»</Heading>
              </Stack>
            </Stack>
          </div>
        </Grid>
        <Text color='dark-gray' align='center'>Шкала уровней ИИ-зрелости ФОИВ</Text>
      </Stack>
    </PageSection>
  )
}

export default LevelsSection
