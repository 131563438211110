import React from 'react'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'
import SubscribeForm from 'page-sections/FooterSection/SubscribeForm/SubscribeForm'
import styles from './ContactsBlock.module.css'
import { socialNetworks } from './data'
import Text from 'components/Text/Text'
import Grid from 'components/Grid/Grid'
import Color from 'common/Color.d'

type ContactsBlockProps = {
  themeWhite?: boolean;
  showSubscribeForm?: boolean;
  headingGap?: number;
}

const ContactsBlock = ({ themeWhite, showSubscribeForm, headingGap }: ContactsBlockProps) => {
  const classNameMixin: string = themeWhite ? ' color-white' : ''
  const textColor: Color = themeWhite ? 'white' : 'black'
  const _headingGap: number = headingGap ?? 60
  return (
    <Stack gap={_headingGap}>
      <SectionHeading textColor={textColor}>Контакты</SectionHeading>
      <Grid cols={2} columnGap={130} tabletCols={1} tabletColumnGap={20}>
        <Stack gap={60}>

          <Stack gap={16}>
            <h3 className={classNameMixin}>Служба поддержки</h3>
            {false && <div className={styles.SupportPhone + classNameMixin}>8 800 222-81-05</div>}
            {false && <div className={styles.SupportPhone + classNameMixin}>+7 495 308 11 84</div>}
            {false && <div className={styles.SupportPhone + classNameMixin}>+7 495 308 11 85</div>}
            <a
              className={styles.Link + classNameMixin}
              href='mailto: zakharova-maa@ranepa.ru'
            >
              zakharova-maa@ranepa.ru
            </a>
          </Stack>

          <Stack gap={6}>
            <h3 className={classNameMixin}>PR-служба Центра</h3>
            <a
              className={styles.Link + classNameMixin}
              href='mailto: selskaya-vv@ranepa.ru'
            >
              selskaya-vv@ranepa.ru
            </a>
            <Text variant='small' color={textColor}>Сельская Варвара</Text>
          </Stack>

          {showSubscribeForm && <SubscribeForm />}

          <Stack orientation='horizontal' alignItems='center' gap={12}>
            <h3 className={classNameMixin}>Соцсети:</h3>
            <a
              className={styles.SocialLink + classNameMixin}
              href={socialNetworks.vk_url}
              target='_blank'
              rel='noreferrer'
            >
              {!themeWhite &&
                <img src="/static/img/icons/vk--emerald.svg" /> ||
                <img src="/static/img/icons/vk--white.svg" />
              }
            </a>
            <a
              className={styles.SocialLink + classNameMixin}
              href={socialNetworks.telegram_url}
              target='_blank'
              rel='noreferrer'
            >
              {!themeWhite &&
                <img src="/static/img/icons/telegram--emerald.svg" /> ||
                <img src="/static/img/icons/telegram--white.svg" />
              }
            </a>
          </Stack>

        </Stack>

        <Stack gap={60}>
          <Stack gap={20}>
            <div>
              <h3 className={classNameMixin}>Офис Центра</h3>
              <Text variant='small' color={textColor}>г. Москва, пр. Вернадского, 84<br /> корп. 3, 21 этаж,</Text>
              <br/>
              <Text variant='small' color={textColor}>офис 2100</Text>
            </div>
          </Stack>

          <Stack gap={20}>
            <h3 className={classNameMixin}>Как добраться</h3>
            <Text color={textColor}>Ст. метро «Юго-Западная»</Text>
            <div>
              <Text variant='small' color={textColor}>Последний вагон из центра, выходы 5, 6. Выходя из метро, поверните на ул. Покрышкина, далее — прямо до КПП № 5 (пр. Вернадского, 84, корп. 3), проходите через КПП № 5 РАНХиГС в 3-й корпус, 21 этаж,</Text>
              <br/>
              <Text variant='small' color={textColor}>офис 2100.</Text>
            </div>
          </Stack>

          <img className={styles.MapImage} src="/static/img/map.jpg" />
        </Stack>
      </Grid>
    </Stack>
  )
}

export default ContactsBlock
