import ArrowPivotItem from 'components/ArrowPivotItem/ArrowPivotItem'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'
import { useState } from 'react'

import { heading, navItems, cardData } from './data'
import ItemsListCard from './ItemsListCard/ItemsListCard'
import OtherAreasCard from './OtherAreasCard/OtherAreasCard'

import styles from './ServicesSection.module.css'

const ServicesSection = () => {
  // eslint-disable-next-line
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <PageSection>
      <SectionHeading>{heading}</SectionHeading>

      <div className={styles.Stack}>
        <Stack gap={16}>
          {navItems.map((item: any, index: number) => {
            return (<ArrowPivotItem
              onClick={() => setSelectedIndex(index)}
              key={item.id}
              text={item.text}
              active={selectedIndex === index}
            />)
          })}
        </Stack>
        <Stack gap={60}>
          <ItemsListCard {...cardData[selectedIndex]} />
          <OtherAreasCard />
        </Stack>

      </div>

    </PageSection>
  )
}

export default ServicesSection
