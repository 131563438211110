import TeamSection from './TeamSection/TeamSection'

type EmployeeData = {
  id: number;
  name: string;
  position: string;
  image_url?: string;
  level: number;
  parent: number | null;
}

type TeamSectionsProps = {
  items: any[]
}

const TeamSections = ({items}: TeamSectionsProps) => {
  return (
    <div>
      {items.filter((employee: EmployeeData) => employee.level === 1).map((employee: EmployeeData, index: number) => {
        return (
          <TeamSection
            index={index}
            key={employee.id}
            employee={employee}
            subordinates={items.filter((subordinate: EmployeeData) => subordinate.parent === employee.id)}
          />
        )
      })}
    </div>
  )
}

export default TeamSections
export type { EmployeeData }
