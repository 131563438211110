import PageSection from 'components/v2/PageSection/PageSection'

import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'

// import Card from 'components/Card/Card'

import { willKnowSection } from './data'
// import useMediaQuery from 'common/useMediaQuery'

const WillKnowSection = () => {
  return (
    <PageSection variant='gradient-green-one-star'>
      <Grid tabletCols={1} cols={2} columnGap={20} rowGap={20}>
        <Stack gap={40}>
          <Heading variant='2' color='white' style={{textTransform: 'uppercase'}}>{willKnowSection.title}</Heading>
          <Stack gap={20}>
            {willKnowSection.items.map((item: any, index: number) => {
              return <Stack key={index} gap={30} orientation='horizontal' alignItems={'center'}>
                <Image
                  src='/static/img/pages/methodological-center/usefulness/checkbox.svg'
                  width={30}
                  animate
                  animationVariant='appear-right'
                  animateDelayIndex={index}
                />
                <Text variant='text-m-medium' color='white'>{item}</Text>
              </Stack>
            })}
          </Stack>
        </Stack>
        <Image src={willKnowSection.imageUrl} width='100%' />
      </Grid>
    </PageSection>
  )
}

export default WillKnowSection
