import React from 'react'
import Image from 'components/Image/Image'
import Stack, { StackProps } from 'components/Stack/Stack'
import Text, { FontWeight, TextProps, TextVariant } from 'components/Text/Text'
import Heading, { HeadingProps, HeadingVariant } from 'components/Heading/Heading'
import nbspacer from 'common/nbspacer'

type PersonaProps = {
  id?: number;
  horizontal?: boolean;
  horizontalAlign?: 'start' | 'center' | 'end';

  imageUrl?: string;
  imageSize?: number;
  imageGap?: number;
  showImage?: boolean;
  showImageBorder?: boolean;

  textGap?: number;

  name: string;
  nameElType?: 'text' | 'heading';
  nameHeadingVariant?: HeadingVariant;
  nameTextVariant?: TextVariant;
  nameFontWeight?: FontWeight;

  position: string;
  positionTextVariant?: TextVariant;
  positionFontWeight?: FontWeight;
  positionTextMaxWidth?: React.CSSProperties['maxWidth'];

  url?: string;
  showLink?: boolean;
}

const Persona = (props: PersonaProps) => {
  const elProps: StackProps = {
    orientation: props.horizontal ? 'horizontal' : 'vertical',
    justifyContent: props.horizontal ? 'start' : 'center',
    alignItems: props.horizontal && props.horizontalAlign ? props.horizontalAlign : 'center',
    gap: props.imageGap
  }

  const imageStyle: React.CSSProperties = {
    height: props.imageSize ?? 80,
    width: props.imageSize ?? 80,
    objectFit: 'cover',
    borderRadius: '50%',
    flexShrink: 0,
    border: props.showImageBorder ? '1px solid white' : 'none',
    display: (props.imageUrl && (props.showImage ?? true)) ? undefined : 'none'
  }

  const imageProps = {
    style: imageStyle,
    src: props.imageUrl ?? ''
  }

  const nameHeadingProps: HeadingProps = {
    // align: props.horizontal ? 'left' : 'center',
    variant: props.nameHeadingVariant ?? '2'
  }
  const nameTextProps: TextProps = {
    align: props.horizontal ? 'left' : 'center',
    variant: props.nameTextVariant ?? 'medium',
    fontWeight: props.nameFontWeight ?? 'normal'
  }

  const positionProps: TextProps = {
    maxWidth: props.positionTextMaxWidth,
    align: props.horizontal ? 'left' : 'center',
    variant: props.positionTextVariant ?? 'medium',
    fontWeight: props.positionFontWeight ?? 'normal'
  }

  const textStackProps: StackProps = {
    gap: props.textGap
  }

  return (
    <Stack {...elProps}>
      <Image {...imageProps} />
      <Stack {...textStackProps}>
        {props.nameElType === 'heading' && <Heading {...nameHeadingProps}>{props.name}</Heading>}
        {props.nameElType !== 'heading' && <Text {...nameTextProps}>{props.name}</Text>}
        <Text {...positionProps}>
          <div dangerouslySetInnerHTML={{__html: nbspacer(props.position ?? '')}} />
        </Text>
        {props.showLink && props.url && <a href={props.url} target='_blank' rel='noreferrer'>Подробнее</a>}
      </Stack>
    </Stack>
  )
}

export default Persona
export type { PersonaProps }
