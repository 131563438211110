import nbspacer from 'common/nbspacer'
import Stack from 'components/Stack/Stack'
import Text from 'components/v2/Text/Text'

const items = [
  {
    name: 'Базовые цифровые компетенции',
    description: nbspacer('Минимально необходимый уровень знаний и навыков использования информационно-коммуникационных технологий (ИКТ) в повседневной и профессиональной деятельности'),
    showHeaders: true,
    col1Name: 'Компетенция',
    col2Name: 'Определение',
    color: 'blue-gray',
    showNumbers: true,
    items: [
      {
        type: 'cols',
        col1: [
          {
            name: 'Цифровая грамотность',
            description: null
          }
        ],
        col2: [
          {
            name: null,
            description: 'Базовая компетенция, включающая умения и навыки применения цифровых технологий для получения, оценки, обработки информации, выбора и безопасного применения программно-технических средств.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Цифровые коммуникации',
            description: null
          }
        ],
        col2: [
          {
            name: null,
            description: 'Базовая компетенция, включающая умения и навыки применения цифровых технологий для создания цифрового контента, коммуникации и обмена информацией в различных контекстах, выбора технологий, сервисов, каналов, средств и форм коммуникации, соблюдение этических стандартов и принципов.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Цифровая безопасность',
            description: null
          }
        ],
        col2: [
          {
            name: null,
            description: 'Базовая компетенция, включающая умения и навыки, необходимые для защиты и обеспечения безопасности информации в цифровой среде, в том числе защита устройств и сетей, обеспечение безопасности паролей, защита конфиденциальной информации, предотвращение кибератак и злонамеренных действий, а также управление репутацией в цифровой среде.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Цифровое потребление',
            description: null
          }
        ],
        col2: [
          {
            name: null,
            description: 'Базовая компетенция, позволяющая применять цифровые знания, умения и навыки использования цифровых ресурсов (интернет, социальные сети, государственные услуги, телемедицина и т. д.) в различных жизненных ситуациях, понимание цифровых прав и обязанностей в качестве потребителя цифровых услуг.'
          }
        ]
      }
    ]
  },
  {
    name: 'Личностные компетенции',
    description: nbspacer('Группа компетенций, отражающих индивидуальные особенности личности, позволяющие успешно участвовать в реализации стратегии цифровой трансформации и проектах цифрового развития'),
    showHeaders: true,
    col1Name: 'Компетенция',
    col2Name: 'Поведенческие индикаторы компетенции',
    color: 'red',
    showNumbers: false,
    items: [
      {
        type: 'cols',
        col1: [
          {
            name: 'Л.1. Нацеленность на результат',
            description: 'Совокупность устойчивых особенностей личности, обеспечивающая максимизацию собственных возможностей при выполнении взятых обязательств, решении нестандартных, сложных задач для полного и своевременного получения результатов и достижения целей цифрового развития наиболее продуктивным способом.'
          }
        ],
        col2: [
          {
            name: 'Л.1.1.',
            description: 'Проактивно мыслит и ведет себя в соответствии с логикой «цель — действие — результат».'
          },
          {
            name: 'Л.1.2.',
            description: 'Применяет правила и процедуры гибко, адекватно реагирует на изменения ситуации, модифицирует тактику действий с учетом текущих обстоятельств для достижения запланированного результата.'
          },
          {
            name: 'Л.1.3.',
            description: 'Активно предлагает собственные идеи и поддерживает идеи участников команды, несет ответственность за свои действия и действия других участников команды.'
          },
          {
            name: 'Л.1.4.',
            description: 'Настойчив в достижении поставленных целей и решении задач; столкнувшись с трудностями, возражениями и/или недостатком ресурсов, находит способы преодолеть их.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Л.2. Клиентоцентричность',
            description: 'Совокупность устойчивых особенностей личности, обеспечивающая сознательную направленность на определение и максимальное удовлетворение явных и скрытых потребностей пользователей результатов цифровой трансформации с учетом разумных издержек и соблюдения интересов заинтересованных сторон.'
          }
        ],
        col2: [
          {
            name: 'Л.2.1.',
            description: 'Понимает явные и скрытые потребности людей и учитывает их при создании продуктов и услуг.'
          },
          {
            name: 'Л.2.2.',
            description: 'Выявляет и эффективно использует информацию о потребностях других людей с целью создать максимальную ценность для них.'
          },
          {
            name: 'Л.2.3.',
            description: 'Предоставляет персонализированные решения, максимально адаптированные для каждого клиента, потребителя.'
          },
          {
            name: 'Л.2.4.',
            description: 'Регулярно запрашивает обратную связь по результатам своей работы и использует ее для корректировки и улучшения деятельности.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Л.3. Коммуникативность',
            description: 'Совокупность устойчивых особенностей участника проектной деятельности, обеспечивающая выбор наилучшей стратегии и тактики общения, эффективную деловую коммуникацию, открытость и проявление уважения в целях развития сотрудничества в процессе реализации стратегии цифровой трансформации и проектов цифрового развития.'
          }
        ],
        col2: [
          {
            name: 'Л.3.1.',
            description: 'Не испытывает сложностей при переключении реальных/виртуальных каналов коммуникации, одинаково эффективно пользуется ими.'
          },
          {
            name: 'Л.3.2.',
            description: 'Понимает мотивы участников коммуникации, скрытые смыслы и подтексты, в том числе на основе невербальных проявлений.'
          },
          {
            name: 'Л.3.3.',
            description: 'Проявляет уважение (умение слушать и слышать), применяет техники активного слушания, терпим к иным точкам зрения.'
          },
          {
            name: 'Л.3.4.',
            description: 'Корректно отстаивает свои интересы, права и линию поведения в ситуации внешнего давления; спокойно говорит «нет», если что-то его/ее не устраивает, корректно обосновывая свою позицию.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Л.4. Эмоциональный интеллект',
            description: 'Совокупность устойчивых особенностей личности, обеспечивающая возможность управлять личными эмоциями и эмоциями других людей для решения практических задач, верно оценивать эмоции, намерения, мотивацию и желания (свои и чужие), создавать и поддерживать рабочую атмосферу во время командной работы.'
          }
        ],
        col2: [
          {
            name: 'Л.4.1.',
            description: 'Тонко дифференцирует свои эмоции, понимает их источники, конструктивно использует разные виды собственных эмоциональных состояний.'
          },
          {
            name: 'Л.4.2.',
            description: 'Управляет эмоциональным и физическим состоянием для быстрого восстановления после стресса и нагрузки.'
          },
          {
            name: 'Л.4.3.',
            description: 'Понимает эмоции, намерения, мотивацию и желания других людей и может управлять ими.'
          },
          {
            name: 'Л.4.4.',
            description: 'Анализирует текущий эмоциональный фон, создает благоприятную психоэмоциональную атмосферу в команде.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Л.5. Креативность',
            description: 'Совокупность устойчивых особенностей личности, обеспечивающая способность формулировать нестандартные идеи, отходить от традиционных схем мышления, быстро находить выход из сложных ситуаций, используя нешаблонные подходы.'
          }
        ],
        col2: [
          {
            name: 'Л.5.1.',
            description: 'Обладает нестандартным, оригинальным мышлением, сам выдвигает новые идеи и поддерживает инициативные идеи коллег.'
          },
          {
            name: 'Л.5.2.',
            description: 'В случае выбора между перспективными инновационными и устаревшими подходами предпочитает новые идеи и методы и предпринимает конкретные действия для генерации и реализации инновационных идей и подходов.'
          },
          {
            name: 'Л.5.3.',
            description: 'Находит креативные способы решения проблемы, анализирует их плюсы и минусы, риски, выбирает оптимальное решение; способен изменить решение при наличии новых аргументов или произошедших изменений.'
          },
          {
            name: 'Л.5.4.',
            description: 'Распознает непродуктивные ментальные модели и стереотипы и отказывается от них.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Л.6. Критичность',
            description: 'Совокупность устойчивых особенностей личности, позволяющая обдуманно и взвешенно проводить оценку событий, процессов, результатов деятельности для всесторонней проверки и исправления возможно допущенных ошибок; способность осознавать свои ошибочные решения и приводить доводы за и против, выдвигать предложения и подвергать их всестороннему анализу.'
          }
        ],
        col2: [
          {
            name: 'Л.6.1.',
            description: 'Ставит под сомнение поступающую информацию, включая собственные убеждения, проверяет достоверность фактов.'
          },
          {
            name: 'Л.6.2.',
            description: 'При принятии решений опирается на метазнания, кругозор, опыт и экспертные мнения.'
          },
          {
            name: 'Л.6.3.',
            description: 'Анализирует, синтезирует и оценивает информацию для принятия решений и реализации своих действий, видит картину потенциальных рисков и ключевых факторов успеха в целом.'
          },
          {
            name: 'Л.6.4.',
            description: 'Выполняя задачу или решая проблему, выделяет и учитывает все актуальные влияющие на нее факторы.'
          }
        ]
      }
    ]
  },
  {
    name: 'Профессиональные компетенции',
    description: nbspacer('Группа компетенций, связанных с функциональным использованием методов и инструментов управления процессами, проектами, продуктами цифровой трансформации и регулярным решением сложных профессиональных задач в цифровой среде'),
    showHeaders: false,
    col1Name: null,
    col2Name: null,
    color: 'bright-emerald',
    showNumbers: false,
    items: [
      {
        type: 'header1',
        bgColor: 'blue',
        name: 'П.1. Управление цифровым развитием',
        description: '— применение методов и инструментов стратегического, тактического и оперативного управления внедрением и развитием цифровых технологий, услуг, инфраструктуры.'
      },
      {
        type: 'header2',
        name: 'П.1.1. Концептуальные положения цифровой экономики'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.1.1. Целевые ориентиры развития промышленных технологий и инноваций',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Четвертая промышленная революция: исторический контекст. Кардинальные и системные изменения. Движущие факторы: мегатренды, переломные моменты, передовые производственные технологии. Цифровизация и платформизация: кризис производительности. Технологический переход. Влияние технологической революции на общество. Технологическая революция как приоритет государственной политики. Современные технологические тренды и предпосылки к формированию цифровой экономики. Целевое видение технологического развития России: приоритеты, сценарии, направления, этапы.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.1.2. Тезаурус цифровой экономики',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Ключевые дефиниции: автоматизация, цифровизация, цифровая трансформация, цифровое развитие, цифровая культура, цифровая экономика, цифровая платформа, цифровая технология, цифровой продукт, цифровой проект, цифровой двойник.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.1.3. Приоритетные направления цифровой экономики',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Нормативное регулирование цифровой среды. Кадры для цифровой экономики. Информационная инфраструктура. Информационная безопасность (ИБ). Цифровые технологии. Цифровое государственное управление.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.1.2. Механизмы и инструменты цифровой трансформации'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.2.1. Цифровая трансформация на основе платформ',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Технологические стеки платформенных решений. Государство как платформа. Единая техническая политика. Государственные информационные системы. Инициативы для предоставления гражданам лучшего сервиса. Суперсервисы.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.2.2. Правовое регулирование цифровой среды',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Правовое регулирование цифровой среды: гибкость и стабильность. Принципы правового регулирования отношений в сфере информации, информационных технологий и защиты информации. Ведомственные программы цифровой трансформации: структура, особенности управления. Ключевые задачи, назначение государственных информационных систем.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.2.3. Критерии цифровой зрелости',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Критерии цифровой зрелости: инфраструктура и инструменты, процессы, цифровые продукты, данные, модели, кадры, цифровая культура. Методология оценки уровня цифровизации организации и ее подразделений. Сервис самодиагностики.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.1.3. Стратегическое управление цифровым развитием'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.3.1. Инструменты и результаты анализа текущего состояния',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Анализ ситуации, поиск разрывов, постановка проблем; определение перспектив цифрового развития организации. Ключевые вызовы и возможности для цифровой трансформации. Риски и угрозы информационной безопасности в ходе реализации цифровой трансформации организации. Формирование целевого видения, цели и ключевых показателей эффективности. Моделирование результатов. Разработка приоритетных направлений стратегии цифрового развития организации.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.3.2. Разработка дорожной карты цифрового развития организации',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Декомпозиция приоритетных направлений на объекты управления (портфели, программы, проекты цифрового развития); 3 горизонта планирования. Модель финансирования реализации стратегии цифрового развития.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.3.3. Модель управления цифровой трансформацией',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Организационная структура управления (субъекты управления). Процессы управления объектами (инициирование, планирование, реализация, контроль и мониторинг, завершение). Система обеспечения информационной безопасности.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.1.4. Государственная технологическая повестка:'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.4.1. Обеспечение технологического суверенитета',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Цели и задачи обеспечения технологического суверенитета. План перехода на российское программное обеспечение. Критическая информационная инфраструктура и обеспечение ее безопасности. Альтернативные ИТ-решения и информационные системы. Реестр отечественного программного обеспечения. Механизмы государственной поддержки обеспечения технологического суверенитета.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.4.2. Цифровизация государственных услуг',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Цели и задачи цифровой трансформации госуслуг. Показатели цифровой трансформации госуслуг. Матрица оценки цифровой зрелости госуслуг. Процессы в деятельности по предоставлению госуслуг и их оптимизация. Нормативное обеспечение цифровизации государственных услуг. Инструменты цифровой трансформации госуслуг: платформа обратной связи, платформа государственных сервисов, робот Макс, визуальный конструктор форм, электронный регламент предоставления госуслуг, система межведомственного электронного документооборота, единая система идентификации и аутентификации. Метрики качества оказания госуслуг. Ролевая модель цифровизации государственных услуг.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '1.4.3. Отраслевая технологическая повестка',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Ведомственные программы цифровой трансформации. Основные отраслевые информационные системы. Органы управления цифровой трансформацией отраслей. Цели, задачи и технологии цифровой трансформации отраслей: промышленности; здравоохранения; транспорта и логистики; строительства, городского хозяйства и ЖКХ; спорта; торговли; сельского хозяйства и др.'
          }
        ]
      },
      {
        type: 'header1',
        bgColor: 'blue',
        name: 'П.2. Развитие организационной культуры',
        description: '(в условиях цифровой трансформации) — применение технологий формирования, управления , изменения функциональных процессов и системы цифровых ценностей, норм и правил поведения персонала, нацеленных на повышение результативности организации.'
      },
      {
        type: 'header2',
        name: 'П.2.1. Инструменты и методы формирования и поддержания организационной культуры'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.1.1. Основы теории организационной культуры',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Понятие организационной культуры. Основные элементы организационной культуры: ценности, нормы и др. Структуры организационной культуры. Уровни корпоративной культуры. Компоненты организационной культуры. Роль организационной культуры. Факторы, влияющие на особенности организационной культуры.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.1.2. Принципы и этапы формирования организационной культуры',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Принципы формирования организационной культуры. Этапы формирования организационной культуры. Методы формирования организационной культуры. Роль лидера в формировании организационной культуры. Основные мероприятия по формированию организационной культуры.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.1.3. Условия поддержания организационной культуры',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Методы поддержания организационной культуры. Взаимосвязь культуры и стратегии организации. Методы изменения культуры. Влияние организационной культуры на организационную эффективность: модели. Элементы организационной культуры и показатели их эффективности. Качественные и формализованные методы диагностики корпоративной культуры.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.2.2. Механизмы управления организационными изменениями'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.2.1. Принципы и алгоритмы управления организационными изменениями',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Трансформационное лидерство: технические и адаптивные вызовы. Матрица признаков изменений по Д. Тапскотту, принципы управления изменениями. Общий алгоритм управления изменениями. Тактика управления изменениями. Роли трансформационного лидера; команды управления изменениями.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.2.2. Модели управления организационными изменениями',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Модели управления изменениями: модель ADKAR; модель изменений Джона Коттера; модель К. Левина. Фазы перехода по У. Бриджесу: окончание, нейтральная полоса, новое начало (психологические признаки).'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.2.3. Методы работы с сопротивлением',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Психологические основания неприятия перемен. 3 плато в развитии сознания взрослого человека. Лестница умозаключений. Виды сопротивления изменениям. Главные причины сопротивления изменениям в компании. Типичные реакции персонала на изменения. Кривая изменений Дж. Адамса, Дж. Хейеса и Б. Хопсона. Методы преодоления сопротивления изменениям. Модель SCARF. Интеллект-карта. Формула преодоления сопротивления изменениям Д. Глейчера.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.2.3. Технологии управления кадровой политикой цифрового развития'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.3.1. Организационные структуры управления цифровой трансформацией',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Особенности формирования подразделения организации, ответственного за цифровую трансформацию. Функции подразделения организации, ответственного за цифровую трансформацию. Команды цифровых проектов. Сложности формирования команд цифровых проектов и способы их преодоления. Ключевые роли в команде цифрового проекта в системе государственного управления. 10 шагов развития кадровой экосистемы цифровой трансформации.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.3.2. Цифровые команды: модель компетенций; модели формирования',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Модель компетенций команды цифровой трансформации в системе государственного управления: базовые цифровые компетенции, личностные и профессиональные компетенции в сфере цифрового развития, цифровая культура. Инструменты оценки компетенций. Модели формирования команд: модель командообразования по Р. М. Белбину, модель взаимодополняющей команды по И. Адизесу (PAEI), модель командных ролей М. Геллерта и К. Новака; модель управленческих ролей Т. Ю. Базарова, модель «Создание команды» А. Дрекслера и Д. Сиббета, модель командного развития Б. Такмана.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.3.3. Управление HR-циклом организации',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'HR-стратегия организации: принципы формирования, основные разделы. Инструменты управления по элементам HR-цикла: кадровое планирование, поиск и привлечение кадров, оценка и отбор кандидатов, адаптация сотрудников, управление карьерой, формирование резервов и управление талантами, оценка компетенций и управление развитием, система мотивации и оценки эффективности персонала. HR-брендинг, ценностное предложение работодателя. HR-цикл сотрудника.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.2.4. Принципы и алгоритмы практик регулярного менеджмента'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.4.1. Управление ресурсами организации',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Управление организационными структурами. Основы финансового менеджмента. Маркетинговая деятельность. Управление материальными активами. Управление знаниями.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.4.2. Управление командами',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Делегирование и контроль. Обратная связь. Проведение совещаний. Кадровые решения. Оценка эффективности и потенциала сотрудников, их развитие. Материальная и нематериальная мотивация сотрудников.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '2.4.3. Управление личной эффективностью',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Самоанализ, стратегии управления потенциалом и компетенциями, управление карьерой. Планирование расписания и управление временем (тайм-менеджмент). Определение приоритетов и оптимальной нагрузки. Публичные выступления и самопрезентация.'
          }
        ]
      },
      {
        type: 'header1',
        bgColor: 'blue',
        name: 'П.3. Инструменты управления',
        description: '— совокупность моделей и методов, используемых для решения задач управления процессами, проектами, продуктами и их информационного, организационного и методического обеспечения.'
      },
      {
        type: 'header2',
        name: 'П.3.1. Методы и инструменты процессного подхода'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.1.1. Основы процессного управления',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Процессы: основные и вспомогательные. Задачи и типы моделей процессов. Стандарты ISO серии 9000. Методология описания процессов BPMN (модель и нотация для моделирования «исполняемых» процессов). Процессный подход к управлению: цикл PDCA (планируй — делай — проверяй — внедряй). Каталогизация и документирование процессов, метрики и KPI процессов.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.1.2. Ролевая модель управления процессами',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Ключевые функции владельца процесса, процессного лидера, администратора процесса, процессного аналитика, процессного методолога, менеджера процесса, проектировщика процесса, процессного (бизнес-) архитектор, системного (бизнес-) аналитика, эксперта предметной области, потребителей результатов бизнес-моделирования.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.1.3. Порядок и инструменты проведения реинжиниринга и оптимизации процессов',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Этапы и виды реинжиниринга процессов. Принципы перепроектирования процессов. Условия и факторы риска, типичные ошибки при проведении реинжиниринга. Оптимизация процессов. Виды потерь в процессах. Методы и инструменты анализа и оптимизации процессов. Технологии постоянного совершенствования.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.3.2. Методы и инструменты продуктового подхода'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.2.1. Основы продуктового подхода',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Продукт и продуктовый подход. Стратегия и видение продукта. Постановка и проверка гипотез с помощью HADI (гипотеза (Hypothesis), действие (Action), сбор данных (Data), выводы (Insights)). Валидация продуктовых гипотез. Маркетинговые, продуктовые и бизнес-метрики. Ценностное предложение. Развитие потребителей (Customer Development). Методы исследования потребителей: качественные, количественные, синдикативные. Создание клиентского пути (Customer Journey Map). Минимально жизнеспособный продукт (MVP). Характеристики качества продукта.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.2.2. Ролевая модель продуктовой команды',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Ключевые функции владельца продукта, продуктового аналитика, Scrum-мастера, команды разработки (архитекторов, аналитиков, дизайнеров, инженеров, тестировщиков, программистов).'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.2.3. Методы и этапы разработки продукта',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Методы разработки продукта: классические (предикативные), гибкие (Agile) и гибридные. Этапы создания продукта: требования, проектирование, разработка, тестирование, внедрение. Управление продуктом: взаимодействие с пользователями (UX) и пользовательский интерфейс (UI), интеграция процессов разработки и сопровождения (DevOps, системы автоматизации производственного процесса разработки продукта и операционных процессов ИТ).'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.3.3. Методы и инструменты проектного подхода'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.3.1. Основы проектного управления',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Понятия проекта, проектной деятельности. Стандарты, методологии, своды знаний по управлению проектной деятельностью (PMBoK, PRINCE2, IPMA ICB, Р2М и др.). Система управления проектной деятельностью (СУПД) на уровне государства и организации: объекты, субъекты, организационные структуры, основные и вспомогательные процессы. Жизненные циклы портфелей проектов, программ и проектов. Автоматизация проектной деятельности.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.3.2. Ролевая модель управления проектом',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Ключевые функции куратора, функционального заказчика, руководителя проекта, администратора проекта, руководителя функционального направления проекта, исполнителя проекта.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.3.3. Методы и инструменты управления проектом',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Управление областями знания проекта: концепция и цели, содержание, сроки, финансы, качество, ресурсы, закупки, риски и возможности, заинтересованные стороны, коммуникация, интеграция проекта. Управление фазами проекта: инициирование, подготовка, реализация, мониторинг и контроль, завершение. Управление изменениями в проекте. Управление межведомственными, территориально распределенными и разноуровневыми проектными командами.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.3.4. Методы и инструменты антикризисного менеджмента'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.4.1. Основы антикризисного менеджмента',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Определение кризисной ситуации, типы кризисных ситуаций. Оценка потенциала возникновения кризисной ситуации. Идентификация стадии кризиса. Оценка степени влияния кризисной ситуации на системы и процессы организации. Оценка факторов дезинтеграции и дисбаланса. Команда антикризисного управления.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.4.2. Методы и инструменты антикризисного менеджмента',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Антикризисная программа, ее структура, элементы, мероприятия. Оценка и отбор антикризисных решений. Вре́менные организационные структуры. Системы внешнего управления и аутсорсинга. Реорганизация и реинжиниринг процессов. Управление потерями.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '3.4.3. Эффективность антикризисного менеджмента',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Качество антикризисной программы, мониторинг кризисных ситуаций, оперативность и гибкость управления, использование человеческого капитала организации.'
          }
        ]
      },
      {
        type: 'header1',
        bgColor: 'blue',
        name: 'П.4. Управление и использование данных',
        description: '— знание и применение методов и технологий сбора, структурирования, анализа данных для построения новых организационных и управленческих моделей, продуктов и сервисов в системе государственного управления.'
      },
      {
        type: 'header2',
        name: 'П.4.1. Инструменты управления данными в рамках функциональных направлений:'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.1.1. Планирование и проектирование данных',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Архитектура данных: формирование, развертывание и внедрение целевых решений в области архитектуры данных; артефакты архитектуры данных (модели, определения и описания потоков данных на различных уровнях). Моделирование и проектирование данных: схемы (реляционная, многомерная, объектно-ориентированная, на основе фактов, NoSQL) и нотации моделирования; типы баз данных; этапы построения концептуальной, логической и физической моделей данных; проверка и оценка качества моделей данных; сопровождение моделей данных.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.1.2. Обеспечение доступности и обслуживания',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Хранение данных и операции с ними: сопровождение баз данных (реализация рабочей среды базы данных, получение данных, их резервное копирование и удаление), технологическая поддержка баз данных (определение технических требований и технической архитектуры; развертывание и администрирование технологических решений; разрешение проблемных вопросов, связанных с технологиями).Ведение хранилища данных: извлечение, очистка, преобразование, контроль и загрузка данных.Интеграция и интероперабельность данных: планирование, проектирование, разработка и внедрение DII-решений.Управление основными и справочными данными: управление моделью данных; сбор и накопление данных; проверка, стандартизация и обогащение данных; разрешение конфликтов; регулирование совместного доступа и обслуживание данных.Безопасность данных: архитектура, политика, стандарты безопасности данных, механизмы контроля доступа к данным.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.1.3. Использование и улучшение данных',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Бизнес-аналитика: операционная отчетность и аналитика; геопространственная и демографическая аналитика; управление эффективностью; визуализация данных; Data & Text, Mining, анализ неструктурированных данных.Наука о данных: статистический анализ, предиктивный анализ, предписывающий анализ, машинное обучение.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.4.2. Технологии управления большими данными'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.2.1. Архитектуры и среды обработки больших данных',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Алгоритм ELT (извлечение → загрузка → преобразование). Источники больших данных. Озера данных. Архитектура на основе сервисов. Визуализация данных.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.2.2. Процессы управления большими данными',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Стратегическое планирование потребностей бизнеса в больших данных. Выбор источников данных. Определение источников и загрузка данных. Выработка гипотез и выбор методов. Предварительная интеграция / согласование данных для анализа. Исследование данных с помощью моделей. Внедрения моделей, мониторинг точности моделей.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.2.3. Техники и методы анализа больших данных',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Организация поиска и сбора данных. Интеграция данных. Прогнозные модели машинного обучения. Распознавание образов. Прогнозная аналитика. Имитационное моделирование. Статистический и пространственный анализ. Визуализация данных.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.4.3. Основы руководства данными'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.3.1. Инструменты руководства данными',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Принципы, политика, процессы, рамочная структура, метрики и механизмы надзора. Организационная система руководства данными. Ролевая модель управления данными. Управление документами и контентом.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.3.2. Обеспечение качества данных',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Стандарты ISO в области качества данных. Критерии качества данных: актуальность, консистентность/допустимость, полнота, разумность, согласованность, соответствие, уникальность / отсутствие дублирования, целостность. Цикл Шухарта—Деминга. Разработка и внедрение операционных процедур обеспечения качества данных.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.3.3. Этика обращения с данными',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Этические принципы, связанные с данными. Основополагающие принципы законодательства о конфиденциальности данных. Этические аспекты работы с данными в режиме онлайн. Риски, обусловленные неэтичными практиками обращения с данными. Формирование культуры этичного обращения с данными. Этика обращения с данными и руководство данными.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.4.4. Управление организацией на основе данных'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.4.1. Интеграция управления на данных в системы менеджмента организации',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Система ценностей управления данными в организации. Управление документами и контентом данных. Иерархия документов управления данными в организации. Data-driven в менеджменте: данные в стратегическом управлении организацией, в управлении маркетинговой и производственной деятельностью, управлении цепочками поставок.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.4.2. Механизм принятия решений, основанных на данных',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Компоненты и архитектура систем принятия решений на основе данных. Качества организации, принимающей решения, основанные на данных (системность, гибкость, прозрачность, бережливость, эффективность), и средства их развития. Система оперативной аналитики, дашборды.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '4.4.3. Оценка зрелости управления данными',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Основные концепции и драйверы повышения зрелости управления данными. Инструменты, методы и метрики оценки зрелости управления данными. Риски при внедрении системы оценки зрелости, организационные и культурные изменения. Использование результатов оценки зрелости, разработка плана улучшений.'
          }
        ]
      },
      {
        type: 'header1',
        bgColor: 'blue',
        name: 'П.5. Применение цифровых технологий',
        description: '— знание и использование методов проектирования, построения и управления корпоративной архитектурой, управления ИТ-системами, применения сквозных технологий, а также средств и методов информационной и кибербезопасности в системе государственного управления.'
      },
      {
        type: 'header2',
        name: 'П.5.1. Сквозные технологии'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.1.1. Цифровые технологии в бизнес-процессах систем',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Системы распределенного реестра и их субтехнологии: технологии организации и синхронизации данных, технологии обеспечения целостности и непротиворечивости данных; технологии создания и исполнения децентрализованных приложений и смарт-контрактов. Приоритетные отрасли. Квантовые технологии и субтехнологии: квантовые вычисления, квантовые коммуникации, квантовые сенсоры и метрология. Приоритетные отрасли.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.1.2. Цифровые технологии, формирующие новую инфраструктуру',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Компоненты робототехники и сенсорики и субтехнологии: сенсоры и цифровые компоненты, технологии сенсорно-моторной координации и пространственного позиционирования; сенсоры и обработка сенсорной информации. Приоритетные отрасли.Технологии беспроводной связи и субтехнологии: WAN, LPWAN, WLAN, PAN, спутниковые технологии связи (СТС). Приоритетные отрасли.Технологии виртуальной и дополненной реальности и субтехнологии: интерфейсы обратной связи, сенсоры и передача данных для VR/AR; технологии захвата движений в VR/AR; средства разработки VR/AR-контента. Приоритетные отрасли.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.1.3. Цифровые технологии проектирования и моделирования',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Новые производственные технологии и субтехнологии: цифровое проектирование, математическое моделирование; технологии умного производства; манипуляторы и технологии манипулирования.Технология информационного моделирования. BIM и жизненный цикл проекта. Программное обеспечение BIM, BIM-стандарты. Потенциал BIM.Модель открытого программного обеспечения, ее сравнение с проприетарными продуктами. Преимущества и недостатки использования программного обеспечения с открытым исходным кодом.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.5.2. ИИ-решения'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.2.1. Основы искусственного интеллекта',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Понятие «искусственный интеллект». Новые возможности человека при применении искусственного интеллекта во всех сферах деятельности. Классификации интеллектуальных информационных систем по типам используемых технологий: системы с коммутативными технологиями, экспертные системы, самообучающиеся и адаптивные системы. Нейротехнологии, их взаимосвязь и отличие от ИИ. Этика и нормативное регулирование использования технологий ИИ.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.2.2. Технологии ИИ-решений',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Нейронные сети. Генетические алгоритмы. Нечеткие множества, числа, вывод, управление. Структура и стратегии поиска в пространстве состояний. Эвристический поиск. Представление данных в системах ИИ. Сильные методы решения задач. ИИ-решения в условиях неопределенности. Открытые инструменты искусственного интеллекта (Midjourney, Copy.ai, JADBio, Notion.ai, Lumen5, ChatGPT и т. п.).'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.2.3. Оценка зрелости внедрения ИИ-решений',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Модели зрелости внедрения ИИ-решений. Уровни ИИ-зрелости. Функциональные области управления ИИ в организации: данные, управление, кадры и культура, процессы, продукты, модели и инструменты, инфраструктура. Метрики зрелости.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.5.3. Технологии трансформации общества'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.3.1. Технологии умного города',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Отслеживание передвижений с помощью RFID. Движущиеся беспроводные сети, сети устройств для интернета вещей. Контент для дополненной реальности. Технологии VLC (Visible Light Communication). Стандарт «Умный город». Компоненты и функциональные области проекта «Умный город». Контроль и прогнозирование состояния инфраструктуры.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.3.2. Цифровое управление регионом',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Архитектура и основные компоненты цифровой платформы управления территорией региона: системы взаимодействия с гражданами. Центры управления, ситуационные центры: функциональные модули, источники данных и каналы их передачи, категории хранилищ данных. Нормативное обеспечение.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.3.3. Цифровые профили граждан',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Цифровые профили граждан и юридических лиц: типы и источники информации, доступ к профилю. Архитектура цифрового профиля, технологии ЕСИА 2.0. Перспективы использования цифрового профиля.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.5.4. Перспективные технологии'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.4.1. Технологии устойчивого развития',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Цели устойчивого развития. Национальные стратегии устойчивого развития. Альтернативные источники энергии. Технологии общего экологического управления, производственные экотехнологии. Зеленые проекты. ИТ-сервисы и технологии для людей с ограниченными возможностями.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.4.2. Биотехнологии',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Биоинформатика и биофармакология. Медицинские технологии и генетика. Биотехнологии в отраслях. TechBio: высокопроизводительные технологии, операционные системы, платформенные решения.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '5.4.3. Новые материалы',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': '· Углеродные материалы, технические ткани, композиционные материалы, полимерные материалы, тонкая химия, металлы и сплавы, керамические материалы, абразивы, нанотехнологии в исследованиях и разработке новых материалов. Перспективные отрасли использования новых материалов.'
          }
        ]
      },
      {
        type: 'header1',
        bgColor: 'blue',
        name: 'П.6. Развитие ИТ-инфраструктуры',
        description: '— применение методов и инструментов, направленных на развитие совокупности информационных центров, подсистем, банков данных и знаний, систем связи, центров управления, аппаратно-программных средств и технологий обеспечения сбора, хранения, обработки и передачи информации организации.'
      },
      {
        type: 'header2',
        name: 'П.6.1. Основы создания ИТ-инфраструктуры организации'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.1.1. Формирование технической документации на этапах создания ИТ-инфраструктуры',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Виды и назначение технической документации информационных систем. Требования к технической документации. Разработка и утверждение технического задания. Техническое описание реализации требований. Создание исполнительной документации с описанием ИТ-инфраструктуры для использования и сопровождения информационной системы. Характеристики универсальных архитектурных документов.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.1.2. Характеристики архитектуры организации',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Понятия «бизнес-архитектура организации», «архитектура информационных технологий организации», «архитектура организации». Уровни архитектуры. Текущая архитектура (Current Architecture) и целевая архитектура (Target Architecture). Основные слои архитектуры организации. Компоненты ИТ-архитектуры: Enterprise Information Architecture (EIA) — информационная архитектура, Enterprise Solution Architecture (ESA) — архитектура прикладных решений, Enterprise Technical Architecture (ETA) — техническая архитектура.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.1.3. Процесс разработки архитектуры организации',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Общая схема архитектурного процесса: уровни (инициирование процесса планирования, бизнес-моделирование и описание текущих систем и технологий, видение будущего состояния, план перехода в будущее состояние).Основные элементы архитектурного процесса. Принципы построения архитектуры: принципы построения приложений, принципы организации данных, принципы построения ИТ-инфраструктуры.Современные методики описания архитектуры организации: Zachman framework, TOGAF (The Open Group Architecture Framework), META Group Framework, Gartner Enterprise Architecture Framework (GEAF), методики Microsoft и др.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.6.2. Инфраструктурные решения'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.2.1. Принципы построения ИТ-инфраструктуры',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Баланс консолидации и распределения ресурсов: 5 уровней (изоляция, группа, предприятие, партнерство, рынок). Типовая последовательность: консолидация вычислительных ресурсов, консолидация информационных ресурсов, создание системы управления ИТ-ресурсами, создание процессов и системы автоматизации работы с ресурсами.Обеспечение гибкости и адаптивности ИТ-инфраструктуры: проведение идентификации и каталогизации ИТ-инфраструктуры. Разработка повторно используемых инфраструктурных шаблонов. Разработка адаптивных инфраструктурных сервисов. Создание инструментальной базы управления. Планирование развития ИТ-инфраструктуры.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.2.2. Концепции управления ИТ-инфраструктурой',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Основные понятия и сущность ITIL (IT Infrastructure Library). Система создания ценности услуг ITIL: цепочка создания ценности услуг, практики, руководящие принципы ITIL, руководство, постоянное улучшение. Модель четырех измерений. Практики ITIL: практики общего управления, практики управления услугами, практики управления технологиями. Роль ITIL в успешной цифровой трансформации.Основные понятия и сущность COBIT (Control Objectives for Information and Related Technologies — задачи управления для информационных и смежных технологий»). Структура COBIT. Управление ИТ по COBIT: стратегии, политики, стандарты, процедуры.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.2.3. Модели и принципы построения облачных решений (IaaS, PaaS, SaaS)',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'IaaS: облачная инфраструктура как услуга. Виртуальные серверы (VPS/VDS). Сетевые настройки. Управление доступом пользователей. Облачные хранилища. Сервисы резервного копирования и катастрофоустойчивости. Примеры использования: перенос ИТ-систем в облако, экономия на инфраструктуре; расширение инфраструктуры и пр.PaaS: облачная платформа как услуга. Примеры использования: базы данных, разработка приложений в контейнерах, аналитика больших данных, машинное обучение.SaaS: облачные сервисы для конкретных прикладных задач. Примеры использования: электронная почта, CRM-системы, планировщики задач, веб-конструкторы для создания сайтов.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.6.3. Средства и методы информационной и кибербезопасности'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.3.1. Цели и задачи защиты информации, типы и источники угроз',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Понятия «информационная безопасность», «кибербезопасность». Цели, задачи защиты информации: конфиденциальность данных, доступность информационных систем, целостность данных, подлинность, неотказуемость. Типы информационных угроз (техногенные, антропогенные, стихийные). Виды киберугроз: киберпреступление, кибератака, кибертерроризм.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.3.2. Системы управления информационной безопасностью',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Положения Указа Президента РФ от 01.05.2022 № 250 и других НПА, национальных стандартов по ИБ. Основные функции систем управления информационной безопасностью: выявление и анализ рисков ИБ; планирование и практическая реализация процессов, направленных на минимизацию рисков ИБ; контроль процессов; внесение в процессы минимизации информационных рисков корректировок.Принципы управления ИБ: комплексный подход, согласованность с задачами и стратегией организации, высокий уровень управляемости, адекватность используемой и генерируемой информации, эффективность, непрерывность управления, процессный подход.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.3.3. Инструменты защиты информации и обеспечения кибербезопасности',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'приложений, серверов, конечных пользователей, защита от атак методами социальной инженерии, повышения готовности. План обеспечения кибербезопасности: сегментация, демилитаризованная зона, регулярное резервное копирование и своевременные обновления, программно-аппаратный комплекс специального назначения, развитие надлежащей корпоративной культуры, ограниченный доступ и уникальные пароли, физическая защита, отношения с системными интеграторами.'
          }
        ]
      },
      {
        type: 'header2',
        name: 'П.6.4. Национальная ИТ-инфраструктура'
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.4.1. Государственные информационные системы (ГИС)',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Жизненный цикл создания и функционирования ГИС. Выбор архитектуры ГИС. Типовой состав рабочей документации ГИС. Стандарты безопасности, применяемые в ГИС. Нормативное обеспечение разработки, внедрения и использования ГИС. Особенности планирования закупочной деятельности и управления ею при создании и обслуживании ГИС. Хранение и защита информации в ГИС, порядок и технологии предоставления доступа к информации в ГИС.'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.4.2. Технологии платформы «ГосТех»',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Цели, задачи и возможности платформы «ГосТех». Доменная модель «ГосТех». Пакет стандартов «ГосТех». Подходы к проектированию и управлению разработкой на платформе, повторное использование типовых компонентов, автоматизированный конвейер разработки. Каналы взаимодействия с клиентами. Хранение и аналитика данных, «Гособлако», «Госмаркет».'
          }
        ]
      },
      {
        'type': 'cols',
        'col1': [
          {
            'name': '6.4.3. Инфраструктура защищенных коммуникаций',
            'description': null
          }
        ],
        'col2': [
          {
            'name': null,
            'description': 'Цели и задачи взаимодействия информационных систем государственных организаций и ведомств. Защищенные каналы и стандарты передачи данных. Технологии систем межведомственных электронных взаимодействий и области их применения (СМЭВ1, СМЭВ2, СМЭВ3, МЭДО). Системы идентификации и аутентификации граждан. Технологии и инфраструктура передачи платежной информации и использования цифрового рубля. Виды и области использования электронных цифровых подписей, программно-аппаратные средства.'
          }
        ]
      }
    ]
  },
  {
    name: 'Цифровая культура',
    description: <Stack gap={20}>
      <Text>В модели компетенций цифровая культура является ключевым блоком, характеризующим изменения, произошедшие в результате развития личностных и профессиональных компетенций участников цифровой трансформации.</Text>
      <Text><b>Цифровая культура организации</b> — система ценностей, установок, норм и правил поведения в цифровой среде, которую принимают, поддерживают и транслируют работники и руководство организации</Text>
      <Text><b>Цифровая культура специалиста</b> — проявление совокупности личностных и профессиональных компетенций, сформированной в системе ценностей, установок, норм и правил поведения в цифровой среде, необходимой для эффективной работы с применением цифровых технологий в рамках своей профессиональной деятельности</Text>
    </Stack>,
    showHeaders: true,
    col1Name: 'Компонент цифровой культуры',
    col2Name: 'Определение',
    color: 'orange-dark',
    showNumbers: true,
    items: [
      {
        type: 'cols',
        col1: [
          {
            name: 'Цифровое мышление',
            description: null
          }
        ],
        col2: [
          {
            name: null,
            description: 'Осознанное познание цифровой среды, основанное на когнитивных способностях, позволяющих переосмыслить привычные процессы, изменить установки, модели поведения и адаптироваться к требованиям цифровой жизни.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Цифровая аффилиация',
            description: null
          }
        ],
        col2: [
          {
            name: null,
            description: 'Личные установки и мотивы, которые влияют на отношение человека к цифровым технологиям и их использованию в профессиональной деятельности. Например: интерес к новым технологиям, цифровые хобби (стриминг, программирование, гейминг, цифровая графика, цифровые книги и пр.), доступность технологий, удобство их использования и пр.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Цифровая гибкость',
            description: null
          }
        ],
        col2: [
          {
            name: null,
            description: 'Быстрая адаптация к изменяющимся условиям использования цифровых технологий и ресурсов, а также эффективное использование их в новых и непредсказуемых ситуациях, включая умение быстро ориентироваться в новых технологиях, осваивать и использовать новые инструменты и ресурсы, а также готовность к постоянному обучению и развитию.'
          }
        ]
      },
      {
        type: 'cols',
        col1: [
          {
            name: 'Цифровой опыт',
            description: null
          }
        ],
        col2: [
          {
            name: null,
            description: 'Практический опыт использования цифровых технологий и ресурсов для достижения конкретных целей и удовлетворения личных и профессиональных потребностей. Влияет на поведение человека в цифровой среде, его предпочтения и потребности, а также на качество жизни и профессиональную эффективность.'
          }
        ]
      }
    ]
  }
]

export default items
