import React from 'react'

import Card from 'components/Card/Card'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'

type CourseAboutCardProps = {
  image: 'checkbox' | 'clock' | 'horn' | 'laptop',
  name: string,
  description: string,
  url?: string;
  invertOrder?: boolean,
  style?: React.CSSProperties
}

const CourseAboutCard = (props: CourseAboutCardProps) => {
  const getImageUrl = () => {
    switch (props.image) {
      case 'checkbox':
        return '/static/img/components/program-registry/course-about-card/checkbox.svg'
      case 'clock':
        return '/static/img/components/program-registry/course-about-card/clock.svg'
      case 'horn':
        return '/static/img/components/program-registry/course-about-card/horn.svg'
      case 'laptop':
        return '/static/img/components/program-registry/course-about-card/laptop.svg'
    }
  }
  return (
    <Card
      style={props.style}
    >
      <Stack
        gap={10}
        alignItems='center'
      >
        <Image
          src={getImageUrl()}
          width='100px'
        />
        {props.invertOrder && <>
          <Text variant='text-m-medium' align='center'>{props.name}</Text>
          <Heading variant='3'>{props.description}</Heading>
        </>}
        {!props.invertOrder && <>
          <Heading variant='3' align='center'>{props.name}</Heading>
          {!props.url && <Text variant='text-m-medium' align='center'>{props.description}</Text>}
          {props.url && <a
            href={props.url}
            style={{
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '150%',
              color: '#407BFF',
              width: '60%',
              textAlign: 'center'
            }}
          >
            {props.description}
          </a>}
        </>}
      </Stack>
    </Card>
  )
}

export default CourseAboutCard
