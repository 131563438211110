import React from 'react'
import HeaderSection from './HeaderSection'
import QuestionSection from 'page-sections/v2/QuestionSection/QuestionSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'
import TimetableSection from './TimetableSection'
import useMediaQuery from 'common/useMediaQuery'
import TimetableSectionMobile from './TimetableSectionMobile'

const TimetablePage = () => {
  const { isTablet } = useMediaQuery()
  return (
    <div>
      <HeaderSection />

      {!isTablet && <TimetableSection />}
      {isTablet && <TimetableSectionMobile />}

      <QuestionSection
        id={'question-section'}
        bgColor='light-gray'
        defaultPurposeOfRequest='program_registry_timetable'
      />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default TimetablePage
