import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import nbspacer from 'common/nbspacer'
import React from 'react'
import PageSection from 'components/v2/PageSection/PageSection'
import Grid from 'components/Grid/Grid'
import ProgramCard from '../components/ProgramCard'

type Program = {
  name: string;
  duration: string;
  start: string | null;
  imageSrc: string;
  url: string | null;
}

const ProgramsSection1 = () => {
  const programs: Array<Program> = [
    {
      name: 'Целеполагание и принятие решений в практике цифрового развития организации',
      duration: '40 часов',
      start: null,
      imageSrc: '/static/img/pages/program-registry/program-registry/programs-section2/image1.svg',
      url: 'https://cdto.ranepa.ru/program-registry/decisions'
    },
    {
      name: 'Создание и развитие цифровых команд',
      duration: '60 часов',
      start: null,
      imageSrc: '/static/img/pages/program-registry/program-registry/programs-section2/image4.svg',
      url: 'https://cdto.ranepa.ru/program-registry/creation-team'
    }
  ]

  return (
    <PageSection variant='white-icons'>
      <Stack gap={40}>
        <Heading variant='2'>{nbspacer('Развитие личностно-управленческих компетенций')}</Heading>
        <Grid
          columnGap={20}
          rowGap={40}
          cols={4}
          tabletCols={1}
        >
          {programs.map((program: Program, index: number) => {
            return (
              <ProgramCard
                key={index}
                bgColor='light-gray'
                {...program}
              />
            )
          })}
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default ProgramsSection1
