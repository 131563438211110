import getCookie from 'common/get-coockie'
import Button from 'components/Button/Button'
import Checkbox from 'components/Checkbox/Checkbox'
import Modal from 'components/Modal/Modal'
import Stack from 'components/Stack/Stack'
import TextField from 'components/TextField/TextField'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import React from 'react'
import { Dropdown, DropdownOption } from 'components/Dropdown/Dropdown'
import FileInput from 'components/FileInput/FileInput'
import Link from 'components/Link/Link'

type RequestFormProps = {
  visible: boolean;
  defaultTariff?: string;
  onSubmit?: () => void;
  onClose?: () => void;
}

const RequestForm = (props: RequestFormProps) => {
  const [tariff, setTariff] = React.useState('')
  const [allowPd, setAllowPd] = React.useState(false)
  const [file, setFile] = React.useState<File | null>(null)
  const [dataUrlFile, setDataUrlFile] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const result = reader.result
        setDataUrlFile(result as string)
      }
      reader.readAsDataURL(file)
    }
  }, [file]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (props.defaultTariff) {
      setTariff(props.defaultTariff)
    }
  }, [props.defaultTariff])

  const checkboxLabel: any = <Text variant='text-s'>
    Даю согласие на обработку
    &nbsp;
    <a
      href='https://cdto.ranepa.ru/media/consent_to_the_processing_of_personal_data.pdf'
      target='_blank'
      rel='noreferrer'
    >
      персональных данных
    </a>
  </Text>

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const target = e.target as typeof e.target & {
      name: { value: string }
      phone: { value: string }
      email: { value: string }
      question: { value: string }
    }
    const name = target.name.value
    const phone = target.phone.value
    const email = target.email.value
    const question = target.question.value

    const body = {
      name: name,
      phone: phone,
      email: email,
      tariff: tariff,
      attachment: dataUrlFile,
      question: question
    }

    const csrftoken = getCookie('csrftoken')

    const url = `/api/career-management-request`
    let params: any = {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, params)
    if (response.ok) {
      setAllowPd(false)
      e.target.reset()
      setFile(null)
      props.onSubmit?.()
    } else {
      console.log('error')
    }
  }

  const options: DropdownOption[] = [
    { key: 'tariff_240', text: 'Тариф 240' },
    { key: 'tariff_360', text: 'Тариф 360' },
    { key: 'tariff_480', text: 'Тариф 480' }
  ]

  return (
    <Modal
      visible={props.visible}
      onClose={() => { props.onClose?.() }}
    >
      <Stack
        gap={30}
      >
        <Heading variant='2'>
          Заявка на услугу
        </Heading>

        <form onSubmit={handleSubmit}>
          <Stack gap={40}>
            <Stack
              gap={40}
            >
              <TextField
                name='phone'
                type='phone'
                blockElement
                fullWidth
                required
                autoComplete='off'
                placeholder='Телефон *'
              />
              <TextField
                name='name'
                blockElement
                fullWidth
                autoComplete='off'
                placeholder='Ф. И. О. *'
                required
              />
              <TextField
                name='email'
                type='email'
                blockElement
                fullWidth
                required
                autoComplete='off'
                placeholder='E-mail *'
              />
              <Dropdown
                value={tariff}
                options={options}
                placeholder='Тариф'
                onChange={setTariff}
              />
              <TextField
                name='question'
                blockElement
                fullWidth
                autoComplete='off'
                placeholder='Какой вопрос Вы хотели бы решить на консультации?'
                size={50}
              />
              <FileInput
                file={file}
                setFile={setFile}
              />
              <div>
                <Checkbox
                  name='allow_pd'
                  onChange={() => setAllowPd(!allowPd)}
                  label={checkboxLabel}
                  checked={allowPd}
                />
              </div>
              <div>
                <Link
                  to='https://disk.yandex.ru/i/mRz0zVJIdkThlA'>
                  Политика РАНХиГС в отношении обработки персональных данных
                </Link>
              </div>
            </Stack>
            <div>
              <Button
                variant='filled'
                disabled={!allowPd}
                type='submit'
              >
                Отправить
              </Button>
            </div>
          </Stack>
        </form>
      </Stack>
    </Modal>
  )
}

export default RequestForm
