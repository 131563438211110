import PageSection from 'components/v2/PageSection/PageSection'
import styles from './FooterSection.module.css'
import ContactsBlock from 'components/v2/ContactsBlock/ContactsBlock'
import Text from 'components/v2/Text/Text'
import Stack from 'components/Stack/Stack'
import Link from 'components/Link/Link'
import CookieConsent from 'components/CookieConsent'

type FooterSectionPropts = {
  id?: number | string;
  showContacts?: boolean;
  showRanepaMoreText?: boolean;
}

const FooterSection = (props: FooterSectionPropts) => {
  const showContacts = props.showContacts ?? true
  return (
    <PageSection id={props.id} className={styles.FooterSection} variant='gradient-green'>
      <Stack gap={35}>
        {showContacts &&
          <ContactsBlock showSubscribeForm themeWhite/>
        }
        <Stack gap={8}>
          {props.showRanepaMoreText && <Text variant='text-s' color='white'>Подробная информация об образовательной программе размещена на официальном сайте Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации по адресу <Link to={'https://ranepa.ru'}><Text variant='text-s' color='white'>ranepa.ru</Text></Link></Text>}
          <Text variant='text-s' color='white'>Cайт разработан в качестве информационно-аналитического материала, обеспечивающего реализацию программ развития компетенций</Text>
          <Text variant='text-s' color='white'>© 2024 Центр подготовки руководителей и команд цифровой трансформации ВШГУ РАНХиГС</Text>
        </Stack>
      </Stack>
      <CookieConsent />
    </PageSection>
  )
}

export default FooterSection
