import nbspacer from 'common/nbspacer'
import Grid from 'components/Grid/Grid'
import Heading from 'components/v2/Heading/Heading'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import React from 'react'

type HeaderBlockProps = {
  imageUrl: string;
  heading: string;
  children?: React.ReactNode;
}

const HeaderBlock = (props: HeaderBlockProps) => {
  return (
    <Grid
      cols={2}
      tabletCols={1}
      columnGap={60}
      rowGap={40}
    >
      <Stack gap={40}>
        <Heading
          variant='1'
          color='white'
          maxWidth854
        >
          {nbspacer(props.heading)}
        </Heading>
        <div>{props.children}</div>
      </Stack>
      <Image src={props.imageUrl} width='100%' />
    </Grid>
  )
}

export default HeaderBlock
export type { HeaderBlockProps }
