import Button from 'components/Button/Button'
import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Image from 'components/Image/Image'
import React from 'react'
import TextField from 'components/TextField/TextField'
import Checkbox from 'components/Checkbox/Checkbox'
import Text from 'components/v2/Text/Text'
import getCookie from 'common/get-coockie'
import Link from 'components/Link/Link'

type IndividualFormProps = {
  purpose?: string;
  onSubmit?: () => void;
}

const IndividualForm = (props: IndividualFormProps) => {
  const [allowPd, setAllowPd] = React.useState(false)

  const checkboxLabel: any = <Text variant='text-s'>
    Даю согласие на обработку
    &nbsp;
    <a
      href='https://cdto.ranepa.ru/media/consent_to_the_processing_of_personal_data.pdf'
      target='_blank'
      rel='noreferrer'
    >
      персональных данных
    </a>
  </Text>

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const target = e.target as typeof e.target & {
      name: { value: string }
      phone: { value: string }
      email: { value: string }
    }
    const name = target.name.value
    const phone = target.phone.value
    const email = target.email.value
    const purpose = props.purpose ?? ''

    const body = {
      name: name,
      phone: phone,
      email: email,
      purpose: purpose,
      subject_type: 'individual'
    }

    const csrftoken = getCookie('csrftoken')

    const url = `/api/appeals`
    let params: any = {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, params)
    if (response.ok) {
      setAllowPd(false)
      e.target.reset()
      props.onSubmit?.()
    }
  }

  return (
    <Grid
      cols={2}
      tabletCols={1}
      columnGap={60}
      rowGap={40}
      alignItems='center'
    >
      {/* left */}
      <form onSubmit={handleSubmit}>
        <Stack gap={40}>
          <Heading variant='1'>Остались вопросы?</Heading>

          <Stack
            gap={40}
          >
            <TextField
              name='name'
              blockElement
              fullWidth
              autoComplete='off'
              placeholder='Ф. И. О. '
              required
            />
            <TextField
              name='phone'
              type='phone'
              blockElement
              fullWidth
              required
              autoComplete='off'
              placeholder='Телефон'
            />
            <TextField
              name='email'
              type='email'
              blockElement
              fullWidth
              required
              autoComplete='off'
              placeholder='E-mail'
            />
            <div>
              <Checkbox
                name='allow_pd'
                onChange={() => setAllowPd(!allowPd)}
                label={checkboxLabel}
                checked={allowPd}
              />
            </div>
            <div>
              <Link
                to='https://disk.yandex.ru/i/mRz0zVJIdkThlA'>
                Политика РАНХиГС в отношении обработки персональных данных
              </Link>
            </div>
          </Stack>
          <div>
            <Button
              variant='filled'
              disabled={!allowPd}
              type='submit'
            >
              Отправить
            </Button>
          </div>
        </Stack>
      </form>
      {/* right */}
      <Stack alignItems='flex-end' tabletAlignItems='flex-start'>
        <Image src='/static/img/pages/program-registry/page-sections/question-section/section-image.svg' />
      </Stack>
    </Grid>
  )
}

export default IndividualForm
