import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'
import React, { useEffect, useState } from 'react'

import EventCard from './EventCard/EventCard'
import NoEventCard from './NoEventCard/NoEventCard'

type EventsSectionProps = {
  bgColor?: Color;
  heading?: string;
}

const EventsSection = (props: EventsSectionProps) => {
  const [eventList, setEventList] = useState([])
  const [eventCategoryList, setEventCategoryList] = useState([])
  const [eventPersonList, setEventPersonList] = useState([])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    await fetchEventCategories()
    await fetchEventPersons()
    fetchEvents()
  }
  const fetchEvents = async () => {
    const response = await fetch('/api/events')
    const data = await response.json()
    setEventList(data)
  }
  const fetchEventCategories = async () => {
    const response = await fetch('/api/event-categories')
    const data = await response.json()
    setEventCategoryList(data)
  }
  const fetchEventPersons = async () => {
    const response = await fetch('/api/event-persons')
    const data = await response.json()
    setEventPersonList(data)
  }

  const getItem = (item: any) => {
    const category: any = eventCategoryList.find((e: any) => e.id === item.category)
    const person: any = eventPersonList.find((e: any) => e.id === item.person)

    return {
      id: item.id,
      name: item.name,
      time: item.time,
      description: item.description,
      date: item.date,
      url: item.url,
      category_name: category ? category.name : '',
      person_name: person ? person.name : '',
      person_position: person ? person.position : '',
      person_image_url: person ? person.image_url : ''
    }
  }

  return (
    <PageSection bgColor={props.bgColor}>
      <SectionHeading>{nbspacer(props.heading ?? '')}</SectionHeading>
      {eventList.length === 0 && <NoEventCard />}
      <Stack gap={20}>
        {eventList.map((item: any) => {
          return <EventCard key={item.id} {...getItem(item)}/>
        })}
      </Stack>
    </PageSection>
  )
}

export default EventsSection
