import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import { DotArrowListCard, DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'

type WhenRelevantSectionProps = {
  bgColor?: Color;
  heading?: string;
  imageUrl?: string;
  bullets?: any[];
}

const WhenRelevantSection = (props: WhenRelevantSectionProps) => {
  return (
    <PageSection>
      <Stack gap={60}>
        <SectionHeading>{nbspacer(props.heading ?? '')}</SectionHeading>
        <Grid cols={2} columnGap={60} phoneCols={1}>
          <Stack gap={30}>
            {props.bullets?.map((item: DotArrowListCardProps) => {
              return <DotArrowListCard key={item.id} {...item} />
            })}
          </Stack>
          <Image src={props.imageUrl ?? ''} width='100%' />
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default WhenRelevantSection
