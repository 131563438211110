import ContactsBlock from 'components/ContactsBlock/ContactsBlock'
import PageSection from 'components/PageSection/PageSection'
import FooterSection from 'page-sections/FooterSection/FooterSection'
import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import styles from './Contacts.module.css'

const Contacts = () => {
  return (
    <div className={styles.Contacts}>
      <HeaderSection bgColor='white' className={styles.HeaderSection} />
      <PageSection>
        <ContactsBlock headingGap={120}/>
      </PageSection>
      <FooterSection showContacts={false} />
    </div>
  )
}

export default Contacts
