import Stack from 'components/Stack/Stack'
import FAQItem from 'components/v2/FAQItem/FAQItem'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'

const FAQSection = () => {
  type Item = {
    name: string;
    description: string;
  }
  const items: Array<Item> = [
    {
      name: 'Специалистов по каким стекам вы ищите?',
      description: 'В нашем портфолио успешный поиск и трудоустройство практически всех технических специалистов, которые встречаются на рынке. Перед поиском мы подробно погружаемся в задачи клиента, определяем портрет требуемого кандидата. Можем скорректировать ожидания клиента или порекомендовать оптимальный набор требований к кандидатам для экономии ресурсов без ущерба для выполнения задачи. Поберем специалиста любого уровня под ваш стек – от junior до senior.'
    },
    {
      name: 'Сколько кандидатов предоставляете?',
      description: 'В среднем 5-10 кандидатов на вакансию в зависимости от профиля и требований. Если вакансия редкая, то среднее количество кандидатов может быть меньше. Свяжитесь с нами, и наш рекрутер подробно вас проконсультирует.'
    },
    {
      name: 'У вас есть своя база кандидатов?',
      description: 'С 2020 года мы формируем собственную базу кандидатов, где на данный момент 15 000+ анкет профессионалов, 7000+ из которых уже прошли независимую оценку компетенций.'
    },
    {
      name: 'Осуществляете ли вы поиск в регионах?',
      description: 'Да, мы работаем с поиском специалистов по всей России.'
    },
    {
      name: 'Что делать, если сотрудник нужен «вчера» и нет времени долго согласовывать договор?',
      description: 'Мы ценим время наших заказчиков, поэтому можем начать работу по подбору специалиста в день обращения, а договор подпишем в процессе.'
    },
    {
      name: 'Что делать, если сотрудник уволится через месяц?',
      description: 'Мы проверяем мотивацию и компетенции каждого специалиста, поэтому уверены в них и предоставляем гарантию на трудоустройство 3 месяца. Если сотрудник по объективной причине не проработает этот срок, мы бесплатно подберем Вам нового специалиста.'
    },
    {
      name: 'Как быстро закрываете вакансии?',
      description: 'Все зависит от профиля и требований к вакансии. Среднее время закрытия вакансии – от 5 до 20 дней. Практика показывает, что вакансии закрываются быстрее, если заказчик вовлечен в процесс и оперативно взаимодействует с кандидатами.'
    },
    {
      name: 'Что будет, если мы сами закроем вакансию, пока работаем с вами?',
      description: 'У нас не предусмотрено системы предоплаты и каких-либо санкций за отмену подбора. Будем рады сотрудничеству по следующей вакансии.'
    }
  ]
  return (
    <PageSection variant='gray'>
      <Stack gap={30}>
        <Heading variant="2">Остались вопросы?</Heading>
        <Stack gap={30}>
          {items.map((item: Item, index: number) => {
            return <FAQItem key={index} {...item} />
          })}
        </Stack>
      </Stack>
    </PageSection>
  )
}
export default FAQSection
