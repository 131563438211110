import Stack from 'components/Stack/Stack'
import IconView from 'components/v2/IconView/IconView'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'
import CourseTargetAudienceCard, {CourseTargetAudienceCardProps} from 'components/v2/CourseTargetAudienceCard/CourseTargetAudienceCard'

const TargetAudienceSection = () => {
  const items: CourseTargetAudienceCardProps[] = [
    {
      image: 'checkbox',
      description: 'Руководителей коммерческих и государственных организаций'
    },
    {
      image: 'computer',
      description: 'Менеджеров подразделений, тимлидов, продакт-менеджеров'
    },
    {
      image: 'chip',
      description: <>Руководителей цифровой трансформации, ИТ<span style={{fontFamily: 'sans-serif'}}>&#8209;</span>директоров</>
    },
    {
      image: 'chart',
      description: 'Руководителей проектных офисов, департаментов'
    },
    {
      image: 'smartphone',
      description: 'Менеджеров проектов'
    }
  ]

  return (
    <PageSection variant='white-icons'>
      <Stack gap={60}>
        <Heading variant='2'>Для кого курс</Heading>
        <IconView
          cols={3}
          tabletCols={2}
          phoneCols={1}
          columnGap={20}
          rowGap={20}
        >
          {items.map((item, index) => {
            return <CourseTargetAudienceCard key={index} animateDelayIndex={index} {...item} />
          })}
        </IconView>
      </Stack>
    </PageSection>
  )
}

export default TargetAudienceSection
