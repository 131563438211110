import { Link, useLocation } from 'react-router-dom'
import styles from './MenuLink.module.css'

type MenuLinkProps = {
  name: String;
  url: string;
  onMouseDown?: any;
}

const MenuLink = ({name, url}: MenuLinkProps) => {
  const location = useLocation()
  let className = styles['menu-link']
  if (location.pathname === url) {
    className = className + ' ' + styles['menu-link--current']
  }

  const elProps: any = {
    to: url,
    className: className
  }

  const aProps: any = {
    href: url,
    className: className,
    target: '_blank',
    rel: 'noopener noreferrer'
  }

  return (
    <>
      {url.startsWith('http') && <a {...aProps}>{name}</a>}
      {!url.startsWith('http') && <Link {...elProps}>{name}</Link>}
    </>
  )
}

export default MenuLink
