import Color from 'common/Color.d'
import { AnimationVariant } from 'common/getAnimationStyle'
import Card from 'components/Card/Card'
import Image, { ImageProps } from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Text, { TextProps, TextVariant } from 'components/Text/Text'
import React from 'react'

type ImageCardProps = {
  elId?: number | string;
  bgColor?: Color;
  imageUrl: string;
  imageSize?: number;
  text: string;
  textVariant?: TextVariant;
  textBold?: boolean;
  animate?: boolean;
  animationVariant?: AnimationVariant;
  animateDelayIndex?: number;
}

const ImageCard = (props: ImageCardProps) => {
  const elProps = {
    id: props.elId,
    bgColor: props.bgColor,
    animate: props.animate,
    animateDelayIndex: props.animateDelayIndex,
    animationVariant: props.animationVariant
  }

  const imageProps: ImageProps = {
    src: props.imageUrl,
    height: props.imageSize ? `${props.imageSize}px` : '80px',
    width: props.imageSize ? `${props.imageSize}px` : '80px'
  }

  const textProps: TextProps = {
    variant: props.textVariant,
    fontWeight: 'medium',
    align: 'center'
  }

  return (
    <Card {...elProps} >
      <Stack gap={20} alignItems='center'>
        <Image {...imageProps} />
        <Text {...textProps}>{props.text}</Text>
      </Stack>
    </Card>
  )
}

export default ImageCard
export type { ImageCardProps }
