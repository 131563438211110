const heading: string = 'Структура формы для сбора данных для проведения оценки ИИ-зрелости ФОИВ'

const imageBullets = [
  {
    id: 1,
    imageUrl: '/static/img/pages/ai-eval/structure-icon1.svg',
    description: 'Реестр ИИ-решений'
  },
  {
    id: 2,
    imageUrl: '/static/img/pages/ai-eval/structure-icon2.svg',
    description: 'Данные'
  },
  {
    id: 3,
    imageUrl: '/static/img/pages/ai-eval/structure-icon3.svg',
    description: 'Управление'
  },
  {
    id: 4,
    imageUrl: '/static/img/pages/ai-eval/structure-icon4.svg',
    description: 'Кадры и культура'
  },
  {
    id: 5,
    imageUrl: '/static/img/pages/ai-eval/structure-icon4.svg',
    description: 'Процессы'
  },
  {
    id: 6,
    imageUrl: '/static/img/pages/ai-eval/structure-icon6.svg',
    description: 'Продукты'
  },
  {
    id: 7,
    imageUrl: '/static/img/pages/ai-eval/structure-icon7.svg',
    description: 'Модели и инструменты'
  },
  {
    id: 8,
    imageUrl: '/static/img/pages/ai-eval/structure-icon8.svg',
    description: 'Инфраструктура'
  }
]
export { heading, imageBullets }
