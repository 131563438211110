import nbspacer from 'common/nbspacer'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'

const HowToPrepareSection = () => {
  const items: Array<{name: string, description: string}> = [
    {
      name: 'Определите вопрос к консультанту',
      description: 'Например, анализ причин частых отказов или отсутствие откликов на резюме'
    },
    {
      name: 'Поставьте цель консультации',
      description: 'Например, хочу получить рекомендации по резюме или заполучить внимание работодателя'
    },
    {
      name: 'Какой результат стремитесь получить? ',
      description: 'Например, через 3 месяца сменить работу'
    },
    {
      name: 'Что конкретно вы хотите проработать с консультантом?',
      description: 'Запишите все основные и дополнительные вопросы'
    }
  ]
  return (
    <PageSection
      variant='white-icons'
      paddingTop={60}
    >
      <Stack gap={30}>
        <Heading
          variant='2'
          color='blue-gray'
        >
          {nbspacer('Как подготовиться?')}
        </Heading>

        <Stack
          orientation='horizontal'
          tabletOrientation='vertical'
          gap={20}
        >
          <Stack
            width='60%'
            tabletWidth='100%'
            gap={20}
          >
            {items.map((item: {name: string, description: string}, index) => {
              return (
                <Stack
                  key={index}
                  gap={20}
                >
                  <Stack gap={10}>
                    <Text
                      variant='text-m-medium'
                      color='emerald'
                    >
                      {nbspacer(item.name)}
                    </Text>
                    <Text variant='text-s'>{nbspacer(item.description)}</Text>
                  </Stack>
                  {items.length - 1 !== index && (
                    <div style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#E9F0F4'
                    }} />
                  )}
                </Stack>
              )
            })}
          </Stack>
          <Stack
            width='40%'
            tabletWidth='100%'
          >
            <Image
              src='/static/img/pages/career-management/how-to-prepare-section/section-image.svg'
              width='100%'
            />
          </Stack>
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default HowToPrepareSection
