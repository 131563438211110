import getCookie from 'common/get-coockie'
import Button from 'components/Button/Button'
import Checkbox from 'components/Checkbox/Checkbox'
import Link from 'components/Link/Link'
import Stack from 'components/Stack/Stack'
import TextField from 'components/TextField/TextField'
import Text from 'components/v2/Text/Text'
import React, { useEffect } from 'react'
import { Check, Mail, Phone } from 'react-feather'
import EmailVerification from './EmailVerification'
import PhoneVerification from './PhoneVerification'

type Step2IndividualProps = {
  onSetStep?: (step: 1 | 2) => void;
}

const Step2Individual = (props: Step2IndividualProps) => {
  const [currentState, setCurrentState] = React.useState<'edit' | 'emailVerification' | 'phoneVerification'>('edit')

  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [agreePd, setAgreePd] = React.useState(false)
  const [agreeOffer, setAgreeOffer] = React.useState(false)

  const agreePdLabel: React.ReactNode = <Stack orientation='horizontal' gap={8}>
    <Text variant='text-s'>Даю согласие на обработку</Text>
    <Link to='/static/docs/consent_to_the_processing_of_personal_data.pdf'>персональных данных</Link>
  </Stack>
  const agreeOfferLabel: React.ReactNode = <Stack orientation='horizontal' gap={8}>
    <Text variant='text-s'>Я согласен с условиями</Text>
    <a href='/media/role_catalog/offer.pdf' target='_blank'>оферты</a>
  </Stack>

  const [emailVerified, setEmailVerified] = React.useState(false)
  const [phoneVerifyUrl, setPhoneVerifyUrl] = React.useState<string | null>(null)
  const [phoneVerified, setPhoneVerified] = React.useState(false)

  useEffect(() => {
  }, [phoneVerifyUrl])

  const verifyEmail = async () => {
    setCurrentState('emailVerification')
  }

  const onEmailVerificationSuccess = () => {
    setEmailVerified(true)
    setCurrentState('edit')
  }

  const onEmailVerificationError = () => {
    setCurrentState('edit')
  }

  const onEmailVerificationCancel = () => {
    setCurrentState('edit')
  }

  const getPhoneVerificationUrl = async () => {
    const body = {
      phone: phone,
      name: name,
      email: email
    }

    const csrftoken = getCookie('csrftoken')
    const url = `/api/role-catalog/phone/verify`
    let params: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, params)
    if (response.ok) {
      const data = await response.json()
      setPhoneVerifyUrl(data.url)
      setCurrentState('phoneVerification')
    } else {
      console.log('error')
    }
  }

  const makePayment = async (data: any) => {
    const form = document.createElement('form')
    var element1 = document.createElement('input')
    var element2 = document.createElement('input')
    var element3 = document.createElement('input')
    var element4 = document.createElement('input')
    var element5 = document.createElement('input')
    var element6 = document.createElement('input')
    var element7 = document.createElement('input')
    var element8 = document.createElement('input')
    var element9 = document.createElement('input')
    var element10 = document.createElement('input')
    var element11 = document.createElement('input')

    form.method = 'POST'
    form.action = 'https://pay.ranepa.ru/process/'

    element1.name = 'amount'
    element1.value = data['amount']
    form.appendChild(element1)

    element2.name = 'order_json'
    element2.value = data['order_json']
    form.appendChild(element2)

    element3.name = 'payer_json'
    element3.value = data['payer_json']
    form.appendChild(element3)

    element4.name = 'order_name'
    element4.value = data['order_name']
    form.appendChild(element4)

    element5.name = 'order_number'
    element5.value = data['order_number']
    form.appendChild(element5)

    element6.name = 'payment'
    element6.value = data['payment']
    form.appendChild(element6)

    element7.name = 'response_api'
    element7.value = data['response_api']
    form.appendChild(element7)

    element8.name = 'response_fail'
    element8.value = data['response_fail']
    form.appendChild(element8)

    element9.name = 'response_res'
    element9.value = data['response_res']
    form.appendChild(element9)

    element10.name = 'system_code'
    element10.value = data['system_code']
    form.appendChild(element10)

    element11.name = 'token'
    element11.value = data['token']
    form.appendChild(element11)

    document.body.appendChild(form)

    form.submit()
  }

  const createOrder = async () => {
    const body = {
      phone: phone,
      name: name,
      email: email
    }
    const csrftoken = getCookie('csrftoken')
    const url = `/api/role-catalog/individual-orders`
    let params: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, params)
    if (response.ok) {
      const data = await response.json()
      makePayment(data)
    } else {
      console.log('error')
    }
  }

  const onPhoneVerificationSuccess = () => {
    setPhoneVerified(true)
    setPhoneVerifyUrl(null)
    setCurrentState('edit')
  }

  const onPhoneVerificationError = () => {
    setPhoneVerifyUrl(null)
    setCurrentState('edit')
  }

  const onPhoneVerificationCancel = () => {
    setPhoneVerifyUrl(null)
    setCurrentState('edit')
  }

  const isVerifyEmailButtonDisabled = () => {
    return !agreePd ||
      !agreeOffer ||
      name === '' ||
      email === ''
  }

  const isVerifyPhoneButtonDisabled = () => {
    const formattedPhone = phone.replace(/[^\d]/g, '').replace(/^7/, '8')
    if (formattedPhone.length !== 11 || formattedPhone[0] !== '8') {
      return true
    }
    return !agreePd ||
      !agreeOffer ||
      name === '' ||
      email === '' ||
      phone === ''
  }

  const isStartPaymentButtonEnabled = () => {
    return !agreePd ||
      !agreeOffer ||
      name === ''
  }

  return (
    <>
      {currentState === 'emailVerification' &&
        <EmailVerification
          name={name}
          email={email}
          onVerificationSuccess={onEmailVerificationSuccess}
          onVerificationError={onEmailVerificationError}
          onVerificationCancel={onEmailVerificationCancel}
        />
      }

      {phoneVerifyUrl && <PhoneVerification
        url={phoneVerifyUrl}
        onVerificationSuccess={onPhoneVerificationSuccess}
        onVerificationError={onPhoneVerificationError}
        onVerificationCancel={onPhoneVerificationCancel}
      />}

      {currentState === 'edit' &&
        <Stack
          gap={40}
          style={{ maxWidth: '530px' }}
        >
          <TextField
            name='name'
            value={name}
            blockElement
            fullWidth
            required
            autoComplete='off'
            placeholder='ФИО'
            onInput={(e) => setName(e.target.value)}
          />

          {!emailVerified &&
            <TextField
              name='email'
              type='email'
              value={email}
              blockElement
              fullWidth
              required
              autoComplete='off'
              placeholder='E-mail'
              onInput={(e) => setEmail(e.target.value)}
            />
          }
          {emailVerified &&
            <Stack orientation='horizontal' gap={8} alignItems='center'>
              <Mail size={24} color='#699D9A' />
              <Text color='emerald'>{email}</Text>
              <Check size={24} color='#699D9A' />
            </Stack>
          }

          {!phoneVerified &&
            <TextField
              name='phone'
              type='phone'
              value={phone}
              blockElement
              fullWidth
              required
              autoComplete='off'
              placeholder='Телефон'
              onInput={(e) => setPhone(e.target.value)}
            />
          }
          {phoneVerified &&
            <Stack orientation='horizontal' gap={8} alignItems='center'>
              <Phone size={24} color='#699D9A' />
              <Text color='emerald'>{phone}</Text>
              <Check size={24} color='#699D9A' />
            </Stack>
          }

          <Stack gap={20}>
            <div>
              <Checkbox
                name='agreePd'
                onChange={() => setAgreePd(!agreePd)}
                label={agreePdLabel}
                checked={agreePd}
              />
            </div><div>
              <Link
                to='https://disk.yandex.ru/i/mRz0zVJIdkThlA'>
                <Text style={{textDecoration: 'underline'}} color='emerald' variant='text-s'>Политика РАНХиГС в отношении обработки персональных данных</Text>
              </Link>
            </div>
            <div>
              <Checkbox
                name='agreeOffer'
                defaultChecked
                onChange={() => setAgreeOffer(!agreeOffer)}
                label={agreeOfferLabel}
                checked={agreeOffer}
              />
            </div>
          </Stack>

          <Stack orientation='horizontal' gap={10}>
            <Button
              onClick={() => props.onSetStep?.(1)}
            >
              &lt; Назад
            </Button>

            {!emailVerified &&
              <Button
                variant='filled'
                onClick={() => { verifyEmail() }}
                disabled={isVerifyEmailButtonDisabled()}
              >
                Подтвердить E-mail
              </Button>
            }

            {emailVerified && !phoneVerified && phoneVerifyUrl === null &&
              <Button
                variant='filled'
                onClick={() => { getPhoneVerificationUrl() }}
                disabled={isVerifyPhoneButtonDisabled()}
              >
                Подтвердить телефон
              </Button>
            }

            {emailVerified && phoneVerified &&
              <Button
                variant='filled'
                disabled={isStartPaymentButtonEnabled()}
                onClick={() => { createOrder() }}
              >
                Перейти к оплате
              </Button>
            }
          </Stack>
        </Stack>
      }
    </>
  )
}

export default Step2Individual
