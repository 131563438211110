import PageSection from 'components/v2/PageSection/PageSection'
import { useState, useEffect } from 'react'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Grid from 'components/Grid/Grid'
import ExpertCard from '../components/ExpertCard'

type Expert = {
  id: number;
  name: string;
  position: string;
  image_url: string;
  expert_group_id: number;
}

type ExpertGroup = {
  id: number;
  name: string;
  experts: Expert[];
}

const ExpertsSection = () => {
  const [experts, setExperts] = useState([])
  const [expertGroups, setExpertGroups] = useState([])

  useEffect(() => {
    fetchExpertGroups()
    fetchExperts()
  }, [])

  const fetchExperts = async () => {
    const response = await fetch('/api/experts')
    const data = await response.json()
    setExperts(data)
  }

  const fetchExpertGroups = async () => {
    const response = await fetch('/api/expert_groups')
    const data = await response.json()
    setExpertGroups(data)
  }

  const getExpertsByGroupId = (groupId: number) => {
    return experts.filter((expert: Expert) => expert.expert_group_id === groupId)
  }

  return (
    <PageSection variant='white-icons'>
      <Stack gap={80}>
        {expertGroups.map((expertGroup: ExpertGroup, index: number) => {
          return (
            <Stack
              key={index}
              gap={40}
            >
              <Heading variant='1'>{expertGroup.name}</Heading>
              <Grid
                cols={4}
                tabletCols={2}
                phoneCols={1}
                columnGap={50}
                rowGap={40}
              >
                {getExpertsByGroupId(expertGroup.id).map((expert: Expert, index: number) => {
                  return (
                    <ExpertCard key={index} {...expert} />
                  )
                })}
              </Grid>
            </Stack>
          )
        })}
      </Stack>
    </PageSection>
  )
}

export default ExpertsSection
