import Carousel from 'components/Carousel/Carousel'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'

const InfoSection = () => {
  return (
    <PageSection variant='gray'>
      <Grid
        cols={2}
        columnGap={80}
      >
        {/* Left */}
        <Stack gap={20}>
          <Heading variant='2'>Команда цифрового развития организации: 44 профиля ролей-</Heading>
          <Text variant='text-m'>
          уникальный каталог ролей для руководителей и HR-специалистов в поиске, подборе и оценке профессионалов для решения ИТ-задач.
            <br />
          Был создан на основе Каталога ролей команд цифровой трансформации (свидетельство о государственной регистрации базы данных №2023621064) и дополнен новыми актуальными ролями в области цифровой трансформации и управления проектами.
          </Text>
        </Stack>

        {/* Right */}
        <Carousel quantity={1}>
          <Image src='/static/img/pages/role-catalog/info-section/image1.svg' />
          <Image src='/static/img/pages/role-catalog/info-section/image2.svg' />
          <Image src='/static/img/pages/role-catalog/info-section/image3.svg' />
          <Image src='/static/img/pages/role-catalog/info-section/image4.svg' />
        </Carousel>

      </Grid>
    </PageSection>
  )
}

export default InfoSection
