const heading: string = 'Другие направления работы Центра'

const imageUrl: string = '/static/img/pages/selection-evaluation/services-image.svg'

const text: any = <>
  <ul>
    <li>Содействие в подборе персонала</li>
    <li>Оценка компетенций команд</li>
  </ul>
</>

const linkText: string = 'Оставить заявку'
const linkUrl: string = ''

export { heading, imageUrl, linkText, linkUrl, text }
