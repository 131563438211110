import React from 'react'
import Color from 'common/Color.d'
import colors from 'common/colors'
import getAnimationStyle, { AnimationVariant } from 'common/getAnimationStyle'
import useScrolledTo from 'common/useScrolledTo'
import useMediaQuery from 'common/useMediaQuery'

type HeadingVariant = '1' | '2' | '3'

type HeadingProps = {
  variant: HeadingVariant;
  children?: React.ReactNode;
  color?: Color;
  className?: string;
  maxWidth854?: boolean;
  maxWidth?: React.CSSProperties['maxWidth'];
  align?: React.CSSProperties['textAlign'],
  textOverflow?: React.CSSProperties['textOverflow'],
  overflow?: React.CSSProperties['overflow'],
  overflowY?: React.CSSProperties['overflowY'],
  overflowX?: React.CSSProperties['overflowX'],
  animate?: boolean;
  animateDelayIndex?: number;
  animationVariant?: AnimationVariant;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void;
  uppercase?: boolean;
}

const Heading = (props: HeadingProps) => {
  const { isTablet } = useMediaQuery()
  const elRef = React.useRef<HTMLHeadingElement>(null)
  const scrolledTo = useScrolledTo(elRef)

  const handleClick = (e: any) => {
    props.onClick && props.onClick(e)
  }

  const getFontFamily = (variant: HeadingVariant) => {
    if (variant === '1') {
      return 'Montserrat'
    }
    if (variant === '2') {
      return 'Montserrat'
    }
    if (variant === '3') {
      return 'IBM Plex Sans'
    }
  }

  const getFontSize = (variant: HeadingVariant) => {
    if (variant === '1') {
      return isTablet ? '40px' : '45px'
    }
    if (variant === '2') {
      return isTablet ? '33px' : '38px'
    }
    if (variant === '3') {
      return isTablet ? '19px' : '24px'
    }
  }

  const getFontWeight = (variant: HeadingVariant) => {
    if (variant === '1') {
      return '900'
    }
    if (variant === '2') {
      return '800'
    }
    if (variant === '3') {
      return '700'
    }
  }

  const getLineHeight = (variant: HeadingVariant) => {
    if (variant === '1') {
      return '110%'
    }
    if (variant === '2') {
      return '110%'
    }
    if (variant === '3') {
      return '150%'
    }
  }

  let style: React.CSSProperties = {
    fontFamily: getFontFamily(props.variant),
    fontSize: getFontSize(props.variant),
    fontWeight: getFontWeight(props.variant),
    lineHeight: getLineHeight(props.variant),
    textAlign: props.align,
    color: colors[props.color ?? 'black'],
    maxWidth: props.maxWidth ?? (props.maxWidth854 ? '854px' : '100%'),
    textOverflow: props.textOverflow,
    overflow: props.overflow,
    overflowY: props.overflowY,
    overflowX: props.overflowX,
    textTransform: props.uppercase ? 'uppercase' : 'none'
  }

  if (props.animate) {
    style = {
      ...style,
      ...getAnimationStyle(scrolledTo, props.animationVariant, props.animateDelayIndex)
    }
  }

  const componentProps = {
    style: {...style, ...props.style},
    className: props.className,
    ref: elRef,
    onClick: handleClick
  }

  if (props.variant === '1') {
    return <h1 {...componentProps}>{props.children}</h1>
  }
  if (props.variant === '2') {
    return <h2 {...componentProps}>{props.children}</h2>
  }
  if (props.variant === '3') {
    return <h3 {...componentProps}>{props.children}</h3>
  }

  return <h3 {...componentProps}>{props.children}</h3>
}

export default Heading
export type { HeadingProps, HeadingVariant }
