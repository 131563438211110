import { NumberListCardProps } from 'components/NumberListCard/NumberListCard'

const heading: string = 'Управленческий и HR-консалтинг позволяет ответить на вопросы'
const itemList: NumberListCardProps[] = [
  {
    id: 1,
    number: 1,
    description: 'Какие действия нужно совершить, чтобы достичь желаемого результата наиболее эффективно?'
  },
  {
    id: 2,
    number: 2,
    description: 'Как оптимизировать систему управления без потери качества? Как масштабировать бизнес?'
  },
  {
    id: 3,
    number: 3,
    description: 'Где я хочу быть через год, через пять лет?'
  },
  {
    id: 4,
    number: 4,
    description: 'Какую пользу/ценность я/мы несем нашим клиентам/миру?'
  },
  {
    id: 5,
    number: 5,
    description: 'Как «выжить», если внешние условия изменятся?'
  }
]

export { heading, itemList }
