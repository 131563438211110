import React from 'react'
import styles from './Button.module.css'
import { Link } from 'react-router-dom'

// type ButtonType = 'button' | 'sumbit' | 'reset'
type ButtonVariant = 'default' | 'large' | 'filled' | 'outlined' | 'filled-and-outlined' | 'simple' | 'filled-white' | 'outlined-white'

type ButtonProps = {
  children?: React.ReactNode;
  onClick?: any;
  onMouseDown?: any;
  variant?: ButtonVariant;
  disabled?: boolean;
  type?: any;
  href?: string;
  className?: string;
  style?: React.CSSProperties;
  target?: string | '_blank' | '_self' | '_parent' | '_top';
  rel?: 'alternate' | 'author' | 'bookmark' | 'external' | 'help' | 'license' |
   'next' | 'nofollow' | 'noreferrer' | 'noopener' | 'prev' | 'search' | 'tag';
}

const Button = (props: ButtonProps) => {
  const handleClick = (e: any) => {
    if ('onClick' in props) {
      props.onClick(e)
    }
  }

  const handleMouseDown = (e: any) => {
    if ('onMouseDown' in props) {
      props.onMouseDown(e)
    }
  }

  const _variant = props.variant ?? 'default'
  let _className = styles['button']

  _className = _variant === 'large' ? `${_className} ${styles['button--large']}` : _className
  _className = _variant === 'filled' ? `${_className} ${styles['button--filled']}` : _className
  _className = _variant === 'outlined' ? `${_className} ${styles['button--outlined']}` : _className
  _className = _variant === 'filled-and-outlined' ? `${_className} ${styles['button--filled-and-outlined']}` : _className
  _className = _variant === 'simple' ? `${_className} ${styles['button--simple']}` : _className
  _className = _variant === 'filled-white' ? `${_className} ${styles['button--filled-white']}` : _className
  _className = _variant === 'outlined-white' ? `${_className} ${styles['button--outlined-white']}` : _className
  _className = props.className ? `${_className} ${props.className}` : _className

  const elProps: any = {
    type: props.type ?? 'button',
    disabled: props.disabled,
    className: _className,
    onClick: handleClick,
    onMouseDown: handleMouseDown,
    to: props.href,
    href: props.href,
    target: props.target,
    rel: props.rel,
    style: props.style
  }

  return (
    <>
      {props.href && props.href.startsWith('http') &&
        <a {...elProps}>
          {props.children}
        </a>
      }
      {props.href && !props.href.startsWith('http') &&
        <Link {...elProps}>
          {props.children}
        </Link>
      }
      {!props.href &&
      <button {...elProps}>
        {props.children}
      </button>
      }
    </>
  )
}

export default Button
export { Button }
export type { ButtonVariant }
