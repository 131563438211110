
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import FooterSection from 'page-sections/FooterSection/FooterSection'
import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import React, { useEffect, useState } from 'react'

import Button from 'components/Button/Button'
import QuestionSection from 'page-sections/QuestionSection/QuestionSection'
import Grid from 'components/Grid/Grid'
import NewsCard from 'pages/Community/NewsSection/NewsCard/NewsCard'
import Stack from 'components/Stack/Stack'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import useMediaQuery from 'common/useMediaQuery'

type NewsItemData = {
  name: string;
  date: string;
  content: string;
}

const News = () => {
  const [latestNewsList, setLatestNewsList] = useState([] as NewsItemData[])
  const [newsList, setNewsList] = useState([] as NewsItemData[])
  const [newsCategoryList, setNewsCategroyList] = useState([] as NewsItemData[])
  const [selectedCategoryId, setSelectedCategoryId] = useState(null as null | number)
  const { isTablet } = useMediaQuery()

  useEffect(() => {
    fetchNewsCategories()
    fetchLatestNews()
    fetchNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryId])

  const fetchLatestNews = async () => {
    const url = `/api/news/`
    let params:any = {
      limit: 3,
      order: '-date',
      sticky: true
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setLatestNewsList(data)
    }
  }

  const fetchNews = async () => {
    const url = `/api/news/`
    let params:any = {
      order: '-date'
    }
    if (selectedCategoryId) {
      params['category_id'] = selectedCategoryId
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setNewsList(data)
    }
  }

  const fetchNewsCategories = async () => {
    const response = await fetch(`/api/news-categories/`)
    if (response.ok) {
      const data = await response.json()
      setNewsCategroyList(data)
    }
  }

  const fetchMore = () => {
    fetchNews()
  }

  const sectionStyle: React.CSSProperties = {
    paddingTop: 0,
    alignItems: isTablet ? '' : 'center'
  }

  return (
    <div>
      <HeaderSection />

      <PageSection bgColor='light-gray' style={sectionStyle}>
        <SectionHeading>Новости</SectionHeading>

        {latestNewsList.length > 0 && <Grid cols={2} tabletCols={1} columnGap={20} rowGap={20}>
          {latestNewsList.slice(0, 1).map((item: any) => {
            return <NewsCard tallImage key={item.id} {...item} />
          })}
          {latestNewsList.length > 1 &&
            <Grid rowGap={20}>
              {latestNewsList.slice(1, latestNewsList.length).map((item: any) => {
                return <NewsCard orientation='horizontal' key={item.id} {...item} />
              })}
            </Grid>
          }
        </Grid>}

        <Stack orientation='horizontal' justifyContent={'flex-start'} wrap='wrap' gap={20}>
          <SwitchButton
            pressed={selectedCategoryId === null}
            onClick={() => setSelectedCategoryId(null)}
          >
            Все новости
          </SwitchButton>
          {newsCategoryList.map((item: any) => {
            return (<SwitchButton
              key={item.id}
              pressed={selectedCategoryId === item.id}
              onClick={() => setSelectedCategoryId(item.id)}
            >
              {item.name}
            </SwitchButton>)
          })}
        </Stack>

        <Grid columnGap={20} rowGap={20} cols={3} tabletCols={2} phoneCols={1}>
          {newsList.map((item: any) => {
            return <NewsCard key={item.id} {...item} />
          })}
        </Grid>

        {false && <Stack alignItems={'center'}>
          <Button variant='filled' onClick={fetchMore}>Показать еще</Button>
        </Stack>}

      </PageSection>

      <QuestionSection id='question-section' />

      <FooterSection />
    </div>
  )
}

export default News
