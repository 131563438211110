import HeaderSectionBase from 'page-sections/v2/HeaderSection/HeaderSection'
import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Button from 'components/Button/Button'

import scrollToId from 'common/scroll-to-id'

const HeaderSection = () => {
  return (
    <HeaderSectionBase>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={160}
        rowGap={24}
      >
        {/* left */}
        <Stack gap={40}>
          <Stack>
            <Heading
              variant='1'
              color='white'
            >
              Введение в государственное и муниципальное управление
            </Heading>
          </Stack>
          <div>
            <Stack gap={40} orientation='horizontal'>
              <Button
                variant='filled-white'
                href='https://my-cdto.gspm.ranepa.ru/registration/commerce'
              >
                  Записаться на курс
              </Button>
              <Button
                variant='outlined-white'
                onClick={() => scrollToId('question-section')}
              >
                Оставить заявку
              </Button>
            </Stack>
          </div>
        </Stack>

        {/* right */}
        <Image
          src='/static/img/pages/program-registry/state-administration/header-section/section-image.svg'
          width='100%'
        />
      </Grid>
    </HeaderSectionBase>
  )
}

export default HeaderSection
