import Heading from 'components/Heading/Heading'
import React from 'react'

import styles from './ArrowPivotItem.module.css'
import arrow from './assets/arrow.png'
import arrowActive from './assets/arrow--active.png'

type ArrowPivotItemProps = {
  text: string;
  active?: boolean;
  onClick?: any;
}

const ArrowPivotItem = (props: ArrowPivotItemProps) => {
  const handleClick = (e: any) => {
    if ('onClick' in props) {
      props.onClick(e)
    }
  }

  return (
    <div
      onClick={handleClick}
      className={styles['arrow-pivot-item']}
    >
      <div
        className={styles['arrow-pivot-item__text'] + (props.active ? ` ${styles['arrow-pivot-item__text--active']}` : '')}
      >
        <Heading
          variant='3'
          color={props.active ? 'white' : 'black'}
        >{props.text}</Heading>
      </div>
      <img
        className={styles['arrow-pivot-item__image']}
        src={props.active ? arrowActive : arrow}
      />
    </div>
  )
}

export default ArrowPivotItem
