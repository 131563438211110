import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import Grid from 'components/Grid/Grid'
import { NumberListCard, NumberListCardProps} from 'components/NumberListCard/NumberListCard'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import React from 'react'

type AdvertisingSectionProps = {
  bgColor?: Color;
  heading?: string;
  bullets?: NumberListCardProps[];
}

const AdvertisingSection = (props: AdvertisingSectionProps) => {
  return (
    <PageSection bgColor={props.bgColor}>
      <SectionHeading>{nbspacer(props.heading ?? '')}</SectionHeading>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={40}
        rowGap={70}
        tabletRowGap={30}
      >
        {props.bullets?.map((item: any, index: number) => {
          return <NumberListCard key={item.id} number={index + 1} {...item} />
        })}
      </Grid>
    </PageSection>
  )
}

export default AdvertisingSection
