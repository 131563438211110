import Color from 'common/Color.d'
import DotArrowListCard, { DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'

import { heading, itemList } from './data'

type AdvantagesSectionProps = {
  id?: number | string;
  bgColor?: Color;
}

const AdvantagesSection = (props: AdvantagesSectionProps) => {
  return (
    <PageSection {...props}>
      <SectionHeading>{heading}</SectionHeading>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={20}
        rowGap={20}
      >
        <Stack gap={60} tabletGap={40} tabletReverse>
          <Stack gap={20}>
            {itemList.slice(0, 3).map((item: DotArrowListCardProps) => {
              return (
                <DotArrowListCard
                  key={item.id}
                  {...item}
                />
              )
            })}
          </Stack>
          <Image
            src='/static/img/pages/expert-support/advantages-image1.svg'
            width='100%'
          />
        </Stack>
        <Stack gap={60} tabletGap={20}>
          <Image
            src='/static/img/pages/expert-support/advantages-image2.svg'
            tabletHide
            width='100%'
          />
          <Stack gap={20}>
            {itemList.slice(-2).map((item: DotArrowListCardProps) => {
              return (
                <DotArrowListCard
                  key={item.id}
                  {...item}
                />
              )
            })}
          </Stack>
        </Stack>
      </Grid>
    </PageSection>
  )
}

export default AdvantagesSection
