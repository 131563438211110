import React from 'react'

import HeaderSection from './HeaderSection'
import QuestionSection from '../page-sections/QuestionSection/QuestionSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'

import AboutCourseSection from './AboutCourseSection'

import WillLearnSection from './WillLearnSection'
import CourseContentSection from 'pages/ProgramRegistry/page-sections/CourceContentSection'
import CertificateSection from '../page-sections/CertificateSection'
import PriceSection from 'pages/ProgramRegistry/page-sections/PriceSection'
import ExpertsSection from '../page-sections/ExpertsSection'
import InfoVideoSection from '../page-sections/InfoVideoSection'
import ProgramDescriptionSection from '../page-sections/ProgramDescriptionSection'
import FaqSection from '../page-sections/FaqSection'

const ItManagmentPracticePage = () => {
  return (
    <div>
      <HeaderSection />

      <AboutCourseSection />

      {false && <InfoVideoSection
        variant='gray'
        src='https://play.boomstream.com/Pu8VJ54U'
      />}

      <WillLearnSection />

      <ProgramDescriptionSection
        items={[
          {
            name: '1. ИТ и архитектура организации ',
            items: [
              'Узнаете, что такое ИТ',
              'Познакомитесь с практикой управления ИТ-инфраструктурой организации'
            ]
          },
          {
            name: '2. Устройство облачной инфраструктуры',
            items: [
              'Разберетесь, чем традиционная ИТ-инфраструктура отличается от облачной, и узнаете, какие виды облачных инфраструктур бывают',
              'Поймете, как осуществить миграцию в облако'
            ]
          },
          {
            name: '3. Технологии обеспечения технологического суверенитета. Импортонезависимость',
            items: [
              'Узнаете, что такое технологический суверенитет и как его достичь',
              'Узнаете, как российские компании переходят на российское ПО'
            ]
          },
          {
            name: '4. Развитие ИТ-инфраструктуры на практике',
            items: [
              'Изучите инвестиционно-финансовый аспект развития ИТ-инфраструктуры',
              'Узнаете, как планировать жизненный цикл ИТ-активов и управлять им'
            ]
          },
          {
            name: '5. Внедрение ИТ-услуг в работу организации',
            items: [
              'Познакомитесь с этапами проектирования ИТ-услуги и методологией продуктового подхода',
              'Поймете, чем отличается непрерывная поставка от непрерывного развертывания',
              'Поймете, в чем заключается качество ИТ-услуг и что нужно учесть, чтобы создать ценность по ITIL4'
            ]
          },
          {
            name: '6. Управление рисками',
            items: [
              'Узнаете, как управлять инцидентами, проблемами и запросами',
              'Изучите инструменты мониторинга событий и управления ими'
            ]
          },
          {
            name: 'Практика',
            items: [
              'Оцените уровень зрелости управления ИТ-инфраструктурой в вашей организации',
              'Составите план по миграции инфраструктуры вашей организации в облако',
              'Определите типы ИТ-активов',
              'Определите процессы управления ИТ-активами, сформированные в вашей организации',
              'Идентифицируйте и опишите риски'
            ]
          }
        ]}
      />

      <ExpertsSection
        experts={['boganov', 'mirzaev', 'gontar']}
        paddingBottom={0}
      />

      <CourseContentSection />

      <CertificateSection variant='gray' />

      {false && <PriceSection />}

      <FaqSection />

      <QuestionSection
        defaultPurposeOfRequest='program_registry_it_managment_practice'
        id={'question-section'}
      />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default ItManagmentPracticePage
