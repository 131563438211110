import React from 'react'
import HeaderSection from './HeaderSection'
import ProgramsSection1 from './ProgramsSection1'
import ProgramsSection2 from './ProgramsSection2'
import CertificateSection from '../page-sections/CertificateSection'
import QuestionSection from 'page-sections/v2/QuestionSection/QuestionSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'

const ProgramRegistryPage = () => {
  return (
    <div>
      <HeaderSection />

      <ProgramsSection1 />
      <ProgramsSection2 />
      <CertificateSection variant='gray' />
      <QuestionSection
        id={'question-section'}
        defaultPurposeOfRequest='program_registry'
      />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default ProgramRegistryPage
