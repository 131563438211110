import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'

const WhereFindSection = () => {
  const items: Array<string> = [
    'Собственная база профессионалов (15000+ анкет)',
    'Профессиональные сервисы',
    'Закрытые тематические форумы',
    'Социальные сети',
    'Нетворкинг'
  ]
  return (
    <PageSection variant='white-icons'>
      <Card bgColor='light-gray'>
        <Stack gap={30}>
          <Heading variant='2'>Где находим <span style={{color: '#00826F'}}>ИТ-специалистов</span></Heading>

          <Grid
            columnGap={80}
            rowGap={20}
            cols={2}
            tabletCols={1}
          >
            {/* left */}
            <Stack gap={20}>
              {items.map((item: string, index: number) => {
                return <TextWithDot
                  key={index}
                  dotVariant='rounded'
                >
                  <Heading variant='3'>{item}</Heading>
                </TextWithDot>
              })}
            </Stack>
            {/* right */}
            <Image
              width='100%'
              src='/static/img/pages/recruiting/digital-transformation-specialists/where-find-section/section-image.svg'
            />
          </Grid>
        </Stack>
      </Card>

    </PageSection>
  )
}

export default WhereFindSection
