import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Quote, { QuoteProps } from './Quote/Quote'
import Text from 'components/Text/Text'
import { quoteList } from './data'
import nbspacer from 'common/nbspacer'
import Color from 'common/Color.d'
import Grid from 'components/Grid/Grid'
import Persona from 'components/Persona/Persona'
import Stack from 'components/Stack/Stack'
import useMediaQuery from 'common/useMediaQuery'

type AboutSectionProps = {
  heading?: string;
  text?: string;
  bgColor?: Color;
}

const AboutSection = (props: AboutSectionProps) => {
  const {isTablet} = useMediaQuery()
  const personaProps: any = {
    horizontal: true,
    imageSize: 80,
    nameTextVariant: 'small',
    nameFontWeight: 'medium',
    positionTextVariant: 'small',
    imageGap: 16
  }

  return (
    <PageSection bgColor={props.bgColor}>
      <SectionHeading animate animationVariant='appear-up'>{nbspacer(props.heading ?? '')}</SectionHeading>

      <Text maxWidth854>
        <div dangerouslySetInnerHTML={{__html: nbspacer(props.text ?? '')}} />
      </Text>

      {!isTablet &&
        <Stack gap={60} tabletGap={30}>
          <Grid cols={2} columnGap={20} rowGap={20}>
            {quoteList.slice(0, 2).map((item: QuoteProps) => {
              return <Quote key={item.id} {...item} />
            })}
            {quoteList.slice(0, 2).map((item: QuoteProps) => {
              return (<div key={item.id} style={{paddingLeft: '48px'}}>
                <Persona {...item} {...personaProps} />
              </div>)
            })}
          </Grid>
          <Grid cols={2} columnGap={20} rowGap={20}>
            {quoteList.slice(-1).map((item: QuoteProps) => {
              return <Quote key={item.id} {...item} />
            })}
            <div></div>
            {quoteList.slice(-1).map((item: QuoteProps) => {
              return (<div key={item.id} style={{paddingLeft: '48px'}}>
                <Persona {...item} {...personaProps} />
              </div>)
            })}
          </Grid>
        </Stack>
      }
      {isTablet &&
        <Stack gap={40}>
          {quoteList.map((item: QuoteProps) => {
            return <Stack key={item.id} gap={20}>
              <Quote {...item} />
              <div key={item.id} style={{paddingLeft: '48px'}}>
                <Persona {...item} {...personaProps} />
              </div>
            </Stack>
          })}
        </Stack>
      }
    </PageSection>
  )
}

export default AboutSection
