import React from 'react'
import Color from 'common/Color.d'
import colors from 'common/colors'
import getAnimationStyle, { AnimationVariant } from 'common/getAnimationStyle'
import useScrolledTo from 'common/useScrolledTo'

type HeadingVariant = '1' | '2' | '3'

type HeadingProps = {
  variant: HeadingVariant;
  children?: React.ReactNode;
  color?: Color;
  className?: string;
  maxWidth854?: boolean;
  maxWidth?: React.CSSProperties['maxWidth'];
  align?: React.CSSProperties['textAlign'],
  textOverflow?: React.CSSProperties['textOverflow'],
  overflow?: React.CSSProperties['overflow'],
  overflowY?: React.CSSProperties['overflowY'],
  overflowX?: React.CSSProperties['overflowX'],
  animate?: boolean;
  animateDelayIndex?: number;
  animationVariant?: AnimationVariant;
}

const Heading = (props: HeadingProps) => {
  const elRef = React.useRef<HTMLHeadingElement>(null)
  const scrolledTo = useScrolledTo(elRef)

  let style: React.CSSProperties = {
    textAlign: props.align,
    color: colors[props.color ?? 'black'],
    maxWidth: props.maxWidth ?? (props.maxWidth854 ? '854px' : '100%'),
    textOverflow: props.textOverflow,
    overflow: props.overflow,
    overflowY: props.overflowY,
    overflowX: props.overflowX
  }

  if (props.animate) {
    style = {
      ...style,
      ...getAnimationStyle(scrolledTo, props.animationVariant, props.animateDelayIndex)
    }
  }

  const componentProps = {
    style: style,
    className: props.className,
    ref: elRef
  }

  if (props.variant === '1') {
    return <h1 {...componentProps}>{props.children}</h1>
  }
  if (props.variant === '2') {
    return <h2 {...componentProps}>{props.children}</h2>
  }
  if (props.variant === '3') {
    return <h3 {...componentProps}>{props.children}</h3>
  }

  return <h3 {...componentProps}>{props.children}</h3>
}

export default Heading
export type { HeadingProps, HeadingVariant }
