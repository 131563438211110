import { ImageCardProps } from 'components/ImageCard/ImageCard'

const heading: string = 'Возможные совместные проекты'

const itemList: ImageCardProps[] = [
  {
    imageUrl: '/static/img/pages/expert-support/joint-projects-image1.svg',
    text: 'Разработка стратегических и тактических управленческих документов'
  },
  {
    imageUrl: '/static/img/pages/expert-support/joint-projects-image2.svg',
    text: 'Создание практических инструментов управления и методических рекомендаций'
  },
  {
    imageUrl: '/static/img/pages/expert-support/joint-projects-image3.svg',
    text: 'Сбор лучших практик и кейсов ЦТ в России и за рубежом и их оформление '
  },
  {
    imageUrl: '/static/img/pages/expert-support/joint-projects-image4.svg',
    text: 'Подготовка докладов и статей'
  },
  {
    imageUrl: '/static/img/pages/expert-support/joint-projects-image5.svg',
    text: 'Проведение экспертного интервью'
  },
  {
    imageUrl: '/static/img/pages/expert-support/joint-projects-image6.svg',
    text: 'Запуск кабинетных, качественных и количественных исследований'
  }
]

export { heading, itemList }
