import getCookie from 'common/get-coockie'
import Button from 'components/Button/Button'
import Stack from 'components/Stack/Stack'
import TextField from 'components/TextField/TextField'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import React, { useEffect } from 'react'

type EmailVerificationProps = {
  name: string;
  email: string;
  onVerificationSuccess?: () => void;
  onVerificationError?: () => void;
  onVerificationCancel?: () => void;
}

const EmailVerification = (props: EmailVerificationProps) => {
  useEffect(() => {
    sendVerificationCode()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [code, setCode] = React.useState('')
  const [invalidCode, setInvalidCode] = React.useState(false)

  const sendVerificationCode = async () => {
    const body = {
      name: props.name,
      email: props.email
    }

    const csrftoken = getCookie('csrftoken')
    const url = `/api/role-catalog/email/verify/send-code`
    let params: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, params)
    if (response.ok) {
      // console.log('sent')
    } else {
      // console.log('error')
    }
  }

  const checkVerificationCode = async () => {
    const body = {
      email: props.email,
      code: code
    }

    const csrftoken = getCookie('csrftoken')
    const url = `/api/role-catalog/email/verify/check-code`
    let params: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, params)
    if (response.ok) {
      const data = await response.json()
      const verified = data.result
      console.log(verified)
      if (verified) {
        props.onVerificationSuccess?.()
      } else {
        setInvalidCode(true)
      }
    } else {
      // props.onVerificationError?.()
    }
  }

  return (
    <Stack gap={30}>
      <Heading variant='3'>Подтвердите E-mail</Heading>
      <Stack gap={8}>
        <Text variant='text-m'>Введите код, который был отправлен на E-mail</Text>
        <Text variant='text-m-medium'>{props.email}</Text>
      </Stack>
      <div>
        <TextField
          name='verification_code'
          size={20}
          required
          autoComplete='off'
          placeholder='Код из письма'
          onInput={(e) => { setCode(e.target.value) }}
        />
        {invalidCode && <Text variant='xs' style={{color: '#B62936'}}>Код не верный</Text>}
      </div>
      <Text variant='text-s'>Не пришло письмо? <a href='#' onClick={() => { sendVerificationCode() }}>Отправить еще раз</a></Text>
      <Stack gap={10} orientation='horizontal'>
        <Button onClick={() => { props.onVerificationCancel?.() }}>&lt; Назад</Button>
        <Button
          variant='filled'
          onClick={() => { checkVerificationCode() }}
          disabled={!code}
        >
            Подтвердить
        </Button>
      </Stack>
    </Stack>
  )
}

export default EmailVerification
