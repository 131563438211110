import nbspacer from 'common/nbspacer'
import Heading from 'components/Heading/Heading'
import Persona from 'components/Persona/Persona'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'
import React from 'react'
import { EmployeeData } from '../TeamSections/TeamSections'

type HeaderBlockProps = {
  heading?: string;
  text?: string;
  companyDirector?: EmployeeData;
}

const HeaderBlock = (props: HeaderBlockProps) => {
  return (
    <Stack gap={40}>
      <Heading maxWidth854 variant='1'>{props.heading ?? ''}</Heading>
      <Stack
        alignItems='flex-start'
        orientation='horizontal'
        tabletOrientation='vertical'
        gap={60}
      >
        <Text width='60%' tabletWidth='100%'>
          <div dangerouslySetInnerHTML={{__html: nbspacer(props.text ?? '')}} />
        </Text>
        {props.companyDirector &&
          <Persona
            horizontal
            imageSize={120}
            imageGap={24}
            nameElType='text'
            nameFontWeight='medium'
            positionTextVariant='small'
            showImageBorder
            {...props.companyDirector}
            imageUrl={props.companyDirector.image_url}
          />}
      </Stack>
    </Stack>
  )
}

export default HeaderBlock
