import Carousel from 'components/Carousel/Carousel'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'

const ProfileStructureSection = () => {
  return (
    <PageSection variant='gray'>
      <Stack gap={40}>
        <Heading variant='2'>
          Структура профиля роли позволяет <br/>
          <span style={{color: '#006050'}}>решать разные управленческие и кадровые задачи.</span>
        </Heading>
        <Carousel quantity={1}>
          <Image src='/static/img/pages/role-catalog/profile-structure-section/image1.svg' />
          <Image src='/static/img/pages/role-catalog/profile-structure-section/image2.svg' />
          <Image src='/static/img/pages/role-catalog/profile-structure-section/image3.svg' />
        </Carousel>
      </Stack>
    </PageSection>
  )
}

export default ProfileStructureSection
