import Button from 'components/Button/Button'
import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'

import styles from './HeaderBlock.module.css'

import { headerTitle, headerText, headerCardText1, headerCardText2, registerUrl } from '../data'
import Grid from 'components/Grid/Grid'

const HeaderBlock = () => {
  return (
    <Stack gap={30}>
      <Grid cols={2} tabletCols={1} columnGap={20}>
        <Stack gap={40}>
          <Heading variant='1'>{headerTitle}</Heading>
          <Stack gap={20}>
            <Text>{headerText}</Text>
            <div>
              <Button
                variant='filled'
                href={registerUrl}
                target='_blank'
                rel='noreferrer'
              >
                Перейти на страницу регистрации
              </Button>
            </div>
          </Stack>
        </Stack>
        <Image
          className={styles.Image}
          src='/static/img/pages/personnel-reserve/header-banner.svg'
        />
      </Grid>

      <Card>
        <Stack
          gap={20}
          orientation='horizontal'
          tabletOrientation='vertical'
          alignItems='center'
          tabletAlignItems='start'
        >
          <Image
            src='/static/img/pages/personnel-reserve/header-card.svg'
            width={168}
          />
          <Grid cols={2} tabletCols={1} columnGap={20} >
            <Text>{headerCardText1}</Text>
            <Text>{headerCardText2}</Text>
          </Grid>
        </Stack>
      </Card>
    </Stack>
  )
}

export default HeaderBlock
