import PageSection from 'components/PageSection/PageSection'
import { useState, useEffect } from 'react'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'

import styles from './PartnersSection.module.css'
import useMediaQuery from 'common/useMediaQuery'

const PartnersSection = () => {
  const {isTablet, isPhone} = useMediaQuery()

  const [partnerList, setPartnerList] = useState([])
  const [cols, setCols] = useState(4)

  useEffect(() => {

  }, [partnerList])

  useEffect(() => {
    fetchPartnerList()
  }, [])

  useEffect(() => {
    if (isPhone) {
      setCols(1)
    } else if (isTablet) {
      setCols(2)
    } else {
      setCols(4)
    }
  }, [isTablet, isPhone])

  const fetchPartnerList = async () => {
    const response = await fetch('/api/partners')
    const data = await response.json()
    setPartnerList(data)
  }

  return (
    <PageSection>
      <Stack gap={120} tabletGap={60}>
        <SectionHeading>Партнеры</SectionHeading>
        <Stack gap={80} tabletGap={40}>
          <Grid
            cols={4}
            tabletCols={2}
            columnGap={20}
            phoneCols={1}
            rowGap={80}
            tabletRowGap={40}
          >
            {partnerList.slice(0, Math.trunc(partnerList.length / cols) * cols).map((item: {
              id: number;
              name: string;
              image_url: string;
            }) => {
              return <Image className={styles.Image} width='100%' key={item.id} src={item.image_url} />
            })}
          </Grid>
          {(partnerList.length % cols) !== 0 &&
            <Stack gap={20} justifyContent='space-around' orientation='horizontal'>
              {partnerList.slice(-(partnerList.length % cols)).map((item: {
                id: number;
                name: string;
                image_url: string;
              }) => {
                return <Image className={styles.Image} width={(100 / cols) + '%'} key={item.id} src={item.image_url} />
              })}
            </Stack>
          }
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default PartnersSection
