import React from 'react'
import ClientsSection from './page-sections/ClientsSection'
import FAQSection from './page-sections/FAQSection'

import HeaderSection from './page-sections/HeaderSection'
import SpecialistSelectionSection from './page-sections/SpecialistSelectionSection'
import ThreeStepsSection from './page-sections/ThreeStepsSection'
import WhereFindSection from './page-sections/WhereFindSection'
import WhyTrustSection from './page-sections/WhyTrustSection'
import WillHelpSection from './page-sections/WillHelpSection'
import QuestionSection from 'page-sections/v2/QuestionSection/QuestionSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'
import SpecialistCatalogSection from './page-sections/SpecialistCatalogSection'

const RecruitingDTSpecialistsPage = () => {
  return (
    <div>
      <HeaderSection />
      <ClientsSection />
      <SpecialistSelectionSection />
      <WillHelpSection />
      <WhyTrustSection />
      <SpecialistCatalogSection />
      <WhereFindSection />
      <ThreeStepsSection />
      <FAQSection />

      <QuestionSection
        id={'question-section'}
        heading='Поможем в выборе!'
        hideDescription
        defaultPurposeOfRequest='recruiting_dt_specialists'
      />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default RecruitingDTSpecialistsPage
