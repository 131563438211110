import React from 'react'
import useMediaQuery from 'common/useMediaQuery'

type StackProps = {
  id?: number | string;

  gap?: React.CSSProperties['gap'];
  tabletGap?: React.CSSProperties['gap'];
  phoneGap?: React.CSSProperties['gap'];

  alignItems?: React.CSSProperties['alignItems'];
  tabletAlignItems?: React.CSSProperties['alignItems'];
  phoneAlignItems?: React.CSSProperties['alignItems']

  justifyContent?: React.CSSProperties['justifyContent'];
  tabletJustifyContent?: React.CSSProperties['justifyContent'];
  phoneJustifyContent?: React.CSSProperties['justifyContent'];

  children?: React.ReactNode;

  className?: string;
  style?: React.CSSProperties;

  flexGrow?: number;
  height?: string | number;

  width?: React.CSSProperties['width'];
  tabletWidth?: React.CSSProperties['width'];
  phoneWidth?: React.CSSProperties['width'];

  reverse?: boolean;
  tabletReverse?: boolean;
  phoneReverse?: boolean;

  orientation?: 'horizontal' | 'vertical';
  tabletOrientation?: 'horizontal' | 'vertical';
  phoneOrientation?: 'horizontal' | 'vertical';

  wrap?: React.CSSProperties['flexWrap'];
  ref?: any;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Stack = (props: StackProps) => {
  const {isTablet, isPhone} = useMediaQuery()

  const getResponsiveProp = (desktop: any, tablet: any, phone: any) => {
    let result = desktop
    if (isTablet && tablet) {
      result = tablet
    }
    if (isPhone && phone) {
      result = phone
    }
    return result
  }

  const gap = getResponsiveProp(props.gap, props.tabletGap, props.phoneGap)
  const alignItems = getResponsiveProp(props.alignItems, props.tabletAlignItems, props.phoneAlignItems)
  const justifyContent = getResponsiveProp(props.justifyContent, props.tabletJustifyContent, props.phoneJustifyContent)

  const orientation = getResponsiveProp(props.orientation, props.tabletOrientation, props.phoneOrientation)
  const reverse = getResponsiveProp(props.reverse, props.tabletReverse, props.phoneReverse)

  const flexDirection = (orientation === 'horizontal' ? 'row' : 'column')

  const handleClick = (e: any) => {
    props.onClick && props.onClick(e)
  }

  const style: React.CSSProperties = {
    display: 'flex',
    gap: gap,
    alignItems: alignItems,
    justifyContent: justifyContent,
    flexGrow: props.flexGrow,
    height: props.height,
    width: (isPhone && props.phoneWidth) ? props.phoneWidth : ((isTablet && props.tabletWidth) ? props.tabletWidth : props.width),
    flexDirection: flexDirection ?? 'vertical' + (reverse ? '-reverse' : ''),
    flexWrap: props.wrap
  }

  const elProps: any = {
    style: {...style, ...props.style},
    className: props.className,
    id: props.id,
    ref: props.ref,
    onClick: handleClick
  }

  return (
    <div {...elProps}>{props.children}</div>
  )
}

export default Stack
export type { StackProps }
