import nbspacer from 'common/nbspacer'
import useMediaQuery from 'common/useMediaQuery'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'

const HowConsultationGoingSection = () => {
  const [activeIndex, setActiveIndex] = React.useState<number>(0)
  const {isTablet} = useMediaQuery()

  type Item = {
    name: string;
    items: string[];
  }

  const items: Item[] = [
    {
      name: 'Знакомство',
      items: [
        'Выявление основной цели',
        'Анализ потребностей',
        'Обсуждение формата работы',
        'Создание правил совместной работы',
        'Обсуждение организационных моментов',
        'Сообщение о соблюдение конфиденциальности'
      ]
    },
    {
      name: 'Разбор резюме с запросом',
      items: [
        'Оформление резюме в едином стиле',
        'Расставление акцентов на сильные стороны',
        'Формирование цифровых показателей и результатов',
        'Проработка ключевых компетенций',
        'Определение пересекающихся навыков',
        'Исключение нерелевантного опыта'
      ]
    },
    {
      name: 'Работа над запросом',
      items: [
        'Совместный анализ сильных и слабых сторон',
        'Составление профиля походящих компаний',
        'Обсуждение вариантов развития карьеры',
        'Составление плана поиска работы',
        'Практические рекомендации по созданию резюме и сопроводительного письма',
        'Практика поиска работодателя: каналы, инструменты, репетиция интервью и т.д.'
      ]
    },
    {
      name: 'Подготовка рекомендаций и пошагового плана',
      items: [
        'Подготовка чек-лист анализа резюме',
        'Содействие при поиске грамотных формулировок',
        'Подготовка списка материалов для изучения',
        'Предложение подходящих направлений и отраслей',
        'Анализ самопрезентации поставленным целям',
        'Корректная обратная связь'
      ]
    }
  ]

  const getBackgroundColor = (index: number) => {
    if (activeIndex === index) {
      return '#00826F'
    }

    return '#D4D4D4'
  }

  // const gridTemplateColumns = isTablet ? '1fr 1fr 1fr 1fr' : '1fr 140px 1fr 140px 1fr 140px 1fr'
  const gridTemplateColumns = isTablet ? '1fr 1fr' : '1fr 140px 1fr 140px 1fr 140px 1fr'

  return (
    <PageSection
      variant='white-icons'
      paddingBottom={0}
    >
      <Stack gap={30}>
        <Heading
          variant='2'
          color='blue-gray'
        >
          {nbspacer('Как проходит онлайн консультация?')}
        </Heading>

        <div
          style={{
            display: 'grid',
            gap: '20px',
            gridTemplateColumns: gridTemplateColumns,
            alignItems: 'center',
            justifyItems: 'center'
          }}
        >
          {items.map((item: Item, index: number) => {
            return (<>
              <Stack
                key={index}
                gap={10}
                alignItems='center'
                style={{
                  cursor: 'pointer',
                  alignSelf: 'flex-start'
                }}
                onClick={() => setActiveIndex(index)}
              >
                <div
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    flexShrink: 0,
                    background: getBackgroundColor(index),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <Heading
                    variant='2'
                    color='white'
                    align='center'
                    style={{
                      position: 'relative',
                      top: '3px'
                    }}
                  >
                    {index + 1}
                  </Heading>
                </div>
                <Text
                  color={activeIndex === index ? 'emerald' : 'blue-gray'}
                  variant='text-m-medium'
                  align='center'
                >
                  {nbspacer(item.name)}
                </Text>
              </Stack>
              {!isTablet && items.length - 1 !== index && (
                <Image
                  key={'arrow' + index}
                  src='/static/img/pages/career-management/how-consultation-going-section/arrow.svg'
                />
              )}
            </>)
          })}
        </div>

        <Stack
          orientation='horizontal'
          tabletOrientation='vertical'
          gap={20}
        >
          <Stack
            width='40%'
          >
            <Heading
              variant='2'
              color='emerald'
            >
              {nbspacer(items[activeIndex].name)}
            </Heading>
          </Stack>
          <Stack gap={18}>
            {items[activeIndex].items.map((item: string, index: number) => {
              return (
                <TextWithDot
                  dotVariant='circle'
                  dotColor='emerald'
                  key={activeIndex + '-' + index}
                >
                  <Text variant='text-m' color='blue-gray'>{nbspacer(item)}</Text>
                </TextWithDot>
              )
            })}
          </Stack>
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default HowConsultationGoingSection
