import nbspacer from 'common/nbspacer'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Text from 'components/v2/Text/Text'
import React from 'react'

type ExpertCardProps = {
  id: number;
  name: string;
  position: string;
  image_url: string;
}

const ExpertCard = (props: ExpertCardProps) => {
  const parseNewLine = (str: string) => {
    return str.replace(/\r\n/g, '<br/>')
  }
  return (
    <Stack gap={30} alignItems='center' justifyContent='flex-start'>
      <Image
        width={260}
        height={260}

        style={{ borderRadius: '50%', objectFit: 'cover' }}
        src={props.image_url}
      />
      <Stack gap={10} alignItems='center'>
        <Text variant='text-l' color='emerald' align='center'>{props.name}</Text>
        <Text variant='text-s' color='gray' align='center'>
          <div dangerouslySetInnerHTML={{ __html: nbspacer(parseNewLine(props.position ?? '')) }} />
        </Text>
      </Stack>
    </Stack>
  )
}

export default ExpertCard
