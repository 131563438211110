import PageSection from 'components/v2/PageSection/PageSection'

import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'

import nbspacer from 'common/nbspacer'
import IconView from 'components/v2/IconView/IconView'
import CourseAboutCard from 'components/v2/CourseAboutCard/CourseAboutCard'

const AboutCourseSection = () => {
  return (
    <PageSection variant='gray'>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={20}
        rowGap={20}
      >
        {/* left */}
        <Stack gap={40}>
          <Heading variant='2'>О курсе</Heading>
          <Text variant='text-m'>{nbspacer('Мир меняется стремительно! В таких условиях выработать и тем более реализовать перспективную стратегию в организации непросто. Как выбрать верное направление для цифрового развития? Как сделать цифровую стратегию гибкой? Как застраховаться от стратегических и тактических ошибок при переходе на «цифру»?')}</Text>
          <Image
            src='/static/img/pages/program-registry/digital-strategy/about-course-section/section-image.svg'
            width='100%'
          />
        </Stack>

        {/* right */}
        <IconView cols={2} tabletCols={1} columnGap={20} rowGap={20}>
          <CourseAboutCard image='horn' name='Как?' description='Онлайн' />
          <CourseAboutCard image='clock' name='Как долго?' description='40 часов, 6 недель' />
          <CourseAboutCard image='checkbox' name='Когда?' url='/program-registry/timetable' description='Ближайшая дата начала обучения' />
        </IconView>
      </Grid>
    </PageSection>
  )
}

export default AboutCourseSection
