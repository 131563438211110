import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'

const WhoWillBeInterestedSection = () => {
  const data: any[] = [
    {
      imageUrl: '/static/img/pages/role-catalog/who-will-be-interested-section/image1.svg',
      title: 'Компаниям, начинающим цифровизацию'
    },
    {
      imageUrl: '/static/img/pages/role-catalog/who-will-be-interested-section/image2.svg',
      title: 'Руководителям цифровой трансформации'
    },
    {
      imageUrl: '/static/img/pages/role-catalog/who-will-be-interested-section/image3.svg',
      title: 'Кадровым службам и HR'
    },
    {
      imageUrl: '/static/img/pages/role-catalog/who-will-be-interested-section/image4.svg',
      title: 'Специалистам цифровой трансформации'
    }
  ]
  return (
    <PageSection variant='white-icons'>
      <Stack gap={40}>
        <Heading variant='2'>Кому будет интересен каталог «Команда цифрового развития организации: 44 профиля ролей»:</Heading>
        <Grid
          cols={4}
          tabletCols={1}
          columnGap={20}
          rowGap={20}
        >
          {data.map((item, index) => {
            return <Card key={index} bgColor='light-gray'>
              <Stack gap={20} alignItems={'center'}>
                <Image src={item.imageUrl} width={100} />
                <Heading variant='3' align='center'>{item.title}</Heading>
              </Stack>
            </Card>
          })}

        </Grid>
      </Stack>
    </PageSection>
  )
}

export default WhoWillBeInterestedSection
