import PageSection from 'components/PageSection/PageSection'
import DotArrowListCard, { DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'
import Stack from 'components/Stack/Stack'
import { heading1, heading2, itemList1, itemList2 } from './data'
import Card from 'components/Card/Card'
import Heading from 'components/Heading/Heading'

import styles from './Advertising1Section.module.css'
import Color from 'common/Color.d'

type Advertising1SectionProps = {
  bgColor?: Color
}

const Advertising1Section = (props: Advertising1SectionProps) => {
  return (
    <PageSection bgColor={props.bgColor}>
      <div className={styles.HorizontalStack}>
        <Stack gap={60}>
          <Heading variant='1'>{heading1}</Heading>
          <Stack gap={28}>
            {itemList1.map((item: DotArrowListCardProps) => {
              return <DotArrowListCard key={item.id} {...item} />
            })}
          </Stack>
        </Stack>

        <Card bgColor='light-gray'>
          <Stack gap={60}>
            <Heading variant='1'>{heading2}</Heading>
            <ul>
              {itemList2.map((item: string) => {
                return <li className={styles.Li} key={item}>{item}</li>
              })}
            </ul>
          </Stack>
        </Card>
      </div>
    </PageSection>
  )
}

export default Advertising1Section
