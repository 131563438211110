const heading: string = 'Цель оценки ИИ-зрелости ФОИВ'
const text: string = '<p>Определить текущий уровень зрелости ведомств в сравнении с целевым значением. В него входит использование ИИ-решений в качестве основного инструмента обработки данных во всех процессах. </p>'

const bullets1 = [
  {
    id: 1,
    description: 'повышаются скорость и качество принимаемых управленческих решений;'
  },
  {
    id: 2,
    description: 'улучшается качество государственных услуг для граждан и организаций;'
  },
  {
    id: 3,
    description: 'оптимизируется выполнение ФОИВ своих функций и задач.'
  }
]
const bullets2 = [
  {
    id: 1,
    description: 'Выделение лучших практик применения искусственного интеллекта в деятельности ФОИВ на основе совокупного эффекта использования.'
  },
  {
    id: 2,
    description: 'Выявление ключевых рисков и сложностей при внедрении искусственного интеллекта в деятельность ФОИВ.'
  }
]
export { heading, text, bullets1, bullets2 }
