const headerSection = {
  title: 'Цифровая стратегия: разработка и практическая реализация',
  text: 'Выстраиваем стратегию перехода на «цифру». Запускаем цифровые преобразования грамотно',
  imageUrl: '/static/img/pages/program-registry/digital-strategy/header-section/section-image.svg'
}

const aboutSection = {
  title: 'О курсе',
  text: 'Мир меняется стремительно! В таких условиях выработать и тем более реализовать перспективную стратегию в компании непросто. Как выбрать верное направление для цифрового развития? Как сделать цифровую стратегию гибкой? Как застраховаться от стратегических и тактических ошибок при переходе на «цифру»?',
  imageUrl: '/static/img/pages/program-registry/digital-strategy/about-section/about-image.svg',
  bullets: [
    {
      imageUrl: '/static/img/pages/program-registry/digital-strategy/about-section/bullet-1.svg',
      name: 'Когда?',
      description: 'В любое время'
    },
    {
      imageUrl: '/static/img/pages/program-registry/digital-strategy/about-section/bullet-2.svg',
      name: 'Как долго?',
      description: '40 часов, 2 недели'
    },
    {
      imageUrl: '/static/img/pages/program-registry/digital-strategy/about-section/bullet-3.svg',
      name: 'Как?',
      description: 'Онлайн'
    },
    {
      imageUrl: '/static/img/pages/program-registry/digital-strategy/about-section/bullet-4.svg',
      name: 'Тренды и идеи для ИТ- проектов органов власти',
      description: null
    }
  ]
}

const targetAudienceSection = {
  title: 'Для кого мы работаем',
  items: [
    {
      imageUrl: '/static/img/pages/program-registry/digital-strategy/target-audience-section/icon1.svg',
      name: 'Менеджеры подразделений, тимлиды, продакт-менеджеры',
      description: <>
        <ul>
          <li>Усилите прикладные навыки внедрения стратегических решений</li>
          <li>Освоите инструменты реализации цифровой стратегии</li>
        </ul>
      </>
    },
    {
      imageUrl: '/static/img/pages/program-registry/digital-strategy/target-audience-section/icon2.svg',
      name: 'Руководители цифровой трансформации, ИТ-директора',
      description: <>
        <ul>
          <li>Познакомитесь с разными видами цифровых стратегий</li>
        </ul>
      </>
    },
    {
      imageUrl: '/static/img/pages/program-registry/digital-strategy/target-audience-section/icon3.svg',
      name: 'Руководители коммерческих и государственных организаций',
      description: <>
        <ul>
          <li>Расширите горизонт планирования и разработаете гибкую цифровую стратегию для своей компании</li>
        </ul>
      </>
    },
    {
      imageUrl: '/static/img/pages/program-registry/digital-strategy/target-audience-section/icon4.svg',
      name: 'Руководители проектных офисов, департаментов, менеджеры проектов',
      description: <>
        <ul>
          <li>Изучите инструменты, которые помогут создать и внедрить цифровую стратегию</li>
        </ul>
      </>
    },
    {
      imageUrl: '/static/img/pages/program-registry/digital-strategy/target-audience-section/icon5.svg',
      name: 'Все, кто разрабатывает и внедряет цифровую стратегию в компании',
      description: <>
        <ul>
          <li>Научитесь выбирать и реализовывать оптимальную модель для ЦТ</li>
        </ul>
      </>
    }
  ]
}

const willKnowSection = {
  title: 'Что вы узнаете',
  imageUrl: '/static/img/pages/program-registry/digital-strategy/will-know-section/will-know-image.png',
  items: [
    'Как оценивать текущее состояние организации: качество данных, степень цифровой зрелости, корпоративную культуру',
    'Зачем организации нужна стратегия',
    'Как создавать работающее цифровое решение',
    'Как анализировать эффективность цифровой стратегии'
  ]
}

const willLearnSection = {
  title: 'Чему вы научитесь',
  imageUrl: '/static/img/pages/program-registry/digital-strategy/will-learn-section/will-learn-image.svg',
  items: [
    'Пошагово разрабатывать цифровую стратегию для своей организации',
    'Выстраивать управление портфелем ИТ-услуг',
    'Выбирать оптимальную модель для цифровой трансформации',
    'Связывать бизнес- и цифровую стратегии компании'
  ]
}

const courseContentSection = {
  title: 'Что будет в курсе',
  items: [
    {
      name: 'Интерактивные лонгриды и видеолекции',
      imageUrl: '/static/img/pages/program-registry/digital-strategy/course-content-section/icon1.svg'
    },
    {
      name: 'Тесты для самопроверки',
      imageUrl: '/static/img/pages/program-registry/digital-strategy/course-content-section/icon2.svg'
    },
    {
      name: 'Актуальные кейсы',
      imageUrl: '/static/img/pages/program-registry/digital-strategy/course-content-section/icon3.svg'
    },
    {
      name: 'Практические задания',
      imageUrl: '/static/img/pages/program-registry/digital-strategy/course-content-section/icon4.svg'
    }
  ]
}

export { headerSection, aboutSection, targetAudienceSection, willKnowSection, willLearnSection, courseContentSection }
