import PageSection from 'components/v2/PageSection/PageSection'

// import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'

import Card from 'components/Card/Card'

import { willLearnSection } from './data'
import useMediaQuery from 'common/useMediaQuery'

const WillLearnSection = () => {
  const { isTablet } = useMediaQuery()
  return (
    <PageSection variant='white-icons'>
      <Stack gap={30} orientation='horizontal' tabletOrientation='vertical'>
        <Card bgColor='light-gray' width={isTablet ? '100%' : '60%'}>
          <Stack gap={40}>
            <Heading variant='3'>{willLearnSection.title}</Heading>
            <Stack gap={30}>
              {willLearnSection.items.map((item: any, index: number) => {
                return <Stack
                  key={index}
                  gap={30}
                  orientation='horizontal'
                  tabletOrientation='vertical'
                  alignItems={'center'}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '55px',
                      height: '55px',
                      borderRadius: '50%',
                      flexShrink: 0,
                      background: 'linear-gradient(to right, #047A69, #449791)'
                    }}
                  >
                    <Text
                      variant='text-m-medium'
                      color='white'
                    >
                      {index < 7 ? index + 1 : '+'}
                    </Text>
                  </div>
                  <Text variant='text-m-medium'>{item}</Text>
                </Stack>
              })}
            </Stack>
          </Stack>
        </Card>
        <div>
          <Image
            src={willLearnSection.imageUrl}
            width='100%'
            animate
            animationVariant='appear-left'
          />
        </div>
      </Stack>
    </PageSection>
  )
}

export default WillLearnSection
