const smallHeading: string = 'Образовательные программы в рамках федерального проекта «Кадры для цифровой экономики»'
const heading: string = 'Программы для госслужащих 2022'
const text: string = 'Программы ориентированы на формирование знаний и навыков слушателей по актуальным задачам цифровой трансформации государственного управления; программы готовят к работе с конкретными инструментами в зависимости от направления профессиональной деятельности.'

const itemList1: any = [
  {
    id: 1,
    name: 'Обучение для госслужащих бесплатно'
  },
  {
    id: 2,
    name: 'Прохождение обучения один раз в год',
    description: <><b>Один человек</b> может пройти обучение по программе в рамках федерального проекта «Кадры для цифровой экономики» <b>только один раз</b> в календарном году.</>
  },
  {
    id: 3,
    name: 'Балльно-рейтинговая система',
    description: <>Во всех программах действует балльно-рейтинговая система. Поступление не означает автоматического успешного завершения обучения и получения удостоверения: слушатели получают баллы за выполнение заданий и тестов.</>
  },
  {
    id: 4,
    name: 'Удостоверение о прохождении',
    description: <>По окончании обучения выдается <b>удостоверение о повышении квалификации установленного образца</b>. Чтобы его получить, необходимо изучить материалы образовательной программы и набрать определенное количество баллов.</>
  }
]

const itemList2: any = [
  {
    id: 1,
    imageSrc: '/static/img/pages/programs/advantages-image1.svg',
    text: 'Дистанционный формат обучения'
  },
  {
    id: 2,
    imageSrc: '/static/img/pages/programs/advantages-image2.svg',
    text: 'Самостоятельный выбор графика обучения без отрыва от работы'
  },
  {
    id: 3,
    imageSrc: '/static/img/pages/programs/advantages-image3.svg',
    text: 'Дополнительные материалы, комментарии и видео от экспертов'
  },
  {
    id: 4,
    imageSrc: '/static/img/pages/programs/advantages-image4.svg',
    text: 'Большое количество кейсов и примеров из практики'
  },
  {
    id: 5,
    imageSrc: '/static/img/pages/programs/advantages-image5.svg',
    text: 'Выполнение интерактивных заданий на отработку навыков'
  }
]

export { smallHeading, heading, text, itemList1, itemList2 }
