import Modal from 'components/Modal/Modal'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import React from 'react'
import Step1 from './Step1'
import Step2Individual from './Step2Individual'
import Step2Legal from './Step2Legal'
import StepInfo from './StepInfo'

type PurchaseFormProps = {
  visible: boolean;
  onClose?: () => void;
  onLegalOrderSuccess?: () => void;
}

const PurchaseForm = (props: PurchaseFormProps) => {
  const onClose = () => {
    props.onClose?.()
  }

  const onLegalOrderSuccess = () => {
    setStep(1)
    setPayerType('individual')
    props.onLegalOrderSuccess?.()
  }

  const [step, setStep] = React.useState<1 | 2>(1)
  const [payerType, setPayerType] = React.useState<'individual' | 'legal'>('individual')

  return (
    <Modal
      visible={props.visible}
      onClose={() => onClose()}
    >
      {/* <Stack gap={40} style={{minWidth: '700px'}}> */}
      <Stack gap={40} style={{padding: '20px 40px'}}>
        <Heading variant='2'>Оплатить каталог</Heading>

        <StepInfo
          step={step}
          onSetStep={(step) => setStep(step)}
        />

        {step === 1 && <Step1
          onNext={() => setStep(2)}
          payerType={payerType}
          onChangePayerType={(payerType) => setPayerType(payerType)}
        />}

        {step === 2 && payerType === 'individual' && <Step2Individual onSetStep={(step) => setStep(step)} />}
        {step === 2 && payerType === 'legal' &&
          <Step2Legal
            onSetStep={(step) => setStep(step)}
            onOrderSuccess={() => onLegalOrderSuccess()}
          />
        }

      </Stack>
    </Modal>
  )
}

export default PurchaseForm
