import { DotArrowListCard, DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import PageSection from 'components/PageSection/PageSection'
import Stack from 'components/Stack/Stack'
import Grid from 'components/Grid/Grid'

import { advantagesTitle, advantages } from '../data'

const AdvantagesSection = () => {
  return (
    <PageSection>
      <Stack gap={60}>
        <Heading variant='1'>{advantagesTitle}</Heading>
        <Grid cols={2} columnGap={60} tabletCols={1}>
          <Stack gap={30}>
            {advantages.map((item: DotArrowListCardProps) => {
              return <DotArrowListCard key={item.id} description={item.description} />
            })}
          </Stack>
          <Image
            src='/static/img/pages/personnel-reserve/advantages-banner.svg'
            width={'100%'}
          />
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default AdvantagesSection
