
import React from 'react'

import AboutCourseSection from './AboutCourseSection'
import HeaderSection from './HeaderSection'
import TargetAudienceSection from './TargetAudienceSection'
import ProgramDescriptionSection from 'pages/ProgramRegistry/page-sections/ProgramDescriptionSection'
import WillLearnSection from 'pages/ProgramRegistry/page-sections/WIlLearnSection'
import WillKnowSection from 'pages/ProgramRegistry/page-sections/WillKnowSection'
import PriceSection from 'pages/ProgramRegistry/page-sections/PriceSection'
import QuestionSection from '../page-sections/QuestionSection/QuestionSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'
import CertificateSection from '../page-sections/CertificateSection'
import CourseContentSection from 'pages/ProgramRegistry/page-sections/CourceContentSection'
import ExpertsSection from '../page-sections/ExpertsSection'
import InfoVideoSection from '../page-sections/InfoVideoSection'
import FaqSection from '../page-sections/FaqSection'

const DigitalDevelopmentPage = () => {
  return (
    <div>
      <HeaderSection />

      <AboutCourseSection />

      {false && <InfoVideoSection
        variant='gray'
        src='https://play.boomstream.com/txMoEbXp'
      />}

      <TargetAudienceSection />

      <WillKnowSection
        items={[
          'Как применять модель компетенций в практике формирования команд цифровой трансформации',
          'Каким образом внедрять изменения и эффективные методы работы с сопротивлением изменениям',
          'Что поможет выстроить системный подход к управлению изменениями',
          'Как оценить тип и особенности организационной культуры в компании и учесть их при переходе на «цифру»'
        ]}
      />

      <WillLearnSection
        items={[
          'Планомерно и эффективно внедрять в организации изменения, связанные с цифровой трансформацией',
          'Выстраивать управление изменениями в зависимости от организационной культуры',
          'Оценивать уровень развития цифровой культуры организации',
          'Выбирать подходящие инструменты внедрения изменений и конструктивно работать с людьми',
          'Выбирать подходящих специалистов в свою проектную команду'
        ]}
      />

      <ProgramDescriptionSection
        items={[
          {
            name: '1. Организационная культура в контексте цифровой трансформации',
            items: [
              'Узнаете, кто создает внутреннюю культуру организации',
              'Познакомитесь с типологией организационной культуры.',
              'Изучите подходы к формированию оргкультуры.',
              'Освоите инструменты поддержания организационной культуры.'
            ]
          },
          {
            name: '2. Практика управления организационными изменениями',
            items: [
              'Узнаете, как сделать организационные изменения управляемыми.',
              'Изучите эффективные подходы к управлению изменениями.',
              'Определите тип культуры в своей организации.'
            ]
          },
          {
            name: '3. Кадровая политика в контексте цифровой трансформации',
            items: [
              'Изучите инструменты, которые помогут решить вопросы кадров для цифровой трансформации.',
              'Познакомитесь с примерами структур цифровой трансформации в разных сферах бизнеса.',
              'Узнаете причины сопротивления изменениям в команде и методы работы с ними. '
            ]
          },
          {
            name: '4. Развитие компетенций команды цифровой трансформации',
            items: [
              'Узнаете, какие есть варианты интеграции команды цифровой трансформации в оргструктуру организации.',
              'Рассмотрите профили ролей участников команды цифровой трансформации.',
              'Изучите модель компетенций как практический инструмент для формирования команды цифровой трансформации.',
              'Познакомитесь с основными компетенциями руководителя цифровой трансформации (CDTO).',
              'Узнаете, какие инструменты и методы формирования организационной культуры эффективны для вашей организации.'
            ]
          },
          {
            name: 'Практика',
            items: [
              'Определите, на какой стадии развития цифровой культуры находится ваша организация.',
              'Составите список наиболее заметных проявлений культуры и поощряемых моделей поведения в вашей организации.',
              'Проанализируете уровни культуры в вашей организации.'
            ]
          }
        ]}
      />

      <ExpertsSection
        experts={['garkusha', 'shubin', 'korotkih']}
        paddingBottom={0}
      />

      <CourseContentSection />

      <CertificateSection variant='gray' />

      <PriceSection />

      <FaqSection />

      <QuestionSection
        id={'question-section'}
        defaultPurposeOfRequest='program_registry_digital_development'
      />

      <FooterSection showRanepaMoreText />
    </div>
  )
}

export default DigitalDevelopmentPage
