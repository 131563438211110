import React from 'react'

import PageSection from 'components/PageSection/PageSection'
import styles from './HeaderSection.module.css'
import LogosPanel from './LogosPanel/LogosPanel'
import MainMenu from 'components/v2/MainMenu/MainMenu'
import Color from 'common/Color.d'
import Stack from 'components/Stack/Stack'

type HeaderSectionProps = {
  children?: React.ReactNode;
  bgColor?: Color;
  className?: string;
  paddingBottom?: React.CSSProperties['paddingBottom'];
  style?: React.CSSProperties;
}

const HeaderSection = (props: HeaderSectionProps) => {
  const bgColor: Color = props.bgColor ?? 'light-gray'
  const className = `${styles.HeaderSection} ${props.className ?? ''}`

  const style: React.CSSProperties = {
    paddingBottom: props.paddingBottom
  }

  const elProps: {
    className: string;
    style: React.CSSProperties;
    bgColor: Color;
  } = {
    className: className,
    style: {...style, ...props.style},
    bgColor: bgColor
  }

  return (
    <PageSection {...elProps}>
      <Stack orientation='vertical' gap={24}>
        <LogosPanel />
        <MainMenu />
      </Stack>
      {props.children}
    </PageSection>
  )
}

export default HeaderSection
