const getFormattedDate = (date: string) => {
  if (!date) {
    return ''
  }
  const _date:any = new Date(date)
  const year: any = _date.getFullYear()
  const month:any = _date.getMonth()
  const day:any = _date.getDate()
  const months:any = {
    0: 'Января',
    1: 'Февраля',
    2: 'Марта',
    3: 'Апреля',
    4: 'Мая',
    5: 'Июня',
    6: 'Июля',
    7: 'Августа',
    8: 'Сентября',
    9: 'Октября',
    10: 'Ноября',
    11: 'Декабря'
  }
  if (year !== new Date().getFullYear()) {
    return `${day} ${months[month]} ${year}`
  }
  return `${day} ${months[month]}`
}

const getFormattedDateLower = (date: string) => {
  const _date:any = new Date(date)
  const month:any = _date.getMonth()
  const day:any = _date.getDate()
  const months:any = {
    0: 'января',
    1: 'февраля',
    2: 'марта',
    3: 'апреля',
    4: 'мая',
    5: 'июня',
    6: 'июля',
    7: 'августа',
    8: 'сентября',
    9: 'окрября',
    10: 'ноября',
    11: 'декабря'
  }
  return `${day} ${months[month]}`
}

const getFormattedDateLowerYear = (date: string) => {
  const _date:any = new Date(date)
  const month:any = _date.getMonth()
  const day:any = _date.getDate()
  const year:any = _date.getFullYear()

  const months:any = {
    0: 'января',
    1: 'февраля',
    2: 'марта',
    3: 'апреля',
    4: 'мая',
    5: 'июня',
    6: 'июля',
    7: 'августа',
    8: 'сентября',
    9: 'окрября',
    10: 'ноября',
    11: 'декабря'
  }
  return `${day} ${months[month]} ${year}`
}

const getFormattedTime = (time: string) => {
  if (!time) {
    return null
  }
  const timeItems: any = time.split(':')

  return `${timeItems[0]}:${timeItems[1]} по МСК`
}

export { getFormattedDate, getFormattedTime, getFormattedDateLower, getFormattedDateLowerYear }
