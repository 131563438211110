import React from 'react'

import Stack from 'components/Stack/Stack'
import IconView from 'components/v2/IconView/IconView'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import CourseTargetAudienceCard, {CourseTargetAudienceCardProps} from 'components/v2/CourseTargetAudienceCard/CourseTargetAudienceCard'
import Image from 'components/Image/Image'

const TargetAudienceSection = () => {
  const items: CourseTargetAudienceCardProps[] = [
    {
      image: 'checkbox',
      description: 'Руководители коммерческих и государственных организаций'
    },
    {
      image: 'smartphone',
      description: 'Менеджеры подразделений, тимлиды, продакт-менеджеры'
    },
    {
      image: 'chart',
      description: 'Специалисты и руководители, которые занимаются внедрением изменений, связанных с цифровой трансформацией'
    },
    {
      image: 'computer',
      description: 'Руководители цифровой трансформации, ИТ-директора, руководители проектных офисов, департаментов, менеджеры проектов'
    }
  ]

  return (
    <PageSection variant='white-icons'>
      <Stack gap={134} orientation='horizontal' tabletOrientation='vertical' alignItems='centerq'>
        <Stack gap={60}>
          <Heading variant='2'>Для кого курс</Heading>
          <IconView
            cols={2}
            tabletCols={2}
            phoneCols={1}
            columnGap={20}
            rowGap={20}
          >
            {items.map((item, index) => {
              return <CourseTargetAudienceCard key={index} animateDelayIndex={index} {...item} />
            })}
          </IconView>
        </Stack>
        <Image
          width='100%'
          src='/static/img/pages/program-registry/digital-development/target-audience-section/section-image.svg'
        />
      </Stack>
    </PageSection>
  )
}

export default TargetAudienceSection
