import Stack from 'components/Stack/Stack'
import React, { useEffect } from 'react'

type PhoneVerificationProps = {
  url: string | null;
  onVerificationSuccess?: () => void;
  onVerificationError?: () => void;
  onVerificationCancel?: () => void;
}

const PhoneVerification = (props: PhoneVerificationProps) => {
  const handleIFrameMessage = (event: MessageEvent) => {
    console.log(event)
    if (event?.data === '[CDTO]hide popup after success') {
      window.removeEventListener('message', handleIFrameMessage)
      props.onVerificationSuccess?.()
    }
    if (event?.data === '[CDTO]reload popup') {
      window.removeEventListener('message', handleIFrameMessage)
      props.onVerificationError?.()
    }
    if (event?.data === '[CDTO]hide popup') {
      window.removeEventListener('message', handleIFrameMessage)
      props.onVerificationCancel?.()
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleIFrameMessage)
    return () => {
      window.removeEventListener('message', handleIFrameMessage)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack>
      {props.url &&
        <iframe
          id='phone-verification-iframe'
          style={{
            margin: '5px auto',
            width: '320px',
            height: '460px',
            position: 'relative',
            border: 'none'
          }}
          src={props.url}
          allowFullScreen={false}
        />
      }
    </Stack>
  )
}

export default PhoneVerification
