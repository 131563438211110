import useMediaQuery from 'common/useMediaQuery'
import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import React, { Children } from 'react'

type CardListProps = {
  cols?: number;
  tabletCols?: number;
  phoneCols?: number;
  columnGap?: number;
  rowGap?: number;
  children?: React.ReactNode;
}

const IconView = (props: CardListProps) => {
  const { isTablet, isPhone } = useMediaQuery()
  const count = Children.count(props.children)
  const getCols = () => {
    if (isPhone && props.phoneCols) {
      return props.phoneCols
    }
    if (isTablet && props.tabletCols) {
      return props.tabletCols
    }
    return props.cols ?? 1
  }
  const reminder = count % getCols()
  const children = Children.toArray(props.children)

  return (
    <Stack gap={props.rowGap}>
      <Grid
        cols={props.cols}
        tabletCols={props.tabletCols}
        phoneCols={props.phoneCols}
        columnGap={props.columnGap}
        rowGap={props.rowGap}
      >
        {children.slice(0, count - reminder).map((child) => {
          return child
        })}
      </Grid>
      {reminder > 0 && <Stack
        justifyContent='center'
        gap={props.columnGap}
        orientation='horizontal'
      >
        {React.Children.map(props.children, (child: any, index: number) => {
          if (index >= count - reminder) {
            return React.cloneElement(child, {
              style: { width: `calc(${100 / getCols()}% - ${(props.columnGap ?? 0) / 2}px)`, boxSizing: 'border-box' }
            })
          }
        })}
      </Stack>}
    </Stack>
  )
}

export default IconView
