import nbspacer from 'common/nbspacer'
import HeaderBlock from 'components/v2/HeaderBlock/HeaderBlock'
import PageSection from 'components/v2/PageSection/PageSection'
import HeaderSection from 'page-sections/v2/HeaderSection/HeaderSection'
import FooterSection from 'page-sections/v2/FooterSection/FooterSection'
import QuestionSection from 'page-sections/v2/QuestionSection/QuestionSection'

import Text from 'components/v2/Text/Text'

import { headerTitle, headerText, targetAudience, usefulness, weCreate, advantages, weWork, reviews } from './data'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Grid from 'components/Grid/Grid'
import Button from 'components/Button/Button'
import Image from 'components/Image/Image'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Card from 'components/Card/Card'
import useMediaQuery from 'common/useMediaQuery'
import scrollToId from 'common/scroll-to-id'
import { useState, useEffect } from 'react'
import Modal from 'components/Modal/Modal'
import styles from './MethodologicalCenterPage.module.css'
import Carousel from 'components/Carousel/Carousel'

const MethodologicalCenterPage = () => {
  const { isTablet } = useMediaQuery()

  const [programs, setPrograms] = useState([])
  const [employees, setEmployees] = useState([])
  const [currentProgram, setCurrentProgram] = useState({} as any)
  const [visibleProgramInfo, setVisibleProgramInfo] = useState(false)

  useEffect(() => {
    fetchPrograms()
    fetchEmployees()
  }, [])

  const fetchPrograms = async () => {
    const response = await fetch('/api/methodological-center/programs/')
    const data = await response.json()
    setPrograms(data)
  }

  const fetchEmployees = async () => {
    const response = await fetch('/api/methodological-center/employees/')
    const data = await response.json()
    setEmployees(data)
  }

  const showProgramInfo = (program: any) => {
    setCurrentProgram(program)
    setVisibleProgramInfo(false)
    // setVisibleProgramInfo(true)
  }

  const getWeWorkBulletBackgound = (index: number) => {
    if (index === 6) {
      return '#B62936'
    }
    if (index === 7) {
      return '#E78E24'
    }
    return 'linear-gradient(to right, #047A69, #449791)'
  }

  return (
    <div>
      <Modal
        visible={visibleProgramInfo}
        onClose={() => setVisibleProgramInfo(false)}
      >
        <Grid cols={2} columnGap={20} rowGap={20} phoneCols={1}>
          <Grid cols={2} columnGap={20} rowGap={20} alignItems='center'>
            <Text variant='date-and-type-s' uppercase>Сроки реализации</Text>
            <Text variant='xs'>
              <div dangerouslySetInnerHTML={{__html: nbspacer(currentProgram.implementation_terms ?? '')}} />
            </Text>
            <Text variant='date-and-type-s' uppercase>Слушатели</Text>
            <Text variant='xs'>
              <div className={styles['program-info-text']} dangerouslySetInnerHTML={{__html: nbspacer(currentProgram.listeners ?? '')}} />
            </Text>
            <Text variant='date-and-type-s' uppercase>Идея</Text>
            <Text variant='xs'>
              <div className={styles['program-info-text']} dangerouslySetInnerHTML={{__html: nbspacer(currentProgram.idea ?? '')}} />
            </Text>
            <Text variant='date-and-type-s' uppercase>Основные темы:</Text>
            <Text variant='xs'>
              <div className={styles['program-info-text']} dangerouslySetInnerHTML={{__html: nbspacer(currentProgram.main_topics ?? '')}} />
            </Text>
          </Grid>
          <Grid cols={2} columnGap={20} rowGap={20} alignItems='center'>
            <Text variant='date-and-type-s' uppercase>Темы онлайн занятий с экспертами:</Text>
            <Text variant='xs'>
              <div className={styles['program-info-text']} dangerouslySetInnerHTML={{__html: nbspacer(currentProgram.topics_of_online_classes_with_experts ?? '')}} />
            </Text>
            <Text variant='date-and-type-s' uppercase>Регулярные активности:</Text>
            <Text variant='xs'>
              <div className={styles['program-info-text']} dangerouslySetInnerHTML={{__html: nbspacer(currentProgram.regular_activities ?? '')}} />
            </Text>
          </Grid>
        </Grid>
      </Modal>

      <HeaderSection paddingBottom={0}>
        <HeaderBlock
          heading={headerTitle}
          imageUrl='/static/img/pages/methodological-center/header-image.svg'
        >
          <Text
            variant='text-xl'
            color='white'
          >
            {nbspacer(headerText)}
          </Text>
        </HeaderBlock>
      </HeaderSection>

      <PageSection variant='white-icons'>
        <Stack gap={40}>
          <Heading variant='2'>Для кого мы работаем</Heading>
          <Grid
            columnGap={20}
            rowGap={20}
            cols={3}
            phoneCols={1}
          >
            {targetAudience.map((item: any, index: number) => {
              return <Stack gap={20} key={index}>
                <div style={{ textAlign: 'center' }}>
                  <Image
                    src={item.icon}
                    width={120}
                    animate
                    animationVariant='zoom-out'
                    animateDelayIndex={index}
                  />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Heading
                    variant='3'
                    color='green'
                    animate
                    animationVariant='appear-left'
                    animateDelayIndex={index}
                  >
                    {item.heading}
                  </Heading>
                </div>
                <Stack gap={10}>
                  {item.items.map((text: string, index: number) => {
                    return <TextWithDot key={index} dotVariant='rounded'>
                      <Text variant='text-s'>{text}</Text>
                    </TextWithDot>
                  })}
                </Stack>
              </Stack>
            })}

          </Grid>
          <div>
            <Button variant='filled' target='_blank' href='/static/docs/commercial_programs_of_the_center.pdf'>
              Скачать каталог образовательных программ
            </Button>
          </div>
        </Stack>
      </PageSection>

      <PageSection variant='gradient-green-stars'>
        <Stack gap={40}>
          <Heading variant='2' color='white'>Как мы можем быть полезны</Heading>
          <Grid
            cols={2}
            rows={3}
            columnGap={20}
            rowGap={20}
            tabletCols={1}
          >
            {usefulness.map((item: any, index: number) => {
              return <Stack key={index} gap={30} orientation='horizontal' alignItems={'center'}>
                <Image src='/static/img/pages/methodological-center/usefulness/checkbox.svg' width={30} />
                <Stack gap={10}>
                  <Heading
                    variant='3'
                    color='white'
                    animate
                    animationVariant='appear-left'
                    animateDelayIndex={index}
                  >
                    {item.heading}
                  </Heading>
                  <Text variant='text-s' color='white'>{item.text}</Text>
                </Stack>
              </Stack>
            })}

          </Grid>
          <div><Button onClick={() => scrollToId('question-section')}>Заказать консультацию</Button></div>
        </Stack>
      </PageSection>

      <PageSection variant='gray'>
        <Stack gap={40}>
          <Heading variant='2'>Что мы создаем</Heading>
          <Grid
            cols={3}
            phoneCols={1}
            tabletCols={1}
            columnGap={20}
            rowGap={20}
          >
            {weCreate.slice(0, 3).map((item: any, index: number) => {
              return <Card
                key={index}
                animate
                animationVariant='appear-up'
                animateDelayIndex={index}
              >
                <Stack
                  gap={12}
                >
                  <div style={{ textAlign: 'center', padding: '0 60px' }}>
                    <Image src={item.icon} width='100%' />
                  </div>
                  <TextWithDot
                    dotVariant='rounded'
                    dotColor={item.type === 'Онлайн-курсы' ? 'green' : 'new-blue'}
                  >
                    <Text
                      variant='date-and-type-s'
                      color={item.type === 'Онлайн-курсы' ? 'green' : 'new-blue'}
                      uppercase
                    >
                      {item.type}
                    </Text>
                  </TextWithDot>
                  <Heading variant='3'>{item.heading}</Heading>
                  <Text variant='text-s' color='dark-gray'>{item.text}</Text>
                </Stack>
              </Card>
            })}
          </Grid>
          <Stack
            gap={20}
            orientation='horizontal'
            phoneOrientation='vertical'
            justifyContent={'center'}
          >
            {weCreate.slice(-2).map((item: any, index: number) => {
              return <Card
                key={index}
                width={isTablet ? '91%' : '28%'}
                animate
                animationVariant='appear-up'
                animateDelayIndex={index}
              >
                <Stack
                  gap={12}
                >
                  <div style={{ textAlign: 'center', padding: '0 60px' }}>
                    <Image src={item.icon} width='100%' />
                  </div>
                  <TextWithDot
                    dotVariant='rounded'
                    dotColor={item.type === 'Онлайн-курсы' ? 'green' : 'new-blue'}
                  >
                    <Text
                      variant='date-and-type-s'
                      color={item.type === 'Онлайн-курсы' ? 'green' : 'new-blue'}
                      uppercase
                    >
                      {item.type}
                    </Text>
                  </TextWithDot>
                  <Heading variant='3'>{item.heading}</Heading>
                  <Text variant='text-s' color='dark-gray'>{item.text}</Text>
                </Stack>
              </Card>
            })}
          </Stack>

        </Stack>
      </PageSection>

      <PageSection variant='white-icons'>
        <Stack gap={100}>
          <Stack gap={40}>
            <Heading variant='2'>Наши преимущества</Heading>
            <Grid
              cols={4}
              phoneCols={1}
              tabletCols={1}
              columnGap={20}
              rowGap={20}
            >
              {advantages.slice(0, 4).map((item: any, index: number) => {
                return <Stack
                  key={index}
                  gap={12}
                  alignItems='center'
                  style={{ textAlign: 'center' }}
                >
                  <Image src={item.icon} width='100px' />
                  <Heading variant='3'>{item.heading}</Heading>
                  <Text variant='text-s'>{item.text}</Text>
                </Stack>
              })}
            </Grid>
            <Stack
              gap={20}
              orientation='horizontal'
              phoneOrientation='vertical'
              justifyContent={'center'}
            >
              {advantages.slice(-3).map((item: any, index: number) => {
                return <Stack
                  key={index}
                  gap={12}
                  alignItems='center'
                  style={{ textAlign: 'center' }}
                  width={isTablet ? '100%' : '23.9%'}
                >
                  <Image src={item.icon} width='100px' />
                  <Heading variant='3'>{item.heading}</Heading>
                  <Text variant='text-s'>{item.text}</Text>
                </Stack>
              })}
            </Stack>
          </Stack>

          <Stack gap={40} orientation='horizontal' alignItems={'center'}>
            <Card width={isTablet ? '100%' : '70%'} bgColor='light-gray'>
              <Stack gap={40}>
                <Heading variant='2'>Как мы работаем</Heading>

                <Stack gap={30}>
                  {weWork.map((item: any, index: number) => {
                    return <Stack key={index} orientation='horizontal' gap={35} alignItems='center'>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '55px',
                          height: '55px',
                          borderRadius: '50%',
                          flexShrink: 0,
                          background: getWeWorkBulletBackgound(index)
                        }}
                      >
                        <Text
                          variant='text-m-medium'
                          color='white'
                        >
                          {index < 7 ? index + 1 : '+'}
                        </Text>
                      </div>
                      <Stack gap={8}>
                        <Heading
                          variant='3'
                          animate
                          animationVariant='appear-left'
                          animateDelayIndex={index}
                        >
                          {item.heading}
                        </Heading>
                        <Text variant='text-s'>{item.text}</Text>
                      </Stack>
                    </Stack>
                  })}
                </Stack>
              </Stack>
            </Card>
            {!isTablet && <div style={{ width: '30%' }}>
              <Image width={'100%'} src='/static/img/pages/methodological-center/we-work/we-work-image.svg' />
            </div>}
          </Stack>
        </Stack>
      </PageSection>

      <PageSection variant='gray'>
        <Stack gap={40}>
          <Heading variant='2'>Примеры реализованных проектов</Heading>

          <Carousel gap={10} quantity={4} tabletQuantity={2}>
            {programs.map((item: any, index: number) => {
              return <div
                key={index}
                style={{
                  padding: '10px'
                }}
              >
                <Card
                  // animate
                  animationVariant='appear-down'
                  animateDelayIndex={index}
                  onClick={() => { showProgramInfo(item) }}
                  // clickable
                  style={{height: '93%'}}
                >
                  <Stack gap={16}>
                    <Image src={item.image_url} />
                    <Stack gap={12}>
                      <TextWithDot dotColor='dark-gray'>
                        <Text variant='date-and-type-s' color='dark-gray' uppercase>{item.academic_duration}</Text>
                      </TextWithDot>
                      <Heading variant='3'>{item.name}</Heading>
                      <Text variant='text-s' color='dark-gray'>{item.description}</Text>
                    </Stack>
                  </Stack>
                </Card>
              </div>
            })}
          </Carousel>
        </Stack>
      </PageSection>

      <PageSection variant='white-icons'>
        <Stack gap={20}>
          <Heading variant='2'>Команда</Heading>
          <Carousel gap={10} quantity={3} tabletQuantity={2}>
            {employees.map((item: any, index: number) => {
              return <div
                key={index}
                style={{
                  padding: '10px',
                  display: 'flex'
                }}
              >
                <Card
                  bgColor='light-gray'
                  animate
                  animationVariant='zoom-in'
                  animateDelayIndex={index}
                >
                  <Stack gap={20}>
                    <Stack gap={16} orientation='horizontal'>
                      <Image src={item.image_url} width={120} height={120} objectFit='cover' style={{borderRadius: '20px'}} />
                      <Stack gap={12}>
                        {item.show_position && <Text variant='date-and-type-m' color='new-blue' uppercase>{item.position}</Text>}
                        <Text variant='text-m-medium'>{item.name}</Text>
                      </Stack>
                    </Stack>
                    <Text variant='text-m-medium' color='dark-gray'>{item.description}</Text>
                  </Stack>
                </Card>
              </div>
            })}
          </Carousel>
        </Stack>
      </PageSection>

      <PageSection variant='gray'>
        <Stack gap={100}>
          <Stack gap={40}>
            <Heading variant='2'>Для кого мы делали проекты</Heading>
            <Grid cols={5} phoneCols={1} tabletCols={2} columnGap={20} rowGap={50} justifyItems='center'>
              <Image height={45} src='/static/img/pages/methodological-center/clients/image1.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image2.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image3.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image4.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image5.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image6.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image7.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image8.svg' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image9.png' />
              <Image height={45} src='/static/img/pages/methodological-center/clients/image10.svg' />
            </Grid>
          </Stack>
          <Stack gap={40} style={{
            backgroundImage: 'url(/static/img/backgrounds/background4.png)',
            backgroundPositionX: 'right'
          }}>
            <Heading variant='2'>Отзывы слушателей</Heading>
            <Grid
              cols={2}
              phoneCols={1}
              tabletCols={1}
              columnGap={20}
              rowGap={20}
            >
              {reviews.map((item: any, index: number) => {
                return <Card key={index}>
                  <Stack gap={8}>
                    <Stack gap={20} orientation='horizontal' alignItems={'center'}>
                      <Image src={item.photoUrl} width={100} />
                      <Stack gap={10}>
                        <Text
                          variant='date-and-type-s'
                          color='new-blue'
                          uppercase
                        >{item.program}</Text>
                        <Stack gap={10} orientation='horizontal'>
                          <Image width={20} src='/static/img/icons/star.svg' />
                          <Image width={20} src='/static/img/icons/star.svg' />
                          <Image width={20} src='/static/img/icons/star.svg' />
                          <Image width={20} src='/static/img/icons/star.svg' />
                          <Image width={20} src='/static/img/icons/star.svg' />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Text variant='text-m-medium'>{item.text}</Text>
                  </Stack>
                </Card>
              })}
            </Grid>
          </Stack>
        </Stack>
      </PageSection>

      <QuestionSection id={'question-section'} defaultPurposeOfRequest='methodological_center' />

      <FooterSection />
    </div>
  )
}

export default MethodologicalCenterPage
