import scrollToId from 'common/scroll-to-id'

const headerTitle: string = ' Методический Центр'

const headerText: string = 'Создаем образовательные продукты для гос.сектора, вузов и бизнеса'

const targetAudience: any = [
  {
    heading: 'Государственный сектор',
    icon: '/static/img/pages/methodological-center/target-audience/icon1.svg',
    items: [
      'Комплексное ведение «под ключ» полноценной реализации программ для организаций гос.сектора',
      'От разработки программы и реализации до выпуска слушателей с удостоверениями'
    ]
  },
  {
    heading: 'Образовательные организации',
    icon: '/static/img/pages/methodological-center/target-audience/icon2.svg',
    items: [
      'Соединяем классические подходы педагогики и андрагогики с современными образовательными технологиями',
      'Помогаем образователям создавать увлекательный и современный контент'
    ]
  },
  {
    heading: 'Бизнес',
    icon: '/static/img/pages/methodological-center/target-audience/icon3.svg',
    items: [
      'Разрабатываем обучающий контент в онлайн и офлайн форматах по конкретными инструментам в зависимости от направления профессиональной деятельности'
    ]
  }
]

const usefulness: any = [
  {
    heading: 'Создать образовательный продукт с нуля',
    text: 'Если вы не представляйте, как создаются образовательные продукты, но у вас есть такая потребность, мы готовы выполнить поставленную задачу'
  },
  {
    heading: 'Аутсорсинг методической команды',
    text: 'Если у вашей организации нет квалифицированной сработанной методической команды под конкретную задачу, наша команда полностью закроет вашу потребность'
  },
  {
    heading: 'Доработка программ Центра под нужны заказчика',
    text: 'Если вам понравилась одна из наших программы, но вы не хотите создавать аналогичную «с нуля», мы готовы адаптировать ее под ваши нужды'
  },
  {
    heading: 'Перевод образовательного продукта из офлайна в онлайн',
    text: 'Вы пробовали перевести офлайн продукт в дистанционный, но пострадало качество и непонятно как сделать эффективное онлайн обучение. Аккуратно переведем продукт без потери качества.'
  },
  {
    heading: 'Работа в СДО',
    text: 'Если у вас есть потребность перевода электронного курса в проверенную СДО наша команда адаптирует и перенесет его в собственную СДО Центра и обеспечит техническое сопровождение слушателей'
  },
  {
    heading: 'Упаковать опыт эксперта',
    text: 'Вы знаете, как сделать что-то самостоятельно, но не знаете как эффективно обучить этому других. Поможем бережно перенести опыт эксперта в образовательный продукт.'
  }
]

const weCreate: any = [
  {
    icon: '/static/img/pages/methodological-center/we-create/icon1.svg',
    type: 'Онлайн-курсы',
    heading: 'Повышение квалификации',
    text: 'Создаем отдельные материалы или комплексные обучающие программы для ДПО. В конце обучения слушатели получает удостоверение образца РАНХиГС'
  },
  {
    icon: '/static/img/pages/methodological-center/we-create/icon2.svg',
    type: 'Методические материалы',
    heading: 'Учебные материалы',
    text: 'Разрабатываем обучающие лонгриды, видео, шаблоны, интерактивы, тренажеры, кейсы, рабочие тетради и др. Красиво упакуем вам контент в Curseditor.'
  },
  {
    icon: '/static/img/pages/methodological-center/we-create/icon3.svg',
    type: 'Онлайн-курсы',
    heading: 'Обучение образованцев',
    text: 'Разрабатываем обучающие программы и материалы для повышения эффективности учителей, тренеров и методистов. Рассказываем про современные технологии обучения.'
  },
  {
    icon: '/static/img/pages/methodological-center/we-create/icon4.svg',
    type: 'Онлайн-курсы',
    heading: 'Методический аудит образовательных продуктов',
    text: 'Проверяем и оптимизируем онлайн-курсы, обучающие программы, методические материалы. Пересобираем концепцию и внедряем изменения на основе обратной связи от слушателей.'
  },
  {
    icon: '/static/img/pages/methodological-center/we-create/icon5.svg',
    type: 'Онлайн-курсы',
    heading: 'Разработка открытых курсов (MOOK)',
    text: 'Создадим и соберем открытый онлайн курс на популярной площадке Stepik (Степик)'
  }
]

const advantages: any = [
  {
    icon: '/static/img/pages/methodological-center/advantages/icon1.svg',
    heading: 'База проверенных экспертов',
    text: 'Сможем подобрать практикующих экспертов для ваших программ'
  },
  {
    icon: '/static/img/pages/methodological-center/advantages/icon2.svg',
    heading: 'Структурное подразделение РАНХиГС',
    text: 'Работаем по методическим стандартам Академии, выдерживаем высокое качество программ'
  },
  {
    icon: '/static/img/pages/methodological-center/advantages/icon3.svg',
    heading: 'Программы любой длительности',
    text: 'У нас есть опыт работы с программами с длительностью от 4х недель и средним объемом от 40 до 300 часов'
  },
  {
    icon: '/static/img/pages/methodological-center/advantages/icon4.svg',
    heading: 'Любое количество слушателей',
    text: 'Умеем работать с большим числом слушателей (от 100 до 10000), организовываем поточное обучение'
  },
  {
    icon: '/static/img/pages/methodological-center/advantages/icon5.svg',
    heading: 'Полный цикл разработки в одном месте',
    text: 'Помимо собственной разработки и команды сопровождения, у нас свои дизайнеры и видео студия для записи экспертов'
  },
  {
    icon: '/static/img/pages/methodological-center/advantages/icon6.svg',
    heading: 'Документы установленного образца',
    text: 'Выдача сертификатов и документов установленного образца дополнительного образования'
  },
  {
    icon: '/static/img/pages/methodological-center/advantages/icon7.svg',
    heading: 'База материалов и конструктор программ',
    text: 'За годы работы собрали большую базу контента, можно найти готовые подборки по вашим запросам'
  }
]

const weWork: any = [
  {
    heading: 'Уточняем и фиксируем запрос',
    text: 'Определяем, какую проблему клиент планирует решить '
  },
  {
    heading: 'Согласовываем концепцию продукта',
    text: 'Помогаем понять, какой образовательный продукт лучше всего решит ваши задачи '
  },
  {
    heading: 'Утверждаем план разработки',
    text: 'Фиксируем виды работ, этапы и сроки выполнения'
  },
  {
    heading: 'Подбираем методистов под утвержденные задачи',
    text: 'В нашей команде профессионалы с большим опытом разработки и различной специализацией'
  },
  {
    heading: 'Контролируем разработку на каждом этапе',
    text: 'Лично вычитываем каждую страницу готового продукта'
  },
  {
    heading: 'Согласовываем промежуточные результаты',
    text: 'Учитываем пожелание и комментарии в рамках согласованной цели'
  },
  {
    heading: 'Передаем разработанный продукт и все материалы к нему',
    text: 'Все готово для возможности использования сразу после передачи'
  },
  {
    heading: 'Передаем инструменты для оценки эффективности',
    text: <><a onClick={() => scrollToId('question-section')}>По запросу</a>, проектируем не только продукт, но и критерии оценки его эффективности</>
  }
]

const programs: any = [
  {
    imageUrl: '/static/img/pages/methodological-center/programs/image1.svg',
    academic_duration: '228 часов',
    name: 'Реализация проектов цифровой трансформации',
    description: 'Повышение уровня квалификации государственных служащих, отвечающих за цифровую трансформацию'
  },
  {
    imageUrl: '/static/img/pages/methodological-center/programs/image2.svg',
    academic_duration: '20 часов',
    name: 'Основы цифровой трансформации',
    description: 'Повышение уровня квалификации государственных служащих, вовлеченных в цифровую трансформацию'
  },
  {
    imageUrl: '/static/img/pages/methodological-center/programs/image3.svg',
    academic_duration: '96 часов',
    name: 'Платформа обратной связи',
    description: 'Программа направлена на улучшение навыков работы с Платформой обратной связи'
  },
  {
    imageUrl: '/static/img/pages/methodological-center/programs/image4.svg',
    academic_duration: '112 часов',
    name: 'Цифровизация государственных услуг',
    description: 'Программа направлена на изучение процесса цифровизации услуг и сервисов'
  }
]

const team: any = [
  {
    photoUrl: '/static/img/pages/methodological-center/team/photo1.png',
    name: 'Валентина Кузнецова',
    position: 'Руководитель направления',
    about: 'Методист с разносторонним опытом работы в корп.обучении, провайдерах курсов и вузовском образовании.'
  },
  {
    photoUrl: '/static/img/pages/methodological-center/team/photo2.png',
    name: 'Татьяна Баратаева',
    position: 'Ведущий специалист',
    about: 'Менеджер образовательных проектов. Опытный куратор команд онлайн-обучения.'
  },
  {
    photoUrl: '/static/img/pages/methodological-center/team/photo3.png',
    name: 'Татьяна Кузнецова',
    position: 'Ведущий специалист',
    about: 'Методист, разработчик образовательных программ по цифровой трансформации, управлению и коммуникациям'
  }
]

const reviews: any = [
  {
    photoUrl: '/static/img/pages/methodological-center/reviews/photo1.png',
    program: 'Цифровая трансформация: Быстрый старт',
    rating: 5,
    text: '“Спасибо за познавательный и интересный курс! Данный курс позволил глубже понять основы цифровой трансформации и систематизировать свои знания в данной сфере.”'
  },
  {
    photoUrl: '/static/img/pages/methodological-center/reviews/photo2.png',
    program: 'Эпоха цифрового развития: основы Цифровой трансформации',
    rating: 5,
    text: '“Для меня цифоровые технологии были непонятны, сейчас благодаря вам все стало понятнее. Понравились презентации, лекции экспертов.”'
  }
]

export {
  headerTitle,
  headerText,
  targetAudience,
  usefulness,
  weCreate,
  advantages,
  weWork,
  programs,
  team,
  reviews
}
