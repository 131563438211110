import nbspacer from 'common/nbspacer'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import Text from 'components/v2/Text/Text'
import React from 'react'

const HowInteractionGoingSection = () => {
  const items: string[] = [
    'Экономим время друг друга – общаемся онлайн',
    'Гибко планируем – вы сами выбираете удобное время',
    'Все инструкции направляются на электронную почту'
  ]
  return (
    <PageSection variant='white-icons'>
      <Stack gap={30}>
        <Heading variant='2' color='blue-gray'>
          {nbspacer('Как проходит взаимодействие?')}
        </Heading>
        <Stack
          width='80%'
          tabletWidth='100%'
        >
          <Grid
            cols={3}
            tabletCols={1}
            columnGap={100}
            rowGap={60}
          >
            {items.map((item: string, index) => {
              return (
                <Stack
                  gap={10}
                  key={index}
                  justifyContent='flex-end'
                >
                  <Image
                    src={`/static/img/pages/career-management/how-interaction-going-section/image${index + 1}.svg`}
                    width={300}
                  />
                  <Text
                    variant='text-m-medium'
                    color='blue-gray'
                  >
                    {nbspacer(item)}
                  </Text>
                </Stack>
              )
            })}
          </Grid>
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default HowInteractionGoingSection
