import Color from 'common/Color.d'
import colors from 'common/colors'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import React from 'react'
import items from './data'

const CompetencyModelDetailSection = () => {
  const getTableWrapperStyle = (color: Color) => {
    return {
      overflow: 'auto',
      borderRadius: '15px',
      border: `1px solid ${colors[color]}`
    }
  }

  const tableStyle: React.CSSProperties = {
    borderStyle: 'hidden',
    borderCollapse: 'collapse',
    borderSpacing: '0'
  }

  const getTdStyle = (color: Color, bgColor?: Color) => {
    return {
      border: `1px solid ${colors[color]}`,
      padding: '20px',
      verticalAlign: 'top',
      background: bgColor ? colors[bgColor] : undefined
    }
  }

  return (
    <div>
      <Stack gap={80}>
        {items.map((item: any, idx: number) => (
          <Stack gap={20} key={item.name + idx}>
            <Heading variant='2' color={item.color as Color}>{item.name}</Heading>
            <Text variant='text-m'>{item.description}</Text>
            <div style={getTableWrapperStyle(item.color as Color)}>
              <table style={tableStyle}>
                {item.showHeaders && <tr>
                  {item.showNumbers && <th style={getTdStyle(item.color as Color)}>
                    <Text variant='text-l' color={item.color as Color}>№</Text>
                  </th>}
                  <th style={getTdStyle(item.color as Color)}>
                    <Text variant='text-l' color={item.color as Color} uppercase>{item.col1Name}</Text>
                  </th>
                  <th style={getTdStyle(item.color as Color)}>
                    <Text variant='text-l' color={item.color as Color} uppercase>{item.col2Name}</Text>
                  </th>
                </tr>}
                {item.items.map((tableRow: any, index: number) => (
                  <tr
                    key={index}
                  >
                    {tableRow.type === 'cols' && <>
                      {item.showNumbers && <td style={getTdStyle(item.color as Color)}>
                        <Text variant='text-l' color={item.color as Color}>{index + 1}</Text>
                      </td>}
                      <td style={getTdStyle(item.color as Color)}>
                        <Stack gap={9}>
                          {tableRow.col1?.map((col1Item: any, index: number) => (
                            <Stack gap={9} key={index}>
                              {col1Item.name && <Text variant='text-l' color={item.color as Color}>{col1Item.name}</Text>}
                              {col1Item.description && <Text variant='text-m'>{col1Item.description}</Text>}
                            </Stack>
                          ))}
                        </Stack>
                      </td>
                      <td style={getTdStyle(item.color as Color)}>
                        <Stack gap={9}>
                          {tableRow.col2?.map((col2Item: any, index: number) => (
                            <span key={index}>
                              {col2Item.name && <Text variant='text-l' color={item.color as Color}>{col2Item.name}&nbsp;</Text>}
                              {col2Item.description && <Text variant='text-m'>{col2Item.description}</Text>}
                            </span>
                          ))}
                        </Stack>
                      </td>
                    </>}

                    {tableRow.type === 'header1' && <>
                      <td style={getTdStyle(item.color as Color, tableRow.bgColor as Color)} colSpan={2}>
                        <Text variant='text-l' color={item.color as Color}>{tableRow.name}</Text>
                        &nbsp;
                        <Text variant='text-m'>{tableRow.description}</Text>
                      </td>
                    </>}

                    {tableRow.type === 'header2' && <>
                      <td style={getTdStyle(item.color as Color, tableRow.bgColor as Color)} colSpan={2}>
                        <Text variant='text-l' color={item.color as Color} uppercase>{tableRow.name}</Text>
                      </td>
                    </>}
                  </tr>
                ))}
              </table>
            </div>
          </Stack>
        ))}
      </Stack>
    </div>
  )
}

export default CompetencyModelDetailSection
