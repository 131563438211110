
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'
import TextWithDot from 'components/TextWithDot/TextWithDot'
import Text from 'components/Text/Text'
import FooterSection from 'page-sections/FooterSection/FooterSection'
import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import Card from 'components/Card/Card'
import Image from 'components/Image/Image'
import { getFormattedDate } from 'common/utils'
import React, { useEffect, useState } from 'react'
import useMediaQuery from 'common/useMediaQuery'
import { useParams } from 'react-router-dom'
// import nbspacer from 'common/nbspacer'

// import {ReactComponent as TelegramIcon} from 'assets/icons/telegram.svg'
import {ReactComponent as VkIcon} from 'assets/icons/vk.svg'
import {ReactComponent as OkIcon} from 'assets/icons/ok.svg'
import {ReactComponent as CopyLinkIcon} from 'assets/icons/copy-link.svg'

import colors from 'common/colors'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import QuestionSection from 'page-sections/QuestionSection/QuestionSection'

import styles from './NewsItem.module.css'
// import nbspacer from 'common/nbspacer'

type NewsItemData = {
  name: string;
  date: string;
  image_url?: string;
  content: string;
}

const NewsItem = () => {
  const {isPhone} = useMediaQuery()
  const { id } = useParams()

  const [newsItem, setNewsItem] = useState({} as NewsItemData)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const url = window.location.href
  const vkShareUrl = `https://vk.com/share.php?url=${window.location.href}`
  const okShareUrl = `https://connect.ok.ru/offer?url=${window.location.href}`

  useEffect(() => {
    fetchNewsItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchNewsItem = async () => {
    const response = await fetch(`/api/news/${id}`)
    if (response.ok) {
      const data = await response.json()
      setNewsItem(data)
    }
  }

  const copyUrl = (event: React.SyntheticEvent) => {
    event.preventDefault()
    navigator.clipboard.writeText(url).then(() => {
      setIsModalVisible(true)
    })
  }

  const sectionStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0
  }

  const cardStyle: React.CSSProperties = {
    padding: isPhone ? 40 : 60
    // display: 'flex'
    // maxWidth: '100%',
    // width: isTablet ? undefined : '100%'
    // width: isTablet ? undefined : 974
  }

  const shareLinkStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <div>
      <Modal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        <Stack gap={30}>
          <Stack gap={6}>
            <Text>Ссылка на новость скопирована в буфер обмена</Text>
          </Stack>
          <Stack alignItems='flex-end'><Button variant='filled' onClick={() => setIsModalVisible(false)}>Ok</Button></Stack>
        </Stack>
      </Modal>

      <HeaderSection />

      <PageSection bgColor='light-gray' style={sectionStyle}>
        <Card style={cardStyle}>
          <Stack gap={20} style={{width: '100%'}}>
            <Stack gap={60} style={{width: '100%'}}>
              <Stack gap={12}>
                <TextWithDot dotColor='dark-gray' dotVariant='rounded'>
                  <Text color='dark-gray' uppercase variant='tiny'>{getFormattedDate(newsItem.date)}</Text>
                </TextWithDot>
                <SectionHeading>{newsItem.name}</SectionHeading>
              </Stack>

              <Image src={newsItem.image_url ?? ''} width='100%' />

              <div
                className={styles.NewsContent}
                dangerouslySetInnerHTML={{__html: newsItem.content ?? ''}}
              />
            </Stack>
            <Stack orientation='horizontal' gap={20} justifyContent='flex-end' alignItems='center'>
              <Text fontWeight='medium'>Поделиться</Text>
              <Stack orientation='horizontal' gap={8} alignItems='center'>
                {/* <TelegramIcon fill={colors['emerald']} width='26px' /> */}
                <a
                  style={shareLinkStyle}
                  href={vkShareUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <VkIcon fill={colors['emerald']} width='26px' />
                </a>
                <a
                  style={shareLinkStyle}
                  href={okShareUrl}
                  target="_blank"
                  rel="noreferrer"
                  title='Поделиться в Одноклассниках'
                >
                  <OkIcon fill={colors['emerald']} width='26px' />
                </a>
              </Stack>
              <a
                style={shareLinkStyle}
                href={okShareUrl}
                onClick={copyUrl}
                title='Скопировать ссылку'
              >
                <CopyLinkIcon
                  stroke={colors['emerald']}
                  width='26px'
                />
              </a>
            </Stack>
          </Stack>
        </Card>
      </PageSection>

      <QuestionSection id='question-section' />

      <FooterSection />
    </div>
  )
}

export default NewsItem
