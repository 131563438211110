import Card from 'components/Card/Card'
import Stack from 'components/Stack/Stack'
import * as Icon from 'react-feather'
import React, { useEffect } from 'react'

import styles from './Modal.module.css'
import Text from 'components/v2/Text/Text'
import Color from 'common/Color.d'
import colors from 'common/colors'

type ModalProps = {
  onClose?: any;
  children?: React.ReactNode;
  visible: boolean;
  title?: string;
  titleColor?: Color;
  maxWidth?: number;
  width?: React.CSSProperties['width'];
  bgColor?: Color;
  closeButtonColor?: Color;
}

const Modal = (props: ModalProps) => {
  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        props.onClose?.()
      }
    }
    const handleClick = () => {
      props.onClose?.()
    }
    window.addEventListener('keydown', handleEsc)
    document.addEventListener('mousedown', handleClick)

    return () => {
      window.removeEventListener('keydown', handleEsc)
      document.removeEventListener('mousedown', handleClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleModalMouseDown = (e: any) => {
    e.stopPropagation()
  }

  const handleCloseButtonMouseDown = (e: any) => {
    props.onClose?.()
  }

  return (
    <div className={styles['modal'] + (!props.visible ? ` ${styles['modal--hidden']}` : '')}>
      <Card
        onMouseDown={handleModalMouseDown}
        className={styles['modal__card']}
        style={{
          width: props.width,
          maxWidth: props.maxWidth
        }}
        bgColor={props.bgColor}
      >
        <Stack gap={20}>
          <Stack orientation='horizontal' gap={20}>
            <Text
              variant='text-l'
              flexGrow={1}
              color={props.titleColor ?? 'black'}
            >
              {props.title}
            </Text>
            <button
              className={styles['modal__button']}
              onMouseDown={handleCloseButtonMouseDown}
            >
              <Icon.X width={24} color={colors[props.closeButtonColor ?? 'black']} />
            </button>
          </Stack>

          <div className={styles['modal__content']}>
            {props.children}
          </div>
        </Stack>
      </Card>
    </div>
  )
}

export default Modal
