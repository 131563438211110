const heading: string = 'Наши услуги'

const navItems: any = [
  {
    id: 1,
    text: 'Для работодателя'
  },
  {
    id: 2,
    text: 'Для соискателей'
  },
  {
    id: 3,
    text: 'Для руководителей подразделений ЦТ'
  }
]

const cardData: any = [
  {
    heading: 'Для работодателя',
    items: [
      {
        id: 1,
        description: 'Проводим квалифицированные консультации в области подбора, обучения и оценки специалистов ИТ-сферы.'
      },
      {
        id: 2,
        description: 'Помогаем закрывать сложные вакансии. '
      },
      {
        id: 3,
        description: 'Сопровождаем трудоустройство соискателей на всех этапах.'
      },
      {
        id: 4,
        description: 'Способствуем в формировании профессионального портфолио кандидатов.'
      }
    ]
  },
  {
    heading: 'Для соискателей',
    items: [
      {
        id: 5,
        description: 'Проводим комплексную оценку компетенций в сфере цифрового развития.'
      },
      {
        id: 6,
        description: 'Помогаем войти в резерв высококвалифицированных руководителей и специалистов цифровой трансформации государственного управления.'
      },
      {
        id: 7,
        description: 'Разрабатываем паспорт компетенций и организуем консультации с квалифицированными отраслевыми экспертами.'
      },
      {
        id: 8,
        description: 'Даем индивидуальные рекомендации по дальнейшему развитию в сфере цифровой трансформации.'
      }
    ]
  },
  {
    heading: 'Для руководителей подразделений ЦТ',
    items: [
      {
        id: 9,
        description: 'Проводим независимую квалифицированную оценку компетенций сотрудников подразделений ЦТ.'
      },
      {
        id: 10,
        description: 'Определяем мотивированных к профессиональному развитию руководителей и специалистов ИТ-сферы и ЦТ.'
      },
      {
        id: 11,
        description: 'Содействуем в поиске узкоспециализированных кадров для уникальных цифровых проектов.'
      },
      {
        id: 12,
        description: 'Помогаем привлечь кандидатов, которые не ищут работу.'
      }
    ]
  }
]

export { heading, navItems, cardData }
