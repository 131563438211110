import nbspacer from 'common/nbspacer'
import Grid from 'components/Grid/Grid'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Text from 'components/Text/Text'

type HeaderBlockProps = {
  heading?: string;
  subheading?: string;
  text?: string;
  imageUrl?: string;
}

const HeaderBlock = (props: HeaderBlockProps) => {
  return (
    <Grid
      cols={2}
      tabletCols={1}
      columnGap={60}
      rowGap={40}
    >
      <Stack gap={40}>
        <Stack gap={12}>
          <Text variant='tiny' fontWeight='medium' uppercase color='dark-gray'>{nbspacer(props.subheading ?? '')}</Text>
          <Heading variant='1'>{nbspacer(props.heading ?? '')}</Heading>
        </Stack>
        <Text>
          <div dangerouslySetInnerHTML={{ __html: nbspacer(props.text ?? '') }} />
        </Text>
      </Stack>
      <Image
        animate
        animationVariant='appear-left'
        src={props.imageUrl ?? ''}
        width={'100%'}
      />
    </Grid>
  )
}

export default HeaderBlock
export type { HeaderBlockProps }
