import PageSection from 'components/v2/PageSection/PageSection'
import { useState, useEffect } from 'react'
import Text from 'components/v2/Text/Text'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Image from 'components/Image/Image'
import ProgramDirection from 'common/program-constructor/ProgramDirection.d'
import Program from 'common/program-constructor/Program.d'

type ProgramsSectionProps = {
  onUpdateSelectedPrograms?: (programs: Program[]) => void
}

const ProgramsSection = (props: ProgramsSectionProps) => {
  const [programDirections, setProgramDirections] = useState([])
  const [programs, setPrograms] = useState([])
  const [selectedProgramDirection, setSelectedProgramDirection] = useState<ProgramDirection | null>(null)
  const [selectedPrograms, setSelectedPrograms] = useState([] as Program[])

  useEffect(() => {
    setSelectedPrograms(JSON.parse(localStorage.getItem('selectedPrograms') ?? '[]'))
    fetchProgramDirections()
    fetchPrograms()
  }, [])

  useEffect(() => {
    props.onUpdateSelectedPrograms?.(selectedPrograms)
  }, [selectedPrograms]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProgramDirections = async () => {
    const response = await fetch('/api/program-constructor/program-directions/')
    const data = await response.json()
    setProgramDirections(data)
  }

  const fetchPrograms = async () => {
    const response = await fetch('/api/program-constructor/programs/')
    const data = await response.json()
    setPrograms(data)
  }

  const getFilteredPrograms = () => {
    if (selectedProgramDirection === null) {
      return programs
    }
    return programs.filter((program: any) => program.program_direction_id === selectedProgramDirection.id)
  }

  const isProgramSelected = (program: Program) => {
    return !!selectedPrograms.find((selectedProgram: Program) => selectedProgram.id === program.id)
  }

  const selectProgram = (program: Program) => {
    if (!isProgramSelected(program)) {
      const newSelectedPrograms = [...selectedPrograms, program]
      setSelectedPrograms(newSelectedPrograms)
      localStorage.setItem('selectedPrograms', JSON.stringify(newSelectedPrograms))
    }
  }

  const unselectProgram = (program: Program) => {
    if (isProgramSelected(program)) {
      const newSelectedPrograms = selectedPrograms.filter((selectedProgram: Program) => selectedProgram.id !== program.id)
      setSelectedPrograms(newSelectedPrograms)
      localStorage.setItem('selectedPrograms', JSON.stringify(newSelectedPrograms))
    }
  }

  return (
    <PageSection variant='white-icons'>
      <Stack gap={40}>
        <Heading variant='2'>Образовательные программы и направления</Heading>
        <Stack
          orientation='horizontal'
          tabletOrientation='vertical'
          gap={40}
        >
          <Stack
            style={{width: '25%'}}
            gap={30}
          >
            <Text
              variant='date-and-type-m'
              uppercase
              color='green'
            >
              Направления
            </Text>

            <div>
              {(selectedProgramDirection === null) && <Heading
                variant='3'
                color='green'
                style={{borderBottom: '2px solid #00826F', display: 'inline-block', userSelect: 'none'}}
              >
                Все направления
              </Heading> ||
              <Text
                variant='text-m-medium'
                color='green'
                style={{cursor: 'pointer', userSelect: 'none'}}
                onClick={() => setSelectedProgramDirection(null)}
              >
                Все направления
              </Text>}
            </div>

            {programDirections.map((programDirection: ProgramDirection, index: number) => {
              return <div key={index}>
                {(selectedProgramDirection?.id === programDirection.id) && <Heading
                  variant='3'
                  color='green'
                  style={{borderBottom: '2px solid #00826F', display: 'inline-block', userSelect: 'none'}}
                >
                  {programDirection.name}
                </Heading> ||
                <Text
                  variant='text-m-medium'
                  color='green'
                  style={{cursor: 'pointer', userSelect: 'none'}}
                  onClick={() => setSelectedProgramDirection(programDirection)}
                >
                  {programDirection.name}
                </Text>}
              </div>
            })}
          </Stack>
          <Stack
            flexGrow={1}
            gap={40}
          >
            <Stack gap={20}>
              <Text
                variant='date-and-type-m'
                uppercase
                color='black'
              >
                Программы
              </Text>
              {getFilteredPrograms().map((program: Program, index: number) => {
                return <div key={index}>
                  <Stack orientation='horizontal'>
                    <Heading variant='3' style={{flexGrow: 1}}>{program.name}</Heading>
                    {isProgramSelected(program) &&
                    <Image
                      src='/static/img/pages/program-constructor/icons/shoppingcart-remove-icon.svg'
                      width={60}
                      style={{cursor: 'pointer'}}
                      onClick={() => unselectProgram(program)}
                    /> ||
                    <Image
                      src='/static/img/pages/program-constructor/icons/shoppingcart-add-icon.svg'
                      width={60}
                      style={{cursor: 'pointer'}}
                      onClick={() => selectProgram(program)}
                    />}
                  </Stack>
                </div>
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default ProgramsSection
