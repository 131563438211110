import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'
import Grid from 'components/Grid/Grid'
import Heading from 'components/Heading/Heading'
import PageSection from 'components/PageSection/PageSection'
import SectionHeading from 'components/SectionHeading/SectionHeading'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Text from 'components/Text/Text'

import { heading, text, bullets1, bullets2 } from './data'
import DotArrowListCard from 'components/DotArrowListCard/DotArrowListCard'

type PurposeSectionProps = {
  id?: number | string;
  bgColor?: Color;
}

const PurposeSection = (props: PurposeSectionProps) => {
  return (
    <PageSection {...props}>
      <SectionHeading animate animationVariant='appear-up'>{heading}</SectionHeading>
      <Text>
        <div dangerouslySetInnerHTML={{__html: nbspacer(text ?? '')}} />
      </Text>

      <Grid
        cols={2}
        tabletCols={1}
        columnGap={40}
        rowGap={40}
        alignItems='center'
      >
        <Stack gap={60}>
          <Stack gap={30}>
            <Heading variant='3'>При достижении целевого значения:</Heading>
            {bullets1.map((bullet: any) => {
              return <DotArrowListCard key={bullet.id} {...bullet} />
            })}
          </Stack>
          <Stack gap={30}>
            <Heading variant='3'>Задачи проведения оценки ИИ-зрелости ФОИВ</Heading>
            {bullets2.map((bullet: any) => {
              return <DotArrowListCard key={bullet.id} {...bullet} />
            })}
          </Stack>
        </Stack>
        <Image animate animationVariant='appear-left' src='static/img/pages/ai-eval/purpose-image.png' width={'100%'} />
      </Grid>
    </PageSection>
  )
}

export default PurposeSection
