import React from 'react'

import PageSection from 'components/v2/PageSection/PageSection'

import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import Grid from 'components/Grid/Grid'
import useMediaQuery from 'common/useMediaQuery'

const FaqSection = () => {
  const [expandedIndexses, setExpandedIndexses] = React.useState<number[]>([])

  const { isTablet } = useMediaQuery()

  const toggleExpanded = (index: number) => {
    if (expandedIndexses.includes(index)) {
      setExpandedIndexses(expandedIndexses.filter((i) => i !== index))
    } else {
      setExpandedIndexses([...expandedIndexses, index])
    }
  }

  type FaqItem = { question: string; answer: React.ReactNode };

  const items: Array<FaqItem> = [
    {
      question:
        'По каким образовательным программам можно пройти обучение в Центре?',
      answer: (
        <>
          Об актуальных программах повышения квалификации, реализуемых Центром в
          2024 году, можно узнать{' '}
          <a href="https://cdto.ranepa.ru/program-registry">тут</a>
        </>
      )
    },
    {
      question:
        'Могу ли я пройти обучение, если у меня нет опыта и знаний в данной сфере и есть ли какие-то требования для абитуриентов?',
      answer: (
        <>
          Единственным требованием является наличие у кандидата диплома о высшем
          или среднем профессиональном образовании.
          <br />
          Пройти обучение по программам может любой желающий, они не
          предполагают обязательного наличия у абитуриента определенных навыков,
          знаний и компетенций по теме программы, но в разделе каждой программы
          “Для кого курс” можно посмотреть, кому мы рекомендуем ее в первую
          очередь.
        </>
      )
    },
    {
      question: 'Где я могу ознакомиться с расписанием программ 2024 год?',
      answer: (
        <>
          Информацию о запланированных потоках обучения можно найти на странице{' '}
          <a href="https://cdto.ranepa.ru/program-registry/timetable">
            «Расписание программ»
          </a>
        </>
      )
    },
    {
      question:
        'Как я могу оплатить обучение? Есть ли возможность оплаты юридическим лицом?',
      answer: (
        <>
          Физические лица могут оплатить обучение дистанционно через эквайринг в
          личном кабинете, предварительно ознакомившись с договором-офертой при
          регистрации.
          <br />
          <br />
          Оплата обучения слушателя или группы слушателей юридическим лицом
          осуществляется на основании договора, заключаемого между РАНХиГС и
          юридическим лицом.
        </>
      )
    },
    {
      question:
        'Где найти ссылку на регистрацию? Как подать заявку на следующие потоки?',
      answer: (
        <ul>
          <li>
            Чтобы зарегистрироваться на курс / определенный поток, пройдите по
            ссылке https://forms.yandex.ru/u/6655ed653e9d08165e1e8108/
          </li>
          <li>После заполнения формы регистрации с Вами свяжется менеджер программы.</li>
        </ul>
      )
    },
    {
      question:
        'Как я узнаю о старте своего курса? Будет ли поддержка во время обучения?',
      answer: (
        <ul>
          <li>За несколько дней до старта вашего обучения мы направим на указанную при регистрации электронную почту письмо с напоминанием и инструкциями</li>
          <li>Во время и после обучения ответы на свои вопросы вы можете получить, написав на почту менеджеру программы{' '}<a href="mailto:zakharova-maa@ranepa.ru">zakharova-maa@ranepa.ru</a></li>
        </ul>
      )
    },
    {
      question:
        'Могу ли я перенести сроки своего обучения/ взять паузу и перевестись в другой поток?',
      answer: (
        <>
          Перенос сроков обучения или перевод на другой поток возможен только
          при предъявлении листка нетрудоспособности (больничного листа).
        </>
      )
    },
    {
      question:
        'Какие документы и в каком виде нужно предоставить для регистрации на обучение?',
      answer: (
        <ul>
          <li>Для регистрации на обучение вам необходимо предоставить копии документов в электронном виде:</li>
          <li>паспорт РФ;</li>
          <li>СНИЛС;</li>
          <li>диплом о высшем или среднем профессиональном образовании;</li>
          <li>документ о смене Ф. И. О., если данные в документах об образовании и паспорте разнятся.</li>
        </ul>
      )
    },
    {
      question: 'Какой документ я получу по итогам обучения?',
      answer: (
        <>
          Выпускники наших программ получают электронные удостоверения о
          повышении квалификации установленного Академией образца, которые имеют
          такую же юридическую силу, как и документы на бумажном носителе.
          Электронный документ подписывается в соответствии с законодательством
          Российской Федерации электронной подписью проректора, курирующего
          образовательную деятельность. Документ формируется в формате pdf и
          отражается в Федеральном реестре сведений о документах об образовании
          и (или) о квалификации, документах об обучении.
          <br />В случае, если набранных баллов будет недостаточно для получения
          удостоверения, мы сможем выдать справку о прохождении обучения.
        </>
      )
    },
    {
      question:
        'Как и где будет проходить обучение? Как долго останется доступ?',
      answer: (
        <>
          Обучение будет проходить на образовательном портале Института ВШГУ РАНХиГС.
          <br />
          За несколько дней до старта обучения вам придет письмо с напоминанием, а также логином и паролем.
          <br />
          Доступ к материалам сохранится на один год.
        </>
      )
    },
    {
      question: 'Получится ли совмещать учебу и работу?',
      answer: (
        <>
          Совмещение работы и учебы возможно, однако наши программы представляют
          из себя интенсивные курсы и строго ограничены по времени. Для
          успешного окончания курса и качественного усвоения всех материалов мы
          рекомендуем уделять учебе около двух часов в день.
        </>
      )
    },
    {
      question: 'Куда обратиться, если остались вопросы?',
      answer: (
        <>
          По всем вопросам можно обратиться к менеджеру программы Марии Захаровой:{' '}
          <a href="mailto:zakharova-maa@ranepa.ru">zakharova-maa@ranepa.ru</a>
        </>
      )
    }
  ]

  const getExpanded = (index: number) => {
    return expandedIndexses.includes(index)
  }

  return (
    <PageSection variant="gray" id="faq-section">
      <Stack gap={40}>
        <Heading variant="2">Часто задаваемые вопросы</Heading>
        <Stack gap={16}>
          <Grid columnGap={20} rowGap={16} cols={2} tabletCols={1}>
            <div style={{ height: '1px', borderBottom: '1px solid #459689' }} />
            {!isTablet && (
              <div
                style={{ height: '1px', borderBottom: '1px solid #459689' }}
              />
            )}
          </Grid>
          <Grid columnGap={20} rowGap={16} cols={2} tabletCols={1}>
            {items.map((item: FaqItem, index: number) => {
              return (
                <Stack
                  key={index}
                  style={{
                    borderBottom: '1px solid #459689',
                    paddingBottom: '16px'
                  }}
                  gap={20}
                >
                  <Stack
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                    orientation="horizontal"
                    alignItems="center"
                    onClick={() => toggleExpanded(index)}
                  >
                    <Heading variant="3" style={{ flexGrow: 1 }}>
                      {item.question}
                    </Heading>
                    <Image
                      src="/static/img/pages/program-registry/page-sections/faq-section/plus.svg"
                      width={20}
                      height={20}
                      alt="arrow"
                      style={{
                        transform: getExpanded(index) ? 'rotate(45deg)' : ''
                      }}
                    />
                  </Stack>
                  {getExpanded(index) && (
                    <Text variant="text-s">{item.answer}</Text>
                  )}
                </Stack>
              )
            })}
          </Grid>
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default FaqSection
