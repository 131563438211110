import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'
import Image from 'components/Image/Image'

type CertificateSectionProps = {
  variant?: 'gradient-green' | 'white-icons' | 'gradient-green-one-star' | 'gradient-green-stars' | 'gray'
}

const CertificateSection = (props: CertificateSectionProps) => {
  return (
    <PageSection variant={props.variant}>
      <Grid
        cols={2}
        tabletCols={1}
        columnGap={20}
        rowGap={20}
        alignItems='center'
      >
        <Stack gap={40}>
          <Heading variant='2'>Удостоверение<div style={{color: '#00826F'}}>установленного<br/>Академией образца</div></Heading>
          <Text variant='text-m-medium'>Обучение на наших программах — это возможность получить официальный документ о повышении квалификации и профессиональной переподготовке в Президентской академии — крупнейшем вузе страны.</Text>
        </Stack>
        <Stack alignItems='center'>
          <Image
            src='/static/img/pages/program-registry/page-sections/certificate-section/certificate.svg'
            width='480px'
          />
        </Stack>
      </Grid>
    </PageSection>
  )
}

export default CertificateSection
