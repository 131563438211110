
import React from 'react'
import Color from 'common/Color.d'
import colors from 'common/colors'
import useMediaQuery from 'common/useMediaQuery'

type TextVariant = 'tiny' | 'small' | 'normal' | 'medium' | 'large' | 'extra-large'
type FontWeight = 'normal' | 'medium' | 'bold' | 'extra-bold'
const FontWeights = {
  'normal': 400,
  'medium': 500,
  'bold': 700,
  'extra-bold': 800
}

type TextProps = {
  children?: React.ReactNode;
  color?: Color;
  className?: string;
  variant?: TextVariant;
  fontWeight?: FontWeight;
  uppercase?: boolean;
  align?: React.CSSProperties['textAlign'];
  maxWidth854?: boolean;
  textOverflow?: React.CSSProperties['textOverflow'];
  overflow?: React.CSSProperties['overflow'];
  overflowY?: React.CSSProperties['overflowY'];
  maxHeight?: React.CSSProperties['maxHeight'];
  maxWidth?: React.CSSProperties['maxWidth'];
  width?: React.CSSProperties['width'];
  tabletWidth?: React.CSSProperties['width'];
  phoneWidth?: React.CSSProperties['width'];
  underline?: boolean;
}

const Text = (props: TextProps) => {
  const {isTablet, isPhone} = useMediaQuery()

  const getResponsiveProp = (desktop: any, tablet: any, phone: any) => {
    if (isPhone && phone) {
      return phone
    }
    if (isTablet && tablet) {
      return tablet
    }
    return desktop
  }

  const className = `text text--${props.variant ?? 'normal'} ${props.className ?? ''}`

  const style: React.CSSProperties = {
    fontWeight: props.fontWeight ? FontWeights[props.fontWeight] : FontWeights['normal'],
    textAlign: props.align,
    color: colors[props.color ?? 'black'],
    textTransform: props.uppercase ? 'uppercase' : 'none',
    maxWidth: props.maxWidth ?? (props.maxWidth854 ? '854px' : '100%'),
    textOverflow: props.textOverflow,
    overflow: props.overflow,
    overflowY: props.overflowY,
    maxHeight: props.maxHeight,
    width: getResponsiveProp(props.width, props.tabletWidth, props.phoneWidth),
    textDecoration: props.underline ? 'underline' : 'none'
  }

  const componentProps = {
    style: style,
    className: className
  }

  return (
    <span {...componentProps}>{props.children}</span>
  )
}

export default Text
export type { TextProps, TextVariant, FontWeight }
