import PageSection from 'components/PageSection/PageSection'
import Stack from 'components/Stack/Stack'
import FooterSection from 'page-sections/FooterSection/FooterSection'
import HeaderSection from 'page-sections/HeaderSection/HeaderSection'
import React, { useEffect, useState } from 'react'
import MaterialCard from 'components/MaterialCard/MaterialCard'
import SwitchButton from 'components/SwitchButton/SwitchButton'
import Grid from 'components/Grid/Grid'
import ColorMaterialCard from 'components/ColorMaterialCard/ColorMaterialCard'
import MaterialData from 'common/MaterialData.d'
import MaterialTypeData from 'common/MaterialTypeData.d'
import Button from 'components/Button/Button'
import { useParams } from 'react-router-dom'
import Heading from 'components/Heading/Heading'

const MaterialsByTypePage = () => {
  const [materialTypeList, setMaterialTypeList] = useState([] as MaterialTypeData[])
  const [materialCategoryList, setMaterialCategoryList] = useState([] as any[])
  const [materialList, setMaterialList] = useState([] as any[])
  const [latestMaterialList, setLatestMaterialList] = useState([] as any[])
  const [selectedCategoryId, setSelectedCategoryId] = useState(null as null | number)
  const [currentType, setCurrentType] = useState({} as MaterialTypeData)

  const { id } = useParams()

  useEffect(() => {
    fetchMaterialTypeList()
    fetchMaterialCategories()
    fetchMaterials()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchMaterialCategories = async () => {
    const response = await fetch(`/api/material-categories/`)
    if (response.ok) {
      const data = await response.json()
      setMaterialCategoryList(data)
    }
  }

  const fetchMaterialTypeList = async () => {
    const response = await fetch(`/api/material-types/`)
    if (response.ok) {
      const data = await response.json()
      setMaterialTypeList(data)
    }
  }

  useEffect(() => {
    fetchMaterials()
    fetchLatestMaterials()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryId])

  useEffect(() => {
    fetchMaterials()
    fetchLatestMaterials()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    const currentTypeItem = materialTypeList.find((e) => {
      return e.id === parseInt(id ?? '')
    })
    if (currentTypeItem) {
      setCurrentType(currentTypeItem)
    }
  }, [materialTypeList, id])

  const fetchMaterials = async () => {
    const url = `/api/materials/`
    let params: any = {}
    params['type_id'] = id
    if (selectedCategoryId) {
      params['category_id'] = selectedCategoryId
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setMaterialList(data)
    }
  }

  const fetchLatestMaterials = async () => {
    const url = `/api/materials/`
    let params: any = {
      limit: 3,
      type_id: id
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setLatestMaterialList(data)
    }
  }

  const getCards = () => {
    let i = 0
    let result = []
    while (i < materialList.length) {
      let current = materialList[i]
      if (current.materialTypeName === 'Исследование') {
        result.push(
          <MaterialCard key={i} {...current} showImage />
        )
        i++
        continue
      }

      let next = i < materialList.length ? materialList[i + 1] : null
      if (next && next.materialTypeName !== 'Исследование') {
        result.push(
          <Grid columnGap={20} rowGap={20} cols={2} tabletCols={1} key={i}>
            <MaterialCard {...current} />
            <MaterialCard {...next} />
          </Grid>
        )
        i = i + 2
        continue
      }
      result.push(
        <Grid columnGap={20} rowGap={20} cols={2} tabletCols={1}>
          <MaterialCard key={i} {...current} />
          <div />
        </Grid>
      )
      i = i + 1
      continue
    }
    return result
  }

  return (
    <div>
      <HeaderSection paddingBottom={0}></HeaderSection>
      <PageSection bgColor='light-gray' style={{ paddingTop: '40px' }}>
        <Stack orientation='horizontal' gap={20}>
          {materialTypeList.filter((e) => { return e.id !== parseInt(id ?? '') }).map((item: MaterialTypeData) => {
            return <Button href={`/sum-of-tech/materials-by-type/${item.id}`} key={item.id} variant='outlined'>{item.name}</Button>
          })}
        </Stack>

        <Heading variant='1'>{currentType ? currentType.namePlural : ''}</Heading>

        <Grid cols={3} columnGap={20}>
          {latestMaterialList.slice(0, 3).map((e: MaterialData, index: number) => {
            return <ColorMaterialCard key={e.id} {...e} gradientColor={index === 0 ? 'green' : 'blue'} size='large' />
          })}
        </Grid>

        <Stack orientation='horizontal' justifyContent={'flex-start'} wrap='wrap' gap={20}>
          <SwitchButton
            pressed={selectedCategoryId === null}
            onClick={() => setSelectedCategoryId(null)}
          >
            Все тексты
          </SwitchButton>
          {materialCategoryList.map((item: any) => {
            return (<SwitchButton
              key={item.id}
              pressed={selectedCategoryId === item.id}
              onClick={() => setSelectedCategoryId(item.id)}
            >
              {item.name}
            </SwitchButton>)
          })}
        </Stack>

        <Stack gap={20} width='100%'>
          {getCards()}
        </Stack>

      </PageSection>
      <FooterSection />
    </div>
  )
}

export default MaterialsByTypePage
