import * as Icon from 'react-feather'
import Heading from 'components/Heading/Heading'
import Stack from 'components/Stack/Stack'
import React from 'react'
import colors from 'common/colors'
import Color from 'common/Color.d'
import { AnimationVariant } from 'common/getAnimationStyle'
import useMediaQuery from 'common/useMediaQuery'

type SectionHeadingProps = {
  showArrow?: boolean;
  children?: React.ReactNode;
  textColor?: Color;
  animate?: boolean;
  animationVariant?: AnimationVariant;
}

const SectionHeading = (props: SectionHeadingProps) => {
  const { isTablet, isPhone } = useMediaQuery()
  let style: React.CSSProperties = {
  }

  const elProps: any = {
    style: style,
    orientation: 'horizontal',
    gap: 20,
    alignItems: 'center'
  }

  const arrowProps: any = {
    size: isPhone ? 26 : (isTablet ? 32 : 38),
    color: colors['emerald'],
    style: {
      position: 'relative',
      top: isTablet ? '3px' : '5px'
    }
  }

  return (
    <Stack {...elProps}>
      <Heading
        maxWidth854
        animate={props.animate}
        animationVariant={props.animationVariant}
        variant='1'
        color={props.textColor}
      >
        {props.children}
        {props.showArrow && <Icon.ArrowRight {...arrowProps} />}
      </Heading>
    </Stack>
  )
}

export default SectionHeading
