import { DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'

const heading: string = 'Наши преимущества'

const advantageList: DotArrowListCardProps[] = [
  {
    id: 1,
    name: 'Экспертность в подборе кадров для ЦТ',
    description: 'Специализированные знания имеют большое значение при наборе персонала. Мы — лидеры в сфере компетенций по цифровой трансформации и готовы оказать услуги, выходящие за рамки обычного функционала кадрового агентства.'
  },
  {
    id: 2,
    name: 'Отличный сервис',
    description: 'Мы ориентированы на заказчика и адаптированы к его потребностям.'
  },
  {
    id: 3,
    name: 'Быстрый и легкий рекрутинг',
    description: 'Скорость имеет решающее значение для найма востребованных сотрудников. У нас есть знания и опыт, чтобы действовать четко и оперативно. Мы находим соискателей в кратчайшие сроки.'
  },
  {
    id: 4,
    name: 'Глубокая методологическая проработка всех процессов',
    description: 'Для проведения оценки компетенций мы разработали модель базовых компетенций команды цифровой трансформации.'
  },
  {
    id: 5,
    name: 'Простые и понятные инструменты',
    description: 'Система тестирования гибко настраивается под потребности и специфику заказчика. Мы разрабатываем модели, которые легко понять и использовать, а также применяем разные инструменты оценки, включая глубинное интервью и метод «360 градусов». '
  }
]

const image1Url: string = '/static/img/pages/selection-evaluation/advantages-image1.png'
const image2Url: string = '/static/img/pages/selection-evaluation/advantages-image2.png'

export { heading, advantageList, image1Url, image2Url }
