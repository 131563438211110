import getCookie from 'common/get-coockie'
import Heading from 'components/v2/Heading/Heading'
import React from 'react'
import Button from 'components/Button/Button'
import Checkbox from 'components/Checkbox/Checkbox'
import TextField from 'components/TextField/TextField'
import Stack from 'components/Stack/Stack'
import Program from 'common/program-constructor/Program.d'
import Textarea from 'components/Textarea/Textarea'
import Link from 'components/Link/Link'

type OrderFormProps = {
  onSubmit?: any;
  disabled?: boolean;
  checkoutPrograms?: Program[];
  onNumberInput: any;
  onSuccessResponse?: any;
}

const OrderFrom = (props: OrderFormProps) => {
  const [allowPd, setAllowPd] = React.useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      name: { value: string }
      email: { value: string }
      phone: { phone: string }
      number: { value: string }
      oraganization: { value: string }
      comment: { value: string }
      allow_pd: { value: boolean }
      program_ids: { value: string }
    }
    const name = target.name.value
    const email = target.email.value
    const phone = target.phone.value
    const number = target.number.value
    const organization = target.organization.value
    const comment = target.comment.value
    const allowPd = target.allow_pd.checked

    const body = {
      name: name,
      email: email,
      phone: phone,
      number: number,
      organization: organization,
      comment: comment,
      allow_pd: allowPd,
      program_ids: props.checkoutPrograms?.map((program) => program.id)
    }

    const csrftoken = getCookie('csrftoken')

    const url = `/api/program-constructor/orders/`
    let params: any = {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(body)
    }
    const response = await fetch(url, params)
    if (response.ok) {
      props.onSuccessResponse?.()
      setAllowPd(false)
      e.target.reset()
      props.onSubmit?.()
    }
  }

  const handleClick = (e: React.SyntheticEvent) => {
  }

  const handleCheckboxChange = (e: any) => {
    setAllowPd(e.target.checked)
  }

  const checkboxLabel: any = <span>
    Даю согласие на обработку&nbsp;
    <a
      href='https://cdto.ranepa.ru/media/consent_to_the_processing_of_personal_data.pdf'
      target='_blank'
      rel='noreferrer'
    >
      персональных данных
    </a>
  </span>

  return (
    <div>
      <Stack gap={20}>
        <Heading variant='3'>Заполните заявку</Heading>
        <form onSubmit={handleSubmit}>
          <Stack gap={40}>
            <TextField
              name='name'
              blockElement
              fullWidth
              autoComplete='off'
              placeholder='ФИО'
              required
            />
            <TextField
              name='phone'
              type='phone'
              blockElement
              fullWidth
              required
              autoComplete='off'
              placeholder='Телефон'
            />
            <TextField
              name='email'
              type='email'
              blockElement
              fullWidth
              required
              autoComplete='off'
              placeholder='E-mail'
            />
            <TextField
              name='number'
              type='number'
              blockElement
              fullWidth
              required
              autoComplete='off'
              placeholder='Количество человек на обучении'
              onInput={props.onNumberInput}
            />
            <TextField
              name='organization'
              blockElement
              fullWidth
              required
              autoComplete='off'
              placeholder='Компания'
            />
            <Textarea
              name='comment'
              placeholder='Комментарий'
              rows={5}
            />
            <Checkbox
              name='allow_pd'
              onChange={handleCheckboxChange}
              label={checkboxLabel}
              checked={allowPd}
            />
            <br />
            <Link
              to='https://disk.yandex.ru/i/mRz0zVJIdkThlA'>
                Политика РАНХиГС в отношении обработки персональных данных
            </Link>
            <div>
              <Button
                onClick={handleClick}
                type='submit'
                variant='filled'
                disabled={!allowPd || props.disabled}
              >
                Отправить заявку
              </Button>
            </div>
          </Stack>
        </form>
      </Stack>
    </div>
  )
}

export default OrderFrom
