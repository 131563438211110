import PageSection from 'components/v2/PageSection/PageSection'

// import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Image from 'components/Image/Image'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'

import Card from 'components/Card/Card'

import useMediaQuery from 'common/useMediaQuery'
import nbspacer from 'common/nbspacer'

type WillLearnSectionProps = {
  items: string[];
}

const WillLearnSection = (props: WillLearnSectionProps) => {
  const { isTablet } = useMediaQuery()
  return (
    <PageSection variant='white-icons'>
      <Stack gap={30} orientation='horizontal' tabletOrientation='vertical'>
        <Card bgColor='light-gray' width={isTablet ? '100%' : '60%'}>
          <Stack gap={40}>
            <Heading variant='2'>Чему вы научитесь</Heading>
            <Stack gap={30}>
              {props.items.map((item: any, index: number) => {
                return <Stack key={index} gap={30} orientation='horizontal' alignItems={'center'}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '55px',
                      height: '55px',
                      borderRadius: '50%',
                      flexShrink: 0,
                      background: 'linear-gradient(to right, #047A69, #449791)'
                    }}
                  >
                    <Text
                      variant='text-m-medium'
                      color='white'
                    >
                      {index + 1}
                    </Text>
                  </div>
                  <Text variant='text-m-medium'>{nbspacer(item)}</Text>
                </Stack>
              })}
            </Stack>
          </Stack>
        </Card>
        <div>
          <Image
            src='/static/img/pages/program-registry/page-sections/will-learn-section/section-image.svg'
            width='100%'
            animate
            animationVariant='appear-left'
          />
        </div>
      </Stack>
    </PageSection>
  )
}

export default WillLearnSection
