import React from 'react'
import Button from 'components/Button/Button'
import { linkItems } from './data'
import scrollToId from 'common/scroll-to-id'
import Grid from 'components/Grid/Grid'
import Stack from 'components/Stack/Stack'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import nbspacer from 'common/nbspacer'

type HeaderBlockProps = {
  heading?: string;
}

const HeaderBlock = (props: HeaderBlockProps) => {
  return (
    <Grid
      cols={2}
      tabletCols={1}
      columnGap={40}
      rowGap={40}
    >
      <Stack gap={40}>
        <Heading variant='1'>{nbspacer(props.heading ?? '')}</Heading>
        <Stack orientation='horizontal' gap={20} wrap='wrap'>
          {linkItems.map(item => {
            if (item.url) {
              return <Button
                variant='large'
                key={item.url}
                href={item.url}
              >
                {item.text}
              </Button>
            }
            if (item.anchor) {
              return <Button
                variant='large'
                key={item.anchor}
                onClick={() => scrollToId(item.anchor)}
              >
                {item.text}
              </Button>
            }
          })}
        </Stack>
      </Stack>
      <div>
        <Image animate animationVariant='appear-left' src='static/img/header-section-img.svg' width={'100%'} />
      </div>
    </Grid>
  )
}

export default HeaderBlock
