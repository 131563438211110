import Card from 'components/Card/Card'
import Grid from 'components/Grid/Grid'
import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import PageSection from 'components/v2/PageSection/PageSection'
import React from 'react'

const ClientsSection = () => {
  return (
    <PageSection paddingBottom={0}>
      <Stack gap={40}>
        <Heading variant='1'>Наши клиенты</Heading>

        <Stack gap={20}>
          <Grid
            cols={2}
            tabletCols={1}
            columnGap={20}
            rowGap={20}
          >
            <Grid
              cols={2}
              tabletCols={1}
              columnGap={20}
              rowGap={20}
            >
              <Card bgColor='light-gray'>
                <Stack gap={20} alignItems='center'>
                  <Image
                    src='/static/img/pages/recruiting/digital-transformation-specialists/clients-section/image1.svg'
                    width='100px'
                  />
                  <Heading
                    variant='3'
                    align='center'
                  >
                  Организации государственного сектора
                  </Heading>
                </Stack>
              </Card>
              <Card bgColor='light-gray'>
                <Stack gap={20} alignItems='center'>
                  <Image
                    src='/static/img/pages/recruiting/digital-transformation-specialists/clients-section/image2.svg'
                    width='100px'
                  />
                  <Heading
                    variant='3'
                    align='center'
                  >
                  Крупные торговые и производственные компании
                  </Heading>
                </Stack>
              </Card>
            </Grid>
            <Card bgColor='green'>
              <Stack gap={40} alignItems='center' orientation='horizontal' height={'100%'}>
                <Image
                  src='/static/img/pages/recruiting/digital-transformation-specialists/clients-section/image3.svg'
                  width='100px'
                />
                <Heading
                  variant='3'
                  color='white'
                >
                Работаем во всех регионах России
                </Heading>
              </Stack>
            </Card>
          </Grid>
          <Grid
            cols={2}
            tabletCols={1}
            columnGap={20}
            rowGap={20}
          >
            <Grid
              cols={2}
              tabletCols={1}
              columnGap={20}
              rowGap={20}
            >
              <Card bgColor='light-gray'>
                <Stack gap={20} alignItems='center' justifyContent='center' height='100%'>
                  <Image
                    src='/static/img/pages/recruiting/digital-transformation-specialists/clients-section/image4.svg'
                    width='100px'
                  />
                  <Heading
                    variant='3'
                    align='center'
                  >
                    Digital-агентства
                  </Heading>
                </Stack>
              </Card>
              <Card bgColor='light-gray'>
                <Stack gap={20} alignItems='center' justifyContent='center' height='100%'>
                  <Image
                    src='/static/img/pages/recruiting/digital-transformation-specialists/clients-section/image5.svg'
                    width='100px'
                  />
                  <Heading
                    variant='3'
                    align='center'
                  >
                  ИТ-компании
                  </Heading>
                </Stack>
              </Card>
            </Grid>
            <Card bgColor='green'>
              <Stack gap={40} alignItems='center' orientation='horizontal' height={'100%'}>
                <Image
                  src='/static/img/pages/recruiting/digital-transformation-specialists/clients-section/image6.svg'
                  width='100px'
                />
                <Heading
                  variant='3'
                  color='white'
                >
                  Подбираем от одного специалиста до целых команд
                </Heading>
              </Stack>
            </Card>
          </Grid>
        </Stack>
      </Stack>
    </PageSection>
  )
}

export default ClientsSection
