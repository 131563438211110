import React from 'react'

const header: any = {
  heading: 'Управленческий и HR-консалтинг',
  imageUrl: '/static/img/pages/consulting/header-image.svg',
  text: <>
    <p>Выстраиваем вектор развития. Помогаем сориентироваться в трендах. Формируем стратегическое видение.</p>
    <p>Мы накопили серьезный опыт в консультировании по «цифре» и кадровым вопросам и готовы предложить вам свои знания.</p>
  </>,
  buttonText: 'Заказать консультацию'
}

export { header }
