import { IndicatorData } from 'components/Indicator/Indicator'
import { DotArrowListCardProps } from 'components/DotArrowListCard/DotArrowListCard'
import { NumberListCardProps } from 'components/NumberListCard/NumberListCard'

const headerTitle: string = 'Рекрутинговый центр Минцифры России'

const headerText: any = <>
Наша цель: содействие федеральным органам власти в подборе высококвалифицированных сотрудников в области цифровой трансформации
</>

const headerCardText: any = <>
По поручению вице-премьера Д. Н. Чернышенко в Центре подготовки руководителей и команд цифровой трансформации ВШГУ РАНХиГС в апреле 2020 года создан Центр оценки и кадрового развития специалистов в области цифровой трансформации.
</>

const solveProblemsTitle: any = 'В Центре оценки и кадрового развития специалистов в области цифровой трансформации мы:'

const solveProblemsItems: any = [
  {
    id: 1,
    number: 1,
    name: 'Проводим анализ кадровой обеспеченности ФОИВ',
    description: 'Выявление потребностей в специалистах в области цифровой трансформации способствует корректной оценке уровня обеспеченности ФОИВ ИТ-кадрами.'
  },
  {
    id: 2,
    number: 2,
    name: 'Помогаем в подборе и оценке персонала',
    description: 'Запросы на подбор и оценку персонала дают возможность определить актуальные векторы профессионального развития кадров в области ЦТ.'
  },
  {
    id: 3,
    number: 3,
    name: 'Разрабатываем инструменты оценки компетенций',
    description: 'Эксперты Рекрутингового центра разрабатывают инструменты оценки личностных и профессиональных компетенций специалистов в области цифровой трансформации.'
  },
  {
    id: 4,
    number: 4,
    name: 'Закрываем вакансии',
    description: 'Для назначения на позиции руководителей и специалистов в команды цифровой трансформации ФОИВ предоставляем портфолио лучших кандидатов.'
  },
  {
    id: 5,
    number: 5,
    name: 'Разрабатываем матрицу ролей',
    description: 'Профили ролей ИТ-специалистов в области цифровой трансформации являются одним из наиболее актуальных инструментов при подборе и оценке кандидатов.'
  },
  {
    id: 6,
    number: 6,
    name: 'Формируем и развиваем Кадровый резерв специалистов в области цифровой трансформации',
    description: 'Сообщество специалистов в области цифровой трансформации ежемесячно пополняется новыми участниками. Кадровый резерв предоставляет большие возможности для развития: вебинары и другие активности, развитие профессиональных и личностных компетенций в формате геймификации.'
  }
]

const resultsTitle: string = 'Результаты Рекрутингового центра'

const resultsItems: IndicatorData[] = [
  {
    id: 1,
    value: 1400,
    description: 'участников Кадрового резерва',
    showPlus: true
  },
  {
    id: 2,
    value: 300,
    description: 'закрытых вакансий в ГосИТ',
    showPlus: true
  },
  {
    id: 3,
    value: 700,
    description: 'участников',
    showPlus: true
  },
  {
    id: 4,
    value: 80,
    description: 'регионов',
    showPlus: true
  }
]

const whenRelevantTitle: string = 'В каких случаях наша услуга актуальна?'
const whenRelevantItems: DotArrowListCardProps[] = [
  {
    id: 1,
    description: 'Отсутствует подразделение, ответственное за подбор специалистов внутри организации.'
  },
  {
    id: 2,
    description: 'Планируется передать функцию поиска и подбора специалистов внешнему провайдеру для разделения ответственности и сосредоточения своих сил на более приоритетных задачах.'
  },
  {
    id: 3,
    description: 'Планируется большой набор специалистов, но внутренние ресурсы ограничены.'
  }
]
const whenRelevantBannerUrl: string = '/static/img/pages/recruiting-center/when-relevant-banner.svg'

const regulationsItems: NumberListCardProps[] = [
  {
    id: 1,
    number: 1,
    description: 'Оформление заказчиком заявки на содействие в подборе специалистов'
  },
  {
    id: 2,
    number: 2,
    description: 'Изучение заявки и уточнение требований экспертами Рекрутингового центра'
  },
  {
    id: 3,
    number: 3,
    description: 'Подбор подходящих кандидатов'
  },
  {
    id: 4,
    number: 4,
    description: 'Предоставление заказчику отобранных портфолио кандидатов'
  },
  {
    id: 5,
    number: 5,
    description: 'Организация собеседований с согласованными кандидатами'
  },
  {
    id: 6,
    number: 6,
    description: 'Получение обратной связи, закрытие заявки'
  }
]

const placeRequestUrl: string = 'https://cdto.work/dlja-rabotodatelej/'

export {
  headerTitle,
  headerText,
  headerCardText,
  solveProblemsTitle,
  solveProblemsItems,
  resultsTitle,
  resultsItems,
  whenRelevantTitle,
  whenRelevantItems,
  whenRelevantBannerUrl,
  regulationsItems,
  placeRequestUrl
}
