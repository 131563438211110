import React from 'react'

import Image from 'components/Image/Image'
import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import Text from 'components/v2/Text/Text'
import Card from 'components/Card/Card'

type CourseTargetAudienceCardProps = {
  image: 'checkbox' | 'computer' | 'chip' | 'chart' | 'smartphone';
  animateDelayIndex?: number;
  name?: string;
  description?: string | React.ReactElement;
  style?: React.CSSProperties;
  withoutShadow?: boolean;
}

const CourseTargetAudienceCard = (props: CourseTargetAudienceCardProps) => {
  const getImageUrl = () => {
    switch (props.image) {
      case 'checkbox':
        return '/static/img/components/program-registry/course-target-audience-card/checkbox.svg'
      case 'computer':
        return '/static/img/components/program-registry/course-target-audience-card/computer.svg'
      case 'chip':
        return '/static/img/components/program-registry/course-target-audience-card/chip.svg'
      case 'chart':
        return '/static/img/components/program-registry/course-target-audience-card/chart.svg'
      case 'smartphone':
        return '/static/img/components/program-registry/course-target-audience-card/smartphone.svg'
    }
  }
  return (
    <Card
      style={props.style}
      bgColor='light-gray'
      withoutShadow={props.withoutShadow}
    >
      <Stack alignItems='center'>
        <Image
          src={getImageUrl()}
          width={100}
          animate
          animationVariant='zoom-in'
          animateDelayIndex={props?.animateDelayIndex ?? 0}
        />
        {props.name && <Heading variant='3' color='green' align='center'>{props.name}</Heading>}
        {props.description && <Text variant='text-s' align='center'>{props.description}</Text>}
      </Stack>
    </Card>
  )
}

export default CourseTargetAudienceCard
export type { CourseTargetAudienceCardProps }
