import Stack from 'components/Stack/Stack'
import Heading from 'components/v2/Heading/Heading'
import nbspacer from 'common/nbspacer'
import React from 'react'
import PageSection from 'components/v2/PageSection/PageSection'
import Grid from 'components/Grid/Grid'
import ProgramCard from '../components/ProgramCard'

type Program = {
  name: string;
  duration: string;
  imageSrc: string;
  start: string | null;
  url: string | null;
}

const ProgramsSection1 = () => {
  const programs: Array<Program> = [
    {
      name: 'Цифровая трансформация: ключевые аспекты',
      duration: '60 часов',
      start: null,
      imageSrc: '/static/img/pages/program-registry/program-registry/programs-section1/image1.svg',
      url: 'https://cdto.ranepa.ru/program-registry/from-the-first-steps'
    },
    {
      name: 'Инструменты цифрового развития: проектный, процессный и продуктовый подходы',
      duration: '40 часов',
      start: null,
      imageSrc: '/static/img/pages/program-registry/program-registry/programs-section1/image4.svg',
      url: 'https://cdto.ranepa.ru/program-registry/digital-dev-management'
    },
    {
      name: 'Основы управления данными',
      duration: '60 часов',
      start: null,
      imageSrc: '/static/img/pages/program-registry/program-registry/programs-section1/image6.svg',
      url: 'https://cdto.ranepa.ru/program-registry/data-management'
    }
  ]

  return (
    <PageSection variant='gray'>

      <Stack gap={40}>
        <Heading variant='2'>{nbspacer('Развитие профессионально-технических компетенций')}</Heading>
        <Grid
          columnGap={20}
          rowGap={40}
          cols={4}
          tabletCols={1}
        >
          {programs.map((program: Program, index: number) => {
            return (
              <ProgramCard
                key={index}
                {...program}
              />
            )
          })}
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default ProgramsSection1
