import { useEffect, useState } from 'react'
import PageSectionData from './PageSectionData.d'

const getDataByKey = (itemList: PageSectionData[], key: string) => {
  return itemList.find(item => item.key === key) || { key: key, heading: '', text: '', image_url: '' }
}

const useSectionsData = (keys: string) => {
  const [itemList, setItemList] = useState<PageSectionData[]>([] as PageSectionData[])

  const fetchData = async () => {
    const url = `/api/page-sections/`
    let urlParams = new URLSearchParams(Object.entries({
      keys: keys
    }))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setItemList(data)
    }
    return []
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return itemList
}

export default useSectionsData
export { getDataByKey }
